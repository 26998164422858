import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ListGroup from "react-bootstrap/ListGroup";
import { ListGroupItem, Row, Col } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import Form from "react-bootstrap/Form";
import { MdDeselect, MdSelectAll } from "react-icons/md";
import { GenerateReportBtn, ExpandBtn } from "../common/btn";
import "./appmetricsview.css";
import { API } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";
import * as Utils from "../../../common/utils";
import * as RBACUtils from "../../../common/RBACUtils";

export const AppMetricsView = ({
  user,
  linkIdAppTypeMap,
  setRecentActivity,
  setModal,
  subscription,
  productCat
}) => {
  const [includeNonBilling, setIncludeNonBilling] = useState(true);
  const [cdrRequest, setCdrRequest] = useState(true);

  const [appMetricMap, setAppMetricMap] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [requestStatus, setRequestStatus] = useState("INITIAL");
  const [monthView, setMonthView] = useState(true);
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(yesterday);
  
  const curStage = Utils.getCurrentStage();

  useEffect(() => {
    const roleAttribute = user["attributes"]["custom:role"];
    let roleValue = roleAttribute
      ? JSON.parse(roleAttribute)
      : RBACUtils.getDefaultUserRole();
    Utils.setInSessionStorage(user.username, "userRole", roleValue);
    if (linkIdAppTypeMap) {
      loadInitData();
    }
  }, [linkIdAppTypeMap]); // eslint-disable-line react-hooks/exhaustive-deps

  // API Functions
  const loadMetrics = async (appMetric) => {

    const appSubTypeList = [];

    appSubTypeList.push(...Object.values(linkIdAppTypeMap).flat());
    const alreadyExists = [];

    for (let i = 0; i < productCat.length; i++) {
      const app = productCat[i];
      if (!appSubTypeList.includes(app.applicationType)) {
        continue;
      }
      const metricObjFields = {
        meterName: app.meterName,
        metricName: app.metricName,
        metricCommercialName: app.metricCommercialName,
        calculationTypeName: app.calculationTypeName,
        metricDescription: app.metricDescription,
        forBilling: String(app.metricIsForBilling),
        isCdr: app.bundleBillingTarget === "ECDR" ? "true" : "false",
        showDescription: false,
        checked: false,
      };
      // console.log("indexOf",alreadyExists.indexOf(metricObjFields.metricName));
      if(alreadyExists.includes(metricObjFields.metricName)) continue;
      else if (appMetric[app.applicationType]) {
        appMetric[app.applicationType].metrics.push(metricObjFields);
        alreadyExists.push(metricObjFields.metricName);
      }
    }
    return appMetric;
  };
  const loadApps = (countryCode = null) => {
    return new Promise(async (resolve, reject) => {
      let cachedApps = Utils.fetchFromSessionStorage(
        user.username,
        countryCode ? countryCode + ".apps" : "apps",
        [],
        true
      );
      if (cachedApps.length > 0) {
        return resolve(cachedApps);
      }
      let params = {
        response: false,
        queryStringParameters: {
          stage: curStage,
          path: "applications",
          countryCode: countryCode,
          region: user.pool.userPoolId.split("_")[0],
        },
      };
      await API.get("SspBackendApi", "/catalog", params)
        .then((response) => {
          const rApps = JSON.parse(JSON.parse(response).body);
          if (rApps && rApps.length > 0) {
            Utils.setInSessionStorage(
              user.username,
              countryCode ? countryCode + ".apps" : "apps",
              rApps,
              true
            );
            return resolve(rApps);
          }
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
          reject([]);
        });
    });
  };

  const loadInitData = async () => {
    const appResponse = await loadApps();
    let appList = [];
    const appMetricObj = {};

    // We want the entire object from storage to be used in the UI

    let appTypeList = [];
    appTypeList.push(...Object.values(linkIdAppTypeMap).flat());
    for (let i = 0; i < appResponse.length; i++) {
      Object.keys(linkIdAppTypeMap).forEach((key) => {
        if (linkIdAppTypeMap[key].includes(appResponse[i].applicationType)) {
          appResponse[i].applicationInstanceId = key;
          const newAppMetricMap = appResponse[i];
          appList.push(newAppMetricMap);
          const appType = appResponse[i].applicationType;
          appResponse[i].metrics = [];
          appResponse[i].expanded = false;
          appResponse[i].checked = false;
          appMetricObj[appType] = appResponse[i];
        }
      });
    }
    const toSetAppMetric = await loadMetrics(appMetricObj);

    setAppMetricMap(toSetAppMetric);
  };
  const handleCdrSelect = (value) => {
    setCdrRequest(value);
    setSelectAll(false);
    resetMetric();
  }

  const resetMetric=()=>{
    for (const linkId of Object.keys(linkIdAppTypeMap)){
      const appTypeList = linkIdAppTypeMap[linkId];
      for (const appType of appTypeList){
        const {metrics} = appMetricMap[appType];
        for (const metric of metrics){
          metric.checked=false;
        }
      }
    }
    setAppMetricMap({...appMetricMap});
  }
  const formatDate=(date)=> {

    const strDate = date.toLocaleDateString('en-US'); // mm/dd/yyyy
    const parts = strDate.split("/").map((part) => part.padStart(2, '0'));
    const [month, day, year] = parts;
    return `${year}-${month}-${day}`;

}
  const getUsageDate = async () => {
    try {
      
      setRequestStatus("INFLIGHT");
      
      
      //iterate over linkId
      for (const linkId of Object.keys(linkIdAppTypeMap)){
        const appTypeList = linkIdAppTypeMap[linkId];
        const metricList =[];
        //TODO: change it to function
        for (const appType of appTypeList){
          const {metrics} = appMetricMap[appType];
          for (const metric of metrics){
            if(metric.checked){
              metricList.push(metric.metricName);
            }
          }
        }
        const payload = {
          startDate:formatDate(startDate),
          endDate: formatDate( endDate),
          linkId: linkId,
          subscriptionId: subscription,
          appTypes: appTypeList,
          metrics: metricList,
          stage: Utils.getCurrentStage(),
          cdrRequest: cdrRequest,
        };
        if (metricList.length > 0) {
          const res = await API.post("SspBackendApi", "/generateReport", {
            body: payload,
            queryStringParameters: {
              stage: Utils.getCurrentStage(),
              id: user.username,
              region: user.pool.userPoolId.split("_")[0],
            },
          });
          const requestId = res.requestId;
          payload.requestId = requestId;
          setRecentActivity(payload);
        }
      }

      setRequestStatus("COMPLETED");
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = JSON.stringify(error.response.data.message);

        if (errorMessage.includes("is not authorized")) {
          setRequestStatus("UNAUTHORIZED");
        } else {
          setRequestStatus("FAILED");
        }
      }
    }
  };

  const handleSubmit = async () => {
    await getUsageDate();
  };

  // UI Functions
  const handleExpandApp = (appTypeData) => {
    appTypeData.expanded = !appTypeData.expanded;
    setAppMetricMap({ ...appMetricMap });
  };

  const handleDatePick = (date) => {
    // insures endDate will never be after yesterday
    setStartDate(date[0]);
    if (monthView) {
      const endOfMonth = new Date(
        date[0].getFullYear(),
        date[0].getMonth() + 1,
        0
      );
      if (endOfMonth > yesterday) setEndDate(new Date(yesterday))
      else setEndDate(endOfMonth);
    } else setEndDate(date[1]);
  };

  const showMetricByBilling = (metricObj) => {
    if (includeNonBilling) return true;
    return "true" === metricObj.forBilling; //convert string to boolean
  };

  const showMetricByCdr = (metricObj) => {
    const isMetricCdr = "true" === metricObj.isCdr; //convert string to boolean;
    if (cdrRequest === isMetricCdr) return true;
    else return false;
  };

  const handleSelectAll = () => {
    Object.keys(appMetricMap).forEach((appType) => {
      appMetricMap[appType].checked = !selectAll;
     Object.values(appMetricMap[appType].metrics).forEach((metricObj) => {
        if (showMetricByBilling(metricObj) && showMetricByCdr(metricObj)) metricObj.checked = !selectAll;
      });
    })
    setAppMetricMap({...appMetricMap});
    setSelectAll(!selectAll);
  };



  const handleAppSelect = (app) => {
    app.checked = !app.checked;
    setAppMetricMap({ ...appMetricMap });
    setSelectAll(false);

    // setSelectedApp(app);
  };
  // useEffect(() => {
  //   handleAppSelect(selectedApp);
  // }, [includeNonBilling]);
  
  const handleMetricSelect = (metricObj) => {
    metricObj.checked = !metricObj.checked;
    setAppMetricMap({ ...appMetricMap });
  };
  const handleExpandMetric = (metricObj) => {
    metricObj.showDescription = !metricObj.showDescription;
    setAppMetricMap({ ...appMetricMap });
  };

  // Input validation
  const allFieldsCompleted = () => {
    return (
      startDate &&
      endDate &&
      subscription&&
      Object.values(appMetricMap).some((app) => Object.values(app.metrics).some((metric) => metric.checked)) 
    );
  };

  return (
    <Container fluid className="p-2 mb-4">
      <Row className="g-0 m-0 p-0">
        <Col className="title-wrap d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-end title-settings"></div>
        </Col>
      </Row>
      <div
        className="d-flex align-items-center justify-content-between bg-light py-2 px-2 w-100% rounded"
        style={{ marginTop: "25px", marginBottom: "10px" }}
      >
        <Form.Group
          id="only-billing-metrics-toggle"
          className="d-flex align-items-center"
        >
          <Form.Label className="mb-1 me-2 d-flex align-items-center mt-1">
            Show only billing metrics
          </Form.Label>
          <Form.Check
            id={"includeNonBilling-toggle"}
            type="switch"
            checked={!includeNonBilling}
            onChange={(e) => setIncludeNonBilling(!includeNonBilling)}
            className="cef-switch"
          />
        </Form.Group>
        <Form.Group
          id="only-cdr-toggle"
          className="flex flex-col align-items-center"
        >
          <Form.Check
            type="radio"
            label="CDR"
            name="cdrOptions"
            id="cdr"
            onChange={() => handleCdrSelect(true)}
            checked={cdrRequest}
            className="me-2"
          />
          <Form.Check
            type="radio"
            label="Non-CDR"
            name="cdrOptions"
            id="non-cdr"
            onChange={() => handleCdrSelect(false)}
            checked={!cdrRequest}
          />
        </Form.Group>

        <div
          className="w-auto me-3 d-flex flex-column align-items-baseline justify-content-start"
          title="Reset all selections"
        >
          <small>{selectAll ? <>Unselect All</> : <>Select All</>}</small>
          <div className="reset" onClick={() => handleSelectAll()}>
            {selectAll ? <MdDeselect /> : <MdSelectAll />}
          </div>
        </div>
        <div>
          <Form>
            <Form.Check
              type="radio"
              label="Day"
              name="day-radio-btn"
              id="day-radio-btn"
              value={true}
              checked={!monthView}
              onChange={() => setMonthView(false)}
            />
            <Form.Check
              type="radio"
              label="Month"
              name="month-radio-btn"
              id="month-radio-btn"
              value={true}
              checked={monthView}
              onChange={() => setMonthView(true)}
            />
          </Form>
        </div>

        <div>
          <DatePicker
            className="report-date-picker"
            selected={startDate}
            onChange={handleDatePick}
            dateFormat={"dd/MM/yyyy"}
            startDate={startDate}
            endDate={endDate}
            maxDate={yesterday} // yesterday
            selectsRange
            showMonthYearPicker={monthView}
          />
        </div>
        <GenerateReportBtn
          requestStatus={requestStatus}
          disabled={!allFieldsCompleted()}
          onClick={() => handleSubmit()}
        />
      </div>
      <Row className="g-0 m-0 p-0">
        <Col className="border-0 rounded px-0 py-0 mx-1 bg-white">
          <h4 className="text-center section-title cap-title ">Applications</h4>

          <ListGroup as="ul" className="list-group-light offer-block">
            {Object.values(appMetricMap).map(
              (appMetric) =>
               (
                  <ListGroupItem
                    as="li"
                    // key={key}
                    onClick={() => handleAppSelect(appMetric)}
                    className="app-selected-true"
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                      <input
                        className="form-check-input bundle-check cursor-pointer"
                        type="checkbox"
                        checked={appMetric.checked}
                      />
                      &nbsp;&nbsp;
                      {appMetric.applicationCommercialName}
                      </div>
                      <ExpandBtn
                        color="#d7f0e8"
                        expanded={appMetric.expanded}
                        onClick={() => handleExpandApp(appMetric)}
                      />
                    </div>
                    <div>
                      <Collapse in={appMetric.expanded}>
                        <div id={appMetric.applicationCommercialName}>
                          <p className="mb-0 bg-white px-3 py-2 rounded-none border-t ">
                            Status:{" "}
                            <span className="font-medium">
                              {appMetric.applicationCommercialStatus}
                            </span>
                          </p>

                          {/* Calculation Type: */}
                          <p className="mb-0 bg-white px-3 py-2  border-t border-gray-200 rounded-none">
                            Description:
                            <span style={{ fontWeight: "500" }}>
                              {appMetric.applicationDescription}
                            </span>
                          </p>

                          <div
                            className="pb-1 pe-3 text-end link-secondary"
                            style={{
                              backgroundColor: "#fff",
                              borderRadius: "5px",
                              borderTopLeftRadius: "0px",
                              borderTopRightRadius: "0px",
                              cursor: "pointer",
                              fontSize: "13px",
                            }}
                            onClick={() =>
                              setModal({
                                show: true,
                                type: "app",
                                content: appMetric,
                                title: "",
                              })
                            }
                          >
                            details&raquo;
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </ListGroupItem>
                )
            )}
          </ListGroup>
        </Col>
        <Col
          className="border-0 rounded px-0 py-0 mx-1"
          style={{ backgroundColor: "#fff" }}
        >
          <h4
            className="metric-header"
          >
            Metrics
          </h4>
          {Object.values(appMetricMap).map(
            (appMetricObject) =>
              appMetricObject.checked &&
              appMetricObject.metrics.map(
                (metricObj) =>
                  showMetricByCdr(metricObj) &&
                  showMetricByBilling(metricObj) && (
                    <ListGroupItem
                      as="li"
                      className="metric-card"
                      action
                      key={metricObj.metricName}
                    >
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%'}}>
                        <div>
                      <input
                        className="form-check-input bundle-check"
                        checked={metricObj.checked}
                        id={"meter-" + metricObj.metricName}
                        style={{ cursor: "pointer" }}
                        type="checkbox"
                        onChange={() => handleMetricSelect(metricObj)}
                      />
                      &nbsp;&nbsp;
                      <label
                        className="form-check-label text-truncate"
                        style={{
                          cursor: "pointer",
                          padding: "3px 2px 4px 10px",
                        }}
                        htmlFor={"metric-" + metricObj.metricName}
                        title={`title` + metricObj.metricName}
                      >
                        {/* only for migration */}
                        {metricObj.metricCommercialName || metricObj.metricName}
                      </label>
                      </div>
                      <div
                        className="float-end"
                        style={{
                          cursor: "pointer",
                          color: "#9dceff",
                          fontSize: "22px",
                          marginTop: "-4px",
                        }}
                        onClick={() => {
                          handleExpandMetric(metricObj);
                        }}
                        aria-controls={metricObj.metricName}
                      >
                        <ExpandBtn
                          color="#95a5a6"
                          expanded={metricObj.showDescription}
                        />
                        </div>
                      </div>
                      <Collapse in={metricObj.showDescription}>
                        <div id={metricObj.metricName} className="metric-expand">
                          <p
                            className="mb-0"
                            style={{
                              backgroundColor: "#fff",
                              padding: "8px 12px",
                              borderRadius: "0px",
                              borderTop: "1px solid #f6ecfb",
                            }}
                          >
                            Description:
                            <span style={{ fontWeight: "500" }}>
                              {metricObj.metricDescription}
                            </span>
                          </p>
                          <p
                            className="mb-0"
                            style={{
                              backgroundColor: "#fff",
                              padding: "8px 12px",
                              borderRadius: "0px",
                              borderTop: "1px solid #f6ecfb",
                            }}
                          >
                            Calculation Type:{" "}
                            <span style={{ fontWeight: "500" }}>
                              {metricObj.calculationTypeName}
                            </span>
                          </p>
                          <div
                            className="pb-1 pe-3 text-end link-secondary"
                            style={{
                              backgroundColor: "#fff",
                              borderRadius: "5px",
                              borderTopLeftRadius: "0px",
                              borderTopRightRadius: "0px",
                              cursor: "pointer",
                              fontSize: "13px",
                            }}
                            onClick={() =>
                              setModal({
                                content: metricObj,
                                show: true,
                                type: "metric",
                                title: metricObj.metricName,
                              })
                            }
                          >
                            details&raquo;
                          </div>
                        </div>
                      </Collapse>
                    </ListGroupItem>
                  )
              )
          )}
        </Col>
      </Row>
    </Container>
  );
};

// Prop types
AppMetricsView.propTypes = {
  user: PropTypes.object.isRequired, // required for cognito/API calls
  linkIdAppTypeMap: PropTypes.object.isRequired, // map: linkId:str -> appType:[]
  setRecentActivity: PropTypes.func, // callback function to set recent activity
  setModal: PropTypes.func, // callback function to set modal
};

AppMetricsView.defaultProps = {
  setRecentActivity: () => {},
  setModal: () => {},
};