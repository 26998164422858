/**
 * Default role for the user, if no custom role specified in AWS Cognito.
 * Here is detailed schema for the User role:
 * 
 {
  "role": "USER* | ADMINISTRATOR",
  "modules": [{
    "name": "SUBSCRIPTION",
    "access" : "READ | WRITE*",
    "offers" : "ALL* | [0,1,2,3]",
    "bundles": "ALL* | [0,1,2,3]",
    "fieldCustomization" : "true | false*"
  },
  {
    "name": "CATALOG",
    "level" : "READ*"
  }],
}
**/

const defaultUserRole = {
  role: 'USER',
  modules: [
    {
      name: 'SUBSCRIPTION',
      access: 'NONE',
      offers: 'ALL',
      bundles: 'ALL',
      fieldCustomization: true,
      countryAccess: 'ALL',
      statusAccess: 'ALL',
      notesAdministrator: false,
      usageData:[],
    },
    {
      name: 'CATALOG',
      access: 'READ'
    }
  ],
};

export default defaultUserRole;