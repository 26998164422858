import { SlArrowLeft } from "react-icons/sl";
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";

// openDrawer
function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
class OpenBpmnDrawerBtn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onClick: this.props.onClick,
      offSetTop: this.props.offSetTop,
      overRideIcon: this.props.OverRideIcon,
      location: convertRemToPixels(5),
    };
  }

  componentDidMount() {
    this.setState({ location: this.state.location * this.state.offSetTop });
  }
  render() {
    return (
      <div
        id="openDrawerBtn"
        style={{
          position: "fixed",
          top: `${this.state.location}px`,
          right: "0px",
          zIndex: "1",
        }}
      >
        <div>
          <Button onClick={this.state.onClick} size="large" variant="secondary">
            <SlArrowLeft />
          </Button>
        </div>
      </div>
    );
  }
}
OpenBpmnDrawerBtn.propTypes = {
  OverRideIcon: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  offSetTop: PropTypes.number,
};
OpenBpmnDrawerBtn.defaultProps = {
  // OverRideIcon: <img src={leftSvg} alt="open bpmn drawer" />,
  OverRideIcon: <div></div>,
  offSetTop: 1,
};


export { OpenBpmnDrawerBtn };
