import { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { fetchFromSessionStorage, removeFromSessionStorage } from "../common/utils";

const Redirect = () => {

  const navigate = useNavigate();

  const redirectWithPreSsoUrl = useCallback(() => {
    const preSsoURL = fetchFromSessionStorage('', 'preSsoURL', 'NA');
    console.log(`preSsoUrl in Redirect.js = ${preSsoURL}`)
    if (preSsoURL !== 'NA' && preSsoURL !== '/') {
      removeFromSessionStorage('', 'preSsoURL');
      navigate(preSsoURL);
    } else {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    redirectWithPreSsoUrl();
  }, [redirectWithPreSsoUrl]);

  return (
    <div>Redirecting...</div>
  );
};
export default Redirect;
