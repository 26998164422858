import "./bpmnpage.css";
import "./bpmnviewer.css";
import { withAuthenticator } from "@aws-amplify/ui-react";

import BpmnjsTool from "./BpmnViewer";
import WorkflowTable from "./WorkflowTable/WorkflowTable";
import { MdSearch } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { BpmnInteractive } from "./functions/getApiGateway";
import { useState, useEffect } from "react";
import { MdArrowBack } from "react-icons/md";

import { Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import "../../scss/user_role_management.scss";
import "../../scss/common.scss";
import "../../scss/workflow.scss";

import "json-diff-kit/dist/viewer.css";
import { useNavigate } from "react-router-dom";
import { Amplify } from "aws-amplify";

import "@aws-amplify/ui-react/styles.css";

import LeftSideBar from "../common/LeftSideBar";
import TopHeadingBar from "../common/TopHeadingBar";

import * as Utils from "../../common/utils";
import * as RBACUtils from "../../common/RBACUtils";

import awsExports from "../../aws-exports";

Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true,
};

const Workflows = ({ signOut, user }) => {
  const [cXml, setXml] = useState();
  const [serverResponse, setServerResponse] = useState();
  const [cDesign, setCDesign] = useState();
  const [cIdJsonList, setIdJsonList] = useState();
  const [cJson, setJson] = useState();
  const [cTableInfoList, setTableInfo] = useState([]);
  const [key, setKey] = useState();
  const [loadStatus, setLoadStatus] = useState({ status: false, message: "" });
  const [flowType, setFlowType] = useState("Subscription");
  const [flowId, setFlowId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const roleAttribute = user["attributes"]["custom:role"];
    let roleValue = roleAttribute
      ? JSON.parse(roleAttribute)
      : RBACUtils.getDefaultUserRole();
    Utils.setInSessionStorage(user.username, "userRole", roleValue);
    if (RBACUtils.hasSubscriptionAccess(user.username, "NONE")) {
      navigate("/user/profile");
    } else {
      let params = new URLSearchParams(window.location.search);
      let queryFlow_id = params.get("flow_id");
      let queryFlowType = params.get("flow_type");
      fetchBpmnGraph(queryFlow_id, queryFlowType);
      setFlowId(queryFlow_id);
      setFlowType(
        queryFlowType === "flow_external_id"
          ? "Subscription"
          : queryFlowType === "flow_external_id"
          ? "External2"
          : "FlowId"
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBpmnGraph = async (flow_id, flowType = "flow_id") => {
    if (flow_id) {
      setKey(flow_id);
      setLoadStatus({
        status: true,
        message: `Loading workflow graphs...`,
      });
      try {
        const { design, id_json_list, json, table_info_list, xml } =
          await BpmnInteractive({ flowId: flow_id, flowType });
        setCDesign(design);
        setIdJsonList(id_json_list);
        setJson(json);
        setTableInfo(table_info_list);
        setServerResponse(xml);
        // setXml(xml[flow_id]);
        setLoadStatus({
          status: false,
          message: "Loading workflow graphs...",
        });
      } catch (e) {
        setLoadStatus({
          status: false,
          message: "Loading workflow graphs...",
        });
      }
    }
  };
  const handleSubmit = (value) => {
    if (flowType === "Subscription") fetchBpmnGraph(value, "flow_external_id");
    else if (flowType === "External2")
      fetchBpmnGraph(value, "flow_external_id2");
    else fetchBpmnGraph(value, "flow_id");
  };
  const handleTableSelection = (value) => {
    setXml(serverResponse[value]);
  };

  return (
    <>
      <Container fluid className="p-2 mb-4">
        <Row className="m-0">
          {/* Left sidebar */}
          <Col md={2} className="l-nav">
            <LeftSideBar active="wf" username={user.username} />
          </Col>

          <Col md={10}>
            <Container
              fluid
              data-bs-spy="scroll"
              data-bs-target="#navbar"
              data-bs-offset="0"
              tabIndex="0"
            >
              {/* top heading bar */}
              <TopHeadingBar title="Workflows" user={user} signOut={signOut} />

              <Row>
                <Col>
                  {cXml ? (
                    <>
                      <Button
                        onClick={() => setXml()}
                        variant="secondary"
                        size="sm"
                        className="mb-2"
                        title="Go back to workflow table"
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="me-1 wf-btn-icon">
                            <MdArrowBack />
                          </div>
                          <div>Back to table</div>
                        </div>
                      </Button>
                      <div id="bpmn-js-viewer-scoped">
                        <BpmnjsTool
                          liveFlowInterval={20}
                          data={cXml}
                          jsonInfo={cIdJsonList}
                          json={cJson}
                          liveViewKey={key}
                          design={cDesign}
                          divName="bpmn-js-viewer-scoped"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <Row className="g-0 px-0 pt-0 pb-0 search-bar-wrap">
                        <Col md={7}>
                          <div className="search-bar w-75 d-flex align-items-center justify-content-start">
                            <div className="text w-100">
                              <FloatingLabel label={`Enter ${flowType} here`}>
                                <Form.Control
                                  type="text"
                                  size="md"
                                  id="flowIdentity"
                                  placeholder="Subscription Id"
                                  style={{ borderColor: "#6c757d" }}
                                  value={flowId}
                                  onKeyDown={(e) =>
                                    e.key === "Enter"
                                      ? handleSubmit(e.target.value)
                                      : ""
                                  }
                                />
                              </FloatingLabel>
                            </div>
                            <div className="options">
                              <Dropdown>
                                <Dropdown.Toggle>{flowType}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => setFlowType("Subscription")}
                                  >
                                    Subscription
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => setFlowType("FlowId")}
                                  >
                                    FlowId
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => setFlowType("External2")}
                                  >
                                    External2
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div className="search">
                              <Button
                                variant="secondary"
                                size="md"
                                onClick={() =>
                                  handleSubmit(
                                    document.getElementById("flowIdentity")
                                      .value
                                  )
                                }
                              >
                                <span className="i">
                                  <MdSearch />
                                </span>
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <WorkflowTable
                        rows={cTableInfoList}
                        setDisplayRow={handleTableSelection}
                      />
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      {
        <Modal
          show={loadStatus["status"]}
          size="lg"
          backdrop="static"
          keyboard={false}
          aria-labelledby="loading-modal"
          centered
          className="loading-modal"
        >
          <Modal.Body>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div
                className="spinner-border spinner-border-md"
                role="status"
              ></div>
              <p className="mb-0 mt-1">{loadStatus["message"]}</p>
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  );
};
export default withAuthenticator(Workflows, withAuthenticatorOptions);
