import { Auth } from "aws-amplify";
import { useEffect } from "react";
import awsExports from "../aws-exports";
import envDetails from "../env-details.json";
import { getCurrentEnv, getCurrentStage, setInSessionStorage } from "../common/utils";

const ENV = getCurrentEnv(), STAGE = getCurrentStage();
const SLO_URL = 'https://sso.avaya.com/idp/startSLO.ping?TargetResource=';

const FederatedLogin = () => {

  const buildDomainForEnv = () => {
    switch (ENV) {
      case 'production':
        return `ssp.auth.${awsExports.aws_cognito_region}.amazoncognito.com`;
      default:
        return `${STAGE}.auth.${awsExports.aws_cognito_region}.amazoncognito.com`;
    }
  };

  const isLocalhost = () => {
    return window.location.hostname === 'localhost' || window.location.hostname === '[::1]' || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/);
  };

  const getSloUrl = (url) => {
    return new URL(`${SLO_URL}${url}`)
  }

  const parseURLforEnv = (urlType) => {
    let curEnv = envDetails.filter(e => e.envName === ENV);
    if (curEnv && curEnv.length > 0) {
      return urlType === 'signin' ? isLocalhost() ? `http://localhost:3000/redirect/` : `${curEnv[0].url}redirect/` : 
        urlType === 'signout' ? isLocalhost() ? `http://localhost:3000/` : curEnv[0].ssoEnabled ? getSloUrl(`${curEnv[0].url}`) : `${curEnv[0].url}` : '';
    } else {
      console.error("Please configure you envinronment details in env-details.json before proceeding.");
    }
  };

  const storePreSsoURL = () => {
    const preSsoURL = `${window.location.pathname}${window.location.search}`.trim();
    if (preSsoURL !== '/') {
      setInSessionStorage('', 'preSsoURL', preSsoURL);
    }
  };

  const buildAuthConfig = () => {
    const config = { ...awsExports };
    config['oauth'] = {};
    return {
      ...config,
      oauth: {
        domain: buildDomainForEnv(),
        redirectSignIn: parseURLforEnv('signin'),
        redirectSignOut: parseURLforEnv('signout'),
        responseType: "token",
        scope: ["email", "aws.cognito.signin.user.admin", "profile", "openid"],
      }
    };
  };

  Auth.configure(buildAuthConfig());

  useEffect(() => {
    const doFederatedSignIn = async () => {
      Auth.currentAuthenticatedUser().catch(err => {
        storePreSsoURL();
        // to skip the federated login page, we can pass custom privider as argument as follows
        // Auth.federatedSignIn({customProvider: 'providerName'})
        Auth.federatedSignIn().catch(err => {
          console.error('Error occurred while federated login. details = ' + err);
        })
      });
    };
    doFederatedSignIn();
  }, []);

  return (
    <></>
  );
};
export default FederatedLogin;
