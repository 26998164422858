import { useEffect, useState, useRef } from "react";

import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Modal from 'react-bootstrap/Modal';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { useParams, useNavigate } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { MdSearch, MdTry, MdBrightnessAuto } from 'react-icons/md';
import ReactJson from '@microlink/react-json-view'
import cloneDeep from 'lodash.clonedeep';

import { Amplify, API } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import EntitlementData from "./EntitlementData";
import LeftSideBar from "../common/LeftSideBar";
import SubDropDown from "../common/SubDropDown";

import EnvDetails from '../../env-details.json';
import FieldCategoriesJson from '../../data/FieldCategories.json';
import FieldData from '../../data/FieldData.json';

import '../../scss/common.scss';
import '../../scss/sub.scss';

import awsExports from '../../aws-exports';
import * as Utils from "../../common/utils";
import * as RBACUtils from '../../common/RBACUtils';

Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true
}

const curEnv = Utils.getCurrentEnv();
const curStage = Utils.getCurrentStage();

// const noSubscriptionMessage = "No subscription Id provided. Enter subscription Id to see the details."
const SUB_ERROR_MESSAGE = "No valid bundles found in the subscription, showing only subscription data associated with the subscription."
const MAX_CHIPS = 5;

const ViewSub = ({ signOut, user }) => {

  const navigate = useNavigate();
  const { id } = useParams();

  const isInitDataLoaded = useRef(false);
  const listOfContracts = useRef({});
  let subResponse = useRef({});

  const initSub = { subData: {}, custData: {}, userOffers: [], userBundles: [], bundleData: {}, userApps: [], userCaps: {}, entData: {} };
  const initData = {
    offers: [],
    bundles: [],
    apps: []
  };
  const subTopRowFields = ["provisioningId1", "provisioningId2", "provisioningId3", 'chargePlanId', 'subscriptionName'];
  const fixedBundleFields = ['fixedCount', 'state', 'lastModified'];
  const DEFAULT_ERROR_TOAST = { show: false, type: 'error', message: '' };

  //#region states

  const [subscription, setSubscription] = useState(initSub);
  const [data, setData] = useState(initData);

  const [subPackageSelection, setSubPackageSelection] = useState({ type: 'offer', value: 0, contract: '' });

  const [showPayload, setShowPayload] = useState({ payload: false, response: false });

  const [loadStatus, setLoadStatus] = useState({ status: false, message: '' });
  const [showErrorToast, setShowErrorToast] = useState(DEFAULT_ERROR_TOAST);
  const [toggleChip, setToggleChip] = useState({});

  const [simpleView, setSimpleView] = useState(true);
  const [trialSub, setTrialSub] = useState(false);

  //#endregion

  //#region local static data

  const FieldDataMap = new Map();
  FieldData.forEach(f => {
    FieldDataMap.set(f.id, f);
  });

  const FieldCategories = new Map();
  for (const p in FieldCategoriesJson) {
    FieldCategories.set(p, FieldCategoriesJson[p]);
  }

  const offerMap = new Map();
  const bundleOfferMap = new Map();
  const offerBundleMap = new Map();
  data['offers'].forEach(o => {
    offerMap.set(o.offerId, o);
    o.offerBundles.forEach(b => {
      if (bundleOfferMap.get(b.bundleId) === undefined) {
        bundleOfferMap.set(b.bundleId, o.offerId);
      }
    });
  });
  for (var i = 0; i < data['offers'].length; i++) {
    const tArr = [];
    for (var j = 0; j < data['offers'][i].offerBundles.length; j++) {
      tArr.push(data['offers'][i].offerBundles[j].bundleId);
    }
    offerBundleMap.set(data['offers'][i].offerId, tArr);
  }

  const bundleMap = new Map();
  const applicationMap = new Map();
  const applicationTypeToId = new Map();
  const capabilityMap = new Map();
  data['bundles'].forEach(b => {
    b.bundleCapabilities.forEach(c => {
      if (applicationMap.get(c.applicationId) === undefined) {
        applicationMap.set(c.applicationId, c);
      }
      if (applicationTypeToId.get(c.applicationName) === undefined) {
        applicationTypeToId.set(c.applicationName, c.applicationId);
      }
      if (capabilityMap.get(c.capabilityId) === undefined) {
        capabilityMap.set(c.capabilityId, c);
      }
    });
  });

  data['bundles'].forEach(bundle => {
    bundleMap.set(bundle.bundleId, bundle);
  });

  const pcApplicationMap = new Map();
  data['apps'].forEach((a) => {
    if (pcApplicationMap.get(applicationTypeToId.get(a.applicationName)) === undefined) {
      pcApplicationMap.set(applicationTypeToId.get(a.applicationName), a);
    }
  });

  let bundleOrderFields = ['provisioningId1'];
  subscription['userBundles'].forEach(bId => {
    bundleMap.get(bId).bundleOrderDetails.forEach(f => {
      if (bundleOrderFields.indexOf(f) === -1) {
        bundleOrderFields.push(f);
      }
    });
  });

  //#endregion

  //#region functions

  const handleOfferBundleSelect = (type, value) => {
    if (subPackageSelection['type'] === type && subPackageSelection['value'] === value) {
      setSubPackageSelection({ ...subPackageSelection, type: 'offer', value: 0 });
    } else {
      setSubPackageSelection({ ...subPackageSelection, type: type, value: value });
    }
  }

  const handleContractSelect = (cid) => {
    setSubPackageSelection({ ...subPackageSelection, type: 'offer', value: 0, contract: subPackageSelection['contract'] === cid ? '' : cid });
  };

  const isBundlePartOfSelectedContract = (bid) => {
    return !(subPackageSelection['contract'] !== '' &&
      subscription['bundleData'][bid] &&
      subscription['bundleData'][bid]['provisioningId2'] &&
      subscription['bundleData'][bid]['provisioningId2'] !== subPackageSelection['contract']);
  };

  const shouldShowApp = (appId) => {
    if (subPackageSelection['contract'] !== '' && subPackageSelection['type'] === 'offer' && subPackageSelection['value'] === 0) {
      return subscription['userBundles'].filter(b => isBundlePartOfSelectedContract(b))
        .filter(b => bundleMap.get(b).bundleCapabilities.filter(c => c.applicationId === appId).length > 0).length > 0;
    }

    if (subPackageSelection['type'] === 'offer') {
      if (subPackageSelection['value'] === 0) return true;
      return offerMap.get(subPackageSelection['value']).offerBundles.filter(b => bundleMap.get(b.bundleId).bundleCapabilities.filter(c => c.applicationId === appId).length > 0).length > 0;
    } else if (subPackageSelection['type'] === 'bundle') {
      return bundleMap.get(subPackageSelection['value']).bundleCapabilities.filter(c => c.applicationId === appId).length > 0;
    }
  }

  // const shouldShowCap = (capId) => {
  //   if (subPackageSelection['type'] === 'offer') {
  //     if (subPackageSelection['value'] === 0) return true;
  //     let tarr = [];
  //     offerMap.get(subPackageSelection['value']).offerBundles.forEach(b => bundleMap.get(b.bundleId).bundleCapabilities.forEach(bc => tarr.push(bc.capabilityId)));
  //     return tarr.indexOf(capId) !== -1;
  //   } else if (subPackageSelection['type'] === 'bundle') {
  //     return bundleMap.get(subPackageSelection['value']).bundleCapabilities.filter(bc => bc.capabilityId === capId).length > 0;
  //   }
  // }

  const getFixedCount = (bid, asNumber = true) => {
    if (asNumber)
      return subscription['bundleData'][bid] ? subscription['bundleData'][bid]['fixedCount'].split('.')[0] : "-";
    else
      return subscription['bundleData'][bid] ? subscription['bundleData'][bid]['fixedCount'] : "-";
  };

  const processSubscription = (response, subId) => {
    Utils.setInLocalStorage(user.userName, "subscriptionId", subId);
    let selSysTypes = [], selSvcTypes = [];
    let subData = {}, custData = {};
    let perBundleDataMap = {};
    let dynamicFieldsMap = new Map(), fieldsValueMap = new Map(), isTrialSub = false;

    if (response.sub.Count === 0) {
      setShowErrorToast({ show: true, type: 'error', message: `No subscription found with subscription Id: ${subId}` });
      setLoadStatus({ ...loadStatus, status: false, message: '' });
      window.history.pushState("", "Title", '/sub/view/');
      document.getElementById('subId').value = '';
      return;
    }

    let knownOffers = data['offers'].map(o => o.offerSystemType);
    let knownBundles = data['bundles'].map(o => o.bundleServiceType);
    let uo = [], ub = [], so = [], sb = [];
    let duplicateLineItems = false;

    // Remove AddSubscriptionLineItem from Items when UpdateSubscriptionLineItem is found for the same bundle
    // let tempData = [];
    // for (let i = 0; i < response.sub.Items.length; i++) {
    //   if (tempData.filter(e => e[0] === response.sub.Items[i].serviceType && e[1] !== response.sub.Items[i].command).length > 0) {
    //     console.log('Found subscription with same bundles but different commands.');
    //     let idx = response.sub.Items.findIndex(s => s.serviceType === response.sub.Items[i].serviceType && s.command.toUpperCase() === 'AddSubscriptionLineItem'.toUpperCase());
    //     console.log(`Removing subscription at index ${idx}, and subscription = ${JSON.stringify(response.sub.Items[idx])}.`);
    //     if (idx !== -1) response.sub.Items.splice(idx, 1);
    //   }
    //   if (response.sub.Items[i]) tempData.push([response.sub.Items[i].serviceType, response.sub.Items[i].command]);
    // }

    // console.log(`Processed susbcription = ${JSON.stringify(response.sub.Items)}.`);

    for (let i = 0; i < response.sub.Items.length; i++) {
      if (knownOffers.indexOf(response.sub.Items[i].systemType) === -1)
        uo.push(response.sub.Items[i].systemType);
      if (knownBundles.indexOf(response.sub.Items[i].serviceType) === -1)
        ub.push(response.sub.Items[i].serviceType);
      if (sb.indexOf(response.sub.Items[i].serviceType) !== -1) {
        duplicateLineItems = true;
      }
      sb.push(response.sub.Items[i].serviceType);
      if (so.indexOf(response.sub.Items[i].systemType) === -1) so.push(response.sub.Items[i].systemType);
    }

    if (uo.length > 0 || ub.length > 0) {
      let msg = `Subscription contains unrecognized or outdated offers/bundles: `;
      if (uo.length > 0) msg += `Unknown offers = ${uo}. `;
      if (ub.length > 0) msg += `Unknown bundles = ${ub}. `;
      setShowErrorToast({ show: true, type: 'warn', message: msg });
    }

    if (duplicateLineItems) {
      setShowErrorToast({ show: true, type: 'warn', message: `Duplicate line items found in the subscription. Duplicate service types: ${sb}.` });
    }

    let unAuthOffers = so.filter(sys => !RBACUtils.hasAccessToOffer(user.username, data['offers'].filter(o => o.offerSystemType === sys).map(o => o.offerId)[0]));
    let unauthBundles = sb.filter(svc => !RBACUtils.hasAccessToBundle(user.username, data['bundles'].filter(b => b.bundleServiceType === svc).map(b => b.bundleId)[0]));

    if (unAuthOffers.length > 0 || unauthBundles.length > 0) {
      setShowErrorToast({ show: true, type: 'error', message: `You do not have access to following resources subscription: Offers: ${unAuthOffers.length < 5 ? unAuthOffers : unAuthOffers.length}, bundles: ${unauthBundles.length < 5 ? unauthBundles : unauthBundles.length}` });
      setLoadStatus({ ...loadStatus, status: false, message: '' });
      window.history.pushState("", "Title", '/sub/view');
      document.getElementById('subId').value = '';
      return;
    }

    let alignedSub = [];
    response.sub.Items.forEach(s => {
      let as = {};
      for (let k in s) {
        if (k !== 'systemSpecificData') {
          as[k] = s[k];
          if (!dynamicFieldsMap.get(k)) dynamicFieldsMap.set(k, { isSsd: false, type: 'S' });
        } else {
          let ssd = JSON.parse(s[k]);
          for (let e in ssd) {
            as[e] = ssd[e];
            if (!dynamicFieldsMap.get(e)) dynamicFieldsMap.set(e, { isSsd: true, 'type': 'S' });
          }
        }
      }
      alignedSub.push(as);
      selSysTypes.push(s.systemType);
      selSvcTypes.push(s.serviceType);
    });

    // console.log('alignedSub = ' + JSON.stringify(alignedSub));

    alignedSub.forEach((s, _idx) => {
      Object.keys(s).forEach(k => {
        if (_idx === 0) {
          dynamicFieldsMap.get(k)['type'] = fixedBundleFields.indexOf(k) !== -1 ? 'B' : FieldCategories.get('customer').indexOf(k) !== -1 ? 'C' : 'S';
        } else {
          if (fieldsValueMap.get(k)) {
            // existing field
            if (dynamicFieldsMap.get(k) && dynamicFieldsMap.get(k)['type'] !== 'B' && fieldsValueMap.get(k) !== s[k]) {
              dynamicFieldsMap.get(k)['type'] = 'B';
            }
          } else {
            // new field
            dynamicFieldsMap.get(k)['type'] = 'B';
          }
        }
        fieldsValueMap.set(k, s[k]);
        if (k === 'trial' && s[k] === 'Y') isTrialSub = true;
      });
    });

    let contracts = {};
    alignedSub.forEach(s => {
      for (let k in s) {
        if (dynamicFieldsMap.get(k)) {
          if (dynamicFieldsMap.get(k)['type'] === 'S') {
            subData[k] = s[k];
          } else if (dynamicFieldsMap.get(k)['type'] === 'C') {
            custData[k] = s[k];
          } else if (dynamicFieldsMap.get(k)['type'] === 'B') {
            let o = {};
            if (perBundleDataMap[s['serviceType']]) o = perBundleDataMap[s['serviceType']];
            o[k] = s[k];
            perBundleDataMap[s['serviceType']] = o;
          }
        }
      }
      if (Object.keys(s).indexOf('provisioningId2') !== -1) {
        if (Object.keys(contracts).indexOf(s['provisioningId2']) === -1) {
          contracts[s['provisioningId2']] = {
            'startDate': s['usageStartDate'],
            'endDate': s['usageEndDate']
          }
        } else {
          if (s['usageStartDate'] !== contracts[s['provisioningId2']]['startDate'] || s['usageEndDate'] !== contracts[s['provisioningId2']]['endDate']) {
            console.log('Multiple lineItems found with different start or end dates.');
          }
        }
      }
    });
    listOfContracts.current = contracts;

    // console.log('perBundleDataMap = ' + perBundleDataMap);

    let eOffers = [];
    data['offers'].forEach(o => {
      if (selSysTypes.indexOf(o.offerSystemType) !== -1) {
        eOffers.push(o.offerId);
      }
    });

    let eBundles = [], eApps = [], eCaps = {};
    let perBundleFieldsData = {};
    data['bundles'].forEach(b => {
      if (selSvcTypes.indexOf(b.bundleServiceType) !== -1 &&
        selSysTypes.indexOf(offerMap.get(bundleOfferMap.get(b.bundleId)).offerSystemType) !== -1) {
        eBundles.push(b.bundleId);
        perBundleFieldsData[b.bundleId] = perBundleDataMap[b.bundleServiceType];
        bundleMap.get(b.bundleId).bundleCapabilities.forEach(bc => {
          if (eApps.indexOf(bc.applicationId) === -1) {
            eApps.push(bc.applicationId);
            eCaps[bc.applicationId] = [bc.capabilityId];
          } else {
            if (eCaps[bc.applicationId].indexOf(bc.capabilityId) === -1) {
              eCaps[bc.applicationId].push(bc.capabilityId);
            }
          }
        });
      }
    });

    // console.log('perBundleFieldsData = ' + perBundleFieldsData);

    const subscription = {};
    subscription['subData'] = subData;
    subscription['custData'] = custData;
    subscription['userOffers'] = eOffers;
    subscription['userBundles'] = eBundles;
    subscription['userApps'] = eApps;
    subscription['userCaps'] = eCaps;
    subscription['bundleData'] = perBundleFieldsData;

    let subEntitlementData = {};
    if (response.entitlement.Items.length > 0 && Object.keys(response.entitlement.Items[0]).length > 0) {
      response.entitlement.Items.forEach(e => {
        subEntitlementData[e.applicationType] = e;
      });
    }
    subscription['entData'] = subEntitlementData;

    // console.log('Setting subscription data = ' + JSON.stringify(subscription));

    setLoadStatus({ ...loadStatus, status: false, message: '' });
    setSubscription(cloneDeep(subscription));
    if (isTrialSub) setTrialSub(true);
    subResponse.current = response;
  };

  const loadOffers = () => {
    return new Promise(async (resolve, reject) => {
      let cachedOffers = Utils.fetchFromSessionStorage(user.username, 'offers', [], true);
      if (cachedOffers.length > 0) {
        return resolve(cachedOffers);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': curStage,
          'path': 'offers',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };
      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rOffers = JSON.parse(JSON.parse(response).body);
        if (rOffers && rOffers.length > 0) {
          Utils.setInSessionStorage(user.username, 'offers', rOffers, true);
          return resolve(rOffers);
        }
      }).catch((error) => {
        console.error(JSON.stringify(error));
        reject([]);
      });
    });
  };

  const loadBundles = () => {
    return new Promise(async (resolve, reject) => {
      let cachedBundles = Utils.fetchFromSessionStorage(user.username, 'bundles', [], true);
      if (cachedBundles.length > 0) {
        return resolve(cachedBundles);
      }

      let params = {
        response: false,
        queryStringParameters: {
          'stage': curStage,
          'path': 'bundles',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };

      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rBundles = JSON.parse(JSON.parse(response).body);
        if (rBundles && rBundles.length > 0) {
          Utils.setInSessionStorage(user.username, 'bundles', rBundles, true);
          return resolve(rBundles);
        }
      }).catch((error) => {
        console.error(JSON.stringify(error));
        return reject([]);
      });
    });
  };

  const loadApps = () => {
    return new Promise(async (resolve, reject) => {
      let cachedApps = Utils.fetchFromSessionStorage(user.username, 'apps', [], true);
      if (cachedApps.length > 0) {
        return resolve(cachedApps);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': curStage,
          'path': 'applications',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };
      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rApps = JSON.parse(JSON.parse(response).body);
        if (rApps && rApps.length > 0) {
          Utils.setInSessionStorage(user.username, 'apps', rApps, true);
          return resolve(rApps);
        }
      }).catch((error) => {
        console.error(JSON.stringify(error));
        reject([]);
      });
    });
  };

  const loadInitData = async () => {

    setLoadStatus({ ...loadStatus, status: true, message: 'Loading initial data...' });

    const initDataPromises = [
      loadOffers(),
      loadBundles(),
      loadApps()
    ];
    Promise.all(initDataPromises).then(res => {
      if (res[0].length > 0 && res[1].length > 0 && res[2].length > 0) {
        setData({ ...data, offers: res[0], bundles: res[1], apps: res[2] });
        isInitDataLoaded.current = true;
        setLoadStatus({ ...loadStatus, status: false, message: '' });
      }
    }).catch(error => {
      console.error('An error occurred while fetching initial data: ', error);
    });

  };

  const loadSubscription = async (subId) => {

    if (!subId) return;
    setLoadStatus({ ...loadStatus, status: true, message: 'Loading subscription...' });
    setSubPackageSelection({ ...subPackageSelection, type: 'offer', value: 0 });
    window.history.pushState("", "Title", '/sub/view/' + subId);
    document.getElementById('subId').value = subId;

    let params = {
      response: false,
      queryStringParameters: {
        'stage': curStage,
        'id': subId,
        'region': user.pool.userPoolId.split('_')[0]
      }
    };
    await API.get("SspBackendApi", "/subscription", params).then((response) => {
      processSubscription(response, subId);
    }).catch((error) => {
      console.error(JSON.stringify(error));
    });
  };

  const getBundleOrderDetails = (bid) => {
    let data = {};
    [...new Set([...Object.keys(subscription['bundleData'][bid]), ...bundleMap.get(bid).bundleOrderDetails])].forEach(f => {
      let val = '';
      if (subscription['bundleData'][bid] && subscription['bundleData'][bid][f] !== undefined) {
        val = subscription['bundleData'][bid][f];
      } else if (subscription['custData'][f]) {
        val = subscription['custData'][f];
      } else if (subscription['subData'][f]) {
        val = subscription['subData'][f];
      }
      data[f] = val;
    });
    return data;
  };

  const isVisibleInSimpleView = (field = '', arr = [], bId = -1) => {

    if (!simpleView) return !simpleView;
    let x = false;
    if (field !== '') {
      x = bId === -1 ? bundleOrderFields.indexOf(field) !== -1 : bundleMap.get(bId).bundleOrderDetails.indexOf(field) !== -1;
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (bId === -1 ? bundleOrderFields.indexOf(arr[i]) !== -1 : bundleMap.get(bId).bundleOrderDetails.indexOf(arr[i]) !== -1) {
          x = true;
          break;
        } else {
          continue;
        }
      }
    }
    return !simpleView || x;
  }

  const getSubscriptionResponseforDisplay = () => {
    let response = cloneDeep(subResponse.current);
    if (!response.sub) return response;
    for (let i = 0; i < response.sub.Items.length; i++) {
      response.sub.Items[i]['systemSpecificData'] = JSON.parse(response.sub.Items[i]['systemSpecificData']);
    }
    return response;
  }

  //#endregion

  //#region load catalog information 

  useEffect(() => {
    const roleAttribute = user['attributes']['custom:role'];
    let roleValue = roleAttribute ? JSON.parse(roleAttribute) : RBACUtils.getDefaultUserRole();
    Utils.setInSessionStorage(user.username, 'userRole', roleValue);
    if (RBACUtils.hasSubscriptionAccess(user.username, 'NONE')) {
      navigate("/user/profile");
    } else {
      if (Object.keys(data).filter(k => data[k].length === 0).length > 0) {
        loadInitData();
      } else {
        if (id) loadSubscription(id);
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(data).filter(k => data[k].length > 0).length === Object.keys(data).length && isInitDataLoaded.current) {
      loadSubscription(id);
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  //#endregion


  return (
    <Container fluid className="p-2 mb-4" >
      <Row className='m-0'>
        {/* Left sidebar */}
        <Col md={2} className='l-nav'>
          <LeftSideBar active='vs' username={user.username} />
        </Col>

        {/* Content */}
        <Col md={8}>
          <Container fluid data-bs-spy="scroll" data-bs-target='#navbar' data-bs-offset="0" className="scrollspy-example" tabIndex="0">

            {/* top heading bar */}
            <Row>
              <Col className='title-wrap d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-start title-text'>
                  <div>View subscription</div>
                </div>
                <div className='d-flex align-items-center justify-content-end title-settings'>
                  <div className='me-2'>{user.attributes.email}</div>
                  <Dropdown size='sm' as={ButtonGroup} className='me-2'>
                    <Button variant="outline-secondary" title={`Environment: ${curEnv}, Stage: ${curStage}`}>{EnvDetails.filter(e => e.envName === curEnv)[0].dispName}</Button>
                    <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />
                    <Dropdown.Menu variant='outline-secondary' align='end'>
                      {
                        EnvDetails.map(e =>
                          Utils.includeEnvInDropdown(e.envName) &&
                          <Dropdown.Item title={`Environment: ${e.envName}, Stage: ${e.stageName}`} href={e.url}>{e.dispName}</Dropdown.Item>
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button size="sm" variant="outline-secondary" onClick={signOut}>Signout</Button>
                </div>
              </Col>
            </Row>

            {/* User options */}
            <Row className="g-0 px-0 pt-0 pb-0 uo-area es">
              <Col md={7}>
                <div className='search-bar w-75 d-flex align-items-center justify-content-start'>
                  <div className='text w-100'>
                    <FloatingLabel label="Enter subscription Id">
                      <Form.Control type="text" size='md' id='subId' placeholder="Subscription Id" onKeyDown={(e) => e.key === 'Enter' ? loadSubscription(e.target.value) : ''} />
                    </FloatingLabel>
                  </div>
                  <div className='sbtn'>
                    <Button variant="secondary" size='md' onClick={() => loadSubscription(document.getElementById('subId').value)}><span className='i'><MdSearch /></span></Button>
                  </div>
                </div>
              </Col>
              <Col md={5} className='d-flex align-items-center justify-content-end'>
                <div className='pl me-2'>
                  {
                    Object.keys(subResponse.current).length > 0 &&
                    <Button className='ms-3' size='sm' variant="secondary" onClick={() => setShowPayload({ ...showPayload, response: true })} title='Shows the response received from AWS environment for subscription'>Response</Button>
                  }
                  <Modal size='lg' show={showPayload['response']} onHide={() => setShowPayload({ ...showPayload, response: false })}>
                    <Modal.Header closeButton>
                      <Modal.Title>Subscription response</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 650 }}>
                        <ReactJson src={getSubscriptionResponseforDisplay()} name='Response' iconStyle="triangle" indentWidth={2} collapsed={false} enableClipboard={true} displayObjectSize={false} displayDataTypes={false} quotesOnKeys={false} groupArraysAfterLength={1000} displayArrayKey={false} />
                      </Scrollbars>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" size='sm' onClick={() => setShowPayload({ ...showPayload, response: false })}>Close</Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className='pl me-2'>
                  {
                    Object.keys(subResponse.current).length > 0 &&
                    <ButtonGroup>
                      <ToggleButton size='sm' id="details-view-switch-1" type="checkbox" variant="secondary" checked={simpleView} onChange={(e) => { setSimpleView(!simpleView) }}>Simple view</ToggleButton>
                      <ToggleButton size='sm' id="details-view-switch-2" type="checkbox" variant="secondary" checked={!simpleView} onChange={(e) => { setSimpleView(!simpleView) }}>Full view</ToggleButton>
                    </ButtonGroup>
                  }
                </div>
                <div className='pl'>
                  {/* example here */}
                  <SubDropDown apps={data.apps}  id={subscription['subData']?.['provisioningId1']} region={awsExports.aws_project_region} username={user.username} hideDetails={true} />
                </div>
              </Col>
            </Row>
            {
              /* No subscription found message */
              id && subscription['userOffers'].length === 0 && !loadStatus['status'] &&
              <Row className='mx-0'>
                <Col className='d-flex align-items-center justify-content-start px-0'>
                  <p className='mb-0 py-3 text-danger'>{SUB_ERROR_MESSAGE}</p>
                </Col>
              </Row>
            }
            {
              <Modal show={loadStatus['status']} size="lg" backdrop="static" keyboard={false} aria-labelledby="loading-modal" centered className='loading-modal'>
                <Modal.Body>
                  <div className='d-flex flex-column align-items-center justify-content-center'>
                    <div className="spinner-border spinner-border-md" role="status"></div>
                    <p className='mb-0 mt-1'>{loadStatus['message']}</p>
                  </div>
                </Modal.Body>
              </Modal>
            }
            {
              /* Error toast */
              showErrorToast['show'] &&
              <ToastContainer className="p-3" position={showErrorToast['type'] === 'error' ? 'top-center' : 'top-end'}>
                <Toast onClose={() => setShowErrorToast(DEFAULT_ERROR_TOAST)} show={showErrorToast['show']}>
                  <Toast.Header className={showErrorToast['type'] === 'error' ? 'text-danger' : 'text-warning'} closeButton={true}>
                    <strong className="me-auto">{showErrorToast['type'] === 'error' ? 'Error' : 'Warning'}</strong>
                  </Toast.Header>
                  <Toast.Body>{showErrorToast['message']}</Toast.Body>
                </Toast>
              </ToastContainer>
            }

            {
              Object.keys(subscription['subData']).length > 0 &&
              <>
                <div className='top-content' id='sub-data'>
                  {
                    subscription['custData'] && subscription['custData']['customerName'] &&
                    <div className='cname d-flex align-items-center justify-content-start' title='Customer name'>
                      <div>{subscription['custData']['customerName']}</div>
                    </div>
                  }
                  <div className='sid d-flex align-items-center justify-content-start'>
                    <div className='k'>Subscription ID: #</div>
                    <div className='v'>{subscription['subData']['provisioningId1']}</div>

                    {
                      trialSub &&
                      <div className='text-success ms-3 fs-7'><small><MdTry /> <small>This is trial subscription</small></small></div>
                    }
                  </div>

                  {
                    Object.keys(listOfContracts.current).length > 0 &&
                    <p className='o-d-title mt-0'>List of contracts</p>
                  }

                  {
                    Object.keys(listOfContracts.current).map(k =>
                      <Row className="g-0 m-0 r-f-row mb-2" md={5} key={k}>
                        <Col className='r-f-col d-flex justify-content-start align-items-center'>
                          <div className='r-f-title'>
                            <div className='text-truncate' title='Contract number / ProvisioningId2 (Pid2)'>Contract number</div>
                            <div className='r-f-val text-break'>{k}</div>
                          </div>
                        </Col>
                        <Col className='r-f-col d-flex justify-content-start align-items-center'>
                          <div className='r-f-title'>
                            <div className='text-truncate' title='Contract start date'>Start date</div>
                            <div className='r-f-val text-break'>{listOfContracts.current[k]['startDate']}</div>
                          </div>
                        </Col>
                        <Col className='r-f-col d-flex justify-content-start align-items-center'>
                          <div className='r-f-title'>
                            <div className='text-truncate' title='Contract end date'>End date</div>
                            <div className='r-f-val text-break'>{listOfContracts.current[k]['endDate']}</div>
                          </div>
                        </Col>
                      </Row>
                    )
                  }

                </div>
              </>
            }

            {
              /* subcription data */
              Object.keys(subscription['subData']).length > 0 &&
              <>
                <p className='o-d-title'>Subscription data</p>
                <Row className="g-0 m-0 r-f-row" md={5}>
                  {
                    subTopRowFields.map(f =>
                      !Utils.isEmpty(subscription['subData'][f]) &&
                      isVisibleInSimpleView(f) &&
                      <Col className='r-f-col'>
                        <div className='r-f-title d-flex justify-content-start'>
                          <div className='text-truncate' title={Utils.displayTitle(f)}>{Utils.displayTitle(f)}</div>
                          {
                            bundleOrderFields.indexOf(f) !== -1 &&
                            <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                          }
                        </div>
                        <div className='r-f-val text-break'>{Utils.displayValue(subscription['subData'][f])}</div>
                      </Col>
                    )
                  }
                </Row>
              </>
            }

            <Row className="g-0 m-0 mt-1 r-f-row" md={5}>
              {
                [
                  ...Object.keys(subscription['subData']).filter(f => bundleOrderFields.indexOf(f) !== -1).sort(),
                  ...Object.keys(subscription['subData']).filter(f => bundleOrderFields.indexOf(f) === -1).sort()
                ].map(f =>
                  subTopRowFields.indexOf(f) === -1 &&
                  isVisibleInSimpleView(f) &&
                  <Col className='r-f-col'>
                    <div className='r-f-title d-flex justify-content-start'>
                      <div className='text-truncate' title={Utils.displayTitle(f)}>{Utils.displayTitle(f)}</div>
                      {
                        bundleOrderFields.indexOf(f) !== -1 &&
                        <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                      }
                    </div>
                    <div className='r-f-val text-break'>{Utils.displayValue(subscription['subData'][f])}</div>
                  </Col>
                )
              }
            </Row>

            {/* Customer data */}
            {
              Object.keys(subscription['custData']).length > 0 &&
              <>
                <p className='o-d-title' id='cust-data'>Customer data</p>
                <Row className="g-0 m-0 r-f-row" md={5}>
                  {
                    [
                      ...Object.keys(subscription['custData']).filter(f => bundleOrderFields.indexOf(f) !== -1).sort(),
                      ...Object.keys(subscription['custData']).filter(f => bundleOrderFields.indexOf(f) === -1).sort()
                    ].map(f =>
                      isVisibleInSimpleView(f) &&

                      <Col className='r-f-col'>
                        <div className='r-f-title d-flex justify-content-start'>
                          <div className='text-truncate' title={Utils.displayTitle(f)}>{Utils.displayTitle(f)}</div>
                          {
                            bundleOrderFields.indexOf(f) !== -1 &&
                            <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                          }
                        </div>
                        <div className='r-f-val text-break'>{Utils.displayValue(subscription['custData'][f])}</div>
                      </Col>
                    )
                  }
                </Row>
              </>
            }

            {/* Selected subscription */}
            {
              subscription['userOffers'].length > 0 &&
              <Row className="g-0 px-0 pt-0 pb-0 mt-3 sub-view" id='sel-sub'>
                <p className='mb-1 title view-title'>Subscription package</p>
                <Col md={5} className='sub-view-col' style={{ borderRadius: '8px 0px 0px 8px' }}>
                  <p className='mb-1 title o-b-title'>Contracts</p>
                  <div className='cont-area d-flex align-items-center justify-content-start'>
                    {
                      Object.keys(listOfContracts.current).map(k =>
                        <div className={subPackageSelection['contract'] === k ? 'cid active' : 'cid'} onClick={() => handleContractSelect(k)}>{k}</div>
                      )
                    }
                  </div>
                  <p className='title o-b-title'>Selected offers/bundles</p>
                  <div className='o-b-area'>
                    {
                      subscription['userOffers'].map(o =>
                        offerMap.get(o).offerBundles.filter(b => subscription['userBundles'].indexOf(b.bundleId) !== -1 && isBundlePartOfSelectedContract(b.bundleId)).length > 0 &&
                        <>
                          <div className={(subPackageSelection['type'] === 'offer' && o === subPackageSelection['value']) ? 'offer active' : 'offer'} onClick={() => handleOfferBundleSelect('offer', parseInt(o))}>{offerMap.get(o).offerCommercialName}</div>
                          <div className='bundles d-flex flex-wrap'>
                            {
                              offerMap.get(o).offerBundles.filter(b => subscription['userBundles'].indexOf(b.bundleId) !== -1 && isBundlePartOfSelectedContract(b.bundleId)).map((b, _idx) =>
                                (_idx < MAX_CHIPS || toggleChip[`ob-toggle-${o}`]) &&
                                <div className={['b-chip', 'd-flex', 'align-items-center', 'justify-cotent-start', subPackageSelection['type'] === 'bundle' && b.bundleId === subPackageSelection['value'] ? 'active' : ''].join(' ')} onClick={() => handleOfferBundleSelect('bundle', parseInt(b.bundleId))}>
                                  <div className='b-name text-truncate' title={bundleMap.get(b.bundleId).bundleCommercialName}>{bundleMap.get(b.bundleId).bundleCommercialName}</div>
                                  <div className='b-count' title={`fixedCount: ${getFixedCount(b.bundleId, false)}`}>
                                    {getFixedCount(b.bundleId)}
                                  </div>
                                </div>
                              )
                            }
                            {
                              offerMap.get(o).offerBundles.map(b => b.bundleId).filter(b => subscription['userBundles'].indexOf(b) !== -1).length > MAX_CHIPS &&
                              !toggleChip[`ob-toggle-${o}`] &&
                              <div className='b-chip count' onClick={() => setToggleChip({ ...toggleChip, [`ob-toggle-${o}`]: true })} style={{ cursor: 'pointer' }}>
                                <div className='b-name' title={`Show ${offerMap.get(o).offerBundles.map(b => b.bundleId).filter(b => subscription['userBundles'].indexOf(b) !== -1).length - MAX_CHIPS} more bundles`}>
                                  {`+${offerMap.get(o).offerBundles.map(b => b.bundleId).filter(b => subscription['userBundles'].indexOf(b) !== -1).length - MAX_CHIPS}`}
                                </div>
                              </div>
                            }
                          </div>
                        </>
                      )
                    }
                  </div>


                </Col>
                <Col md={7} className='sub-view-col' style={{ borderRadius: '0px 8px 8px 0px' }}>
                  {
                    subPackageSelection['type'] === 'offer' && subPackageSelection['value'] !== 0 &&
                    <div>
                      <p className='title b-vi-title'>{'Offer: ' + offerMap.get(subPackageSelection['value']).offerCommercialName}</p>
                      <div className='b-i-area'>
                        <Container fluid className='b-i-cont'>
                          <Row className='b-i-row'>
                            <Col className='b-i-col desc'>{offerMap.get(subPackageSelection['value']).offerDescription}</Col>
                          </Row>
                          <Row className='b-i-row'>
                            <Col className='b-i-col'>
                              <span className='k'>System Type:</span>
                              <span className='v'>{offerMap.get(subPackageSelection['value']).offerSystemType}</span>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  }
                  {
                    subPackageSelection['type'] === 'bundle' && subPackageSelection['value'] !== 0 &&
                    <div>
                      <p className='title b-vi-title'>{'Bundle: ' + bundleMap.get(subPackageSelection['value']).bundleCommercialName}</p>
                      <div className='b-i-area'>
                        <Container fluid className='b-i-cont'>
                          <Row className='b-i-row'>
                            <Col className='b-i-col desc text-break'>{bundleMap.get(subPackageSelection['value']).bundleDescription}</Col>
                          </Row>
                          <Row className='b-i-row'>
                            <Col className='b-i-col'>
                              <span className='k'>Service Type:</span>
                              <span className='v text-break'>{bundleMap.get(subPackageSelection['value']).bundleServiceType}</span>
                            </Col>
                          </Row>
                          <Row className='b-i-row'>
                            <Col className='b-i-col text-truncate'>
                              <span className='k'>Material Code:</span>
                              <span className='v' title={bundleMap.get(subPackageSelection['value']).bundleSmartCode}>{bundleMap.get(subPackageSelection['value']).bundleSmartCode}</span>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  }
                  {
                    subPackageSelection['type'] === 'bundle' && subPackageSelection['value'] !== 0 &&
                    <div>
                      <p className='title b-v-title'>Bundle values</p>
                      <div className='b-v-area'>
                        <Container fluid className='b-val-cont'>
                          <Row className='b-val-row' md={3}>
                            {
                              [
                                ...Object.entries(getBundleOrderDetails(subPackageSelection['value'])).filter(f => bundleMap.get(subPackageSelection['value']).bundleOrderDetails.indexOf(f[0]) !== -1).sort((a, b) => a[0].localeCompare(b[0])),
                                ...Object.entries(getBundleOrderDetails(subPackageSelection['value'])).filter(f => bundleMap.get(subPackageSelection['value']).bundleOrderDetails.indexOf(f[0]) === -1).sort((a, b) => a[0].localeCompare(b[0]))
                              ].map(f =>
                                (!simpleView || bundleMap.get(subPackageSelection['value']).bundleOrderDetails.indexOf(f[0]) !== -1) &&
                                <Col className='b-val-col'>
                                  <div className='sub-title b-val-title d-flex justify-content-start'>
                                    <div className='text-truncate' title={Utils.displayTitle(f[0])}>{Utils.displayTitle(f[0])}</div>
                                    {
                                      bundleMap.get(subPackageSelection['value']).bundleOrderDetails.indexOf(f[0]) !== -1 &&
                                      <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                                    }
                                  </div>
                                  <div className='b-val text-break'>{Utils.displayValue(f[1])}</div>
                                </Col>
                              )
                            }
                          </Row>
                        </Container>
                      </div>
                    </div>
                  }
                  <p className='title a-c-title'>Enabled applications</p>
                  <div className='a-c-area'>
                    {
                      subscription['userApps'].map(a =>
                        shouldShowApp(a) &&
                        <div className='a-card' id={'app-' + a}>
                          <div className='app-title d-flex align-items-start justify-content-between'>
                            <div className='app-name'>
                              <div className='d-flex align-items-center justify-content-start'>
                                <div className='app-name-icon'><MdBrightnessAuto /></div>
                                <div className='text-truncate'>{applicationMap.get(a) ? applicationMap.get(a).applicationCommercialName : applicationMap.get(a).applicationName}</div>
                                {
                                  pcApplicationMap.get(a) && pcApplicationMap.get(a).applicationType &&
                                  <div className='app-name-type'>{`(${pcApplicationMap.get(a).applicationType})`}</div>
                                }
                              </div>
                              <div className='app-instance-small text-muted' title={`Application Instance (Datacenter): ${subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['applicationInstanceId'] : ''}`}>
                                {subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['applicationInstanceId'] : ''}
                              </div>
                            </div>
                            <div className={`app-dc d-flex align-items-center justify-content-end ${Utils.parseAppStatus(subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['state'] : '')}`}>
                              <div className='app-status-icon' style={{ margin: '-2px 4px 0px 0px' }} title={Utils.parseAppStatus(subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['state'] : '')}>{Utils.parseAppStatusIcon(subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['state'] : '')}</div>
                              <div className='text-truncate'>{subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['state'] : ''}</div>
                            </div>
                          </div>
                          {
                            pcApplicationMap.get(a) && !subscription['entData'][pcApplicationMap.get(a).applicationType] &&
                            <Container fluid className='p-0 px-1'>
                              <Row className='d-flex align-items-center px-3 py-1'>
                                <Col md={4} className='text-start text-wrap ps-1'>Application type</Col>
                                <Col md={8} className='text-break text-end text-wrap pe-2'>{pcApplicationMap.get(a).applicationType}</Col>
                              </Row>
                              <Row className='d-flex align-items-center px-3 py-1'>
                                <Col md={4} className='text-start text-wrap ps-1'>Application description</Col>
                                <Col md={8} className='text-break text-end text-wrap pe-2'>{pcApplicationMap.get(a).applicationDescription}</Col>
                              </Row>
                            </Container>
                          }
                          {
                            pcApplicationMap.get(a) && subscription['entData'][pcApplicationMap.get(a).applicationType] &&
                            <EntitlementData appEntData={subscription['entData'][pcApplicationMap.get(a).applicationType]} appDesc={pcApplicationMap.get(a).applicationDescription} subId={subscription['subData']['provisioningId1']} username={user.username} />
                          }
                          <div className='cap-heading'>Capabilities</div>
                          <div className='app-caps d-flex flex-wrap'>
                            {
                              subscription['userCaps'][a].map((c, _idx) =>
                                (_idx < MAX_CHIPS || toggleChip[`ac-toggle-${a}`]) &&
                                <OverlayTrigger trigger={['hover', 'focus']} delay={250} placement="auto" rootClose={true} overlay={
                                  <Popover id={'cap-popover-' + c} className='cap-popover'>
                                    <Popover.Header as="h3" className='text-truncate'>{capabilityMap.get(c) ? capabilityMap.get(c).capabilityCommercialName : capabilityMap.get(c).capabilityName}</Popover.Header>
                                    <Popover.Body>{capabilityMap.get(c) ? capabilityMap.get(c).capabilityDescription ? capabilityMap.get(c).capabilityDescription : 'NA' : 'NA'}</Popover.Body>
                                  </Popover>
                                }>
                                  <div className='app-cap'>
                                    {capabilityMap.get(c) ? capabilityMap.get(c).capabilityCommercialName : capabilityMap.get(c).capabilityName}
                                  </div>
                                </OverlayTrigger>
                              )
                            }
                            {
                              subscription['userCaps'][a].length > MAX_CHIPS && !toggleChip[`ac-toggle-${a}`] &&
                              <div className='app-cap' onClick={() => setToggleChip({ ...toggleChip, [`ac-toggle-${a}`]: true })} style={{ cursor: 'pointer' }} title={`Show ${subscription['userCaps'][a].length - MAX_CHIPS} more capabilities`}>{`+${subscription['userCaps'][a].length - MAX_CHIPS}`}</div>
                            }
                          </div>
                        </div>
                      )
                    }
                  </div>
                </Col>
              </Row>
            }
          </Container>
        </Col>

        {/* Right sidebar */}
        <Col md={2}>
          {
            subscription['userOffers'].length !== 0 &&
            <Nav id='navbar' className="flex-column r-nav">
              <p className='mb-1'>On this page</p>
              {
                Object.keys(subscription['subData']).length > 0 &&
                <Nav.Link href="#sub-data" eventKey='sub-data'>Subscription data</Nav.Link>
              }
              {
                Object.keys(subscription['custData']).length > 0 &&
                <Nav.Link href="#cust-data" eventKey='cust-data'>Customer data</Nav.Link>
              }
              {
                subscription['userOffers'].length > 0 &&
                <Nav.Link href="#sel-sub" eventKey='sel-sub'>Subscription package</Nav.Link>
              }
              {
                subscription['userApps'].map(a =>
                  shouldShowApp(a) &&
                  <Nav.Link className='ps-4' style={{ fontSize: '13px' }} href={'#app-' + a} eventKey={'app-' + a}>{applicationMap.get(a) ? applicationMap.get(a).applicationCommercialName : applicationMap.get(a).applicationName}</Nav.Link>
                )
              }
            </Nav>
          }

        </Col>

      </Row>
    </Container>
  );

};

export default withAuthenticator(ViewSub, withAuthenticatorOptions);