import { isProductionEnv } from './utils';
import defaultRole from '../data/default_role'
import defaultProdRole from '../data/default_role_prod'

const defaultUserRole = isProductionEnv() ? defaultProdRole : defaultRole;

/**
 * Parses a user string into a User object, applying default values where necessary.
 * 
 * @param {string} userRoleString - The user string to parse.
 * @returns {User} The parsed User object.
 * 
 * TODO : Add validations to the fields.
 */
export default function parseUserRole({ role: userRole = defaultUserRole.role, modules: userModules = defaultUserRole.modules } = defaultUserRole) {
  let parsedUserRole = userRole || defaultUserRole.role;
  const defaultModuleMap = new Map(defaultUserRole.modules.map(m => [m.name, m]));
  const parsedModules = userModules.map(m => {
    const defaultModule = defaultModuleMap.get(m.name);
    if (!defaultModule) return null;
    const parsedModule = { ...m };
    parsedModule.access = parsedModule.access || defaultModule.access;
    if (m.name === 'SUBSCRIPTION') {
      parsedModule.offers = parsedModule.offers || defaultModule.offers;
      parsedModule.bundles = parsedModule.bundles || defaultModule.bundles;
      parsedModule.fieldCustomization = parsedModule.fieldCustomization ?? defaultModule.fieldCustomization;
      parsedModule.countryAccess = parsedModule.countryAccess || defaultModule.countryAccess;
      parsedModule.statusAccess = parsedModule.statusAccess || defaultModule.statusAccess;
      parsedModule.usageData = parsedModule.usageData || defaultModule.usageData;
    }
    return parsedModule;
  }).filter(m => m !== null);
  return { role: parsedUserRole, modules: parsedModules };
}
