import React, { useState, useMemo, useEffect } from "react";

import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';

import '../../scss/user_role_management.scss'

import { useNavigate } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { useTable, usePagination, useSortBy, useExpanded, useGlobalFilter } from 'react-table';
import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown, TiPlus } from 'react-icons/ti';

import 'json-diff-kit/dist/viewer.css';

import { Amplify, API, Auth } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import LeftSideBar from "../common/LeftSideBar";

import EnvDetails from '../../env-details.json';
import * as Utils from '../../common/utils';
import * as RBACUtils from '../../common/RBACUtils';

import '../../scss/sub.scss';

import awsExports from '../../aws-exports';

Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true
}

const ENV = Utils.getCurrentEnv(), STAGE = Utils.getCurrentStage();

const UserManagement = ({ signOut, user }) => {

  return (
    <Container fluid className="p-2 mb-4">
      <Row className='m-0'>

        {/* Left sidebar */}
        <Col md={2} className='l-nav'>
          <LeftSideBar active='um' username={user.username} />
        </Col>

        <Col md={10}>
          <Container fluid data-bs-spy="scroll" data-bs-target='#navbar' data-bs-offset="0" tabIndex="0">
            {/* top heading bar */}
            <Row>
              <Col className='title-wrap d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-start title-text'>
                  <div>User management</div>
                </div>
                <div className='d-flex align-items-center justify-content-end title-settings'>
                  <div className='me-2'>{user.attributes.email}</div>
                  <Dropdown size='sm' as={ButtonGroup} className='me-2'>
                    <Button variant="outline-secondary" title={`Environment: ${ENV}, Stage: ${STAGE}`}>{EnvDetails.filter(e => e.envName === ENV)[0].dispName}</Button>
                    <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />
                    <Dropdown.Menu variant='outline-secondary' align='end'>
                      {
                        EnvDetails.map(e =>
                          Utils.includeEnvInDropdown(e.envName) &&
                          <Dropdown.Item key={`${e.envName}-${e.stateName}`} title={`Environment: ${e.envName}, Stage: ${e.stageName}`} href={e.url}>{e.dispName}</Dropdown.Item>
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button size="sm" variant="outline-secondary" onClick={() => signOut()}>Signout</Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <UserRoleManagement user={user} />
              </Col>
            </Row>
          </Container>
        </Col>

      </Row>
    </Container>
  );
}

const UserAccessCheckbox = ({ checked, username, fetchAllUsersFn }) => {

  const [isProcessing, setProcessing] = useState('');

  const handleAccessChange = async (e, username) => {
    setProcessing(username);
    let params = {
      body: {
        "username": username,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    };
    API.post('AdminQueries', !e.target.checked ? '/enableUser' : '/disableUser', params).then(response => {
      fetchAllUsersFn().then(() => setProcessing(''));
    });
  };

  return (
    <div className='d-flex aliagn-items-center justify-content-start'>
      {
        <Form.Check type="checkbox" variant='secondary' checked={checked} onChange={(e) => handleAccessChange(e, username)} disabled={isProcessing === username} />
      }
      {
        isProcessing === username &&
        <div className='ms-2'>
          <Spinner size='sm' variant='secondary' animation="border" role="status" />
        </div>
      }
    </div>
  );

};

const UserRoleManagement = ({ user }) => {

  const [users, setUsers] = useState([]);
  // const [isProcessing, setProcessing] = useState('');
  const [createUser, setCreateUser] = useState({ show: false, email: '', alert: '' });
  const [filterInput, setFilterInput] = useState('');
  const [debouncedFilterInput] = useDebounce(filterInput, 500);

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div>#</div>
        ),
        accessor: 'id',
        Cell: ({ value }) => {
          return <div>{value}</div>
        },
        width: '5%'
      },
      {
        Header: () => (
          <div>Access</div>
        ),
        accessor: 'access',
        Cell: ({ value, row }) => {
          return <UserAccessCheckbox checked={Boolean(value)} username={row.original['username']} fetchAllUsersFn={getAllEnvironmentUsers} />
        },
        width: '5%',
        disableSortBy: true
      },
      {
        Header: () => (
          <div>Username</div>
        ),
        accessor: 'username',
        Cell: ({ value }) => {
          return <div className='text-truncate' title={value}>{value}</div>
        },
        width: '25%'
      },
      {
        Header: () => (
          <div>Email</div>
        ),
        accessor: 'email',
        Cell: ({ value }) => {
          return <div className='text-truncate' title={value}>{value}</div>
        },
        width: '15%'
      },
      {
        Header: () => (
          <div>Verified</div>
        ),
        accessor: 'verified',
        Cell: ({ value }) => {
          let is_verified = Boolean(value) ? 'Yes' : 'No';
          return <div title={`User has ${!Boolean(value) ? 'not' : ''} verified the account`}>{is_verified}</div>
        },
        width: '15%',
        disableSortBy: true
      },
      {
        Header: () => (
          <div>Status</div>
        ),
        accessor: 'status',
        Cell: ({ value }) => {
          return <div title={value}>{value}</div>
        },
        width: '15%'
      },
      {
        Header: () => (
          <div>Role</div>
        ),
        accessor: 'role',
        Cell: ({ value, row }) => {
          return <DisplayUserRole userRole={value} username={row.original['username']} currentUsername={user.username} updateUsersFn={getAllEnvironmentUsers} />
        },
        width: '15%',
        disableSortBy: true
      },
    ],
    []
  ); // eslint-disable-line react-hooks/exhaustive-deps

  //#region functions 

  const getAllEnvironmentUsers = async () => {
    let init = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    };
    let res = await API.get('AdminQueries', '/listUsers', init);
    if (res.length > 0) {
      let users = [];
      res.forEach((u, _idx) => {
        let user = {};
        user['id'] = _idx + 1;
        user['username'] = u.Username;
        user['access'] = u.Enabled;
        user['email'] = getAttr(u, 'email');
        user['verified'] = getAttr(u, 'email_verified');
        user['status'] = u.UserStatus;
        user['role'] = getAttr(u, 'custom:role', RBACUtils.getDefaultUserRole());
        users.push(user);
      });
      setUsers(users);
    };
  };

  const getAttr = (user, name, def = '') => {
    if (!user['Attributes']) return def
    let filtered_op = user['Attributes'].filter(a => a.Name.toUpperCase() === name.toUpperCase());
    if (filtered_op.length > 0) return filtered_op[0].Value;
    else return def;
  }

  const initActivities = () => {
    if (!RBACUtils.hasRole(user.username, 'ADMINISTRATOR')) {
      //window.alert(`User does not have access to create subscription.`);
      navigate("/user/profile");
    } else {
      getAllEnvironmentUsers();
    }
  };

  const handleUserEmail = (email) => {
    // check if email is already present in users
    let filteredUsers = users.filter(u => getAttr(u, 'email') === email);
    if (filteredUsers.length > 0) {
      setCreateUser({ ...createUser, alert: 'error::Email already exists' });
    } else {
      setCreateUser({ ...createUser, email: email });
    }
  };

  const createNewUser = async () => {
    setCreateUser({ ...createUser, alert: 'info::Creating user ...' });
    if (!Utils.validateEmail(createUser['email'])) {
      setCreateUser({ ...createUser, alert: 'error::Enter correct email' });
      return;
    }
    let params = {
      body: {
        "email": createUser['email'],
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    };
    API.post('AdminQueries', '/createUser', params).then(response => {
      getAllEnvironmentUsers().then(() => setCreateUser({ ...createUser, show: false, email: '', alert: '' }));
    });

  };

  const getAlertVariant = () => {
    let alert = createUser['alert'];
    if (alert === '') return 'secondary';
    else if (alert.split('::')[0] === 'error') return 'danger';
    else if (alert.split('::')[0] === 'info') return 'info';
    else if (alert.split('::')[0] === 'success') return 'success';
    else return 'secondary';
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilterInput(value);
    setGlobalFilter(value); // Set the global filter value
  };

  //#endregion

  //#region react table

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: users
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  //#endregion

  //#region react effects 

  useEffect(() => {
    const roleAttribute = user['attributes']['custom:role'];
    let roleValue = roleAttribute ? JSON.parse(roleAttribute) : RBACUtils.getDefaultUserRole();
    Utils.setInSessionStorage(user.username, 'userRole', roleValue);
    initActivities();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  //#endregion

  return (
    <Container fluid className='user-details-container p-0'>
      <Row className='m-0 p-0'>
        <Col className='p-0'>

          <div className='d-flex align-item-center justify-content-between'>
            <div>
              <div className='d-flex align-items-center justify-content-start m-0 mt-2'>
                <h4 className='m-0 me-2'>Provisioned users</h4>
                {
                  users.length === 0 &&
                  <Spinner size='sm' variant='secondary' animation="border" role="status" />
                }
              </div>
              <p className='text-muted mb-2'><small>Click on the role to view or change user role details.</small></p>
            </div>
            <div className='d-flex align-items-center justify-content-end'>
              <div className='me-2'>
                <div className='add-user-btn d-flex align-items-center justify-content-start' onClick={() => setCreateUser({ ...createUser, show: true })}>
                  <div className='add-user-btn-icon'><TiPlus /></div>
                  <div className='text'>Add user</div>
                </div>
                <Modal size='md' show={createUser['show']} onHide={() => { setCreateUser({ show: false, email: '', alert: '' }) }} keyboard={true} backdrop>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <div>Add new user</div>
                      <div className='text-muted' style={{ fontSize: '14px', fontWeight: 'normal' }}>This will add new user in the environment.</div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className='p-2'>
                    <Container fluid className='p-1'>
                      <Row className='m-0'>
                        <Col className='p-0'>
                          <Alert variant={getAlertVariant()} show={createUser['alert'] !== '' && createUser['alert'].split('::').length > 1} onClose={() => setCreateUser({ ...createUser, alert: '' })} dismissible={createUser['alert'].split('::')[0] !== 'info'}>
                            <div className='d-flex align-items-center justify-content-start'>
                              {
                                createUser['alert'].split('::')[0] === 'info' &&
                                <Spinner size='sm' animation="border" className='me-2' role="status"></Spinner>
                              }
                              <div>{createUser['alert'].split('::')[1]}</div>
                            </div>
                          </Alert>
                        </Col>
                      </Row>
                      <Row className='m-0'>
                        <Col className='p-0 ps-2'>
                          <Form.Group className="mb-0 w-100 py-2" controlId="email">
                            <Form.Label className='me-2 mb-1'>Email:</Form.Label>
                            <Form.Control id='new-user-email-id' type="text" placeholder="Enter email" value={createUser['email']} onChange={(e) => handleUserEmail(e.target.value)} />
                            <Form.Text className="text-muted d-block">Enter email address of the new user you want to add.</Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" size='sm' disabled={createUser['email'] === ''} onClick={() => createNewUser()}>Ok</Button>
                    <Button variant="secondary" size='sm' onClick={() => setCreateUser({ show: false, email: '', alert: '' })}>Cancel</Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <div>
                <Form.Control id='global-filter-input-id' size='sm' type="text" value={filterInput} placeholder="Enter text to search" onChange={(e) => handleFilterChange(e)} />
              </div>
            </div>
          </div>


          <Table {...getTableProps()} className='table'>
            <thead className="thead-dark">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className='align-middle'>
                  {headerGroup.headers.map((column, index) => (
                    <th key={index} {...column.getHeaderProps({
                      className: column.collapse ? 'collapse px-1' : 'px-1',
                      style: { width: column.width }
                    })}>
                      <div className="d-flex align-items-center justify-content-between" onClick={() => { if (!column.disableSortBy) column.toggleSortBy() }} style={{ cursor: 'pointer' }}>
                        <div>
                          <span>{column.render('Header')}</span>
                        </div>
                        <div>
                          {
                            !column.disableSortBy &&
                            <Button variant="link" className="p-0" title={`Sort by ${column.id}`} style={{ color: '#666' }}>
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TiArrowSortedDown />
                                  ) : (
                                    <TiArrowSortedUp />
                                  )
                                ) : (
                                  <>
                                    <TiArrowUnsorted className="text-muted" />
                                  </>
                                )}
                              </span>
                            </Button>
                          }
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className='align-middle'>
                    {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps({
                          className: cell.column.collapse ? 'collapse px-1' : 'px-1',
                          style: { width: cell.column.width }
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="d-flex align-items-center justify-content-start">
              <ButtonGroup size="sm" className='me-2'>
                <Button variant="secondary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>First</Button>
                <Button variant="secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
                <Button variant="secondary" onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
                <Button variant="secondary" onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>Last</Button>
              </ButtonGroup>
              <div className='me-2'>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </div>
            </div>
            <div>
              <Dropdown className='pagesize-dropdown'>
                <Dropdown.Toggle variant="secondary" id="page-size-dropdown" size="sm">Show {pageSize}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {[10, 15, 20].map(pageSizeOption => (
                    <Dropdown.Item
                      key={pageSizeOption}
                      active={pageSizeOption === pageSize}
                      onClick={() => setPageSize(pageSizeOption)}
                    >
                      Show {pageSizeOption}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
};

const DisplayUserRole = ({ userRole, username, currentUsername, updateUsersFn }) => {

  let role1 = (typeof userRole === 'string' || userRole instanceof String) ? JSON.parse(userRole) : userRole;

  const defaultAlert = { show: false, message: 'Validations failed!', spinner: false, type: 'danger' }

  const [showRoleModal, setShowRoleModal] = useState(false);
  const [alert, setAlert] = useState(defaultAlert);
  const [role, setRole] = useState(role1);

  useEffect(() => {
    let role1 = (typeof userRole === 'string' || userRole instanceof String) ? JSON.parse(userRole) : userRole;
    setRole(role1);
  }, [userRole]);


  //#region Functions

  const parseModule = (moduleName, customRole = null) => {
    return customRole === null ?
      role['modules'].filter(m => m.name === moduleName)[0] :
      customRole['modules'].filter(m => m.name === moduleName)[0];
  };

  const parseUserPrimaryRole = (customRole = null) => {
    return customRole === null ?
      role['role'] :
      customRole['role'];
  };

  const parseSubAccess = (customRole = null) => {
    return parseModule('SUBSCRIPTION', customRole)['access'];
  };

  const parseSubOffers = (customRole = null) => {
    return parseModule('SUBSCRIPTION', customRole)['offers'];
  };

  const parseSubBundles = (customRole = null) => {
    return parseModule('SUBSCRIPTION', customRole)['bundles'];
  };

  const parseSubUsageData = (customRole = null) => {
    return parseModule('SUBSCRIPTION', customRole)['usageData'];
  };

  const parseSubFieldCustomizations = (customRole = null) => {
    return parseModule('SUBSCRIPTION', customRole)['fieldCustomization'];
  };

  const parseSubNotesAdministrator = (customRole = null) => {
    return parseModule('SUBSCRIPTION', customRole)['notesAdministrator'];
  };

  const parseCatAccess = (customRole = null) => {
    return parseModule('CATALOG', customRole)['access'];
  };

  const parseOriginalOffers = () => {
    let origOffers = parseModule('SUBSCRIPTION', role)['offers'];
    return origOffers !== 'ALL' ? origOffers : [];
  }

  const parseOriginalBundles = () => {
    let origBundles = parseModule('SUBSCRIPTION', role)['bundles'];
    return origBundles !== 'ALL' ? origBundles : [];
  }

  const parseOriginalUsageData = () => {
    let origUsageData = parseModule('SUBSCRIPTION', role)['usageData'];
    return origUsageData!=='ALL' ? origUsageData : [];
  }

  const setUserPrimaryRole = (value) => {
    let customRole = { ...role };
    if (value === 'ADMINISTRATOR') {
      parseModule('SUBSCRIPTION', customRole)['access'] = 'WRITE';
      parseModule('SUBSCRIPTION', customRole)['offers'] = 'ALL';
      parseModule('SUBSCRIPTION', customRole)['bundles'] = 'ALL';
      parseModule('SUBSCRIPTION', customRole)['fieldCustomization'] = true;
      parseModule('SUBSCRIPTION', customRole)['notesAdministrator'] = true;
    }
    customRole['role'] = value;
    updateRole(customRole);
  };

  const setSubAccess = (value) => {
    let customRole = { ...role };
    parseModule('SUBSCRIPTION', customRole)['access'] = value;
    updateRole(customRole);
  };

  const setSubOffers = (value) => {
    let customRole = { ...role };
    parseModule('SUBSCRIPTION', customRole)['offers'] = value;
    let oldBundles = parseModule('SUBSCRIPTION', customRole)['bundles'];
    if (value !== 'ALL' && oldBundles !== 'ALL') {
      let newBundles = oldBundles.filter(b => offers.filter(o => value.indexOf(o.offerId) !== -1).map(o => o.offerBundles.map(ob => ob.bundleId)).flat().indexOf(b) !== -1);
      if (oldBundles.length !== newBundles.length || !oldBundles.sort().every((v, i) => v === newBundles.sort()[i])) {
        parseModule('SUBSCRIPTION', customRole)['bundles'] = newBundles;
      }
    }
    updateRole(customRole);
  };

  const setSubBundles = (value) => {
    let customRole = { ...role };
    parseModule('SUBSCRIPTION', customRole)['bundles'] = value;
    updateRole(customRole);
  };

  const setSubUsageData = (value) => {
    let customRole = { ...role };
    parseModule('SUBSCRIPTION', customRole)['usageData'] = value;
    updateRole(customRole);
  }


  const setSubFieldCustomizations = (value) => {
    let customRole = { ...role };
    parseModule('SUBSCRIPTION', customRole)['fieldCustomization'] = value;
    updateRole(customRole);
  };

  const setSubNotesAdministrator = (value) => {
    let customRole = { ...role };
    parseModule('SUBSCRIPTION', customRole)['notesAdministrator'] = value;
    updateRole(customRole);
  };

  const updateRole = (customRole) => {
    let response = validateRole(customRole);
    if (response['valid']) {
      setAlert({ ...defaultAlert });
      setRole(customRole);
    } else {
      setAlert({ ...alert, show: true, message: response['errorMessage'] });
    }
  };

  const validateRole = (customRole) => {

    // validations for primary role
    if (['USER', 'ADMINISTRATOR'].indexOf(parseUserPrimaryRole(customRole)) === -1) {
      return { valid: false, errorMessage: `Role can not set be other than 'USER' or 'ADMINISTRATOR'.` };
    }

    // validation for sub - access
    if (['READ', 'WRITE', 'NONE'].indexOf(parseSubAccess(customRole)) === -1) {
      return { valid: false, errorMessage: `Access can not set be other than 'READ' or 'WRITE'.` };
    }

    // validation for sub - offers
    if (!(parseSubOffers(customRole) === 'ALL' || Array.isArray(parseSubOffers(customRole)))) {
      return { valid: false, errorMessage: `Offers can be set either 'ALL' or Offer Id array.` };
    }

    if (Array.isArray(parseSubOffers(customRole)) && parseSubOffers(customRole).length === 0) {
      return { valid: false, errorMessage: `Select at least one offer or select all offers.` };
    }

    // validation for sub - bundles
    if (!(parseSubBundles(customRole) === 'ALL' || Array.isArray(parseSubBundles(customRole)))) {
      return { valid: false, errorMessage: `Bundles can be set either 'ALL' or Bundle Id array.` };
    }

    if (Array.isArray(parseSubBundles(customRole)) && parseSubBundles(customRole).length === 0) {
      return { valid: false, errorMessage: `Select at least one bundle or select all bundles.` };
    }

    // it is required to select at least one bundle from selected offers
    if (parseSubOffers(customRole) !== 'ALL' && parseSubBundles(customRole) !== 'ALL') {
      let isOfferPresentWithoutBundle = offers.filter(o => parseSubOffers(customRole).indexOf(o.offerId) !== -1).filter(o => o.offerBundles.map(ob => ob.bundleId).filter(bid => parseSubBundles(customRole).indexOf(bid) !== -1).length === 0).length > 0;
      if (isOfferPresentWithoutBundle) {
        return { valid: false, errorMessage: `Select at least one bundle from selected offers.` };
      }
    }

    // validation for cat - access
    if (parseCatAccess(customRole) !== 'READ') {
      return { valid: false, errorMessage: `Access for 'CATALOG' module can be only set to 'READ'.` };
    }

    return { valid: true, errorMessage: '' };
  };

  const saveUserRole = async () => {
    setAlert({ ...alert, show: true, message: 'Saving role details ...', spinner: true, type: 'info' });
    let customroleAttr = [{ 'Name': 'custom:role', 'Value': JSON.stringify(role) }];
    let init = {
      body: {
        "username": username,
        "attributes": customroleAttr
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    };
    API.post('AdminQueries', '/updateUserAttributes', init).then(response => {
      setShowRoleModal(false);
      updateUsersFn();
    });
  };

  const showUserRoleDetails = () => {
    setAlert({ ...defaultAlert });
    setShowRoleModal(true);
  };

  //#endregion

  const offers = Utils.fetchFromSessionStorage(currentUsername, 'offers', [], true);
  const bundles = Utils.fetchFromSessionStorage(currentUsername, 'bundles', [], true);

  const bundleMap = new Map();
  bundles.forEach(b => bundleMap.set(b.bundleId, b));

  let displayBundles = [];
  offers.forEach(o => {
    o.offerBundles.forEach(b => {
      if (parseSubOffers() === 'ALL' || (Array.isArray(parseSubOffers()) && parseSubOffers().indexOf(o.offerId) !== -1))
        displayBundles.push({ name: bundleMap.get(b.bundleId).bundleCommercialName, id: b.bundleId, offer: o.offerCommercialName })
    });
  });

  return (
    <>
      <div title='Click to see details or change permissions' className={parseUserPrimaryRole() === 'USER' ? 'role u' : 'role a'} onClick={() => showUserRoleDetails()} >{parseUserPrimaryRole()}</div>
      <Modal size='lg' show={showRoleModal} onHide={() => setShowRoleModal(false)} keyboard={false} backdrop>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>Role details</div>
            <div className='text-muted' style={{ fontSize: '14px', fontWeight: 'normal' }}>You can view and change roles and permissions assigned to the user.</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row className='m-0'>
              <Col className='p-0'>
                <Alert variant={alert['type']} show={alert['show']} onClose={() => setAlert({ ...defaultAlert })}>
                  <div className='d-flex align-items-center justify-content-start'>
                    {
                      alert['spinner'] &&
                      <Spinner size='sm' animation="border" className='me-2' role="status"></Spinner>
                    }
                    <div>{alert['message']}</div>
                  </div>
                </Alert>
              </Col>
            </Row>
            <Row className='m-0'>
              <Col className='px-0 py-1'>
                <Form.Group className="mb-0 w-100 py-2" controlId="primary-role">
                  <div className='d-flex align-items-center justify-content-start'>
                    <Form.Label className='me-2 mb-1'>Role:</Form.Label>
                    <Form.Check inline type="radio" onChange={() => setUserPrimaryRole('USER')} checked={parseUserPrimaryRole() === 'USER'} className='me-1' label='User' name='primary-role-u' id='primary-role-user' />
                    <Form.Check inline type="radio" onChange={() => setUserPrimaryRole('ADMINISTRATOR')} checked={parseUserPrimaryRole() === 'ADMINISTRATOR'} className='mx-1' label='Administrator' name='primary-role-a' id='primary-role-admin' />
                  </div>
                  <Form.Text className="text-muted d-block">Choose the primary role for the user.</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row className='m-0'>
              <Col className='bg-light my-2 px-0 py-1 text-center fw-bold' style={{ letterSpacing: '1.5px', borderWidth: '1px 0px 1px 0px', borderStyle: 'solid', borderColor: '#ccc' }}>SUBSCRIPTION</Col>
            </Row>
            <Row className='m-0'>
              <Col className='px-0 py-1'>
                <Form.Group className="mb-0 w-100 py-2" controlId="sub-access">
                  <div className='d-flex align-items-center justify-content-start'>
                    <Form.Label className='me-2 mb-1'>Access:</Form.Label>
                    <Form.Check inline disabled={parseUserPrimaryRole() === 'ADMINISTRATOR'} onChange={() => setSubAccess('READ')} checked={parseSubAccess() === 'READ'} type="radio" className='me-1' label='Read' name='sub-access' id='sub-access-r' />
                    <Form.Check inline disabled={parseUserPrimaryRole() === 'ADMINISTRATOR'} onChange={() => setSubAccess('WRITE')} checked={parseSubAccess() === 'WRITE'} type="radio" className='mx-1' label='Write' name='sub-access' id='sub-access-w' />
                    <Form.Check inline disabled={parseUserPrimaryRole() === 'ADMINISTRATOR'} onChange={() => setSubAccess('NONE')} checked={parseSubAccess() === 'NONE'} type="radio" className='mx-1' label='None' name='sub-access' id='sub-access-n' />
                  </div>
                  <Form.Text className="text-muted d-block">Choose access level for subscription module.</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row className='m-0'>
              <Col className='px-0 py-1'>
                <Form.Group className="mb-0 w-100 py-2" controlId="offers">
                  <div className='d-flex align-items-center justify-content-start'>
                    <Form.Label className='me-2 mb-1'>Offers:</Form.Label>
                    <Form.Check inline type="checkbox" disabled={parseUserPrimaryRole() === 'ADMINISTRATOR' || parseSubAccess() === 'NONE'} onChange={e => e.target.checked ? setSubOffers('ALL') : setSubOffers(parseOriginalOffers())} checked={parseSubOffers() === 'ALL'} className='mx-1' label='All' name='offers' id='offers-all' title='All offers' />
                    <Form.Label className='mx-2 mb-1 fw-bold'>OR</Form.Label>
                    <div className='flex-grow-1'>
                      <Multiselect
                        onSelect={(selectedList, selectedItem) => { setSubOffers(selectedList.map(e => e.id)) }}
                        onRemove={(selectedList, removedItem) => { setSubOffers(selectedList.map(e => e.id)) }}
                        options={offers.map(o => { return { name: o.offerCommercialName, id: o.offerId } })}
                        selectedValues={offers.filter(o => parseSubOffers() !== 'ALL' && parseSubOffers().indexOf(o.offerId) !== -1).map(o => { return { name: o.offerCommercialName, id: o.offerId } })}
                        displayValue='name'
                        placeholder='Choose one/more offers'
                        showCheckbox
                        closeOnSelect={false}
                        disable={parseSubOffers() === 'ALL' || parseUserPrimaryRole() === 'ADMINISTRATOR' || parseSubAccess() === 'NONE'}
                      />
                    </div>
                  </div>
                  <Form.Text className="text-muted d-block">Choose offers for which user can create subscriptions.</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row className='m-0'>
              <Col className='px-0 py-1'>
                <Form.Group className="mb-0 w-100 py-2" controlId="bundles">
                  <div className='d-flex align-items-center justify-content-start'>
                    <Form.Label className='me-2 mb-1'>Bundles:</Form.Label>
                    <Form.Check inline disabled={parseUserPrimaryRole() === 'ADMINISTRATOR' || parseSubAccess() === 'NONE'} type="checkbox" onChange={(e) => e.target.checked ? setSubBundles('ALL') : setSubBundles(parseOriginalBundles())} checked={parseSubBundles() === 'ALL'} className='mx-1' label='All' name='bundles' id='bundles-all' title='All bundles' />
                    <Form.Label className='mx-2 mb-1 fw-bold'>OR</Form.Label>
                    <div className='flex-grow-1'>
                      <Multiselect
                        onSelect={(selectedList, selectedItem) => { setSubBundles(selectedList.map(e => e.id)) }}
                        onRemove={(selectedList, removedItem) => { setSubBundles(selectedList.map(e => e.id)) }}
                        options={displayBundles}
                        selectedValues={displayBundles.filter(b => parseSubBundles() !== 'ALL' && parseSubBundles().indexOf(b.id) !== -1)}
                        displayValue='name'
                        groupBy='offer'
                        placeholder='Choose one/more bundles'
                        showCheckbox
                        closeOnSelect={false}
                        disable={parseSubBundles() === 'ALL' || parseUserPrimaryRole() === 'ADMINISTRATOR' || parseSubAccess() === 'NONE'}
                      />
                    </div>
                  </div>
                  <Form.Text className="text-muted d-block">Choose bundles for which user can create subscriptions.</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row className='m-0'>
              <Col className='px-0 py-1'>
                <Form.Group className="mb-0 w-100 py-2" controlId="bundles">
                  <div className='d-flex align-items-center justify-content-start'>
                    <Form.Label className='me-2 mb-1'>Usage Data:</Form.Label>
                    <Form.Check inline disabled={parseUserPrimaryRole() === 'ADMINISTRATOR' } type="checkbox" onChange={(e) => e.target.checked ? setSubUsageData('ALL') : setSubUsageData(parseOriginalUsageData())} checked={parseSubUsageData() === 'ALL'} className='mx-1' label='All' name='usage' id='usage-all' title='All bundles' />
                    {/* Code comment for when phase 2 is implemented */}
                    {/* <Form.Label className='mx-2 mb-1 fw-bold'>OR</Form.Label>
                    <div className='flex-grow-1'>
                      <Multiselect
                        onSelect={(selectedList, selectedItem) => { setSubUsageData(selectedList.map(e => e.id)) }}
                        onRemove={(selectedList, removedItem) => { setSubUsageData(selectedList.map(e => e.id)) }}
                        // options={displayBundles}
                        selectedValues={displayBundles.filter(b => parseSubBundles() !== 'ALL' && parseSubBundles().indexOf(b.id) !== -1)}
                        displayValue='name'
                        groupBy='offer'
                        placeholder='Choose one/more Usage data'
                        showCheckbox
                        closeOnSelect={false}
                        disable={true }
                      />
                    </div> */}
                  </div>
                  <Form.Text className="text-muted d-block">Choose Subscriptions for which user can get usage data.</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row className='m-0'>
              <Col className='px-0 py-1'>
                <Form.Group className="mb-0 w-100 py-2" controlId="fieldCustomization">
                  <div className='d-flex align-items-center justify-content-start'>
                    <Form.Label className='me-2 mb-1'>Field customization:</Form.Label>
                    <Form.Check inline disabled={parseUserPrimaryRole() === 'ADMINISTRATOR' || parseSubAccess() === 'NONE'} type="checkbox" checked={parseSubFieldCustomizations()} onChange={e => setSubFieldCustomizations(e.target.checked)} className='mx-1' name='fieldCustomization' id='field-customization' />
                  </div>
                  <Form.Text className="text-muted d-block">Choose access for customization of fields for subscription.</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row className='m-0'>
              <Col className='px-0 py-1'>
                <Form.Group className="mb-0 w-100 py-2" controlId="subNotesAdministrator">
                  <div className='d-flex align-items-center justify-content-start'>
                    <Form.Label className='me-2 mb-1'>Subscription Notes Administrator:</Form.Label>
                    <Form.Check inline disabled={parseUserPrimaryRole() === 'ADMINISTRATOR' || parseSubAccess() === 'NONE'} type="checkbox" checked={parseSubNotesAdministrator()} onChange={e => setSubNotesAdministrator(e.target.checked)} className='mx-1' name='subNotesAdministrator' id='sub-notes-admin' />
                  </div>
                  <Form.Text className="text-muted d-block">Select if you want user to be able to change or remove subscription notes.</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row className='m-0'>
              <Col className='bg-light my-2 px-0 py-1 text-center fw-bold' style={{ letterSpacing: '1.5px', borderWidth: '1px 0px 1px 0px', borderStyle: 'solid', borderColor: '#ccc' }}>CATALOG</Col>
            </Row>
            <Row className='m-0'>
              <Col className='px-0 py-1'>
                <Form.Group className="mb-0 w-100 py-2" controlId="cat-access">
                  <div className='d-flex align-items-center justify-content-start'>
                    <Form.Label className='me-2 mb-1'>Access:</Form.Label>
                    <Form.Check inline type="radio" checked={parseCatAccess() === 'READ'} onChange={e => { }} className='me-1' disabled label='READ' name='cat-access' id='cat-access-r' />
                    {/* <Form.Check inline type="radio" className='mx-1' disabled label='WRITE' name='sub-access' id='sub-access-w' /> */}
                  </div>
                  <Form.Text className="text-muted d-block">Choose access level for subscription module. For now only READ access is available.</Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size='sm' disabled={!validateRole(role)['valid']} onClick={() => saveUserRole()}>Save</Button>
          <Button variant="secondary" size='sm' onClick={() => { setShowRoleModal(false); setRole(role1); }}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default withAuthenticator(UserManagement, withAuthenticatorOptions);