// import moment from 'moment';
import envToUrlDict from '../data/DevopsUIcbsUrlMap.json';
import awsExports from '../aws-exports';
import envDetails from '../env-details.json';
import lz from 'lz-string';

import { MdErrorOutline, MdOutlineCheckCircle, MdTimelapse } from 'react-icons/md';

const formKey = (uid, field) => {
  return 'ssp.' + getCurrentEnv() + '.' + getCurrentStage() + '.' + uid + '.' + field;
};

export const SELECT_RECORDS_MAX_SIZE = 3000;
export const RETRY_COUNT = 3;
export const DOWNLOAD_RECORDS_MAX_SIZE = 9000;

export const setInLocalStorage = (uid, field, value, compress = false) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        localStorage.setItem(formKey(uid, field), compress ? lz.compress(JSON.stringify(value)) : JSON.stringify(value));
        resolve();
      } catch (e) {
        console.error(`Error occured while storing in local storage. Cleaning up local storage.`, e);
        localStorage.clear();
        reject();
      }
    }, 2000);
  });
};

export const fetchFromLocalStorage = (uid, fieldName, defaultValue, compress = false) => {
  let key = formKey(uid, fieldName);
  return localStorage.getItem(key) !== null ? JSON.parse(compress ? lz.decompress(localStorage.getItem(key)) : localStorage.getItem(key)) : defaultValue;
};

export const setInSessionStorage = (uid, field, value, compress = false) => {
  try {
    sessionStorage.setItem(formKey(uid, field), compress ? lz.compress(JSON.stringify(value)) : JSON.stringify(value));
  } catch (e) {
    console.error(`Error occured while storing in session storage. Cleaning up session storage.`, e);
    sessionStorage.clear();
  }
};

export const fetchFromSessionStorage = (uid, fieldName, defaultValue, compress = false) => {
  let key = formKey(uid, fieldName);
  return sessionStorage.getItem(key) !== null ? JSON.parse(compress ? lz.decompress(sessionStorage.getItem(key)) : sessionStorage.getItem(key)) : defaultValue;
};

export const removeFromSessionStorage = (uid, fieldName) => {
  const key = formKey(uid, fieldName);
  sessionStorage.removeItem(key);
};

export const getCurrentEnv = () => {
  return awsExports.aws_cloud_logic_custom.filter(f => f.name === 'SspBackendApi').map(f => f.endpoint.split('/').pop())[0]
};

export const isProductionEnv = () => {
  return getCurrentEnv().toUpperCase() === 'production'.toUpperCase();
};

export const isNonProductionEnv = () => {
  return !isProductionEnv();
};

export const isSsoEnabledForEnv = () => {
  let curEnvDetails = envDetails.filter(f => f.envName === getCurrentEnv());
  if (!curEnvDetails || curEnvDetails.length === 0) return false;
  if ('ssoEnabled' in curEnvDetails[0] && curEnvDetails[0]['ssoEnabled'] === true) return true;
  else return false;
};

export const getCurrentStage = () => {
  return envDetails.filter(f => f.envName === getCurrentEnv()).map(f => f.stageName)[0];
};

export const toCBSPFormat = (defaultFormat) => {
  if (defaultFormat === '') return '';
  //return moment(defaultFormat, 'YYYY-MM-DDTkk:mm:ss.SSS').format('YYYY-MM-DDTkk:mm:ss.SSS') + 'Z';
  return defaultFormat + 'Z';
};

export const toDefaultFormat = (cbspFormat) => {
  // if (cbspFormat)
  //let date = moment(cbspFormat, moment.ISO_8601);
  if (cbspFormat === '') return cbspFormat;
  else if (!cbspFormat.includes('T')) cbspFormat = cbspFormat + 'T00:00:00.000';
  return cbspFormat.replace('Z', '');
  //return moment(cbspFormat, 'YYYY-MM-DDTkk:mm:ss.SSS').format('YYYY-MM-DDTkk:mm:ss.SSS')
};

export const isEmpty = (val) => {
  if (val === undefined || val === null || val === 'NULL') return true;
  if (typeof val === 'string')
    return val === '';
  else if (typeof val === 'boolean' || typeof val === 'number')
    return false;
  else
    return val !== null;
};

export const contains = (a, b) => {
  return a.toLocaleLowerCase().includes(b.toLocaleLowerCase());
}

export const displayTitle = (title) => {
  if (isEmpty(title)) return title;
  return title.replace(/([A-Z])/g, (match) => ` ${match.toLowerCase()}`).replace(/^./, (match) => match.toUpperCase()).trim();
};

export const displayValue = (val) => {
  if (val === null) return 'null';
  if (isEmpty(val))
    return '-'
  else
    return val.toString();
};

export const getDevopsUIcbsUrl = () => {
  return envToUrlDict[getCurrentEnv()]['url'];
};

export const generateQrn = () => {
  const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const alphanums = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
  const first_part = [...Array(3)].reduce(a => a + alpha[~~(Math.random() * alpha.length)], '');
  const second_part = [...Array(7)].reduce(a => a + alphanums[~~(Math.random() * alphanums.length)], '');
  return first_part + second_part;
};

export const intersect = (a, b) => {
  return a.filter(Set.prototype.has, new Set(b));
};

export const parseChargePlanId = (bundleSmartCode) => {
  if (bundleSmartCode === undefined) return "000000";
  else if (bundleSmartCode.length === 0) return "000000";
  else if (bundleSmartCode.match(/^0+$/)) return "000000";
  else if (bundleSmartCode.length < 6) return bundleSmartCode.trim().padEnd(6, "0");
  else return bundleSmartCode.split(',').pop().trim();
};

export const jsonEquals = (a, b) => {
  if (typeof a !== typeof b) {
    return false;
  }
  if (typeof a === "object") {
    if (Array.isArray(a) && Array.isArray(b)) {
      if (a.length !== b.length) {
        return false;
      }
      for (let i = 0; i < a.length; i++) {
        if (!jsonEquals(a[i], b[i])) {
          return false;
        }
      }
      return true;
    } else {
      const aKeys = Object.keys(a);
      const bKeys = Object.keys(b);
      if (aKeys.length !== bKeys.length) {
        return false;
      }
      for (const key of aKeys) {
        if (!b.hasOwnProperty(key)) {
          return false;
        }
        if (!jsonEquals(a[key], b[key])) {
          return false;
        }
      }
      return true;
    }
  } else {
    return a === b;
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getLatestDate = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  if (d1 > d2) {
    return date1;
  } else if (d2 > d1) {
    return date2;
  } else {
    // Dates are equal
    return date1;
  }
};

export const isValidDate = (dateString) => {
  if (!dateString) return false;
  const date = new Date(dateString);
  return date.toString() !== 'Invalid Date';
};

export const getCurrentTime = () => {
  const current = new Date();
  return (
    String(current.getHours()).padStart(2, '0') +
    ":" +
    String(current.getMinutes()).padStart(2, '0') +
    ":" +
    String(current.getSeconds()).padStart(2, '0')
  )
}

export const parseAppStatus = (status) => {
  switch (status.toUpperCase()) {
    case 'INITIAL':
    case 'VALIDATE_ACCOUNT_ACK':
    case 'VALIDATE_ACCOUNT_RESPONSE_RECEIVED':
    case 'ACTIVATION_NOTIFICATION_ACK':
    case 'INTERIM_ACTIVATION_RESPONSE_RECEIVED':
    case 'INTERIM_ACTIVATION_RESULT_ACK':
    case 'ACTIVATION_RESPONSE_RECEIVED':
      return 'IN_PROGRESS';
    case 'ACTIVATION_COMPLETE':
    case 'SUCCESS':
      return 'SUCCESS';
    case 'FAIL':
    case 'IGNORE':
    case 'REJECT':
      return 'FAIL';
    default:
      return '';
  }
};

export const parseAppStatusIcon = (status) => {
  return parseAppStatus(status) === 'IN_PROGRESS' ?
    <MdTimelapse className='text-warning' /> :
    parseAppStatus(status) === 'SUCCESS' ?
      <MdOutlineCheckCircle className='text-success' /> :
      parseAppStatus(status) === 'FAIL' ?
        <MdErrorOutline className='text-danger' /> : '';
};

export const caseInsensitiveHas = (set, value) => {
  // Convert the value to lowercase for case-insensitive check
  return Array.from(set).some(item => typeof item === 'string' && item.toLowerCase() === value.toLowerCase());
}

export const isFinOpsEnv = () => {
  return ['punedemo', 'dev'].includes(getCurrentEnv());
};

export const includeEnvInDropdown = (envName) => {
  return envName !== getCurrentEnv() && ['punedemo', 'testeuc', 'testing', 'production'].includes(envName);
};


export const urlParamsToObj = (entries) => {
  const result = {}
  for (const [key, value] of new URLSearchParams(entries).entries()) {
    result[key] = value;
  }
  return result;
};

export const parseBoolean = (value) => {
  if (value === true || value === false) return value;
  else if (value === null || value === undefined) return false;
  else return !!String(value).toLowerCase().trim();
};