import { Handle, Position } from 'reactflow';
 
function StatusNode({ data }) { 
  return (
    <div style={{ 
      'textAlign': 'center',
      'width': '120px',
      'fontSize': '8px',
      'background': '#f5f5f6',
      'color': '#222',
      'boxShadow': '0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%)',
      'borderRadius': '12px',
      'borderStyle': 'solid',
      'borderColor': data.statusColour 
      }}>
      <Handle type="target" position={Position.Left} />
      <div style = {{ 'padding': '8px 10px', 'borderBottom': '1px solid #2e3031' }}>
        <strong>{data.label}</strong>
      </div>
      <div style={{ 'padding': '8px 10px'}}>
        {data.status}
      </div>
      <Handle type="source" position={Position.Right} id="a" />
    </div>
  );
}

function StartNode() {
  return (
    <div style={{ 
      'textAlign': 'center',
      'fontSize': '12px',
      'background': '#84adb9',
      'color': '#222',
      'boxShadow': '0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%)',
      'borderRadius': '50%',
      'borderStyle': 'solid',
      'borderColor': '#84adb9' 
      }}>
      {/* <Handle type="target" position={Position.Left} /> */}
      <div style = {{ 'padding': '8px 10px' }}>
        <strong>Start</strong>
      </div>
      <Handle type="source" position={Position.Right} id="a" />
    </div>
  );
}

function EndNode() {
  return (
    <div style={{ 
      'textAlign': 'center',
      'fontSize': '12px',
      'background': '#d46d66',
      'color': '#222',
      'boxShadow': '0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%)',
      'borderRadius': '50%',
      'borderStyle': 'solid',
      'borderColor': '#d46d66' 
      }}>
      <Handle type="target" position={Position.Left} />
      <div style = {{ 'padding': '8px 10px' }}>
        <strong>End</strong>
      </div>
    </div>
  );
}

export {StatusNode, StartNode, EndNode};