// react lib
import { useState } from 'react';

// react bootstrap components and style
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Spinner from 'react-bootstrap/Spinner';

// stylesheet
import '../../scss/sub_dropdown.scss';

// external libs
import { API } from 'aws-amplify';
import { MdOutlineMoreHoriz, MdDescription, MdMode, MdCancel, MdPauseCircle, MdPlayCircle, MdStore, MdBarChart, MdInsights, MdOpenInNew, MdDataUsage, MdOutlineShop2 } from 'react-icons/md';
import { DiJira } from 'react-icons/di';

// internal data and libs
import * as Utils from '../../common/utils';
import * as RBACUtils from '../../common/RBACUtils';
import { utils } from '@aws-amplify/datastore';
import { useNavigate } from "react-router-dom";


//#region constants
const ENV = Utils.getCurrentEnv(), STAGE = Utils.getCurrentStage();
//#endregion

const SubDropDown = ({ id, appsPayload, onSubscriptionAction, region, username, apps, hideDetails = false, hideChange = false, contracts = '' }) => {
  const navigate=useNavigate();

  //#region states & hooks
  const [jiraEpicRequest, setJiraEpicRequest] = useState({ status: 0, id: null });
  const [showCompareModal,setShowCompareModal]= useState(false);

  //#endregion


  //#region local static data

  //#endregion


  //#region functions

  const isProductionEnv = () => {
    return ENV.toUpperCase() === 'production'.toUpperCase();
  };

  const hasJiraDashboard = () => {
    return isProductionEnv() || ENV.toUpperCase() === 'punedemo'.toUpperCase() || ENV.toUpperCase() === 'testeuc'.toUpperCase();
  }

  const fetchJiraEpicId = async (subId) => {
    setJiraEpicRequest({ status: 0, id: null });
    let epicId = Utils.fetchFromSessionStorage(username, `epicId-${subId}`, null, false)
    if (epicId) {
      setJiraEpicRequest({ status: 1, id: epicId });
      return;
    }
    let params = {
      response: false,
      queryStringParameters: {
        'stage': STAGE,
        'id': subId,
        'region': region
      }
    };

    await API.get("SspBackendApi", "/epic", params)
      .then(response => {
        if (response && response.epicId) {
          Utils.setInSessionStorage(username, `epicId-${subId}`, response.epicId, false);
          setJiraEpicRequest({ status: 1, id: response.epicId });
        } else {
          setJiraEpicRequest({ status: 1, id: null });
        }
      }).catch(error => {
        console.log(`Error occurred while fetching epic for subscription : ${error}`);
        setJiraEpicRequest({ status: 1, id: null });
      });
  };

  const shouldFetchJiraEpicId = (e) => {
    return e.target.tagName.toUpperCase() !== 'div'.toUpperCase() && hasJiraDashboard();
  };


  const buildAvayaStoreLinks = () => {
    let carr = contracts ? contracts.split(',') : [];
    return carr.length > 0 ?
      carr.map(c => { return { 'cid': c, 'link': `https://www.avaya.com/store/shopping/subscriptions/${id}?subscriptionName=${id}&contractNumber=${c}&type=Business%20Partner` } }) :
      [{ cid: 'NA', link: `https://www.avaya.com/store/shopping/subscriptions/${id}?subscriptionName=${id}&contractNumber=&type=Business%20Partner` }]
  };

  const buildPowerBIDashboardLink = () => {
    return `https://app.powerbi.com/groups/me/reports/4c9be708-5347-49a8-b67b-3f21b499bfc3/ReportSection729a800a7e405edbc624?filter=SubscriptionMaster/SubscriptionId%20eq%20'${id}'%20and%20Bundle%2FSubscriptionId%20eq%20'${id}'%20and%20Capability%2FSubscriptionId%20eq%20'${id}'%20and%20AllOtherMetric%2FSubscriptionId%20eq%20'${id}'`
  };

  //#endregion

  return (
    <>
    
      <DropdownButton size='sm' id={`dropdown-split-basic-${id}`} title={<MdOutlineMoreHoriz />} className='d-flex align-items-center justify-content-end w-100 h-100 table-row-dropdown' onClick={(e) => { if (shouldFetchJiraEpicId(e)) fetchJiraEpicId(id); }} renderMenuOnMount={true}>
        {
          !hideDetails &&
          <Dropdown.Item target={`${!hideChange ? '_blank' : ''}`} href={`/sub/view/${id}`} rel='opener'>
            <div className='d-flex align-items-center justify-content-start'>
              <div className='ssd-item-icon'><MdDescription /></div>
              <div className='ssd-item-title'>Details</div>
              {!hideChange && <small className='newtab'><MdOpenInNew /></small>}
            </div>
          </Dropdown.Item>
        }
        {
          !hideDetails &&
          <Dropdown.Item href={`/order/list?sub_id=${id}`} rel='opener'>
            <div className='d-flex align-items-center justify-content-start'>
              <div className='ssd-item-icon'><MdOutlineShop2 /></div>
              <div className='ssd-item-title'>Orders</div>
              {!hideChange && <small className='newtab'><MdOpenInNew /></small>}
            </div>
          </Dropdown.Item>
        }
        {
          !hideChange && RBACUtils.hasSubscriptionAccess(username, 'WRITE') &&
          <Dropdown.Item target={`${!hideDetails ? '_blank' : ''}`} href={`/sub/edit/${id}`} rel='opener'>
            <div className='d-flex align-items-center justify-content-start'>
              <div className='ssd-item-icon'><MdMode /></div>
              <div className='ssd-item-title'>Change</div>
              {!hideDetails && <small className='newtab'><MdOpenInNew /></small>}
            </div>
          </Dropdown.Item>
        }
        {
          appsPayload && !isProductionEnv() &&
          <Dropdown.Item onClick={(e) => { e.stopPropagation(); onSubscriptionAction?.('cancel', id, appsPayload); }}>
            <div className='d-flex align-items-center justify-content-start'>
              <div className='d-flex align-items-center justify-content-start me-1'>
                <div className='ssd-item-icon'><MdCancel /></div>
                <div className='ssd-item-title'>Cancel</div>
              </div>
              <small className='beta'>BETA</small>
            </div>
          </Dropdown.Item>
        }
        {
          appsPayload && !isProductionEnv() &&
          <Dropdown.Item onClick={(e) => { e.stopPropagation(); onSubscriptionAction?.('suspend', id, appsPayload); }}>
            <div className='d-flex align-items-center justify-content-start'>
              <div className='d-flex align-items-center justify-content-start me-1'>
                <div className='ssd-item-icon'><MdPauseCircle /></div>
                <div className='ssd-item-title'>Suspend</div>
              </div>
              <small className='beta'>BETA</small>
            </div>
          </Dropdown.Item>
        }
        {
          appsPayload && !isProductionEnv() &&
          <Dropdown.Item onClick={(e) => { e.stopPropagation(); onSubscriptionAction?.('resume', id, appsPayload); }}>
            <div className='d-flex align-items-center justify-content-start'>
              <div className='d-flex align-items-center justify-content-start me-1'>
                <div className='ssd-item-icon'><MdPlayCircle /></div>
                <div className='ssd-item-title'>Resume</div>
              </div>
              <small className='beta'>BETA</small>
            </div>
          </Dropdown.Item>
        }
        {
          RBACUtils.hasUsageDataAccess(username,id)&&<Dropdown.Item target='_blank' href={`/usagedata?subscription=${id}`} rel='opener'>
          <div className='d-flex align-items-center justify-content-start'>
            <div className='ssd-item-icon'><MdDataUsage /></div>
            <div className='ssd-item-title'>Usage Data</div>
            <small className='beta'>BETA</small>
          </div>
        </Dropdown.Item>
        }
        
        <Dropdown.Divider className='sub-dd-divider' />
        <Dropdown.Item target='_blank' href={`/bpmn?flow_id=${id}&flow_type=flow_external_id`} rel='opener'>
          <div className='d-flex align-items-center justify-content-start'>
            <div className='ssd-item-icon'><MdInsights /></div>
            <div className='ssd-item-title'>Workflow</div>
            <small className='newtab'><MdOpenInNew /></small>
          </div>
        </Dropdown.Item>
        {
          isProductionEnv() &&
          <>
            <Dropdown.Divider className='sub-dd-divider' />
            {
              buildAvayaStoreLinks().map(d =>
                <Dropdown.Item target='_blank' href={d['link']}>
                  <div className='d-flex align-items-center justify-content-start'>
                    <div className='ssd-item-icon'><MdStore /></div>
                    <div className='ssd-item-title'>Avaya Store (<small>{d['cid']}</small>)</div>
                    <small className='newtab'><MdOpenInNew /></small>
                  </div>
                </Dropdown.Item>
              )
            }
            <Dropdown.Item target='_blank' href={buildPowerBIDashboardLink()}>
              <div className='d-flex align-items-center justify-content-start'>
                <div className='ssd-item-icon'><MdBarChart /></div>
                <div className='ssd-item-title'>PowerBI Dashboard</div>
                <small className='newtab'><MdOpenInNew /></small>
              </div>
            </Dropdown.Item>
          </>
        }
        {
          hasJiraDashboard() &&
          <>
            {
              jiraEpicRequest['status'] === 0 ?
                <Dropdown.Item>
                  <Spinner size='sm' variant='secondary' animation="border" role="status" />
                </Dropdown.Item>
                :
                <Dropdown.Item target='_blank' disabled={jiraEpicRequest['id'] === null} href={`https://jira.forge.avaya.com/browse/${jiraEpicRequest['id']}`} onClick={(e) => e.stopPropagation()}>
                  <div className='d-flex align-items-center justify-content-start'>
                    <div className='ssd-item-icon'><DiJira /></div>
                    <div className='ssd-item-title'>Jira Epic</div>
                    <small className='newtab'><MdOpenInNew /></small>
                  </div>
                </Dropdown.Item>
            }
          </>
        }
      </DropdownButton>
      
    </>
  );
};

export default SubDropDown;


