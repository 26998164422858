import Modal from "react-bootstrap/Modal";
import "../../../scss/common.scss";
import "../../../scss/pc.scss";
export const Spinner=({spinner})=>{
    return (
        <Modal
        show={spinner.status}
        size="lg"
        backdrop="static"
        aria-labelledby="loading-modal"
        centered
        className="loading-modal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div
              className="spinner-border spinner-border-md"
              role="status"
            ></div>
            <p className="mb-0 mt-1">{spinner["message"]}</p>
          </div>
        </Modal.Body>
      </Modal>
    )
}