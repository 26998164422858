import * as React from "react";
import { useState } from "react";
//components
import ReactJson from '@microlink/react-json-view'
import { Offcanvas } from "react-bootstrap";

import { OpenBpmnDrawerBtn } from "./bpmnDrawerButton/ArrowIcons";
import PropTypes from "prop-types";

BpmnDrawer.propType = {
  content: PropTypes.object.isRequired,
  offSetTop: PropTypes.number,
  CloseDrawerIcon: PropTypes.object,
  OpenDrawerIcon: PropTypes.object,
};

export default function BpmnDrawer({ content, offSetTop }) {
  const [drawerTrueFalse, setDrawer] = useState(false);

  function onDrawerOpen(event) {
    setDrawer(true);
  }

  function getJSONTitle(content) {
    try {
      if ("step_state" in content) return "step";
      if ("version" in content) return "flow";
      if ("trigger_name" in content) return "ruleEngine";
      if ("child_step_state" in content) return "childStep";
    } catch (e) {
      return "Undef";
    }
  }
  return (
    <div id="bpmnwrapperid">
      <div>
        <Drawer
          open={drawerTrueFalse}
          onChange={setDrawer}
          headline={"Step details"}
        >
          <ReactJson
            src={content}
            name={getJSONTitle(content)}
            displayDataTypes={false}
            quotesOnKeys={false}
            theme="shapeshifter:inverted"
            enableClipboard={(copy) => {
              if (typeof copy.src === "string")
                navigator.clipboard.writeText(copy.src);
            }}
          />
        </Drawer>
      </div>

      <div id="json-drawer-div">
        {drawerTrueFalse ? (
          <></>
        ) : (
          <OpenBpmnDrawerBtn onClick={onDrawerOpen} offSetTop={offSetTop} />
        )}
      </div>
    </div>
  );
}
const Drawer = (props) => {
  const { open, onChange, headline, children } = props;
  return (
    <>
      <Offcanvas style={{maxWidth:'50vw'}}show={open} onHide={() => onChange(false)} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{headline ? headline : ""}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{children ? children : <></>}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
