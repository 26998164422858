import React, { useState, useMemo, useReducer, useEffect,useRef ,useCallback} from "react";
import { useTable, usePagination, useSortBy, useExpanded } from 'react-table';
import Modal from 'react-bootstrap/Modal'
import { FormControl,InputGroup } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container'
import { Row, Col } from 'react-bootstrap';
import { MdUpdate, MdErrorOutline, MdOutlineCheckCircle, MdTimelapse, MdOutlineNoteAdd } from 'react-icons/md';
import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown, TiFilter } from 'react-icons/ti';
import { FaExpandAlt } from "react-icons/fa";
import { BsDot } from "react-icons/bs";
import { TbNotes } from "react-icons/tb";
import { useSearchParams } from "react-router-dom";
import { ShakyFormControl, TruncatedFormLabel } from "./BootStrapCom";
import CompareOrdersModal from './CompareOrdersModal';
import { VscDebugStart } from "react-icons/vsc";
import { IoIosArrowForward ,IoIosArrowBack} from "react-icons/io";

import "flatpickr/dist/themes/material_blue.css";

import Flatpickr from "react-flatpickr";
import Multiselect from 'multiselect-react-dropdown';
import * as Utils from '../../common/utils';
import * as RBACUtils from '../../common/RBACUtils';

import { API } from 'aws-amplify';

import '../../scss/data_table.scss';

import SubDropDown from "./SubDropDown";
import NavigateButton from "./NavigateButton"

const APPS_TO_HIDE = ['CCaaSDevops', 'VERINT_SERVICES'];
const APPS_TO_RENAME = {
  IXCC: 'AXP',
  AMH: 'ASC',
  AOCP_MSAZURE: 'AEC'
};
const STAGE = Utils.getCurrentStage()
const DEFAULT_FILTER = { };
const DEFAULT_SUB_NOTE_MODAL = { show: false, sid: '', note: '', updated_by: '', updated_at: '' };
const EXTENDED_SUB_NOTE_MODAL_FILTER = { show: false,customerType:'',ctOp:'=',proServices:'',psOp:'=',text:null,tOp:'=' };
const ALLOWED_COMPARE_ORDER_STATES = ['new','change','suspend','cancel'];

const DEFAULT_SUB_NOTE_MODAL_ALERT = { show: false, message: '', type: 'info' };
const PAGE_CHANGED = 'PAGE_CHANGED',
  PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED',
  PAGE_SORT_CHANGED = 'PAGE_SORT_CHANGED',
  PAGE_FILTER_CHANGED = 'PAGE_FILTER_CHANGED',
  TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';
const INIT_FILTER_STATE = {
  queryPageIndex: 0,
  queryPageSize: 5,
  totalCount: null,
  queryPageFilter: {},
  queryPageSortBy: [{ id: 'updated', desc: true }],
};

const DataTable = ({ username, data, onTableUpdated, isLoading, onSubscriptionAction, region, useremail, totalRecords }) => {

  //#region states

  const [gotoPageInput, setGotoPageInput] = useState("");
  const [showFilter, setShowFilter] = useState(true);
  const [hideInternalApps, setHideInternalApps] = useState(true);
  const [subNoteModal, setSubNoteModal] = useState(DEFAULT_SUB_NOTE_MODAL);
  const [subNoteModalAlert, setSubNoteModalAlert] = useState(DEFAULT_SUB_NOTE_MODAL_ALERT);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState(Utils.urlParamsToObj(searchParams));
  const [dataCentersOptions,setDataCentersOptions] = useState([]);
  const [carrierConnectionOptions,setCarrierConnectionOptions] = useState([]);
  const [extendedNoteFilter, setExtendedNoteFilter] = useState(EXTENDED_SUB_NOTE_MODAL_FILTER);
  const [showCompareModal,setShowCompareModal]= useState({show:false,id:''});
  const [dateType,setDateType]=useState('start');

  const isFirstRender = useRef(true);

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case PAGE_CHANGED:
        return {
          ...state,
          queryPageIndex: payload,
        };
      case PAGE_SIZE_CHANGED:
        return {
          ...state,
          queryPageSize: payload,
        };
      case PAGE_SORT_CHANGED:
        return {
          ...state,
          queryPageSortBy: payload,
        };
      case PAGE_FILTER_CHANGED:
        return {
          ...state,
          queryPageFilter: payload,
        };
      case TOTAL_COUNT_CHANGED:
        return {
          ...state,
          totalCount: payload,
        };
      default:
        throw new Error(`Unhandled action type: ${type}`)
    }
  };

  const handleToggleClick = useCallback((e) => {
    e.stopPropagation(); // Stop the click from propagating to elements behind the span
    setDateType((currentType) => (currentType === 'start' ? 'end' : 'start'));
  }, []);
  

  const [{ queryPageIndex, queryPageSize, totalCount, queryPageSortBy, queryPageFilter }, dispatch] = useReducer(reducer, INIT_FILTER_STATE);

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div>
            <div title='Provisioning Id 1 (pid1)'>Subscription Id</div>
            <div title='Provisioning Id 2 (pid2)' className='fw-light text-muted'><small>Contracts</small></div>
          </div>
        ),
        accessor: 'id',
        Cell: ({ value, row }) => {
          return <div>
            <div className='d-flex align-items-center justify-content-start sub-id'>
              <div title={String(value)} className='text-truncate sub-id-text me-1'>{value ? value : '--'}</div>
              <NavigateButton id={row.original.id} navigateTo="orders" />
              {
                !row.original['note'] && (RBACUtils.isSubNotesAdministrator(username) || RBACUtils.hasRole(username, 'ADMINISTRATOR')) &&
                <div className='sub-id-note' title='Add subscription note' onClick={() => showSubNoteModal(value, row.original['note'], row.original['updated_by'], row.original['updated_at'])}><MdOutlineNoteAdd /></div>
              }
            </div>
            <div title={`Contracts:. ${String(row.original['id2'] ? row.original['id2'] : '--')}`} className='acc-num fw-light text-muted text-truncate'>{row.original['id2'] ? row.original['id2'] : '--'}</div>
            {
                row.original['last_order_state'] !== null && row.original['last_order_state'] !== 'NA' &&(
                ALLOWED_COMPARE_ORDER_STATES.includes(row.original.last_order_state.toLowerCase())&&<div title={buildOrderStateStatusTitle(row.original['last_order_state'])} className={`d-inline-block me-1 order-status ${buildOrderStateStatusClass(row.original['last_order_state'])}`}><span style={{cursor:'pointer'}} onClick={()=>setShowCompareModal({show:true,id:row.original.id,type:row.original.last_order_state})}>{row.original['last_order_state']}</span></div>||
                <div title={buildOrderStateStatusTitle(row.original['last_order_state'])} className={`d-inline-block me-1 order-status ${buildOrderStateStatusClass(row.original['last_order_state'])}`}>{row.original['last_order_state']}</div>)
            }
            {
              row.original['sub_trial'] !== '-' && row.original['sub_trial'] !== 'N' && row.original['sub_trial'] !== '-,N' && row.original['sub_trial'] !== 'N,-' && (
              <div title={buildSubTrialStatusTitle(row.original['sub_trial'])} className={`ms-1 d-inline-block order-status ${buildSubTrialStatusClass(row.original['sub_trial'])}`}>{row.original['sub_trial'] === 'Y' ? 'Trial' : ''} </div>)
            }
            {
              row.original['sub_sender'] !== '-' && (
              <div title={buildSubSenderStatusTitle(row.original['sub_sender'])} className={`ms-1 d-inline-block order-status ${buildSubSenderStatusClass(row.original['sub_sender'])}`}>{row.original['sub_sender']}</div>)
            }
            {
              row.original['note'] &&
              <div className='d-flex align-items-center justify-content-start' title={row.original['note']} onClick={() => showSubNoteModal(value, row.original['note'], row.original['updated_by'], row.original['updated_at'])}>
                <div className='sub-id-note'><TbNotes /></div>
                <div className='sub-id-note-text d-inline-block text-truncate' title={row.original['note']}>{row.original['note']}</div>
              </div>
            }
          </div>
        },
        width: '14%',
        filter: 'startsWithText'
      },
      {
        Header: () => (
          <div>
            <div title='Customer name'>Customer</div>
            <div title='Customer account number' className='fw-light text-muted'><small>Account No. / Enterprise Id</small></div>
            <div title='Business partner id/ Mea Id' className='fw-light text-muted'><small>BpLinkId / Mea Id</small></div>
            <div title='Customer domain' className='fw-light text-muted'><small>Customer domain</small></div>
          </div>
        ),
        accessor: 'name',
        Cell: ({ value, row }) => {
          return  <div className=''>  
            <div title={String(value)} className='text-truncate'>{value ? value : '--'}</div>
            <div title={`Account No: ${String(row.original['account'] ? row.original['account'] : '-')}, Enterprise Id: ${String(row.original['eid'] ? row.original['eid'] : '-')}`} className='acc-num text-truncate'>{row.original['account'] ? row.original['account'] : '-'} {row.original['eid'] ? ` / ${row.original['eid']}` : ''}</div>
            <div  title={`Business partner Id : ${row.original.bp_link_id && row.original.bp_link_id!=='' ? formatCustomerColumn(row.original.bp_link_id):'-'},Mea Id:${row.original.mea_id? formatCustomerColumn(row.original.mea_id):'-'}`} className='acc-num text-truncate'>{row.original.bp_link_id&&row.original.bp_link_id!=='' ? formatCustomerColumn(row.original.bp_link_id) : '--'} / {row.original.mea_id ? formatCustomerColumn(row.original.mea_id) : '--'}</div>
            <div  title={`Customer domain : ${row.original.customer_domain ? formatCustomerColumn(row.original.customer_domain):'-'}`} className='acc-num text-truncate'>{row.original.customer_domain ? formatCustomerColumn(row.original.customer_domain) : '-'} </div>
            </div> 
        },
        width: '13%',
        filter: 'text'
      },
      {
        Header: () => (
          <div>
            <div title='Country Code'>Region</div>
            <div title='Country code' className='fw-light text-muted'><small>Country Code</small></div>
          </div>
        ),
        accessor: 'rgn',
        Cell: ({ value, row }) => {
          return <div className=''>
            <div title={String(value)} className='text-truncate'>{value ? value : '--'}</div>
            <div title={`Country Code:. ${String(row.original['ccode'] ? row.original['ccode'] : '--')}`} className='fw-light text-muted'>{row.original['ccode'] ? row.original['ccode'] : '--'}</div>
          </div>
        },
        width: '13%',
        filter: 'text'
      },
      {
        Header: () => (
          <div>
            <div title='Application type'>Applications</div>
            <div title='Application Instance Id / Application Account' className='fw-light text-muted'><small>Data Center / Application Account</small></div>
          </div>
        ),
        accessor: 'apps',
        Cell: ({ value }) => {
          return <div className='d-flex align-items-center justify-content-start flex-wrap'>
            {
              value ?
                JSON.parse(value).map(a => {
                  return a['app'] ?
                    <div className={`rounded tooltip-app w-100 text-wrap d-flex align-items-center justify-content-start ${parseAppStatus(a['state'])}`}>
                      <div className='app-status-wrap'>
                        <div className='app-status-icon' title={a['state']}>{parseAppStatusIcon(a['state'])}</div>
                        <div>&nbsp;</div>
                      </div>
                      <div className='app-desc-wrap'>
                        <div className='app-title text-truncate' title={parseAppTitle(a['app'])}>{a['app']}</div>
                        <div className='app-instance text-truncate' title={`App Instance: ${a['instance']}, Application Account: ${a['appAccount']}`}>
                          {a['instance']} {a['appAccount'] ? ` / ${a['appAccount']}` : ''}
                        </div>
                      </div>
                    </div>
                    : '--';
                }) :
                '--'
            }
          </div>;
        },
        width: '15%',
        filter: 'multiselect',
        disableSortBy: true
      },
      {
        Header: 'Offers',
        accessor: 'offers',
        Cell: ({ value, row }) => {
          return <div className='d-flex align-items-center justify-content-start flex-wrap'>
            {
              value.split(',').map(v =>
                <OverlayTrigger className='data-table-tooltip' placement="auto" overlay={renderTooltip(v.trim(), row.original['bundles'],row.original['fixed_count'],row.original.carrier_connection)} delay={250}>
                  <div className='rounded tooltip-offer text-wrap'>{parseOfferTitle(v.trim())}</div>
                </OverlayTrigger>
              )
            }
          </div>;
        },
        width: '14%',
        filter: 'multiselect',
        disableSortBy: true
      },
      {
        Header: 'Created On',
        accessor: 'created',
        Cell: ({ value }) => {
          const formattedDate = value && value.indexOf('+') === -1 ? value.split(' ')[0] : '--';
          return <div>
            <div title={String(value)} className='text-truncate'>{formattedDate}</div>
            {
              formattedDate !== '--' &&
              <div><MdUpdate /> <small>{describeDate(formattedDate)}</small></div>
            }
          </div>
        },
        width: '10%',
        filter: 'date'
      },
      {
        Header: <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer'}} onClick={handleToggleClick}>
        Sub period
        {dateType === "start" ? (
          <IoIosArrowForward title='sorting by start' />
        ) : (
          <IoIosArrowBack title='sorting by end' />
        )}
      </span>
        ,
        accessor: dateType,
        Cell: ({ row }) => {
          const startDate = row.original.start;
          const endDate = row.original.end;
          const formattedDateS = startDate ? startDate.split(' ')[0] : '--';
          const formattedDateE = endDate ? endDate.split(' ')[0] : '--';
          const formattedDate = formattedDateS !== '--' && formattedDateE !== '--' ? `${formattedDateS} till ${formattedDateE}` : '--';
          return <div title={String(formattedDate)} className='text-truncate'>{formattedDate}</div>;
        },
        width: '15%',
        filter: 'date'
      },
      {
        Header: 'Last updated',
        accessor: 'updated',
        Cell: ({ value }) => {
          const formattedDate = value ? value.split(' ')[0] : '--';
          return <div className="d-flex align-items-stretch justify-content-between">
            <div className="">
              <div title={String(value)} className='text-truncate'>{formattedDate}</div>
              <div><MdUpdate /> <small>{describeDate(formattedDate)}</small></div>
            </div>
          </div>;
        },
        width: '12%',
        filter: 'date'
      },
      {
        Header: '',
        accessor: 'bundles',
        Cell: ({ value, row }) => {
          return <SubDropDown apps={row.original['apps']} id={row.original['id']} appsPayload={buildSubActionPayload(row.original['apps'])} onSubscriptionAction={onSubscriptionAction} region={region} username={username} contracts={row.original['id2']} />
        },
        width: '3%',
        disableSortBy: true
      },
    ],
    [dateType]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  //#endregion

  //#region local static data

  const offers = Utils.fetchFromSessionStorage(username, 'offers', [], true);
  const bundles = Utils.fetchFromSessionStorage(username, 'bundles', [], true);
  const apps = Utils.fetchFromSessionStorage(username, 'apps', [], true);
  const countrycodes = Utils.fetchFromSessionStorage(username, 'countrycodes', [], true);  

  const bundleMap = new Map();
  bundles.forEach(b => bundleMap.set(b.bundleId, b));

  //#endregion

  //#region functions

  const parseOfferTitle = (systemType) => {
    let filteredOffer = offers.filter(o => o.offerSystemType === systemType);
    return filteredOffer.length > 0 ? filteredOffer[0].offerCommercialName : systemType;
  };

  const parseAppTitle = (appType) => {
    let localAppType = getOriginalAppType(appType) || appType;
    let filteredApp = apps.filter(a => a.applicationType === localAppType);
    return filteredApp.length > 0 ? filteredApp[0].applicationCommercialName : appType;
  };

  const isBundleFromOffer = (bundleServiceType, offerSystemType) => {
    let filteredOffer = offers.filter(o => o.offerSystemType === offerSystemType);
    let result = filteredOffer.length > 0 ? filteredOffer[0].offerBundles.map(ob => ob.bundleServiceType).indexOf(bundleServiceType) !== -1 : false;
    return result;
  };

  const parseBundleTitle = (serviceType) => {
    let filteredBundles = bundles.filter(o => o.bundleServiceType === serviceType);
    return filteredBundles.length > 0 ? filteredBundles[0].bundleCommercialName : serviceType;
  };
  
const parseCarrierConnection = (carrierCon) => {
  if (["None","-","none"].includes(carrierCon)||!carrierCon){
    return "";
  }
  return "| " + carrierCon;
}

  const renderTooltip = (offerSystemType, bundles, fixedCount,carrierConnections) => {
    const bundleCounts = {};
    const carrierCon = {};
    carrierConnections &&
    carrierConnections.split(',').map(cc=>{
      const [bundle, carry] = cc.split(':');
      //if in the future carrier connections will be relevant for more bundle -remove the if statement
      if(bundle==='BYOC_MIN')   {
        carrierCon[bundle] = carry;
      }
    });
    
    fixedCount &&
    fixedCount.split(',').map(fc=>{
      const [bundle, count] = fc.split(':');
      bundleCounts[bundle] = count;
    })
    return(
    <Tooltip className='dt-offer-tooltip'>
      <div >
        {
          bundles.split(',').map(b =>
            isBundleFromOffer(b.trim(), offerSystemType) &&
            <div className='tooltip-bundle text-truncate'>
              {parseBundleTitle(b.trim())} {bundleCounts[b] ? ` (${bundleCounts[b]})` : ''}  {parseCarrierConnection(carrierCon[b])}
            </div>
          )
        }
      </div>
    </Tooltip>)
  };

  const showSubNoteModal = (sid, note, updated_by, updated_at) => {
    setSubNoteModal({ show: true, sid: sid, note: note, updated_by: updated_by, updated_at: updated_at })
    setSubNoteModalAlert(DEFAULT_SUB_NOTE_MODAL_ALERT);
  };

  const hideSubNoteModal = () => {
    setSubNoteModal(DEFAULT_SUB_NOTE_MODAL);
    setSubNoteModalAlert(DEFAULT_SUB_NOTE_MODAL_ALERT);
  };

  const createSubNote = async () => {
    setSubNoteModalAlert({ show: true, message: 'Creating subscription note...', type: 'info' });
    let params = {
      'stage': STAGE,
      'region': region,
      'id': subNoteModal['sid'],
      'note': subNoteModal['note'],
      'updated_by': useremail
    };
    await API.post("SspBackendApi", "/snote", {
      body: params,
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      hideSubNoteModal();
      onTableUpdated(pageIndex, pageSize, sortBy, filter, true);
    }).catch(error => {
      setSubNoteModalAlert({ show: true, message: 'Error occurred while creating note.', type: 'danger' });
    });
  };

  const updateSubNote = async () => {
    setSubNoteModalAlert({ show: true, message: 'Saving subscription note...', type: 'info' });
    let params = {
      'stage': STAGE,
      'region': region,
      'note': subNoteModal['note'],
      'updated_by': useremail
    };
    await API.put("SspBackendApi", `/snote/${subNoteModal['sid']}`, {
      body: params,
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      hideSubNoteModal();
      onTableUpdated(pageIndex, pageSize, sortBy, filter, true);
    }).catch(error => {
      setSubNoteModalAlert({ show: true, message: 'Error occurred while saving note.', type: 'danger' });
    });
  };

  const removeSubnote = async () => {
    setSubNoteModalAlert({ show: true, message: 'Removing subscription note...', type: 'info' });
    let params = {
      queryStringParameters: {
        'stage': STAGE,
        'region': region
      }
    };
    await API.del("SspBackendApi", `/snote/${subNoteModal['sid']}`, params)
      .then(response => {
        hideSubNoteModal();
        onTableUpdated(pageIndex, pageSize, sortBy, filter, true);
      }).catch(error => {
        setSubNoteModalAlert({ show: true, message: 'Error occurred while removing note.', type: 'danger' });
      });
  };
  const formatCustomerColumn = (value)=>{
    let formatted ='';
    const separatedByComma = value.split(',');
    if (separatedByComma.length > 1){
      const filteredValues = separatedByComma.filter(val => val !== '-');
      formatted = filteredValues.join(',');
    }
    else{
      formatted = value;
    }
    return String(formatted);
  }

  // const textFilter = (rows, columnId, filterValue) => {
  //   return rows.filter(row =>
  //     String(row.values[columnId]).toLowerCase().includes(filterValue.toLowerCase())
  //   );
  // };

  // const startsWithTextFilter = (rows, columnId, filterValue) => {
  //   return rows.filter(row =>
  //     String(row.values[columnId]).toLowerCase().startsWith(filterValue.toLowerCase())
  //   );
  // };

  // const dateFilter = (rows, columnId, filterValue) => {
  //   return rows.filter(row => {
  //     if (!isNaN(new Date(String(row.values[columnId]))) && !isNaN(new Date(String(filterValue))))
  //       return new Date(String(row.values[columnId])) < new Date(filterValue);
  //     return false;
  //   });
  // };

  // const multiSelectFilter = (rows, columnId, filterValue) => {
  //   return rows.filter(row => {
  //     return filterValue.indexOf('') === 0 || Utils.intersect(filterValue, row.values[columnId]).length > 0;
  //   });
  // };

  const parseAppStatus = (status) => {
    switch (status.toUpperCase()) {
      case 'INITIAL':
      case 'VALIDATE_ACCOUNT_ACK':
      case 'VALIDATE_ACCOUNT_RESPONSE_RECEIVED':
      case 'ACTIVATION_NOTIFICATION_ACK':
      case 'INTERIM_ACTIVATION_RESPONSE_RECEIVED':
      case 'INTERIM_ACTIVATION_RESULT_ACK':
      case 'ACTIVATION_RESPONSE_RECEIVED':
        return 'IN_PROGRESS';
      case 'ACTIVATION_COMPLETE':
      case 'SUCCESS':
        return 'SUCCESS';
      case 'FAIL':
      case 'IGNORE':
      case 'REJECT':
        return 'FAIL';
      default:
        return '';
    }
  };

  const parseAppStatusIcon = (status) => {
    return parseAppStatus(status) === 'IN_PROGRESS' ?
      <MdTimelapse className='text-warning' /> :
      parseAppStatus(status) === 'SUCCESS' ?
        <MdOutlineCheckCircle className='text-success' /> :
        parseAppStatus(status) === 'FAIL' ?
          <MdErrorOutline className='text-danger' /> : '';
  };

  const buildOrderStatusClass = (orderStatus) => {
    return orderStatus === 'New' ? 'new' : orderStatus === 'Change' ? 'change' : '';
  };

  const buildOrderStatusTitle = (orderStatus) => {
    return orderStatus === 'New' ? ' New Subscription.' : orderStatus === 'Change' ? 'This is Changed Subscription' : '';
  };

  const buildSubTrialStatusClass = (orderStatus) => {
    return orderStatus === 'Y' ? 'trial' : '';
  };

  const buildSubTrialStatusTitle = (orderStatus) => {
    return orderStatus === 'Y' ? 'This is Trial Order.' : orderStatus === 'N' ? 'This is Not Trial Order.' : '';
  };

  const buildSubSenderStatusClass = (orderStatus) => {
    return orderStatus === 'Internal' ? 'internal' : orderStatus === 'Commercial' ? 'commercial' : '';
  };

  const buildSubSenderStatusTitle = (orderStatus) => {
    return orderStatus === 'Internal' ? 'This is Internal Order.' : orderStatus === 'Commercial' ? 'This is Commercial Order' : '';
  };

  const buildOrderStateStatusClass = (orderStatus) => {
    return orderStatus === 'Cancel' ? 'cancel' : orderStatus === 'Suspend' ? 'suspend' : orderStatus === 'Trial Extension' ? 'trialExtension' : orderStatus === 'Trial To Paid' ? 'trialToPaid' : orderStatus === 'New' ? 'new' : orderStatus === 'Change' ? 'change' : '';
  };

  const buildOrderStateStatusTitle = (orderStatus) => {
    return orderStatus === 'Cancel' ? 'This is Cancel Order.' : orderStatus === 'Suspend' ? 'This is Suspend Order.' : orderStatus === 'Trial Extension' ? 'This is Trial Extension Order.' : orderStatus === 'Trial To Paid' ? 'This is Trial To Paid Order.' : orderStatus === 'New' ? 'This is New Order.' : orderStatus === 'Change' ? 'This is Change Order.' : '';
  };

  const getOriginalAppType = (renamedApp) => {
    return Object.keys(APPS_TO_RENAME).find(
      (originalAppType) => APPS_TO_RENAME[originalAppType].trim() === renamedApp.trim()
    ) || null;
  };

  const handleGotoPageSubmit = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= pageOptions.length) {
      gotoPage(pageNumber - 1);
      setGotoPageInput('');
    }
  };

  const handleFilterChange = (columnId, value) => {
    let o = { ...filter, [`${columnId}`]: value };
    setFilter(o);
  };

  const handleClearFilters = () => {
    setSearchParams(DEFAULT_FILTER);
    setFilter({ ...DEFAULT_FILTER });
    handleTableChange(pageIndex, pageSize, sortBy, DEFAULT_FILTER);
    gotoPage(0);
    setExtendedNoteFilter({EXTENDED_SUB_NOTE_MODAL_FILTER});
  };

  const handleFilterSubmit = () => {
    setSearchParams(filter);
    handleTableChange(pageIndex, pageSize, sortBy, filter);
    gotoPage(0);
  };

  const handleSortIconClick = (column) => {
    column.toggleSortBy();
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const describeDate = (date) => {
    const currentDate = new Date();
    const providedDate = new Date(date);

    if (providedDate > currentDate) {
      return 'In the future';
    }

    const timeDifference = currentDate.getTime() - providedDate.getTime();
    const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

    if (daysDifference < 30) {
      return `${daysDifference} days ago`;
    } else if (daysDifference < 365) {
      const monthsDifference = Math.floor(daysDifference / 30);
      return `${monthsDifference} months ago`;
    } else {
      const yearsDifference = Math.floor(daysDifference / 365);
      return `${yearsDifference} years ago`;
    }
  };

  const handleMultiItemSelect = (columnId, value) => {
    if (!value) setFilter({ ...filter, [`${columnId}`]: '' });
    else setFilter({ ...filter, [`${columnId}`]: value });
  };

  const handleTableChange = (pageIndex, pageSize, sortBy, filter) => {
    onTableUpdated(pageIndex, pageSize, sortBy, filter, false);
  };

  const validateInputSnote = (input) => {
    if (['where','and',"'","having"].some(keyword => input.toLowerCase().includes(keyword)))
    return false;
  return true;
  }
  


  const buildSubActionPayload = (apps) => {
    if (!apps) return null;
    let jsonApps = null;
    try {
      jsonApps = JSON.parse(apps);
    } catch (e) { }
    if (!jsonApps || !Array.isArray(jsonApps)) return null;
    let atArr = jsonApps.filter(a => a['app']).map(a => Object.values(APPS_TO_RENAME).indexOf(a['app']) !== -1 ? Object.entries(APPS_TO_RENAME).filter(e => e[1] === a['app'])[0][0] : a['app']);
    return atArr.length > 0 ? { 'appType': atArr } : null;
  };

  //#endregion

  //#region data processing

  const interimTableData = data.results ?
    data.results.map(item => ({
      ...item,
      apps: JSON.stringify(
        item.apps && item.apps !== '--' ? JSON.parse(item.apps).filter(a => (!hideInternalApps || APPS_TO_HIDE.indexOf(a['app'] ? a['app'].trim() : a['app']) === -1)) : []
      )
    })) : [];

  const tableData = interimTableData.map(item => ({
    ...item,
    apps: JSON.stringify(JSON.parse(item.apps).map(app => ({
      ...app,
      app: APPS_TO_RENAME[app.app] || app.app
    })))
  }));

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    headers,
    page,
    prepareRow,
    state: { pageIndex, pageSize, sortBy },
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
  } = useTable(
    {
      columns,
      data: tableData || [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
        filter: queryPageFilter
      },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / queryPageSize),
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  //#endregion

  //#region react effects 
  const loadDataCenters = async()=>{
    try{
    const res = await API.get("SspBackendApi", "/data-center",{ queryStringParameters: {
      'stage': STAGE,
      'region': region
    }});
    setDataCentersOptions(res.sort());
  }
  catch(e){
    setDataCentersOptions([]);
    console.log(e);
  }
    };

  const loadCarrierConnections = async () => {
      try{
      const res = await API.get("SspBackendApi", "/carrier-connection",{ queryStringParameters: {
        'stage': STAGE,
        'region': region
      }});
      const filteredCC = new Set();
      res.forEach(cc=>{
        try{
        filteredCC.add(cc.charAt(0).toUpperCase() + cc.slice(1).toLowerCase());
        }
        catch(e){
        }
      });

    setCarrierConnectionOptions(Array.from(filteredCC).sort());
    }
    catch(e) {
      setCarrierConnectionOptions([]);
      console.log(e);
    }
      };


  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
    gotoPage(0);
  }, [sortBy, gotoPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.count,
      });
    }
  }, [data?.count]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    loadDataCenters();
    loadCarrierConnections();
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleTableChange(pageIndex, pageSize, sortBy, filter);
  }, [pageIndex, pageSize, gotoPage, sortBy]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the logic in the first render
      isFirstRender.current = false;
      return;
    }
  
    // Your existing logic
    const { customerType, proServices, ctOp, psOp, text, tOp } = extendedNoteFilter;
    let newNoteFilter = '';
  
    if (customerType) {
      customerType.split(',').map((ct) => {
      newNoteFilter += `customerType${ctOp || '='}${ct},`;
      });
    }
    if (proServices) {
      proServices.split(',').map((ps) => {
      newNoteFilter += `ProfessionalServices${psOp || '='}${ps},`;
      });
    } 
    if (text) {
      newNoteFilter += `note${tOp || '='}${text},`;
    }
    newNoteFilter = newNoteFilter.slice(0, -1);
    if (newNoteFilter) {
      setFilter((prevFilter) => ({ ...prevFilter, snote: newNoteFilter }));
    }
    else{
      const { snote, ...restFilter } = filter;
      setFilter({ ...restFilter });
    }    
  }, [extendedNoteFilter]);
  //#endregion

  return (
    <div className='table-container'>

      <div className='filter-section-title'>
        <div>
          <span className='filter-icon pe-1'><TiFilter /></span><span>Filter</span> <small className='ms-1 toggle' onClick={() => setShowFilter(!showFilter)} >{showFilter ? 'Hide' : 'Show'}</small>
        </div>
      </div>

      <Collapse in={showFilter}>
        <div>
          <div className="filter-section" id="filter-section">
            <div className='d-flex align-items-top justify-content-start'>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '15%' }}>
                <Form.Group controlId="subscriptionId">
                <TruncatedFormLabel>Subscription Id</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['id'] || ''} onChange={event => handleFilterChange('id', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '13%' }}>
                <Form.Group controlId="meaId">
                <TruncatedFormLabel>Mea Id</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['mea_id'] || ''} onChange={event => handleFilterChange('mea_id', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '13%' }}>
                <Form.Group controlId="contractId">
                <TruncatedFormLabel>Contract</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['id2'] || ''} onChange={event => handleFilterChange('id2', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '20%' }}>
              <Form.Group controlId="subscriptionNote">
              <TruncatedFormLabel>Note</TruncatedFormLabel>
                <InputGroup className="mb-3" >
                  <ShakyFormControl size='sm' className='pt-1 pe-1' value={filter.snote|| ''} validate={(value)=>validateInputSnote(value)} onSuccess={(event)=>{setExtendedNoteFilter({...EXTENDED_SUB_NOTE_MODAL_FILTER,text:event.target.value.replace('note=','')})}}/>
                  <Button size='sm' className='pt-1' variant='secondary' onClick={()=>setExtendedNoteFilter({...extendedNoteFilter,show:true})}><FaExpandAlt/></Button>
                </InputGroup>
              </Form.Group>
            </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '13%' }}>
                <Form.Group controlId="accountName">
                <TruncatedFormLabel>Customer</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['name'] || ''} onChange={event => handleFilterChange('name', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '13%' }}>
                <Form.Group controlId="account">
                <TruncatedFormLabel>Account No.</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['account'] || ''} onChange={event => handleFilterChange('account', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '13%' }}>
                <Form.Group controlId="ent-id">
                <TruncatedFormLabel>Enterprise Id</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['eid'] || ''} onChange={event => handleFilterChange('eid', event.target.value)} />
                </Form.Group>
              </Form>
              <div className='me-1' style={{ width: '18%' }}>
                <Form.Group controlId="countryCode">
                <TruncatedFormLabel>Country code</TruncatedFormLabel>
                  <div>
                    <Multiselect
                      onSelect={(selectedList, selectedItem) => handleMultiItemSelect('ccode', selectedList.map(e => e.id).join(','))}
                      onRemove={(selectedList, removedItem) => handleMultiItemSelect('ccode', selectedList.map(e => e.id).join(','))}
                      options={countrycodes.map(c => ({ name: `${c.code} (${c.country})`, id: `${c.code} (${c.country})` }))}
                      selectedValues={filter['ccode'] ? filter['ccode'].split(',').map(s => ({ name: `${s} ${countrycodes.find(c => c.code === s)?.country || ''}`, id: s })) : []}
                      displayValue="name"
                      placeholder=""
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className="filter-multiselect"
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '18%' }}>
                <Form.Group controlId="countryRegion">
                <TruncatedFormLabel>Region</TruncatedFormLabel>
                  <div>
                    <Multiselect
                      onSelect={(selectedList, selectedItem) => handleMultiItemSelect('rgn', selectedList.map(e => e.id).join(','))}
                      onRemove={(selectedList, removedItem) => handleMultiItemSelect('rgn', selectedList.map(e => e.id).join(','))}
                      options={[...new Set(countrycodes.map(c => c.region))].map(region => ({ name: region, id: region }))}
                      selectedValues={filter['rgn'] ? filter['rgn'].split(',').map(s => ({ name: `${s}`, id: s })) : []}
                      displayValue="name"
                      placeholder=""
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className="filter-multiselect"
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '20%' }}>
                <Form.Group controlId="subTrial">
                <TruncatedFormLabel>Trial</TruncatedFormLabel>
                  <div>
                    <Multiselect
                      onSelect={(selectedList, selectedItem) => {
                        const lastSelectedItem = [selectedItem];
                        handleMultiItemSelect('sub_trial', lastSelectedItem.map(e => e.id).join(','));
                      }}
                      onRemove={(selectedList, removedItem) => {
                        if (selectedList.length === 0) {
                          handleMultiItemSelect('sub_trial', '');
                        } else {
                          handleMultiItemSelect('sub_trial', selectedList.map(e => e.id).join(','));
                        }
                      }}
                      options={[{ name: 'Trial', id: 'Y' }, { name: 'No Trial', id: '-' }
                      ]}
                      selectedValues={filter['sub_trial'] ? filter['sub_trial'].split(',').map(s => {
                        return { name: s === '-' ? 'No Trial' : 'Trial', id: s };
                      }) : []}
                      displayValue='name'
                      placeholder=''
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className='filter-multiselect'
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '20%' }}>
                <Form.Group controlId="subSender">
                <TruncatedFormLabel>Subscription Type</TruncatedFormLabel>
                  <div>
                    <Multiselect
                      onSelect={(selectedList, selectedItem) => {
                        // Keep only the last selected item
                        const lastSelectedItem = [selectedItem];
                        handleMultiItemSelect('sub_sender', lastSelectedItem.map(e => e.id).join(','));
                      }}
                      onRemove={(selectedList, removedItem) => {
                        // Handle removal of selected item
                        if (selectedList.length === 0) {
                          handleMultiItemSelect('sub_sender', '');
                        } else {
                          handleMultiItemSelect('sub_sender', selectedList.map(e => e.id).join(','));
                        }
                      }}
                      options={['Commercial','Internal'].map(s => { return { name: s, id: s } })}
                      selectedValues={filter['sub_sender'] ? filter['sub_sender'].split(',').map(s => { return { name: s, id: s } }) : null}
                      displayValue='name'
                      placeholder=''
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className='filter-multiselect'
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '20%' }}>
                <Form.Group controlId="subState">
                  <TruncatedFormLabel>Order State</TruncatedFormLabel>
                  <div>
                    <Multiselect
                      onSelect={(selectedList, selectedItem) => {
                        // Keep only the last selected item
                        const lastSelectedItem = [selectedItem];
                        handleMultiItemSelect('last_order_state', lastSelectedItem.map(e => e.id).join(','));
                      }}
                      onRemove={(selectedList, removedItem) => {
                        // Handle removal of selected item
                        if (selectedList.length === 0) {
                          handleMultiItemSelect('last_order_state', '');
                        } else {
                          handleMultiItemSelect('last_order_state', selectedList.map(e => e.id).join(','));
                        }
                      }}
                      options={['New', 'Change', 'Trial Extension', 'Trial To Paid', 'Suspend', 'Cancel'].map(s => { return { name: s, id: s } })}
                      selectedValues={filter['last_order_state'] ? filter['last_order_state'].split(',').map(s => { return { name: s, id: s } }) : null}
                      displayValue='name'
                      placeholder=''
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className='filter-multiselect'
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '22%' }}>
                <Form.Group controlId="applications">
                <TruncatedFormLabel>Applications</TruncatedFormLabel>
                  <div>
                    <Multiselect
                      onSelect={(selectedList, selectedItem) => handleMultiItemSelect('app', selectedList.map(e => e.id).join(','))}
                      onRemove={(selectedList, removedItem) => handleMultiItemSelect('app', selectedList.map(e => e.id).join(','))}
                      options={apps.map(app => { return { name: `${app.applicationCommercialName} (${app.applicationType})`, id: app.applicationType } })}
                      selectedValues={filter['app'] ? filter['app'].split(',').map(app => { return { name: `${apps.filter(a => a.applicationType === app)[0]['applicationCommercialName']} (${app})`, id: app } }) : null}
                      displayValue='name'
                      placeholder=''
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className='filter-multiselect'
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className='d-flex align-items-top justify-content-start mt-3'>
              <div className='me-1' style={{ width: '15%' }}>
                <Form.Group controlId="appStats">
                <TruncatedFormLabel>State</TruncatedFormLabel>
                  <div>
                    <Multiselect
                      onSelect={(selectedList, selectedItem) => handleMultiItemSelect('state', selectedList.map(e => e.id).join(','))}
                      onRemove={(selectedList, removedItem) => handleMultiItemSelect('state', selectedList.map(e => e.id).join(','))}
                      options={['SUCCESS', 'IN_PROGRESS', 'FAIL'].map(s => { return { name: s, id: s } })}
                      selectedValues={filter['state'] ? filter['state'].split(',').map(s => { return { name: s.toUpperCase(), id: s.toUpperCase() } }) : null}
                      displayValue='name'
                      placeholder=''
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className='filter-multiselect'
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />
                  </div>
                </Form.Group>
              </div>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} style={{ width: '15%' }} className='me-1'>
                <Form.Group controlId="appInstance">
                <TruncatedFormLabel>Data center</TruncatedFormLabel>
                  <div>
                  <Multiselect
                      onSelect={(selectedList, selectedItem) => handleMultiItemSelect('instance', selectedList.map(e => e.id).join(','))}
                      onRemove={(selectedList, removedItem) => handleMultiItemSelect('instance', selectedList.map(e => e.id).join(','))}
                      options={dataCentersOptions.map(dc => { return { name: dc, id: dc } })}
                      selectedValues={filter['instance'] ? filter['instance'].split(',').map(app=>{return {name:app,id:app}}) : null}
                      displayValue='name'
                      placeholder=''
                      groupBy='offer'
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className='filter-multiselect'
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />
                  </div>
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} style={{ width: '15%' }} className='me-1'>
                <Form.Group controlId="carrier-connection">
                <TruncatedFormLabel>Carrier connections</TruncatedFormLabel>
                  <div>
                  <Multiselect
                      onSelect={(selectedList, selectedItem) => handleMultiItemSelect('carrier_connection', selectedList.map(e => e.name).join(','))}
                      onRemove={(selectedList, removedItem) => handleMultiItemSelect('carrier_connection', selectedList.map(e => e.name).join(','))}
                      options={carrierConnectionOptions.map(cc => { return { name: cc, id: cc } })}
                      selectedValues={filter['carrier_connection'] ? filter['carrier_connection'].split(',').map(o => { return {name:o} }) : null}
                      displayValue='name'
                      placeholder=''
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className='filter-multiselect'
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />
                  </div>
                </Form.Group>
              </Form>

              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '15%' }}>
                <Form.Group controlId="app-account">
                <TruncatedFormLabel>Application Account</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['app_acc'] || ''} onChange={event => handleFilterChange('app_acc', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '15%' }}>
                <Form.Group controlId="bp-link-id">
                <TruncatedFormLabel>Business partner id</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['bp_link_id'] || ''} onChange={event => handleFilterChange('bp_link_id', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '15%' }}>
                <Form.Group controlId="customer-domain">
                <TruncatedFormLabel>Customer domain</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['customer_domain'] || ''} onChange={event => handleFilterChange('customer_domain', event.target.value)} />
                </Form.Group>
              </Form>
              <div className='me-1' style={{ width: '20%' }}>
                <Form.Group controlId="offers">
                <TruncatedFormLabel>Offers</TruncatedFormLabel>
                  <div>
                    <Multiselect
                      onSelect={(selectedList, selectedItem) => handleMultiItemSelect('system', selectedList.map(e => e.id).join(','))}
                      onRemove={(selectedList, removedItem) => handleMultiItemSelect('system', selectedList.map(e => e.id).join(','))}
                      options={offers.map(o => { return { name: `${o.offerCommercialName} (${o.offerSystemType})`, id: o.offerSystemType } })}
                      selectedValues={filter['system'] ? filter['system'].split(',').map(o => { return { name: `${offers.filter(offer => offer.offerSystemType === o)[0]['offerCommercialName']} (${o})`, id: o } }) : null}
                      displayValue='name'
                      placeholder=''
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className='filter-multiselect'
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '20%' }}>
                <Form.Group controlId="bundles">
                <TruncatedFormLabel>Bundles</TruncatedFormLabel>
                  <div>
                    <Multiselect
                      onSelect={(selectedList, selectedItem) => handleMultiItemSelect('service', selectedList.map(e => e.id).join(','))}
                      onRemove={(selectedList, removedItem) => handleMultiItemSelect('service', selectedList.map(e => e.id).join(','))}
                      options={offers.map(o => o.offerBundles.map(ob => { return { name: `${bundleMap.get(ob.bundleId).bundleCommercialName} (${bundleMap.get(ob.bundleId).bundleServiceType})`, id: bundleMap.get(ob.bundleId).bundleServiceType, offer: o.offerCommercialName } })).flat()}
                      selectedValues={filter['service'] ? filter['service'].split(',').map(b => { return { name: `${bundles.filter(bundle => bundle.bundleServiceType === b)[0]['bundleCommercialName']} (${b})`, id: b } }) : null}
                      displayValue='name'
                      placeholder=''
                      groupBy='offer'
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className='filter-multiselect'
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className='d-flex align-items-bottom justify-content-between mt-3'>
              <div className='d-flex align-items-center justify-content-start w-100'>
              <div className='me-1' style={{ width: '15%' }}>
                <Form.Group controlId="createdOn">
                <TruncatedFormLabel>Created on</TruncatedFormLabel>
                  <div>
                    <Flatpickr
                      value={filter['created']}
                      defaultValue={null}
                      onChange={(date) => handleFilterChange('created', date.map(d => formatDate(new Date(d))))}
                      className='form-control form-control-sm'
                      placeholder="Select date or range"
                      options={{
                        enableTime: false,
                        dateFormat: "Y-m-d",
                        altInput: true,
                        altFormat: 'd-M-y',
                        maxDate: formatDate(new Date()),
                        mode: 'range'
                      }}
                    />
                  </div>
                </Form.Group>
              </div>
                <div className='me-1' style={{ width: '15%' }}>
                  <Form.Group controlId="startDate">
                  <TruncatedFormLabel>Start date</TruncatedFormLabel>
                    <div>
                      <Flatpickr
                        value={filter['start']}
                        defaultValue={null}
                        onChange={(date) => handleFilterChange('start', date.map(d => formatDate(new Date(d))))}
                        className='form-control form-control-sm'
                        placeholder="Select date or range"
                        options={{
                          enableTime: false,
                          dateFormat: "Y-m-d",
                          altInput: true,
                          altFormat: 'd-M-y',
                          mode: 'range'
                        }}
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className='me-1' style={{ width: '15%' }}>
                  <Form.Group controlId="startDate">
                  <TruncatedFormLabel>End date</TruncatedFormLabel>
                    <div>
                      <Flatpickr
                        value={filter['end']}
                        defaultValue={null}
                        onChange={(date) => handleFilterChange('end', date.map(d => formatDate(new Date(d))))}
                        className='form-control form-control-sm'
                        placeholder="Select date or range"
                        options={{
                          enableTime: false,
                          dateFormat: "Y-m-d",
                          altInput: true,
                          altFormat: 'd-M-y',
                          mode: 'range'
                        }}
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className='me-1' style={{ width: '15%' }}>
                  <Form.Group controlId="lastUpdated">
                  <TruncatedFormLabel>Last updated</TruncatedFormLabel>
                    <div>
                      <Flatpickr
                        value={filter['updated']}
                        defaultValue={null}
                        onChange={(date) => handleFilterChange('updated', date.map(d => formatDate(new Date(d))))}
                        className='form-control form-control-sm'
                        placeholder="Select date or range"
                        options={{
                          enableTime: false,
                          dateFormat: "Y-m-d",
                          altInput: true,
                          altFormat: 'd-M-y',
                          maxDate: formatDate(new Date()),
                          mode: 'range'
                        }}
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className='me-1' style={{ width: '7.5%' }}>
                  <Form.Group controlId="hidePPUOffers">
                    <Form.Label>Hide PPU offers</Form.Label>
                    <Form.Check type="checkbox" onChange={(e) => { handleFilterChange('show_ppu', !e.target.checked) }} checked={!Utils.parseBoolean(filter['show_ppu'])} className="ms-1 internal-app-checkbox" name='hide-ppu-offers' id='hide-ppu-offers' />
                  </Form.Group>
                </div>
                <div style={{ width: '10%' }}>
                  <Form.Group controlId="hideInternalApps">
                    <Form.Label>Hide Internal Apps</Form.Label>
                    <Form.Check type="checkbox" onChange={(e) => { setHideInternalApps(e.target.checked) }} checked={hideInternalApps} className="ms-1 internal-app-checkbox" name='hide-internal-apps' id='hide-internal-apps' />
                  </Form.Group>
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-end'>
                <div className='me-2'>
                  <Button size='sm' className='pt-1' variant="secondary" onClick={() => handleFilterSubmit()}>Filter</Button>
                </div>
                <div className=''>
                  <Button size='sm' className='pt-1' variant="secondary" onClick={() => handleClearFilters()}>Reset</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>

      <div className={isLoading ? 'table-wrap loading' : 'table-wrap'}>
        <Table {...getTableProps()} className='sub-table'>
          <thead className="thead-dark">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className='align-middle'>
                {headerGroup.headers.map((column, index) => (
                  <th key={index} {...column.getHeaderProps({
                    className: column.collapse ? 'collapse px-1' : 'px-1',
                    style: { width: column.width }
                  })}>
                    <div className="d-flex align-items-center justify-content-between" onClick={() => { if (!column.disableSortBy) handleSortIconClick(column) }} style={{ cursor: 'pointer' }}>
                      <div>
                        <span>{column.render('Header')}</span>
                      </div>
                      <div>
                        {
                          !column.disableSortBy &&
                          <Button variant="link" className="p-0" title={`Sort by ${column.id}`} style={{ color: '#666' }}>
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TiArrowSortedDown />
                                ) : (
                                  <TiArrowSortedUp />
                                )
                              ) : (
                                <>
                                  <TiArrowUnsorted className="text-muted" />
                                </>
                              )}
                            </span>
                          </Button>
                        }
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className='align-middle'>
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps({
                        className: cell.column.collapse ? 'collapse px-1' : 'px-1',
                        style: { width: cell.column.width }
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
            {
              page.length === 0 &&
              <tr className='align-middle text-center'>
                <td colSpan={headers.length}>No Subscription records found.</td>
              </tr>
            }
          </tbody>
        </Table>

        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="d-flex align-items-center justify-content-start">
            <ButtonGroup size="sm" className='me-2'>
              <Button variant="secondary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>First</Button>
              <Button variant="secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
              <Button variant="secondary" onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
              <Button variant="secondary" onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>Last</Button>
            </ButtonGroup>
            <div className='me-2'>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </div>
            <div>
              <Form onSubmit={handleGotoPageSubmit}>
                <Form.Group className="mb-0 ms-1" style={{ width: '150%' }}>
                  <Form.Control size="sm" type="number" min="1" max={pageOptions.length} placeholder="Go to page" value={gotoPageInput} onChange={(e) => setGotoPageInput(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
          <div className='d-flex'><div className="me-2 mb-0 ms-1 mt-1">Total records: <strong>{totalRecords}</strong></div>
            <Dropdown className='pagesize-dropdown'>
              <Dropdown.Toggle variant="secondary" id="page-size-dropdown" size="sm">Show {pageSize}</Dropdown.Toggle>
              <Dropdown.Menu>
                {[5, 10, 15].map(pageSizeOption => (
                  <Dropdown.Item
                    key={pageSizeOption}
                    active={pageSizeOption === pageSize}
                    onClick={() => setPageSize(pageSizeOption)}
                  >
                    Show {pageSizeOption}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

      </div>

      {/* Subscription Note modal */}
      <Modal size='md' show={subNoteModal['show']} onHide={() => hideSubNoteModal()} backdrop="static" keyboard={false} className='sub-note-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Subscription Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {
              subNoteModalAlert['show'] &&
              <Row className='m-0'>
                <Col className='sub-note-col'>
                  <Alert variant={subNoteModalAlert['type']} show={true} dismissible={false}>
                    <div className='d-flex align-items-center justify-content-start'>
                      {
                        subNoteModalAlert['type'] === 'info' &&
                        <Spinner size='sm' animation="border" className='me-2' role="status"></Spinner>
                      }
                      <div>{subNoteModalAlert['message']}</div>
                    </div>
                  </Alert>
                </Col>
              </Row>
            }
            <Row>
              <Col className='sub-note-col' md={3}>Subscription Id</Col>
              <Col className='sub-note-col' md={9}>
                <Form.Control size='sm' type="text" value={subNoteModal['sid']} disabled={true} />
              </Col>
            </Row>
            <Row>
              <Col className='sub-note-col' md={3}>Note</Col>
              <Col className='sub-note-col' md={9}>
                <Form.Control title='Note text' disabled={!(RBACUtils.isSubNotesAdministrator(username) || RBACUtils.hasRole(username, 'ADMINISTRATOR'))} placeholder="Type subscription note here" size='sm' as="textarea" rows={3} value={subNoteModal['note']} onChange={(e) => setSubNoteModal({ ...subNoteModal, note: e.target.value })} />
              </Col>
            </Row>
            {
              subNoteModal['updated_by'] &&
              <Row className='m-0'>
                <Col className='sub-note-col'>
                  <div className='d-flex align-items-center justify-content-end note-info'>
                    <div title={`Last updated at: ${new Date(`${subNoteModal['updated_at']}Z`)}`}>{describeDate(`${subNoteModal['updated_at']}Z`)}</div>
                    <div className='note-info-dot'><BsDot /></div>
                    <div title={`Last updated by: ${subNoteModal['updated_by']}`}>{subNoteModal['updated_by']}</div>
                  </div>
                </Col>
              </Row>
            }
          </Container>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <div>
            {
              subNoteModal['note'] && (RBACUtils.isSubNotesAdministrator(username) || RBACUtils.hasRole(username, 'ADMINISTRATOR')) &&
              <Button variant="danger" size='sm' onClick={() => removeSubnote()}>Remove</Button>
            }
          </div>
          <div className='d-flex align-items-center justify-content-end'>
            {
              (RBACUtils.isSubNotesAdministrator(username) || RBACUtils.hasRole(username, 'ADMINISTRATOR')) &&
              <Button variant="secondary" size='sm' onClick={() => { !subNoteModal['updated_at'] ? createSubNote() : updateSubNote() }}>Save</Button>
            }
            <Button variant="secondary" size='sm' className='ms-2' onClick={() => hideSubNoteModal()}>Close</Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* extended note filter modal */}
      <Modal
  size="md"
  show={extendedNoteFilter["show"]}
  backdrop="static"
  onHide={() => setExtendedNoteFilter({ ...extendedNoteFilter, show: false })}
  keyboard={false}
  className="sub-note-modal"
>
  <Modal.Header closeButton>
    <Modal.Title>Build note filter</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Container>
      <Row>
        <Col md={5}>CustomerType</Col>
        <Col>
          <Dropdown as={InputGroup.Prepend}>
            <Dropdown.Toggle
              style={{ borderColor: "rgb(207,212,213)" }}
              className="snote-filter-toggle"
              split
              variant="outline-secondary"
            >
              {extendedNoteFilter.ctOp ? extendedNoteFilter.ctOp : "="}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                key={"="}
                disabled={extendedNoteFilter.ctOp === "="}
                title="Includes"
                onClick={() =>
                  setExtendedNoteFilter({ ...extendedNoteFilter, ctOp: "=" })
                }
              >
                =
              </Dropdown.Item>
              <Dropdown.Item
                disabled={extendedNoteFilter.ctOp === "!"||extendedNoteFilter.customerType === ""}
                key={"!"}
                title="Not contain"
                onClick={() =>
                  setExtendedNoteFilter({ ...extendedNoteFilter, ctOp: "!" })
                }
              >
                !
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="sub-note-col">
        <Multiselect
        onSelect={(selectedList, selectedItem) => setExtendedNoteFilter({...extendedNoteFilter, customerType:selectedList.map(e => e.id).join(',')})}
        onRemove={(selectedList, selectedItem) => setExtendedNoteFilter({...extendedNoteFilter, customerType:selectedList.map(e => e.id).join(',')})}
        options={["Partner","End Customer","End Customer (Seeding)"].map(s => { return { name: s, id: s } })}
        selectedValues={extendedNoteFilter.customerType ? extendedNoteFilter.customerType.split(',').map(ct => { return { name: ct, id: ct } }) : null}
        displayValue='name'
        placeholder=''
        groupBy='offer'
        showCheckbox={true}
        caseSensitiveSearch={false}
        className='filter-multiselect'
        avoidHighlightFirstOption={true}
        hidePlaceholder={true}
        showArrow={true}
        />
        </Col>
      </Row>
      <Row>
        <Col md={5}>ProfessionalServices</Col>
        <Col>
          <Dropdown as={InputGroup.Prepend}>
            <Dropdown.Toggle
              style={{ borderColor: "rgb(207,212,213)" }}
              className="snote-filter-toggle"
              split
              variant="outline-secondary"
            >
              {extendedNoteFilter.psOp ? extendedNoteFilter.psOp : "="}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
              disabled={extendedNoteFilter.psOp === "="}
                key={"="}
                title="Includes"
                onClick={() =>
                  setExtendedNoteFilter({ ...extendedNoteFilter, psOp: "=" })
                }
              >
                =
              </Dropdown.Item>
              <Dropdown.Item
              disabled={extendedNoteFilter.psOp === "!"||extendedNoteFilter.proServices === ""}
                key={"!"}
                title="Not contain"
                onClick={() =>
                  setExtendedNoteFilter({ ...extendedNoteFilter, psOp: "!" })
                }
              >
                !
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="sub-note-col">
        <Multiselect
        onSelect={(selectedList, selectedItem) => setExtendedNoteFilter({...extendedNoteFilter, proServices:selectedList.map(e => e.id).join(',')})}
        onRemove={(selectedList, selectedItem) => setExtendedNoteFilter({...extendedNoteFilter, proServices:selectedList.map(e => e.id).join(',')})}
        options={["Basic","Premium"].map(s => { return { name: s, id: s } })}
        selectedValues={extendedNoteFilter.proServices ? extendedNoteFilter.proServices.split(',').map(ct => { return { name: ct, id: ct } }) : null}
        displayValue='name'
        placeholder=''
        groupBy='offer'
        showCheckbox={true}
        caseSensitiveSearch={false}
        className='filter-multiselect'
        avoidHighlightFirstOption={true}
        hidePlaceholder={true}
        showArrow={true}
        />
        </Col>
      </Row>
      <Row>
        <Col md={5}>Text</Col>
        <Col>
          <Dropdown as={InputGroup.Prepend}>
            <Dropdown.Toggle
              style={{ borderColor: "rgb(207,212,213)" }}
              className="snote-filter-toggle"
              split
              variant="outline-secondary"
            >
              {extendedNoteFilter.tOp ? extendedNoteFilter.tOp : "="}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
              disabled={extendedNoteFilter.tOp === "="}
                key={"="}
                title="Includes"
                onClick={() =>
                  setExtendedNoteFilter({ ...extendedNoteFilter, tOp: "=" })
                }
              >
                =
              </Dropdown.Item>
              <Dropdown.Item
              disabled={extendedNoteFilter.tOp === "!"}
                key={"!"}
                title="Not contain"
                onClick={() =>
                  setExtendedNoteFilter({ ...extendedNoteFilter, tOp: "!" })
                }
              >
                !
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="sub-note-col">
        <ShakyFormControl 
              title ="Note text"
              value={extendedNoteFilter.text}
              size='sm'
              onSuccess={(e) => {
              setExtendedNoteFilter({
                ...extendedNoteFilter,
                text: e.target.value,
              });
            }}
            validate={(value)=>validateInputSnote(value)} />
        </Col>
      </Row>
      <Row>
        <Form.Control
          title="Note text"
          disabled={true}
          placeholder="Filter"
          size="sm"
          as="textarea"
          rows={1}
          value={filter.snote}
        />
      </Row>
    </Container>
  </Modal.Body>
  <Modal.Footer className="justify-content-between">
    <div className="d-flex align-items-center justify-content-end">
      <Button
        variant="secondary"
        size="sm"
        className="ms-2"
        onClick={() =>
          setExtendedNoteFilter({
            ...EXTENDED_SUB_NOTE_MODAL_FILTER,
            show: true,
          })
        }
      >
        Reset
      </Button>
      <Button
        variant="secondary"
        size="sm"
        className="ms-2"
        onClick={() =>
          setExtendedNoteFilter({ ...extendedNoteFilter, show: false })
        }
      >
        Set
      </Button>
    </div>
    </Modal.Footer>
      </Modal>
      <Modal dialogClassName='left-aligned-modal' show={showCompareModal.show} onHide={()=>setShowCompareModal({show:false,id:'',type:''})}>
        <Modal.Header style={{background:'rgb(238,238,238)',width:'80vw'}}  closeButton>
          <Modal.Title>Compare Subscription State</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background:'white',width:'80vw',height:'80vh',overflow:'auto'}}>
          <CompareOrdersModal sub={showCompareModal.id} stage={STAGE} region={region} 
        />
        </Modal.Body>
      </Modal>


    </div>
  );

};

export default DataTable;
