import { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import LeftSideBar from "../common/LeftSideBar";

import { Amplify, API } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { TiArrowLeft } from 'react-icons/ti';
import { GrEdit } from 'react-icons/gr';
import { RiDeleteBinLine } from 'react-icons/ri';
// import { IoPersonAdd } from 'react-icons/io5';

import * as Utils from "../../common/utils";
import awsExports from '../../aws-exports';

import azure_regions from '../../data/cloud-provider-regions/azure_regions.json'
import aws_regions from '../../data/cloud-provider-regions/aws_regions.json'
import gcp_regions from '../../data/cloud-provider-regions/gcp_regions.json'

import { useNavigate } from 'react-router-dom';

import '../../scss/envs.scss';

import axios from 'axios';

Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true
}

const STAGE = Utils.getCurrentStage();
const RANDOM_CODE = (() => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let code = '';
  for (let i = 0; i < 5; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }
  return code.toLowerCase();
})();

const RANDOM_DIGITS = (() => {
  const characters = '0123456789';
  let code = '';
  for (let i = 0; i < 3; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }
  return code.toLowerCase();
})();

const ENV_TYPES = [ 
  {
    name: "Sandbox",
    shortCode: "sbx"
  },
  {
    name: "Pre-Prod",
    shortCode: "preprod"
  },
  {
    name: "Production",
    shortCode: "prod"
  }
];


const CreateEnv = ({ signOut, user }) => {

  const navigate = useNavigate();

  const DEFAULT_ENV_DETAILS = {
    env_name: '',
    env_type: "Sandbox",
    env_owner: user.attributes.email,
    expense_type: "ics",
    tags: [{ key: 'env_type', value: 'sandbox', type: 'system' }, { key: 'exp_type', value: 'ics', type: 'system' }],
    management_group: "",
    mgmt_groups: [],
    description: '',
    env_owner_oid: "",
    acc_name: '',
    acc_vendor: "azure",
    acc_tenant: "valdis",
    acc_region: azure_regions[0]['Name'],
    acc_deployment_id: `${crypto.randomUUID()}`,
    customer_code: "",
    acc_observability: "NATIVE",
    acc_pim_access: false,
    acc_defender: false,
    acc_pcihipaa: false,
    acc_fedramp: false,
    acc_compliance: 'Non-Regulatory',
    acc_orchestration: false,
    github_team: "",
    github_teams: [],
    terraform_team: "",
    terraform_teams: [],
    prod_id: "",
    env_budget: 0.00,
    acc_mod_storage: false,
    acc_mod_database: false,
    acc_mod_kubernetes: false,
    acc_mod_vm: false,
    acc_mod_networking: false,
    jira_id: "",
    getcloud_id: ""
  };

  // const DEFAULT_ADDMGROUP = { name: "", env_type: 'Sandbox', compliance: 'Non-Regulatory', message: '', is_error: false, save_enabled: true };

  const DEFAULT_STATUS = { show: false, type: '', message: '' };

  const [envDetails, setEnvDetails] = useState(DEFAULT_ENV_DETAILS);
  const [avayaProds, setAvayaProds] = useState([]);
  const [managementGroups, setManagementGroups] = useState([]);
  // const [addMGroup, setAddMGroup] = useState(DEFAULT_ADDMGROUP);
  const [token, setToken] = useState('');
  const [status, setStatus] = useState(DEFAULT_STATUS);
  const [modalOptions, setModalOptions] = useState({ tags: false, users: false, mgmt_groups: false, github_team: false, terraform_team: false, deploy_trigger: false, submit: false});
  const [valErrors, setValErrors] = useState([]);
  const [localUTags, setLocalUTags] = useState([]);
  const [envUsers, setEnvusers] = useState([]);
  // const [localEnvUsers, setLocalEnvusers] = useState([]);
  const [githubTeams, setGithubTeams] = useState([]);
  const [terraformTeams, setTerraformTeams] = useState([""]);
  const [newGithubTeam, setNewGithubTeam] = useState("");
  const [localNewGithubTeam, setLocalNewGithubTeam] = useState("");
  const [githubTeamMembers, setGithubTeamMembers] = useState([]);
  const [localGithubTeamMembers, setLocalGithubTeamMembers] = useState([]);
  const [newTerraformTeam, setNewTerraformTeam] = useState("");
  const [localNewTerraformTeam, setLocalNewTerraformTeam] = useState("");
  const [terraformTeamMembers, setTerraformTeamMembers] = useState([]);
  const [localTerraformTeamMembers, setLocalTerraformTeamMembers] = useState([]);
  const [isJiraValidated, setIsJiraValidated] = useState(false);

  const ENV_FORM_DATA = {
    "jiraValidation": {
      "title": "JIRA Validation",
      "fields": [
        {
          "id": "jira_id",
          "title": "JIRA ID",
          "type": "text"
        },
        
        {
          "id": "getcloud_id",
          "title": "GetCloud ID",
          "type": "text"
        },
        

        // {
        //   "id": "validate_jira",
        //   "title": isJiraValidated? "Validated": "Validate JIRA ID",
        //   "label": "Validate",
        //   "type": "button",
        //   "onClickFn": async () => {
        //     console.log(envDetails.jira_id)
        //     await API.post('SspBackendApi', `/environments/jira-status/${envDetails.jira_id}`, {
        //       headers: {
        //         "Content-Type": "application/json"
        //       }
        //     }).then(response => {
        //       console.log(JSON.stringify(response));
        //     }).catch(error => {
        //       console.error(JSON.stringify(error));
        //     });
        //   }
        // }
      ]
    },
    "budgetDetails": {
      "title": "Budget details",
      "fields": [
        {
          "id": "prod_id",
          "title": "Choose product",
          "type": "select",
          "options": avayaProds.filter(e => {return e.prod_code.startsWith("aec") || e.prod_code.startsWith("axp")}).map(e => { return { "id": `${[e.prod_name]} (${e.prod_code})`, "value": [e.prod_id] } })
        }
       
      ]
    },
    "cspDetails": {
      "title": "Cloud Service Provider details",
      "fields": [       
        {
          "id": "acc_vendor",
          "title": "Cloud platform",
          "type": "select",
          "options": [
            { "id": "Azure", "value": "azure" }
          ]
        },        
        {
          "id": "acc_region",
          "title": "Account region",
          "type": "select",
          "options": () => {
            switch (envDetails['acc_vendor']) {
              case "aws":
              case "gcp":
                return ((arr) => {
                  const uniquePairs = new Set(arr.map(obj => `${obj.region}:::${obj.name}`));
                  return Array.from(uniquePairs).map(pairString => {
                    const [region, name] = pairString.split(':::');
                    return { region, name };
                  });
                })(envDetails['acc_vendor'] === 'aws' ? aws_regions : gcp_regions).map(i => ({ "id": i.name, "value": i.region }));
              case "azure":
                return azure_regions.map(i => ({ "id": i.DisplayName, "value": i.Name }));
              default:
                return [];
            }
          }
        },
        {
          "id": "acc_tenant",
          "title": "Tenant",
          "type": "select",
          "options": [
            {"id": "Valdis", "value": "valdis"},
            {"id": "Corporate", "value": "corporate"}
          ]
        }
      ]
    },
    "envDetails": {
      "title": "Environment details",
      "fields": [        
        {
          "id": "env_type",
          "title": "Environment type",
          "type": "select",
          "options": () => {
            return ENV_TYPES.map(item => ({ id: `${item.name} (${item.shortCode})`, value: item.name }))
          }
        },
        {
          id: "acc_compliance",
          title: "Compliance",
          type: "buttongroup",
          options: ["Regulatory", "Non-Regulatory"],
          colWidth: 3
        },
        {
          "id": "env_owner",
          "title": "Environment owner",
          "type": "text",
          "disabled": true
        },
        {
          "id": "management_group",
          "title": "Management Group",
          "type": "select",
          "options": () => {
            return managementGroups.map(item => ({ id: `${item.display_name}`, value: item.id }))
          }
        },
        // {
        //   "id": "add_management_group",
        //   "title": "Add Management Group",
        //   "type": "button",
        //   "onClickFn": () => {            
        //     showMGModal();
            
        //   }          
        // },
        {
          "id": "description",
          "title": "Description (Business case)",
          "type": "textarea"
        }
      ]
    },
    "accDetails": {
      "title": "Subscription details",
      "fields": [        
        {
          "id": "acc_name",
          "title": "Subscription name",
          "type": "text",
          "disabled": true
        },
        {
          "id": "customer_code",
          "title": "Customer code",
          "type": "text"          
        },
        {
          "id": "expense_type",
          "title": "Expense type",
          "type": "select",
          "options": [
            { "id": "ICS", "value": "ics" },
            { "id": "COGS", "value": "cogs" },
          ]
        }
        // {
        //   "id": "env_budget",
        //   "title": "Environment budget (Monthly)",
        //   "type": "number"
        // }
        // {
        //   "id": "env_owner_oid",
        //   "title": "Owner Object Id",
        //   "type": "text"
        // }
        // {
        //   "id": "Users",
        //   "title": "Environment users",
        //   "type": "users"
        // }
      ],
      "subModules": [
        {
          title: "Add ons",
          fields: [ 
            // {
            //   "id": "acc_observability",
            //   "title": "Observability",
            //   "type": "buttongroup",
            //   "options": ["DATADOG", "NATIVE"],
            //   colWidth: 2
            // },
            {
              id: "acc_orchestration",
              title: "Orchestration",
              type: "check",
              colWidth: 2
            },
            // {
            //   id: "acc_defender",
            //   title: "Enable Defender",
            //   type: "check",
            //   colWidth: 2
            // },
            {
              id: "github_team",
              title: "Choose GitHub team",
              type: "select",
              disabled: envDetails['acc_orchestration'] === false ? true : false,
              options: () => {
                return githubTeams.map(item => ({ id: item, value: `${item.replaceAll(" ", "-").toLowerCase()}`}))
              }
            },
            {
              id: "add_github_team",
              title: "Create GitHub Team",
              type: "gh_team",
              disabled: envDetails['acc_orchestration'] === false ? true : false,
            },
            {
              id: "terraform_team",
              title: "Choose Terraform team",
              type: "select",
              disabled: envDetails['acc_orchestration'] === false ? true : false,
              options: () => {
                return terraformTeams.map(item => ({ id: item, value: item}))
              }
            },
            {
              id: "add_terraform_team",
              title: "Create Terraform Team",
              type: "tf_team",
              disabled: envDetails['acc_orchestration'] === false ? true : false,
            }
            // {
            //   id: "acc_pim_access",
            //   title: "PIM Access",
            //   type: "check",
            //   colWidth: 2
            // },
            // {
            //   id: "acc_pcihipaa",
            //   title: "PCI HIPAA",
            //   type: "check",
            //   disabled: envDetails['acc_compliance'] === 'Non-Regulatory' ? true : false,
            //   colWidth: 2
            // },
            // {
            //   id: "acc_fedramp",
            //   title: "FEDRAMP",
            //   type: "check",
            //   disabled: envDetails['acc_compliance'] === 'Non-Regulatory' ? true : false,
            //   colWidth: 2
            // }
          ]
        },
        {
          title: "Modules",
          fields: [  
            // {
            //   "id": "acc_deployment_id",
            //   "title": "Deployment Id",
            //   "type": "text",              
            //   "disabled": true
            // },    
            {
              id: "acc_mod_storage",
              title: "Storage",
              type: "check",
              colWidth: 2
            },
            // {
            //   id: "acc_mod_database",
            //   title: "Database",
            //   type: "check",
            //   colWidth: 2
            // },
            {
              id: "acc_mod_kubernetes",
              title: "Kubernetes",
              type: "check",
              colWidth: 2
            },
            {
              id: "acc_mod_vm",
              title: "Virtual Machine",
              type: "check",
              colWidth: 2
            },
            // {
            //   id: "acc_mod_networking",
            //   title: "Networking",
            //   type: "check",
            //   colWidth: 2
            // },            
            {
              "id": "tags",
              "title": "Tags",
              "type": "tags"
            },
          ]
        }       
      ]
    }
    
  };

  //#region states
  // const validateJira = (jira_id) => {
  //   console.log(jira_id)
  // }
  const generateEnvName = (prod, etype, customer_code) => {
    let prod_name = prod || 'na';
    let random_code = RANDOM_CODE;
    return [prod_name, customer_code, etype, random_code].join('-');
  };

  const generateAccName = (prod, etype, customercode) => {
    let prod_name = prod || 'na';
    return [prod_name, customercode, etype].join('-');
  };

  // You must return on an y validation failure after you register error to valErrors
  const doFormValidation = (k, v) => {
    switch (k) {
      case 'env_budget':
        let pbe = fetchBudgetForSelProd('E'), pbc = fetchBudgetForSelProd('C'), eb = v;
        let isEnvBudgetValid = envDetails['expense_type'] === 'ics' ? parseFloat(eb) <= parseFloat(pbe) : envDetails['expense_type'] === 'cogs' ? parseFloat(eb) <= parseFloat(pbc) : true;
        if (!isEnvBudgetValid) {
          setValErrors([
            ...valErrors,
            {
              id: k,
              type: 'error',
              message: `Environment monthly budget can't exceed monthly product budget`
            }
          ]);
          return;
        }
        break;
      case 'expiry':
        if (parseInt(v) <= 0 || parseInt(v) > 3650) {
          setValErrors([
            ...valErrors,
            {
              id: k,
              type: 'error',
              message: `Please enter valid expiry days between 1 - 3650 (10 Years).`
            }
          ]);
          return;
        }
        break;
      case 'acc_name':
        // console.log('acc_name length = ' + v.length)
        // if (v.length > 17) {
        //   console.log('acc_name length = ' + v.length)
        //   setValErrors([
        //     ...valErrors,
        //     {
        //       id: k,
        //       type: 'error',
        //       message: `Account name can not be larger than 17 characters.`
        //     }
        //   ]);
        //   return;
        // }
        break;
      default:
    }
    if (valErrors.filter(e => e.id === k).length > 0) setValErrors(valErrors.filter(e => e.id !== k));
  };

  const handleFormChange = (key, value) => {
    doFormValidation(key, value);

    const keyAndVals = [[key, value]];
    switch (key) {
      case 'prod_id':
        keyAndVals.push(['env_name', generateEnvName(avayaProds.filter(p => p.prod_id === value)[0].prod_code, ENV_TYPES.filter(p => p.name === envDetails['env_type'])[0]?.shortCode  || 'sbx', envDetails['customer_code'])])
        keyAndVals.push(['acc_name', generateAccName(avayaProds.filter(p => p.prod_id === value)[0].prod_code, ENV_TYPES.filter(p => p.name === envDetails['env_type'])[0]?.shortCode  || 'sbx', envDetails['customer_code'])])
        break;
      case 'acc_compliance':
        keyAndVals.push(['acc_defender', value === 'REGULATORY' ? true : false]);
        break;
      case 'env_type':
        keyAndVals.push(['description', ENV_TYPES.filter(e => e.name === value)[0]?.name || value]);
        keyAndVals.push(['env_name', generateEnvName(avayaProds.filter(p => p.prod_id === envDetails['prod_id'])[0].prod_code, ENV_TYPES.filter(p => p.name === value)[0]?.shortCode  || 'sbx', envDetails['customer_code'])])
        keyAndVals.push(['acc_name', generateAccName(avayaProds.filter(p => p.prod_id === envDetails['prod_id'])[0].prod_code, ENV_TYPES.filter(p => p.name === value)[0]?.shortCode  || 'sbx', envDetails['customer_code'])])
        //setManagementGroups(mgmt_groups)
        let ltags = [...envDetails['tags']];
        let ettIdx = ltags.findIndex(t => t.key === 'env_type');
        if (ettIdx !== -1) {
          ltags[ettIdx]['value'] = ENV_TYPES.filter(t => t.name === value)[0]?.name;
          keyAndVals.push(['tags', ltags])
        }
        break;
      case 'acc_vendor':
        if (value === 'aws') keyAndVals.push(['acc_region', aws_regions[0]['region']])
        else if (value === 'azure') keyAndVals.push(['acc_region', azure_regions[0]['Name']])
        else if (value === 'gcp') keyAndVals.push(['acc_region', gcp_regions[0]['region']])
        break;
      case 'expense_type':
        let ltags1 = [...envDetails['tags']];
        let ettIdx1 = ltags1.findIndex(t => t.key === 'exp_type');
        if (ettIdx1 !== -1) {
          ltags1[ettIdx1]['value'] = value.toLowerCase();
          keyAndVals.push(['tags', ltags1])
        }
        break;
      case 'customer_code':
        keyAndVals.push(['acc_name', generateAccName(avayaProds.filter(p => p.prod_id === envDetails['prod_id'])[0].prod_code, ENV_TYPES.filter(p => p.name === envDetails['env_type'])[0]?.shortCode  || 'sbx', value)])
        keyAndVals.push(['env_name', generateEnvName(avayaProds.filter(p => p.prod_id === envDetails['prod_id'])[0].prod_code, ENV_TYPES.filter(p => p.name === envDetails['env_type'])[0]?.shortCode  || 'sbx', value)])
        break;
      case 'acc_orchestration':
        keyAndVals.push(['github_team', ''])
        keyAndVals.push(['terraform_team', '']);
        break
      case 'github_team':
        keyAndVals.push(['github_team', value]);
        break;
      case 'github_team_members':
        keyAndVals.push(['github_team_members', value]);
        break;
      case 'terraform_team':
        keyAndVals.push(['terraform_team', value]);
        break;
      case 'terraform_team_members':
        keyAndVals.push(['terraform_team_members', value]);
        break;
      default:
    }
    if (keyAndVals.length > 0) setEnvDetails({ ...envDetails, ...Object.fromEntries(keyAndVals) });
  };

  const fetchBudgetForSelProd = (expType) => {
    if (!envDetails['prod_id']) return '';
    let p = avayaProds.filter(p => p.prod_id === envDetails['prod_id'])?.[0] || undefined;
    if (p)
      return parseFloat(JSON.parse(p.prod_budget)[expType]) - parseFloat(JSON.parse(p.budget_utilized)[expType]);
    else
      return 0;
  };

  const fetchOwnerForSelProd = () => {
    if (!envDetails['prod_id']) return '';
    let p = avayaProds.filter(p => p.prod_id === envDetails['prod_id'])[0];
    return p.owner_name + ' (' + p.owner_email + ')';
  };

  const formatCurrency = (val) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(val);;
  };

  const renderField = (field) => {

    switch (field.type) {
      case 'text':
        return <>
          <Form.Group controlId={field.id}>
            <Form.Label className='mb-1' title={field.title}>{field.title}</Form.Label>
            <Form.Control
              type='text'
              size='sm'
              name={field.id}
              disabled={field.disabled}
              value={envDetails[`${field.id}`]}
              onChange={(e) => handleFormChange(field.id, e.target.value)}
            />
            {
              valErrors.filter(e => e.id === field.id).length > 0 &&
              <div className={valErrors.filter(e => e.id === field.id)[0]['type'] === 'error' ? 'text-danger' : 'text-warning'}><small>{valErrors.filter(e => e.id === field.id)[0]['message']}</small></div>
            }
          </Form.Group>
        </>;
      case 'number':
        return <>
          <Form.Group controlId={field.id}>
            <Form.Label className='mb-1' title={field.title}>{field.title}</Form.Label>
            <Form.Control
              type='number'
              size='sm'
              name={field.id}
              disabled={field.disabled}
              value={envDetails[`${field.id}`]}
              onChange={(e) => handleFormChange(field.id, e.target.value)}
            />
            {
              field.id === 'env_budget' &&
              <small>{`Available budget: Expense: ${formatCurrency(fetchBudgetForSelProd('E'))}, COGS: ${formatCurrency(fetchBudgetForSelProd('C'))}`}</small>
            }
            {
              field.id === 'expiry' && envDetails[`${field.id}`] &&
              <small>{`Your environment will expire on: ${new Date(Date.now() + parseInt(envDetails[`${field.id}`]) * 24 * 60 * 60 * 1000).toLocaleDateString('en-GB')}`}</small>
            }
            {
              valErrors.filter(e => e.id === field.id).length > 0 &&
              <div className={valErrors.filter(e => e.id === field.id)[0]['type'] === 'error' ? 'text-danger' : 'text-warning'}><small>{valErrors.filter(e => e.id === field.id)[0]['message']}</small></div>
            }
          </Form.Group>
        </>;
      case 'textarea':
        return <>
          <Form.Group controlId={field.id}>
            <Form.Label className='mb-1' title={field.title}>{field.title}</Form.Label>
            <Form.Control
              as='textarea'
              size='sm'
              name={field.id}
              disabled={field.disabled}
              value={envDetails[`${field.id}`]}
              onChange={(e) => handleFormChange(field.id, e.target.value)}
            />
            {
              field.id === 'env_budget' &&
              <small>{`Available budget: Expense: ${formatCurrency(fetchBudgetForSelProd('E'))}, COGS: ${formatCurrency(fetchBudgetForSelProd('C'))}`}</small>
            }
            {
              valErrors.filter(e => e.id === field.id).length > 0 &&
              <div className={valErrors.filter(e => e.id === field.id)[0]['type'] === 'error' ? 'text-danger' : 'text-warning'}><small>{valErrors.filter(e => e.id === field.id)[0]['message']}</small></div>
            }
          </Form.Group>
        </>;
      case 'select':
        return <>
          <Form.Group controlId={field.id}>
            <Form.Label className='mb-1' title={field.title}>{field.title}</Form.Label>
            <Form.Select
              value={envDetails[`${field.id}`]}
              size="sm"
              title={field.title}
              aria-label={field.title}
              disabled={field.disabled}
              onChange={(e) => field.onChangeFn ? field.onChangeFn(e.target.val) : handleFormChange(field.id, e.target.value)}
            >
              {
                Array.isArray(field.options) ? field.options.map(c =>
                  <option value={c.value}>{c.id}</option>
                ) :
                  field.options().map(c =>
                    <option value={c.value}>{c.id}</option>
                  )
              }
            </Form.Select>
            {
              field.id === 'prod_id' &&
              <small>{`Owner: ${fetchOwnerForSelProd()}`}</small>
            }
            {
              field.id === 'acc_region' &&
              <small>{`Region: ${envDetails['acc_region']}`}</small>
            }
            {
              valErrors.filter(e => e.id === field.id).length > 0 &&
              <div className={valErrors.filter(e => e.id === field.id)[0]['type'] === 'error' ? 'text-danger' : 'text-warning'}><small>{valErrors.filter(e => e.id === field.id)[0]['message']}</small></div>
            }
          </Form.Group>
        </>;
      case 'check':
        return <>
          <Form.Group controlId={field.id}>
            <Form.Label className='mb-1' title={field.title}>{field.title}</Form.Label>
            <Form.Check
              id={field.id}
              type='switch'
              disabled={field.disabled}
              checked={envDetails[`${field.id}`]}
              onChange={(e) => handleFormChange(field.id, e.target.checked)}
              style={{ paddingTop: '2px' }}
              className='cef-switch'
            />
            {
              valErrors.filter(e => e.id === field.id).length > 0 &&
              <div className={valErrors.filter(e => e.id === field.id)[0]['type'] === 'error' ? 'text-danger' : 'text-warning'}><small>{valErrors.filter(e => e.id === field.id)[0]['message']}</small></div>
            }
          </Form.Group>
        </>
      case 'button':
        return <>
          <Form.Group controlId={field.id}>
            <Form.Label className='mb-1' title={field.title}>{field.label}</Form.Label>
            <div>            
              <Button 
              size="sm" 
              variant="primary" 
              onClick={(e) => field.onClickFn ? field.onClickFn(e.target.val) : handleFormChange(field.id, e.target.value)}>
                {field.title}
              </Button>
            </div>            
          </Form.Group>
        </>
      case 'buttongroup':
        return <>
          <Form.Group controlId={field.id}>
            <Form.Label className='mb-1' title={field.title}>{field.title}</Form.Label>
            <div>
              <ButtonGroup aria-label={`button-group-${field.id}`} size='sm'>
                {
                  field.options.map(f =>
                    <Button variant="secondary" disabled={envDetails[`${field.id}`] === f} onClick={() => handleFormChange(field.id, f)}>{f}</Button>
                  )
                }
              </ButtonGroup>
            </div>
            {
              valErrors.filter(e => e.id === field.id).length > 0 &&
              <div className={valErrors.filter(e => e.id === field.id)[0]['type'] === 'error' ? 'text-danger' : 'text-warning'}><small>{valErrors.filter(e => e.id === field.id)[0]['message']}</small></div>
            }
          </Form.Group>
        </>
      case 'tags':
        return <>
          <Form.Group>
            <Form.Label className='mb-1' title={field.title}>{field.title}</Form.Label>
            <div className='d-flex flex-wrap justify-content-start align-items-center'>
              {
                envDetails['tags']?.map(t =>
                  <div className={`etag ${t.type === 'system' ? 's' : 'u'}`} key={'etag-' + t.key}>
                    <span className='k'>{t.key}</span>
                    <span className='v'>{t.value}</span>
                  </div>
                )
              }
              <div className='tag-edit' onClick={showUTModal}>
                <GrEdit />
              </div>
            </div>
            {
              valErrors.filter(e => e.id === field.id).length > 0 &&
              <div className={valErrors.filter(e => e.id === field.id)[0]['type'] === 'error' ? 'text-danger' : 'text-warning'}><small>{valErrors.filter(e => e.id === field.id)[0]['message']}</small></div>
            }
          </Form.Group>
        </>;
      // case 'users':
      //   return <>
      //     <Form.Group>
      //       <Form.Label className='mb-1' title={field.title}>{field.title}</Form.Label>
      //       <div className='d-flex flex-wrap justify-content-start align-items-top'>
      //         {
      //           envUsers?.map(u =>
      //             <div className='euser me-2' key={`euser-${u.email}`}>
      //               <div className='e'>{u.email}</div>
      //               <div className='o fst-italic'>{u.oid}</div>
      //             </div>
      //           )
      //         }
      //         <div className='add-user' onClick={showEUModal}>
      //           <IoPersonAdd />
      //         </div>
      //       </div>
      //       {
      //         valErrors.filter(e => e.id === field.id).length > 0 &&
      //         <div className={valErrors.filter(e => e.id === field.id)[0]['type'] === 'error' ? 'text-danger' : 'text-warning'}><small>{valErrors.filter(e => e.id === field.id)[0]['message']}</small></div>
      //       }
      //     </Form.Group>
      //   </>;
      case 'gh_team':
        return <>
          <Form.Group>
            <Form.Label className='mb-1' title={field.title}>{field.title}</Form.Label>
            <div className='d-flex flex-wrap justify-content-start align-items-top'>
              <div className='create-team' onClick={field.disabled? null: showGTModal}>
                <button className='btn btn-sm btn-outline-secondary' disabled={field.disabled}>Create Team</button>
              </div>
            </div>
            {
              valErrors.filter(e => e.id === field.id).length > 0 &&
              <div className={valErrors.filter(e => e.id === field.id)[0]['type'] === 'error' ? 'text-danger' : 'text-warning'}><small>{valErrors.filter(e => e.id === field.id)[0]['message']}</small></div>
            }
          </Form.Group>
        </>;
      case 'tf_team':
        return <>
          <Form.Group>
            <Form.Label className='mb-1' title={field.title}>{field.title}</Form.Label>
            <div className='d-flex flex-wrap justify-content-start align-items-top'>
              <div className='create-team' onClick={field.disabled? null: showTFModal}>
                <button className='btn btn-sm btn-outline-secondary' disabled={field.disabled}>Create Team</button>
              </div>
            </div>
            {
              valErrors.filter(e => e.id === field.id).length > 0 &&
              <div className={valErrors.filter(e => e.id === field.id)[0]['type'] === 'error' ? 'text-danger' : 'text-warning'}><small>{valErrors.filter(e => e.id === field.id)[0]['message']}</small></div>
            }
          </Form.Group>
        </>;
      default:
        console.error('Unsupported field type');
    };

  };

  const showUTModal = () => {
    setModalOptions({ ...modalOptions, tags: true });
    let luTags = envDetails['tags']?.filter(t => t.type === 'user') || [];
    if (luTags.length > 0) {
      setLocalUTags(luTags.map((t, _idx) => ({ ...t, id: `ut-${_idx + 1}` })));
    }
  };

  const saveUTModal = () => {
    let stags = envDetails['tags']?.filter(t => t.type === 'system');
    let utags = localUTags.map(t => {
      if (t.hasOwnProperty('id')) delete t['id'];
      return t;
    });
    setEnvDetails({ ...envDetails, tags: [...stags, ...utags] });
    setLocalUTags([]);
    setModalOptions({ ...modalOptions, tags: false });
  };

  const cancelUTModal = () => {
    setLocalUTags([]);
    setModalOptions({ ...modalOptions, tags: false });
  };

  const addUT = () => {
    setLocalUTags([...localUTags, { id: `ut-${localUTags.length + 1}`, key: '', value: '', type: 'user' }]);
  };

  const removeUT = (event) => {
    let id = event.currentTarget.dataset.tagId;
    setLocalUTags(localUTags.filter(t => t.id !== id));
  };

  const updateUT = (id, type, value) => {
    let idx = localUTags.findIndex(t => t.id === id);
    if (idx !== -1) {
      localUTags[idx][`${type}`] = value;
      setLocalUTags([...localUTags]);
    }
  };

  // const showMGModal = () => {
  //   setModalOptions({ ...modalOptions, mgmt_groups: true });
  //   setAddMGroup(({ ...addMGroup, save_enabled: true }))    
  // };

  // const saveMGModal = () => {    
  //   setAddMGroup(({ ...addMGroup, save_enabled: false, message: 'Saving...', is_error: false}))
  //   addManagementGroup();
  // };

  // const cancelMGModal = () => {
  //   setLocalUTags([]);
  //   setModalOptions({ ...modalOptions, mgmt_groups: false });
  // };

  const showGTModal = () => {
    setModalOptions({ ...modalOptions, github_team: true });
    let members = [...githubTeamMembers];
    if (members.length > 0) {
      setLocalGithubTeamMembers(members.map((m, _idx) => ({ ...m, id: `gtm-${_idx + 1}` })));
    }
    setLocalNewGithubTeam(newGithubTeam)
  };

  const saveGTModal = () => {
    let members = localGithubTeamMembers.map(m => {
      if (m.hasOwnProperty('id')) delete m['id'];
      return m;
    });
    setGithubTeamMembers(members);
    setNewGithubTeam(localNewGithubTeam) 
    const newTeam = localNewGithubTeam + " (new)";
    setGithubTeams([newTeam, ...githubTeams]);
    setEnvDetails({
      ...envDetails,
      github_team: localNewGithubTeam,
      github_team_members: localGithubTeamMembers.map(m => m.handle)
    })
    setLocalNewGithubTeam(""); 
    setLocalGithubTeamMembers([]);
    setModalOptions({ ...modalOptions, github_team: false });
  };

  const cancelGTModal = () => {
    setLocalNewGithubTeam("");
    setLocalGithubTeamMembers([]);
    setModalOptions({ ...modalOptions, github_team: false });
  };

  const addGHTeamMember = () => {
    setLocalGithubTeamMembers([...localGithubTeamMembers, { id: `gtm-${localGithubTeamMembers.length + 1}`, handle: '' }])
  }
  const validateEmail = (user) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const domainContainsValdis = /.+@.*valdis.*\..*/i;
  
    console.log('user', user);
    if (!emailRegex.test(user.email)) {
      return false;
    }
    if (!domainContainsValdis.test(user.email)) {
      return false;
    }
    return true;
  };

  const updateGitHubTeamMember = (id, value) => {
    let idx = localGithubTeamMembers.findIndex(u => u.id === id);
    if (idx !== -1) {
      localGithubTeamMembers[idx][`handle`] = value;
      setLocalGithubTeamMembers([...localGithubTeamMembers]);
    }
  }

  const updateNewGithubTeamName = (value) => {
    setLocalNewGithubTeam(value);
  }
  
  const removeGitHubTeamMember = (event) => {
    let id = event.currentTarget.dataset.userId;
    setLocalGithubTeamMembers(localGithubTeamMembers.filter(u => u.id !== id));
  };

  const showTFModal = () => {
    setModalOptions({ ...modalOptions, terraform_team: true });
    let members = [...terraformTeamMembers];
    if (members.length > 0) {
      setLocalTerraformTeamMembers(members.map((m, _idx) => ({ ...m, id: `ttm-${_idx + 1}` })));
    }
    setLocalNewTerraformTeam(newTerraformTeam)
  };

  const saveTFModal = () => {
    let members = localTerraformTeamMembers.map(m => {
      if (m.hasOwnProperty('id')) delete m['id'];
      return m;
    });
    setTerraformTeamMembers(members);
    setNewTerraformTeam(localNewTerraformTeam) 
    const newTeam = localNewTerraformTeam + " (new)";
    setTerraformTeams([newTeam, ...terraformTeams]);
    setEnvDetails({
      ...envDetails,
      terraform_team: localNewTerraformTeam,
      terraform_team_members: localTerraformTeamMembers.map(m => m.handle)
    })
    setLocalNewTerraformTeam(""); 
    setLocalTerraformTeamMembers([]);
    setModalOptions({ ...modalOptions, terraform_team: false });
  };

  const cancelTFModal = () => {
    setLocalNewTerraformTeam("");
    setLocalTerraformTeamMembers([]);
    setModalOptions({ ...modalOptions, terraform_team: false });
  };

  const addTFTeamMember = () => {
    setLocalTerraformTeamMembers([...localTerraformTeamMembers, { id: `ttm-${localTerraformTeamMembers.length + 1}`, handle: '' }])
  };

  const updateTerraformTeamMember = (id, value) => {
    let idx = localTerraformTeamMembers.findIndex(u => u.id === id);
    if (idx !== -1) {
      localTerraformTeamMembers[idx][`handle`] = value;
      setLocalTerraformTeamMembers([...localTerraformTeamMembers]);
    }
  }

  const updateNewTerraformTeamName = (value) => {
    setLocalNewTerraformTeam(value);
  }
  
  const removeTerraformTeamMember = (event) => {
    let id = event.currentTarget.dataset.userId;
    setLocalTerraformTeamMembers(localTerraformTeamMembers.filter(u => u.id !== id));
  };

  const showDeployedModal = () => {
    setModalOptions({ ...modalOptions, deploy_trigger: true });
  }

  const hideDeployedModal = () => {
    setModalOptions({ ...modalOptions, deploy_trigger: false })
    navigate("/environments");
  }

  const showSubmitModal = () => {
    if (envDetails.management_group.length === 0) {alert("Please select a management group"); return;}
    if (envDetails.customer_code.length === 0) {alert("Please enter a customer code"); return;}
    if (envDetails.jira_id.length === 0 && envDetails.getcloud_id.length === 0) {alert("Please enter either a Jira ID or a GetCloud ID"); return;}
    setModalOptions({ ...modalOptions, submit: true });
  }

  const hideSubmitModal = () => {
    setModalOptions({ ...modalOptions, submit: false });
  }
  // const showEUModal = () => {
  //   setModalOptions({ ...modalOptions, users: true });
  //   let lusers = [...envUsers];
  //   if (lusers.length > 0) {
  //     setLocalEnvusers(lusers.map((u, _idx) => ({ ...u, id: `eu-${_idx + 1}` })));
  //   }
  // };
 
  // const saveEUModal = () => {
   
  //   let eusers = localEnvUsers.map(u => {
  //     if (u.hasOwnProperty('id')) delete u['id'];
  //     return u;
  //   });
  //   console.log('eusers', eusers);
  //   for (let user of eusers) {
  //     if (!validateEmail(user)) {
  //       setAddEUGroup(({ ...addEUGroup, save_enabled: false, message: `Invalid email format. Email domain for user ${user.email} must contain 'valdis'`, is_error: true}));
  //       return;
  //     }
  //   }
  //   setAddEUGroup(({ ...addEUGroup, save_enabled: true, message: '', is_error: false}));
  //   setEnvusers(eusers);
  //   setLocalEnvusers([]);
  //   setModalOptions({ ...modalOptions, users: false });
  // };

  // const cancelEUModal = () => {
  //   setAddEUGroup(({ ...addEUGroup, save_enabled: true, message: '', is_error: false}));
  //   setLocalEnvusers([]);
  //   setModalOptions({ ...modalOptions, users: false });
  // };

  // const addUser = () => {
  //   setLocalEnvusers([...localEnvUsers, { id: `eu-${localEnvUsers.length + 1}`, email: '', oid: '' }])
  // };

  // const removeUser = (event) => {
  //   let id = event.currentTarget.dataset.userId;
  //   setLocalEnvusers(localEnvUsers.filter(u => u.id !== id));
  // };

  // const updateUser = (id, type, value) => {
  //   let idx = localEnvUsers.findIndex(u => u.id === id);
  //   if (idx !== -1) {
  //     if (type === 'email') {
  //       localEnvUsers[idx][`${type}`] = value;
  //       localEnvUsers[idx][`oid`] = value;
  //     } else {
  //       localEnvUsers[idx][`${type}`] = value;
  //     }
  //     setLocalEnvusers([...localEnvUsers]);
  //   }
  // };

  const buildPayload = () => {
    const prodDetails = { prod_name: '', prod_owner: '', prod_code: '' };
    if (envDetails['prod_id'] && avayaProds.filter(p => p.prod_id === envDetails['prod_id']).length > 0) {
      let p = avayaProds.filter(p => p.prod_id === envDetails['prod_id'])[0];
      prodDetails.prod_name = p.prod_name;
      prodDetails.prod_owner = p.owner_email;
      prodDetails.prod_code = p.prod_code;
    }
    return {
      ...envDetails,
      ...prodDetails,
      env_users: envUsers,
      github_members: githubTeamMembers.map(m => m.handle),
      terraform_members: terraformTeamMembers.map(m => m.handle),
    };
  };

  const createEnvironment = async () => {
    hideSubmitModal();
    if (envDetails.management_group.length === 0) {alert("Please select a management group"); return;}
    if (envDetails.customer_code.length === 0) {alert("Please enter a customer code"); return;}
    if (envDetails.jira_id.length === 0 && envDetails.getcloud_id.length === 0) {alert("Please enter either a Jira ID or GetCloud ID"); return;}
    setStatus({ show: true, type: 'progress', message: 'Creating cloud environment...' });
    const payload = buildPayload();
    const params = {
      ...payload,
      stage: STAGE,
      region: user.pool.userPoolId.split('_')[0],
    };
    await API.post('SspBackendApi', '/environments', {
      body: params,
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      console.log(JSON.stringify(response));
      setStatus({ show: true, type: 'success', message: 'Cloud environment created successfully.' })
      showDeployedModal();
    }).catch(error => {
      console.error(JSON.stringify(error));
      setStatus({ show: true, type: 'error', message: 'Error occurred while creating cloud environment.' })
    });
    // .finally(() => {
    //   // setTimeout(() => {
    //   //   navigate("/environments");
    //   // }, 1000);
    // });
  };

  

  

  const fetchProducts = async () => {
    let retries = 1;
    setStatus({ show: true, type: 'progress', message: 'Fetching products info...' });
    let products = Utils.fetchFromSessionStorage(user.username, 'products', null);
    if (products) {
      setAvayaProds(products);
      setStatus(DEFAULT_STATUS);
      if (products.length > 0)
        setEnvDetails({
          ...envDetails,
          prod_id: products[0].prod_id,
          env_name: generateEnvName(products[0].prod_code, ENV_TYPES.filter(p => p.name === envDetails['env_type'])[0]?.shortCode  || 'sbx', envDetails['customer_code']),
          acc_name: generateAccName(products[0].prod_code, ENV_TYPES.filter(p => p.name === envDetails['env_type'])[0]?.shortCode  || 'sbx', envDetails['customer_code'])
        });
      return;
    }
    let params = {
      queryStringParameters: {
        stage: STAGE,
        region: user.pool.userPoolId.split('_')[0]
      }
    };
    while (retries <= Utils.RETRY_COUNT) {
      try {
        const response = await API.get('SspBackendApi', '/products', params);
        if (response && response.status === 'success') {
          let filtered_products = response.data.filter(p => {return p.prod_code.startsWith('aec') || p.prod_code.startsWith('axp') || p.prod_code.startsWith('aocp')});
          setAvayaProds(filtered_products);
          Utils.setInSessionStorage(user.username, 'products', filtered_products);
          if (filtered_products.length > 0) {
            setStatus(DEFAULT_STATUS);
            setEnvDetails({ ...envDetails, [`prod_id`]: filtered_products[0].prod_id,
            env_name: generateEnvName(filtered_products[0].prod_code, ENV_TYPES.filter(p => p.name === envDetails['env_type'])[0]?.shortCode  || 'sbx', envDetails['customer_code']),
            acc_name: generateAccName(filtered_products[0].prod_code, ENV_TYPES.filter(p => p.name === envDetails['env_type'])[0]?.shortCode  || 'sbx', envDetails['customer_code']) });
          }
        } else {
          setStatus({ show: true, type: 'error', message: 'Error occured while fetching the products info.' });
        }
        return;
      } catch (err) {
        let error = JSON.parse(JSON.stringify(err));
        if (parseInt(error.status) === 504) {
          retries++;
          if (retries <= Utils.RETRY_COUNT) {
            setStatus({ show: true, type: 'progress', message: 'Request timed-out, retrying...' });
            await new Promise((resolve) => setTimeout(resolve, 1000));
          } else {
            setStatus({ show: true, type: 'error', message: 'Retry limit reached, please reload the page.' });
            return;
          }
        } else {
          console.error('Error occured while fetching the products info.', err);
          setStatus({ show: true, type: 'error', message: 'Error occured while fetching the products info.' });
          return;
        }
      }
    }
  };

  //#endregion

  const cnpUrl = 'https://cnp-api.azurewebsites.net'

  const fetchToken = async () => {
    try {
      const response = await axios.post(`${cnpUrl}/token`, {
        username: 'form_trigger',
        password: 'WvkDbO34p28C',
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }});
      setToken(response.data.access_token);
      return response.data.access_token;
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  const fetchManagementGroups = async () => {
    try {      
      let prod_code = avayaProds.filter(p => p.prod_id === envDetails['prod_id'])[0].prod_code;
      // prodDetails.prod_name = p.prod_name;
      // prodDetails.prod_owner = p.owner_email;
      // prodDetails.prod_code = p.prod_code;
      const response = await axios.get(
        `${cnpUrl}/management-groups/${envDetails['acc_compliance']}/${envDetails['env_type']}/${prod_code}/${envDetails['acc_tenant']}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setManagementGroups(response.data)
      if (response.data && response.data.length > 0) {
        setEnvDetails(envDetails => ({...envDetails, 'management_group': response.data[0].id}))
      } else {
        setEnvDetails(envDetails => ({...envDetails, 'management_group': ''}))
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Unauthorized, token may have expired, fetch a new one
        console.log('Authentication error. Fetching a new token...');
        await fetchToken();
      } else {
        console.error('Error fetching management groups:', error);
      }
    }
  };

  const fetchGitHubTeams = useCallback(async (newToken) => {
    try {
      const response = await axios.get(
        `${cnpUrl}/github-team`,
        {
          headers: {
            Authorization: `Bearer ${newToken}`,
          },
        }
      );
      setGithubTeams([""].concat(response.data))
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Unauthorized, token may have expired, fetch a new one
        console.log('Authentication error. Fetching a new token...');
        await fetchToken();
      } else {
        console.error('Error fetching GitHub teams:', error);
      }
    }
  }, []);

  const fetchTerraformTeams = useCallback(async (newToken) => {
    try {
      const response = await axios.get(
        `${cnpUrl}/terraform-team`,
        {
          headers: {
            Authorization: `Bearer ${newToken}`,
          },
        }
      ); 
      setTerraformTeams([""].concat(response.data))
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Unauthorized, token may have expired, fetch a new one
        console.log('Authentication error. Fetching a new token...');
        await fetchToken();
      } else {
        console.error('Error fetching Terraform teams:', error);
      }
    }
  }, []);

  const fetchSubNameCount = async (name) => {
    await fetchToken();
    try {
      const response = await axios.get(
        `${cnpUrl}/subscription-name/${name}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data && response.data.length > 0) {
        console.log([name, response.data.count].join('-'))
        setEnvDetails({...envDetails, acc_name: [name, response.data.count].join('-')});
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Unauthorized, token may have expired, fetch a new one
        console.log('Authentication error. Fetching a new token...');
      } else {
        console.error('Error fetching subscription name count:', error);
      }
    }
  }

  // const addManagementGroup = async () => {
  //   try {      
  //     const body = {
  //       "display_name": addMGroup['name'],
  //       "deployment_id":envDetails['acc_deployment_id'],
  //       "environment_type": addMGroup["env_type"],
  //       "compliance": addMGroup["compliance"]
  //     };

  //     const response = await axios.post(
  //       `${cnpUrl}/management-groups/`, body,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     console.log('addManagementGroup response.data:', response.data.message);
  //     setAddMGroup(({ ...addMGroup, save_enabled: true }))
  //     setAddMGroup(({ ...addMGroup, message: `${response.data.message}`, is_error: false }))      
  //   } catch (error) {
  //     setAddMGroup(({ ...addMGroup, save_enabled: true }))
  //     if (error.response && error.response.status === 401) {
  //       // Unauthorized, token may have expired, fetch a new one
  //       console.log('Authentication error. Fetching a new token...');
  //       await fetchToken();
  //     } else {
  //       setAddMGroup(({ ...addMGroup, message: 'Error adding management groups:', is_error: true }))
  //       console.error('Error adding management groups:', error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   fetchToken();
  // }, []);

  useEffect(() => {
    
    if (token.length > 0) {
      fetchManagementGroups();
    }
  }, [token, envDetails['env_type'], envDetails['acc_compliance'], envDetails['prod_id'], envDetails['management_group'], envDetails['acc_tenant']]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const fetchData = async () => {
      const newToken = await fetchToken();
      fetchGitHubTeams(newToken);
      fetchTerraformTeams(newToken);
    };
  
    fetchData();
  }, [fetchGitHubTeams, fetchTerraformTeams]); 

  useEffect(() => {

    fetchProducts();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="p-2 mb-4" >
      <Row className='m-0'>

        {/* Left sidebar */}
        <Col md={2} className='l-nav'>
          <LeftSideBar username={user.username} />
        </Col>

        {/* Content */}
        <Col md={9}>

          <Container fluid data-bs-spy="scroll" data-bs-target='#navbar' data-bs-offset="0" tabIndex="0" className='scrollspy-example'>

            <Row>
              <Col className='title-wrap d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-start title-text'>
                  <div>Cloud Environments</div>
                </div>
                <div className='d-flex align-items-center justify-content-end title-settings'>
                  <div className='me-2'>{user.attributes.email}</div>
                  <Button size="sm" variant="outline-secondary" onClick={signOut}>Signout</Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className='d-flex align-items-center justify-content-between'>
                  <div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <div className='fs-4 fw-bold'>Request cloud environment</div>
                      {
                        status['show'] && status['type'] === 'progress' &&
                        <Spinner animation="border" size="sm" className='ms-2 pt-1' />
                      }
                      {
                        status['show'] && status['message'] &&
                        <div className={`ms-2 pt-1 ${status['type'] === 'success' ? 'text-success' : status['type'] === 'error' ? 'text-danger' : 'text-secondary'}`}>{status['message']}</div>
                      }
                    </div>
                    <small>Cloud environment is an entity which holds cloud vendor account and budget information</small>
                  </div>
                  <div>
                    <div className='d-flex justify-content-end align-items-center bg-dark px-2 py-1 rounded text-light' style={{ cursor: 'pointer' }} onClick={() => navigate('/environments')}>
                      <div><TiArrowLeft /></div>
                      <div className='text ms-1'>Environments list</div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {
              Object.keys(ENV_FORM_DATA).map(k =>
                <Row id={k} className={`mt-4 ${status['type'] === 'progress' ? 'loading' : ''}`}>
                  <Col>
                    <Container fluid>
                      <Row className=''>
                        <Col className='m-0 p-0'><h5 className='fw-500 pb-1' style={{ borderBottom: '1px solid #f1f1f1' }}>{ENV_FORM_DATA[k].title}</h5></Col>
                      </Row>
                      <Row md={3} className='pt-1 pb-2'>
                        {
                          ENV_FORM_DATA[k].fields.map(f =>
                            <Col className='p-0 pe-3 pt-2 pb-3'>{renderField(f)}</Col>
                          )
                        }
                      </Row>
                      {
                        ENV_FORM_DATA[k].subModules && ENV_FORM_DATA[k].subModules.length > 0 &&
                        ENV_FORM_DATA[k].subModules.map(sm =>
                          <>
                            <Row className='mt-2'>
                              <Col className='m-0 p-0'><h6 className='fw-400 pb-1' style={{ borderBottom: '1px solid #f5f5f5' }}>{sm.title}</h6></Col>
                            </Row>
                            <Row>
                              <Col className='p-0 mb-4'>
                                <div className='d-flex align-items-center justify-content-start'>
                                  {
                                    sm.fields.map(f =>
                                      <div className='pe-4 me-4'>{renderField(f)}</div>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </>
                        )

                      }
                    </Container>
                  </Col>
                </Row>
              )
            }

            <Row className={`${status['type'] === 'progress' ? 'loading' : ''}`}>
              <Col>
                <div className='d-flex align-items-center justify-content-end'>
                  <Button size='md' className='me-2' variant="secondary" onClick={() => showSubmitModal()}>Continue</Button>
                </div>
              </Col>
            </Row>

            {/* Tags Modal */}
            <Modal size='lg' show={modalOptions['tags']} onHide={() => setModalOptions({ ...modalOptions, tags: false })} keyboard={true} backdrop>
              <Modal.Header closeButton>
                <Modal.Title>
                  <div>Manage Tags</div>
                  <div className='text-muted' style={{ fontSize: '14px', fontWeight: 'normal' }}>A tag is custom label that you assign to an Environment. Use tags to organize and idenitfy your Environments.</div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='p-2'>
                <Container fluid className='p-1'>
                  {/* <Row className='m-0'>
                    <Col className='p-0'>
                      <Alert variant={getAlertVariant()} show={createUser['alert'] !== '' && createUser['alert'].split('::').length > 1} onClose={() => setCreateUser({ ...createUser, alert: '' })} dismissible={createUser['alert'].split('::')[0] !== 'info'}>
                        <div className='d-flex align-items-center justify-content-start'>
                          {
                            createUser['alert'].split('::')[0] === 'info' &&
                            <Spinner size='sm' animation="border" className='me-2' role="status"></Spinner>
                          }
                          <div>{createUser['alert'].split('::')[1]}</div>
                        </div>
                      </Alert>
                    </Col>
                  </Row> */}
                  <div>
                    <div className='fw-bold mt-1 mb-2 tags-m-t-head'>System Tags</div>
                    <Row className='m-0 mb-1' md={2}>
                      <Col className='p-0'>Key</Col>
                      <Col className='p-0'>Value</Col>
                    </Row>
                    {
                      envDetails['tags']?.map(t =>
                        t.type === 'system' &&
                        <Row className='m-0 mb-2' md={2}>
                          <Col className='p-0 pe-3'>
                            <Form.Control type="text" size='sm' className='border-0 bg-light' value={t.key} disabled />
                          </Col>
                          <Col className='p-0'>
                            <Form.Control type="text" size='sm' className='border-0 bg-light' value={t.value} disabled />
                          </Col>
                        </Row>
                      )
                    }
                  </div>
                  <div className='mt-3'>
                    <div className='fw-bold mt-1 mb-2 tags-m-t-head u'>User Tags</div>
                    {
                      localUTags.length > 0 &&
                      <Row className='m-0 mb-1' md={2}>
                        <Col className='p-0'>Key</Col>
                        <Col className='p-0'>Value</Col>
                      </Row>
                    }
                    {
                      localUTags.map((t, _idx) =>
                        t.type === 'user' &&
                        <Row className='m-0 mb-2' md={2}>
                          <Col className='p-0 pe-3'>
                            <Form.Control type="text" size='sm' name={t.id} value={t.key} onChange={e => updateUT(e.target.name, 'key', e.target.value)} />
                          </Col>
                          <Col className='p-0'>
                            <div className='d-flex align-items-center justify-content-start'>
                              <Form.Control type="text" size='sm' name={t.id} value={t.value} onChange={e => updateUT(e.target.name, 'value', e.target.value)} />
                              <div className='ps-2 tags-m-rem-icon' id={`${t.id}`} data-tag-id={t.id} onClick={e => removeUT(e)}><RiDeleteBinLine /></div>
                            </div>
                          </Col>
                        </Row>
                      )
                    }
                    {/* <Row className='m-0 mb-2' md={2}>
                      <Col className='p-0 pe-3'>
                        <Form.Control type="text" size='sm' value='Team' onChange={(e) => { }} />
                      </Col>
                      <Col className='p-0'>
                        <div className='d-flex align-items-center justify-content-start'>
                          <Form.Control type="text" size='sm' value='Maverick' onChange={(e) => { }} />
                          <div className='ps-2 tags-m-rem-icon'><RiDeleteBinLine /></div>
                        </div>
                      </Col>
                    </Row>
                    <Row className='m-0 mb-2' md={2}>
                      <Col className='p-0 pe-3'>
                        <Form.Control type="text" size='sm' value='Team' onChange={(e) => { }} />
                      </Col>
                      <Col className='p-0'>
                        <div className='d-flex align-items-center justify-content-start'>
                          <Form.Control type="text" size='sm' value='Maverick' onChange={(e) => { }} />
                          <div className='ps-2 tags-m-rem-icon'><RiDeleteBinLine /></div>
                        </div>
                      </Col>
                    </Row> */}
                    <Row className='m-0 mb-2 mt-3'>
                      <Col className='p-0'>
                        <Button variant="outline-success" size='sm' disabled={localUTags.length >= 5} onClick={() => addUT()}>Add User Tag</Button>
                        <div><small>You can add {5 - (localUTags.length || 0)} more User Tags.</small></div>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" size='sm' onClick={() => saveUTModal()}>Save</Button>
                <Button variant="secondary" size='sm' onClick={() => cancelUTModal()}>Cancel</Button>
              </Modal.Footer>
            </Modal>

            {/* Mangement Groups Modal */}
            {/* <Modal size='lg' show={modalOptions['mgmt_groups']} onHide={() => setModalOptions({ ...modalOptions, mgmt_groups: false })} keyboard={true} backdrop>
              <Modal.Header closeButton>
                <Modal.Title>
                  <div>Add Management Groups</div>
                  <div className='text-muted' style={{ fontSize: '14px', fontWeight: 'normal' }}>Add Management Groups.</div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='p-2'>
                <Container fluid className='p-1'>                  
                  <div>
                    <div className='fw-bold mt-1 mb-2 tags-m-t-head'>Add Management Group</div>                                     
                  </div>
                  <div className='mt-3'>  
                    <Row className='m-0 mb-2 mt-3'>
                      <Col className='p-0'>
                        <Form.Label className='mb-1' name="new-mg-deploymend-id" title="">{`Deployment id for refernce: ${envDetails['acc_deployment_id']}`}</Form.Label>                        
                      </Col>
                    </Row>                                                     
                    <Row className='m-0 mb-2' md={2}>                                              
                        <Col className='p-0 pe-3'>
                          <Form.Group >
                          <Form.Label className='mb-1' title="">Environment type</Form.Label>
                          <Form.Select  size='sm' name="new-mg-env-type" value={addMGroup["env_type"]}  onChange={e => setAddMGroup(({ ...addMGroup, env_type: e.target.value }))}>
                               {ENV_TYPES.map(item => (
                                  <option key={item.shortCode} value={item.shortCode}>
                                    {`${item.name} (${item.shortCode})`}
                                  </option>
                                ))}

                          </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col className='p-0 pe-3'>
                          <Form.Group >
                            <Form.Label className='mb-1' title="">Compliance</Form.Label>
                            <Form.Select  size='sm' name="new-mg-env-compliance" value={addMGroup["compliance"]}   onChange={e => setAddMGroup(({ ...addMGroup, compliance: e.target.value }))}                         
                            >
                               {["Regulatory", "Non-regulatory"].map(item => (
                                  <option key={item} value={item}>
                                    {`${item}`}
                                  </option>
                                ))}

                            </Form.Select>                            
                          </Form.Group>
                        </Col>
                        <Col className='p-0 pe-3'>
                          <Form.Group >
                          <Form.Label className='mb-1' title="">Management Group Name</Form.Label>
                            <Form.Control type="text" size='sm' name="new-mg-name" value={addMGroup["name"]} onChange={e => setAddMGroup(({ ...addMGroup, name: e.target.value }))} />
                            </Form.Group>
                        </Col>
                          
                      </Row>
                      <Row className='m-0 mb-2 mt-3'>
                      <Col className='p-0'>
                        <Form.Label className={`mb-1 ${addMGroup["is_error"] ? 'text-danger' : ''}`} name="new-mg-message" title="">{'' + addMGroup["message"]}</Form.Label>                        
                      </Col>
                    </Row>
                  </div>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" size='sm' disabled={addMGroup["save_enabled"] !== true} onClick={() => saveMGModal()}>Save</Button>
                <Button variant="secondary" size='sm' onClick={() => {setModalOptions({ ...modalOptions, mgmt_groups: false }); setAddMGroup(({ ...addMGroup, name: "", save_enabled: true, message: '', is_error: false}))} }>Close</Button>
                <Button variant="secondary" size='sm' onClick={() => cancelMGModal()}>Cancel</Button>
              </Modal.Footer>
            </Modal> */}

            {/* Users modal */}
            {/* <Modal size='lg' show={modalOptions['users']} onHide={() => setModalOptions({ ...modalOptions, users: false })} keyboard={true} backdrop>
              <Modal.Header closeButton>
                <Modal.Title>
                  <div>Manage Environment Users</div>
                  <div className='text-muted' style={{ fontSize: '14px', fontWeight: 'normal' }}>Environment users will be granted an neccesary permissions for created environment.</div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='p-2'>
                <Container fluid className='p-1'> */}
                  {/* <Row className='m-0'>
                    <Col className='p-0'>
                      <Alert variant={getAlertVariant()} show={createUser['alert'] !== '' && createUser['alert'].split('::').length > 1} onClose={() => setCreateUser({ ...createUser, alert: '' })} dismissible={createUser['alert'].split('::')[0] !== 'info'}>
                        <div className='d-flex align-items-center justify-content-start'>
                          {
                            createUser['alert'].split('::')[0] === 'info' &&
                            <Spinner size='sm' animation="border" className='me-2' role="status"></Spinner>
                          }
                          <div>{createUser['alert'].split('::')[1]}</div>
                        </div>
                      </Alert>
                    </Col>
                  </Row> */}
                  {/* <div>
                    {
                      localEnvUsers.length > 0 &&
                      <Row className='m-0 mb-1' md={2}>
                        <Col className='p-0'>Email</Col>
                        <Col className='p-0'>Object Id</Col>
                      </Row>
                    }
                    {
                      localEnvUsers.map((u, _idx) =>
                        <Row className='m-0 mb-4' md={2} key={`eusers-${u.id}`}>
                          <Col className='p-0 pe-3'>
                            <Form.Control type="text" size='sm' value={u.email} name={u.id} onChange={e => updateUser(e.target.name, 'email', e.target.value)} />
                          </Col>
                          <Col className='p-0'>
                            <div className='d-flex align-items-center justify-content-start'>
                              <Form.Control type="text" size='sm' value={u.oid} name={u.id} onChange={e => updateUser(e.target.name, 'oid', e.target.value)} />
                              <div className='ps-2 tags-m-rem-icon' data-user-id={u.id} onClick={e => removeUser(e)}><RiDeleteBinLine /></div>
                            </div>
                          </Col>
                        </Row>
                      )
                    }
                    <Row className='m-0 my-1'>
                      <Col className='p-0'>
                        <Button variant="outline-success" size='sm' disabled={localEnvUsers.length >= 5} onClick={addUser}>Add New User</Button>
                        <div><small>You can add {5 - (localEnvUsers?.length || 0)} more Users.</small></div>
                      </Col>
                    </Row>
                    <Row className='m-0 mb-2 mt-3'>
                      <Col className='p-0'>
                        <Form.Label className={`mb-1 ${addEUGroup["is_error"] ? 'text-danger' : ''}`} name="new-mg-message" title="">{'' + addEUGroup["message"]}</Form.Label>                        
                      </Col>
                    </Row>
                  </div>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" size='sm' onClick={saveEUModal}>Save</Button>
                <Button variant="danger" size='sm' onClick={cancelEUModal}>Cancel</Button>
              </Modal.Footer>
            </Modal> */}
            
            {/* GitHub Teams Modal */}
            <Modal size='lg' show={modalOptions['github_team']} onHide={() => setModalOptions({ ...modalOptions, github_team: false })} keyboard={true} backdrop>
              <Modal.Header closeButton>
                <Modal.Title>
                  <div>Create GitHub Team</div>
                  <div className='text-muted' style={{ fontSize: '14px', fontWeight: 'normal' }}>This GitHub team will be granted access to the repository created.</div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='p-2'>
                <Container fluid className='p-1'>
                  <div>
                    <Row className='m-0 mb-4'>
                      <Col className='p-0'> New GitHub Team Name
                        <Form.Control type="text" required size='sm' value={localNewGithubTeam} onChange={e => updateNewGithubTeamName(e.target.value)} placeholder="Team Name (minimum of 3 cahracters)" />
                      </Col>
                    </Row>
                    {
                      localGithubTeamMembers.length > 0 &&
                      <Row className='m-0 mb-1' md={2}>
                        <Col className='p-0'>GitHub Handle</Col>
                      </Row>
                    }
                    {
                      localGithubTeamMembers.map((u, _idx) =>
                        <Row className='m-0 mb-4' md={2} key={`gtmember-${u.id}`}>
                          <Col className='p-0 pe-3'>
                            <Form.Control type="text" required size='sm' value={u.handle} name={u.id} onChange={e => updateGitHubTeamMember(e.target.name, e.target.value)} />
                          </Col>
                          <Col className='p-0'>
                            <div className='d-flex align-items-center justify-content-start'>
                              <div className='ps-2 tags-m-rem-icon' data-user-id={u.id} onClick={e => removeGitHubTeamMember(e)}><RiDeleteBinLine /></div>
                            </div>
                          </Col>
                        </Row>
                      )
                    }
                    <Row className='m-0 my-1'>
                      <Col className='p-0'>

                        <Button variant="outline-success" size='sm' disabled={githubTeamMembers.length >= 5 || (localGithubTeamMembers.length > 0 && localGithubTeamMembers[localGithubTeamMembers.length - 1]['handle'].length < 2)} onClick={addGHTeamMember}>Add New User</Button>
                        {/* <div><small>You can add {5 - (githubTeamMembers?.length || 0)} more Users.</small></div> */}
                      </Col>
                    </Row>
                  </div>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" size='sm' onClick={saveGTModal} disabled={localNewGithubTeam.length < 3}>Save</Button>
                <Button variant="danger" size='sm' onClick={cancelGTModal}>Cancel</Button>
              </Modal.Footer>
            </Modal>
            
            {/* On Submit Modal */}
            <Modal size='lg' show={modalOptions['deploy_trigger']} onHide={hideDeployedModal} keyboard={true} backdrop>
              <Modal.Header closeButton>
                <Modal.Title>
                  <div>Deployment triggered successfully</div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='p-2'>
                <Container fluid className='p-1'>
                  <div>Deployment ID: </div>
                  <div id='deployment_id' className='text' style={{ fontSize: '14px', fontWeight: 'bold' }}>{envDetails['acc_deployment_id']} </div>
                </Container>
              </Modal.Body>
            <Modal.Footer
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
              <Button variant="danger" size='sm' onClick={hideDeployedModal}>Close</Button>
            </Modal.Footer>
            </Modal>

            {/* Terraform Teams Modal */}
            <Modal size='lg' show={modalOptions['terraform_team']} onHide={() => setModalOptions({ ...modalOptions, terraform_team: false })} keyboard={true} backdrop>
              <Modal.Header closeButton>
                <Modal.Title>
                  <div>Create Terraform Team</div>
                  <div className='text-muted' style={{ fontSize: '14px', fontWeight: 'normal' }}>This Terraform team will be granted access to the workspace created.</div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='p-2'>
                <Container fluid className='p-1'>
                  <div>
                    <Row className='m-0 mb-4'>
                      <Col className='p-0'> New Terraform Team Name
                        <Form.Control type="text" required size='sm' value={localNewTerraformTeam} onChange={e => updateNewTerraformTeamName(e.target.value)} placeholder="Team Name (minimum of 3 cahracters)" />
                      </Col>
                    </Row>
                    {
                      localTerraformTeamMembers.length > 0 &&
                      <Row className='m-0 mb-1' md={2}>
                        <Col className='p-0'>Terraform Handle</Col>
                      </Row>
                    }
                    {
                      localTerraformTeamMembers.map((u, _idx) =>
                        <Row className='m-0 mb-4' md={2} key={`ttmember-${u.id}`}>
                          <Col className='p-0 pe-3'>
                            <Form.Control type="text" required size='sm' value={u.handle} name={u.id} onChange={e => updateTerraformTeamMember(e.target.name, e.target.value)} />
                          </Col>
                          <Col className='p-0'>
                            <div className='d-flex align-items-center justify-content-start'>
                              <div className='ps-2 tags-m-rem-icon' data-user-id={u.id} onClick={e => removeTerraformTeamMember(e)}><RiDeleteBinLine /></div>
                            </div>
                          </Col>
                        </Row>
                      )
                    }
                    <Row className='m-0 my-1'>
                      <Col className='p-0'>

                        <Button variant="outline-success" size='sm' disabled={terraformTeamMembers.length >= 5 || (localTerraformTeamMembers.length > 0 && localTerraformTeamMembers[localTerraformTeamMembers.length - 1]['handle'].length < 2)} onClick={addTFTeamMember}>Add New User</Button>
                        {/* <div><small>You can add {5 - (terraformTeamMembers?.length || 0)} more Users.</small></div> */}
                      </Col>
                    </Row>
                  </div>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" size='sm' onClick={saveTFModal} disabled={localNewTerraformTeam.length < 3}>Save</Button>
                <Button variant="danger" size='sm' onClick={cancelTFModal}>Cancel</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={modalOptions['submit']} keyboard={true} backdrop onHide={hideSubmitModal}>
              <Modal.Header >
                <Modal.Title>
                  <div>
                    Create Subscription
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="acc_name">
                  <Form.Label className='mb-1' title="Subscription Name">Subscription Name</Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    name='acc_name'
                    value={envDetails['acc_name']}
                    onChange={(e) => handleFormChange('acc_name', e.target.value)}
                  />
                </Form.Group>                    
              </Modal.Body>
              <Modal.Footer
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
                <Button variant="secondary" size='sm' onClick={() => createEnvironment()}>Submit</Button>
                <Button show variant="danger" size='sm' onClick={hideSubmitModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            {/* <Row>
              <Col>
                <div>
                  {
                    JSON.stringify(buildPayload())
                  }
                </div>
                <div className='mt-3'>
                  {
                    JSON.stringify(localEnvUsers)
                  }
                </div>
                <div className='mt-3'>
                  {
                    JSON.stringify(envUsers)
                  }
                </div>
              </Col>
            </Row> */}

          </Container>

        </Col>

      </Row>
    </Container>
  );

};

export default withAuthenticator(CreateEnv, withAuthenticatorOptions);