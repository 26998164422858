import { useEffect, useState } from "react";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container";
import { Col, Row } from 'react-bootstrap';

const FinOpsModal = (props) => {

  const [formState, setFormState] = useState({});
  const [userFeedback, setUserFeedback] = useState([]);

  const fetchFormState = (id) => {
    if (formState[`${id}`]) return formState[`${id}`];
    return '';
  };

  // console.log('formState = ' + JSON.stringify(formState))

  // console.log('data = ' + JSON.stringify(props.data))
  // console.log('initData = ' + JSON.stringify(props.initData))

  // console.log(JSON.stringify(userFeedback))

  const updateFormState = (id, value, validationFn, userMessageFn) => {
    let isValidationFailed = false;
    if (validationFn) {
      const result = validationFn(value);
      if (result) {
        if (userFeedback.findIndex(e => e.id === id) !== -1) userFeedback.splice(userFeedback.findIndex(e => e.id === id), 1);
        setUserFeedback([...userFeedback, { 'id': id, 'type': 'error', 'message': result }]);
        isValidationFailed = true;
      } else {
        if (userFeedback.filter(e => e.id === id && e.type === 'error').length > 0)
          setUserFeedback(userFeedback.filter(e => e.id !== id));
      }
    }
    if (userMessageFn && !isValidationFailed) {
      const result = userMessageFn(value);
      if (result) {
        if (userFeedback.findIndex(e => e.id === id) !== -1) userFeedback.splice(userFeedback.findIndex(e => e.id === id), 1);
        setUserFeedback([...userFeedback, { 'id': id, 'type': 'message', 'message': result }])
      } else {
        if (userFeedback.filter(e => e.id === id && e.type === 'message').length > 0)
          setUserFeedback(userFeedback.filter(e => e.id !== id));
      }
    }
    setFormState({ ...formState, [`${id}`]: value });
  };

  const renderDataByRows = (data) => {
    const dataMap = {};
    data.sort((f1, f2) => f1.row - f2.row).forEach(f => {
      if (dataMap[f.row]) {
        const fdata = dataMap[f.row];
        fdata.push(f);
        dataMap[f.row] = fdata;
      } else {
        dataMap[f.row] = [f];
      }
    });
    return dataMap;
  };

  const renderForm = (data) => {

    const dataMap = renderDataByRows(data);

    return <Form>
      <Container>
        {
          Object.keys(dataMap).map(fieldRow => {
            return <Row className={dataMap[`${fieldRow}`][0]['type'] === 'header' ? (Object.keys(dataMap)[0] === fieldRow ? 'mt-0 mb-2' : 'mt-4 mb-2') : 'mb-3'}>
              {
                dataMap[`${fieldRow}`].map(field => {
                  switch (field.type) {
                    case 'text':
                    case 'number':
                      return <>
                        <Col md={field.col}>
                          <Form.Group controlId={field.id}>
                            <Form.Label className='mb-0 fw-600' style={{ fontSize: '14px', fontWeight: '600' }} title={field.title}>{field.title}</Form.Label>
                            <Form.Control
                              type={field.type}
                              size='sm'
                              name={field.id}
                              disabled={!field.mutable}
                              placeholder={field.placeholder}
                              value={fetchFormState(`${field.id}`)}
                              onChange={(e) => updateFormState(`${field.id}`, e.target.value, field.validationFn, field.userMessageFn)}
                              className={userFeedback.filter(e => e.id === field.id && e.type === 'error').length > 0 ? 'is-invalid' : ''}
                            />
                            {
                              userFeedback.filter(e => e.id === field.id && e.type === 'error').length > 0 &&
                              <div className='invalid-feedback text-truncate' title={userFeedback.filter(e => e.id === field.id)[0]['message']}>{userFeedback.filter(e => e.id === field.id)[0]['message']}</div>
                            }
                            {
                              userFeedback.filter(e => e.id === field.id && e.type === 'message').length > 0 &&
                              <div className='text-truncate' title={userFeedback.filter(e => e.id === field.id)[0]['message']}><i><small>{userFeedback.filter(e => e.id === field.id)[0]['message']}</small></i></div>
                            }
                          </Form.Group>
                        </Col>
                      </>;
                    case 'select':
                      return <>
                        <Col md={field.col}>
                          <Form.Group controlId={field.id}>
                            <Form.Label className='mb-1' style={{ fontSize: '14px', fontWeight: '600' }} title={field.title}>{field.title}</Form.Label>
                            <Form.Select
                              type={field.type}
                              size='sm'
                              aria-label={field.title}
                              name={field.id}
                              disabled={!field.mutable}
                              placeholder={field.placeholder}
                              value={fetchFormState(`${field.id}`)}
                              onChange={(e) => updateFormState(`${field.id}`, e.target.value, field.validationFn, field.userMessageFn)}
                              className={userFeedback.filter(e => e.id === field.id && e.type === 'error').length > 0 ? 'is-invalid' : ''}
                            >
                              {
                                Array.isArray(field.options) ?
                                  field.options.map(c => <option value={c.v}>{c.d}</option>) :
                                  field.options().map(c => <option value={c.v}>{c.d}</option>)
                              }
                            </Form.Select>
                            {
                              userFeedback.filter(e => e.id === field.id && e.type === 'error').length > 0 &&
                              <div className='invalid-feedback text-truncate' title={userFeedback.filter(e => e.id === field.id)[0]['message']}>{userFeedback.filter(e => e.id === field.id)[0]['message']}</div>
                            }
                            {
                              userFeedback.filter(e => e.id === field.id && e.type === 'message').length > 0 &&
                              <div className='text-truncate' title={userFeedback.filter(e => e.id === field.id)[0]['message']}><i><small>{userFeedback.filter(e => e.id === field.id)[0]['message']}</small></i></div>
                            }
                          </Form.Group>
                        </Col>
                      </>;
                    case 'header':
                      return <>
                        <Col md={field.col}>
                          <div style={{ fontWeight: '700', borderBottom: '1px solid #eee', paddingBottom: '2px', textAlign: 'left', color: '#666' }}>{field.title}</div>
                        </Col>
                      </>;
                    default:
                      return <></>;
                  }
                })
              }
            </Row>
          })
        }
      </Container>
    </Form>
  };

  const renderModalBody = () => {
    switch (props.type) {
      case 'text':
        return <>{props.data}</>
      case 'form':
        return renderForm(props.data);
      default:
        return <></>;
    }
  };

  useEffect(() => {
    setFormState(Array.isArray(props.data) ? props.data.map(f => {
      if (f.initValue) {
        return { [`${f.id}`]: f.initValue };
      }
    }).reduce((obj, elm) => ({ ...obj, ...elm }), {}) : {});

    // if (props.initData) setFormState(props.initData);
  }, [props]);

  return (
    <Modal show={props.show} onHide={props.onExit} size={props.size}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className='fs-5'>{props.title}</div>
          {
            props.titleDescription &&
            <div className='text-muted' style={{ fontSize: '13px', fontWeight: 'normal' }}>{props.titleDescription}</div>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderModalBody()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size='sm' disabled={userFeedback.filter(e => e.type === 'error').length > 0} onClick={() => props.onSubmit(formState)}>Submit</Button>
        <Button variant="secondary" size='sm' onClick={props.onExit}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};


export default FinOpsModal;