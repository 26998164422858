import Modal from "react-bootstrap/Modal";
import {  Row, Col } from "react-bootstrap";
import * as Utils from "../../../common/utils";
import Button from "react-bootstrap/Button";
import { Scrollbars } from "react-custom-scrollbars-2";
import Container from "react-bootstrap/Container";
export const UsageDataModal = ({title,type,content,show,setModal,removeFields=[]}) => {
  function isArrayLike(obj) {
    return obj != null && typeof obj === 'object' && isFinite(obj.length) && obj.length >= 0 && obj.length === Math.floor(obj.length) && obj.length < 4294967296;
  }
    return(
    <Modal
    size="lg"
    show={show}
    onHide={() => setModal({ show: false, type: "", content:{}})}
    keyboard={true}
    backdrop
  >
    <Modal.Header closeButton>
      <Modal.Title>
        <div className="ps-3">
            {title}
        </div>
        <div
          className="text-muted ps-3 pt-1"
          style={{ fontSize: "14px", fontWeight: "normal" }}
        >
          Additional information for the selected {Utils.displayTitle(type)}
        </div>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Scrollbars
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
        autoHeight
        autoHeightMin={100}
        autoHeightMax={650}
      >
        <Container fluid className="p-0">
          {
             Object.keys(content).map((k) => (
                !removeFields.includes(k)&&
                <Row
                  className="mx-2 py-1 d-flex align-items-center justify-content-start"
                  style={{ borderBottom: "1px dashed #f1f1f1" }}
                >
                  <Col md={3} className="px-1 py-1 fw-bold text-black-50">
                    {Utils.displayTitle(k)}
                  </Col>
                  <Col md={9} className="px-1 py-1">
                    <div className="text-break">
                      {isArrayLike(content[k])&&content[k].join(", ")}
                      {typeof content[k] === "string" && content[k]}
                      {typeof content[k]==="boolean"&&content[k].toString()}
                    </div>
                  </Col>
                </Row>
              ))}
            
        </Container>
      </Scrollbars>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="secondary"
        size="sm"
        onClick={() => setModal({ show: false, type: "", content: {} })}
      >
        Close
      </Button>
    </Modal.Footer>
  </Modal>
    )
};