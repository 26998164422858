// react lib
import { useState, useEffect, useRef } from "react";

// react bootstrap components and style
import ListGroup from 'react-bootstrap/ListGroup'
import { ListGroupItem, Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Collapse from 'react-bootstrap/Collapse';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import Nav from 'react-bootstrap/Nav';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { BsFillPatchPlusFill, BsFillPatchMinusFill, BsFillPlusCircleFill } from "react-icons/bs";
import { MdOutlineCheckBox, MdOutlineIndeterminateCheckBox, MdSearch, MdBrightnessAuto } from "react-icons/md";

// stylesheet
import '../../scss/sub.scss';

// external libs
import { useParams } from 'react-router-dom';
import { Differ, Viewer } from 'json-diff-kit';
import 'json-diff-kit/dist/viewer.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ReactJson from '@microlink/react-json-view'
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { useNavigate } from "react-router-dom";

// internal components
import EntitlementData from "./EntitlementData";
import LeftSideBar from "../common/LeftSideBar";
import TopHeadingBar from "../common/TopHeadingBar";
import UserInputField from "./UserInputField";
import SubDropDown from "../common/SubDropDown";

import fastFillFields from '../../data/fastfill_fields.json';
import FieldData from '../../data/FieldData.json';
import FieldCategoriesJson from '../../data/FieldCategories.json';

import * as Utils from "../../common/utils";
import * as RBACUtils from '../../common/RBACUtils';
import '../../scss/common.scss';
import '../../scss/sub.scss';

// amplify imports & config
import { Amplify, API } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../../aws-exports';
Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true
}

//#region constants

const STAGE = Utils.getCurrentStage();
// const QRN = Utils.generateQrn();
const initSub = { subData: {}, custData: {}, userOffers: [], userBundles: [], bundleData: {}, userApps: [], userCaps: {}, entData: {} };
let originalSubscription = initSub;
let globalfieldMap = new Map();
let errorMessage = '';
const noSubscriptionMessage = "No subscription Id provided. Enter subscription Id to see the details.";
const MAX_CHIPS = 5;
// const subTopRowFields = ["provisioningId1", "provisioningId2", "provisioningId3", 'chargePlanId', 'subscriptionName'];
const subTopRowFields = ["provisioningId1"];
const disabledFieldsInEdit = ['provisioningId1', 'orderNumber', 'enterpriseId', 'command', 'chargePlanId'];
const skippedFieldsForEdit = ['hashKey', 'lastModified', 'serviceType', 'state', 'createdOn', 'rangeKey', 'systemType'];
const skippedFieldsForPayload = ['hashKey', 'lastModified', 'state', 'createdOn', 'rangeKey'];
const PID1_LINKED_FIELDS = ['orderNumber', 'provisioningId1', 'provisioningId2', 'subscriptionName', 'customerAccountNumber', 'bpLinkId', 'contractNumber', 'opportunityNumber', 'purchaseOrder'];
const defaultNewField = { id: '', type: 'text', value: '', category: 'subscription', use: true, mutable: true, isSsd: true, offers: 'ALL' };
const defaultFieldForModal = { show: false, fieldToAdd: 'default', newFieldName: '', newFieldValue: '', from: 'S', bundleId: '' };

//#endregion

const EditSub = ({ signOut, user }) => {

  //#region states & hooks

  const [subscription, setSubscription] = useState(initSub);
  const [data, setData] = useState({ offers: [], bundles: [], apps: [] });
  const [fields, setFields] = useState([]);
  const [activeProfile, setActiveProfile] = useState('offers');
  const [offerDetails, setOfferDetails] = useState([]);
  const [loadStatus, setLoadStatus] = useState({ status: false, message: '' });
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [toggleChip, setToggleChip] = useState({});
  const [bundleDetails, setBundleDetails] = useState([]);
  const [bundleSelectAllMap, setBundleSelectAllMap] = useState([]);
  const [simpleView, setSimpleView] = useState(true);
  const [subAlertVariant, setSubAlertVariant] = useState("primary");
  const [subAlertmessage, setSubAlertMessage] = useState("Updating subscription...");
  const [showSubStatusModal, setShowSubStatusModal] = useState(false);
  const [subStatusLogs, setSubStatusLogs] = useState('');
  const [showPayload, setShowPayload] = useState({ payload: false, response: false });
  const [subPackageSelection, setSubPackageSelection] = useState({ type: 'offer', value: 0 });
  const [confirmationModal, setConfirmationModal] = useState({ show: false, title: '', body: '', successFn: null });
  const [addFieldModal, setAddFieldModal] = useState(defaultFieldForModal);
  const [bundleDisabledFields, setBundleDisabledFields] = useState({});

  const navigate = useNavigate();
  const { id } = useParams();
  let subResponse = useRef({});
  const isInitDataLoaded = useRef(false);

  //#endregion

  //#region local static data

  const FieldDataMap = new Map();
  FieldData.forEach(f => {
    FieldDataMap.set(f.id, f);
  });

  const FieldCategories = new Map();
  for (const p in FieldCategoriesJson) {
    FieldCategories.set(p, FieldCategoriesJson[p]);
  }

  const offerMap = new Map();
  const bundleOfferMap = new Map();
  const offerBundleMap = new Map();
  data['offers'].forEach(o => {
    offerMap.set(o.offerId, o);
    o.offerBundles.forEach(b => {
      if (bundleOfferMap.get(b.bundleId) === undefined) {
        bundleOfferMap.set(b.bundleId, o.offerId);
      }
    });
  });
  for (var i = 0; i < data['offers'].length; i++) {
    const tArr = [];
    for (var j = 0; j < data['offers'][i].offerBundles.length; j++) {
      tArr.push(data['offers'][i].offerBundles[j].bundleId);
    }
    offerBundleMap.set(data['offers'][i].offerId, tArr);
  }

  const bundleMap = new Map();
  const applicationMap = new Map();
  const applicationTypeToId = new Map();
  const capabilityMap = new Map();
  data['bundles'].forEach(b => {
    b.bundleCapabilities.forEach(c => {
      if (applicationMap.get(c.applicationId) === undefined) {
        applicationMap.set(c.applicationId, c);
      }
      if (applicationTypeToId.get(c.applicationName) === undefined) {
        applicationTypeToId.set(c.applicationName, c.applicationId);
      }
      if (capabilityMap.get(c.capabilityId) === undefined) {
        capabilityMap.set(c.capabilityId, c);
      }
    });
  });

  data['bundles'].forEach((bundle) => {
    bundleMap.set(bundle.bundleId, bundle);
  });

  const pcApplicationMap = new Map();
  data['apps'].forEach((a) => {
    if (pcApplicationMap.get(applicationTypeToId.get(a.applicationName)) === undefined) {
      pcApplicationMap.set(applicationTypeToId.get(a.applicationName), a);
    }
  });

  let bundleOrderFields = ['provisioningId1'];
  subscription['userBundles'].forEach(bId => {
    bundleMap.get(bId).bundleOrderDetails.forEach(f => {
      if (bundleOrderFields.indexOf(f) === -1) {
        bundleOrderFields.push(f);
      }
    });
  });

  //#endregion

  //#region functions

  const handleSubscriptionData = (event) => {
    if (event.target.name === 'provisioningId1') {
      updateSubscriptionId(event.target.value);
    } else {
      subscription['subData'][event.target.name] = event.target.value;
      setSubscription(cloneDeep(subscription));
    }
  };

  const handleCustomerData = (event) => {
    subscription['custData'][event.target.name] = event.target.value;
    setSubscription(cloneDeep(subscription));
  }

  const updateSubscriptionId = (value) => {
    Utils.setInLocalStorage('subscriptionId', value);
    PID1_LINKED_FIELDS.forEach(k => subscription['subData'][k] = value);
    setSubscription(cloneDeep(subscription));
  };

  const handleMultipleOfferSelect = (offers) => {
    offers.forEach(oid => {
      if (subscription['userOffers'].indexOf(oid) === -1) {
        subscription['userOffers'].push(oid);
      } else {
        subscription['userOffers'].splice(subscription['userOffers'].indexOf(oid), 1);
        offerBundleMap.get(oid).forEach((bundleId) => {
          if (subscription['userBundles'].indexOf(bundleId) !== -1) {
            subscription['userBundles'].splice(subscription['userBundles'].indexOf(bundleId), 1);
          }
        });
      }
      if (bundleSelectAllMap.indexOf(oid) !== -1) {
        bundleSelectAllMap.splice(bundleSelectAllMap.indexOf(oid), 1);
        setBundleSelectAllMap(bundleSelectAllMap.slice());
      }
    });
    subscription['userApps'].length = 0;
    subscription['userCaps'] = {};
    subscription['bundleData'] = {};
    subscription['userBundles'].forEach(bid => {
      bundleMap.get(bid).bundleCapabilities.forEach(c => {
        if (subscription['userApps'].indexOf(c.applicationId) === -1) {
          subscription['userApps'].push(c.applicationId);
          subscription['userCaps'][c.applicationId] = [c.capabilityId];
        } else {
          if (subscription['userCaps'][c.applicationId].indexOf(c.capabilityId) === -1) {
            subscription['userCaps'][c.applicationId].push(c.capabilityId);
          }
        }
      });
      subscription['bundleData'][bid] = originalSubscription['bundleData'][bid] ? originalSubscription['bundleData'][bid] : getNewBundleFields(bid);
    });

    setSubscription(cloneDeep(subscription));
  };

  const handleOfferSelect = (oid) => {
    handleMultipleOfferSelect([oid]);
  };

  const handleMultipleBundleSelect = (bundles) => {
    bundles.forEach(bid => {
      if (subscription['userBundles'].indexOf(bid) === -1) {
        subscription['userBundles'].push(bid);
      } else {
        subscription['userBundles'].splice(subscription['userBundles'].indexOf(bid), 1);
      }
    });

    subscription['userApps'].length = 0;
    subscription['userCaps'] = {};
    subscription['bundleData'] = {};
    subscription['userBundles'].forEach(bid => {
      bundleMap.get(bid).bundleCapabilities.forEach(c => {
        if (subscription['userApps'].indexOf(c.applicationId) === -1) {
          subscription['userApps'].push(c.applicationId);
          subscription['userCaps'][c.applicationId] = [c.capabilityId];
        } else {
          if (subscription['userCaps'][c.applicationId].indexOf(c.capabilityId) === -1) {
            subscription['userCaps'][c.applicationId].push(c.capabilityId);
          }
        }
      });
      subscription['bundleData'][bid] = originalSubscription['bundleData'][bid] ? originalSubscription['bundleData'][bid] : getNewBundleFields(bid);
    });
    setSubscription(cloneDeep(subscription));
  };

  const handleBundleSelect = (bid) => {
    handleMultipleBundleSelect([bid]);
  }

  const getNewBundleFields = (bid) => {
    let bId = -1;
    const arr = Utils.intersect(offerMap.get(bundleOfferMap.get(bid)).offerBundles.map(b => b.bundleId).filter(b => b !== bid), Object.keys(originalSubscription['bundleData']).map(Number));
    if (Array.isArray(arr) && arr.length > 0) {
      let arr1 = Object.keys(originalSubscription['bundleData']).map(Number).filter(b => arr.indexOf(b) !== -1).map(b => [b, Object.keys(originalSubscription['bundleData'][b]).length]).sort((a, b) => a[1] < b[1] ? 1 : -1);
      bId = arr1.length > 0 ? arr1[0][0] : -1;
    } else {
      let arr1 = Object.keys(originalSubscription['bundleData']).map(Number).map(b => [b, Object.keys(originalSubscription['bundleData'][b]).length]).sort((a, b) => a[1] < b[1] ? 1 : -1);
      bId = arr1.length > 0 ? arr1[0][0] : -1;
    }
    // TODO : Handle the case of new bundle data picked from existing and new susbcription
    let newBundle = {};
    if (bId && bId !== -1 && originalSubscription['bundleData'][bId]) newBundle = { ...originalSubscription['bundleData'][bId] };
    newBundle['serviceType'] = bundleMap.get(bid).bundleServiceType;
    newBundle['systemType'] = offerMap.get(bundleOfferMap.get(bid)).offerSystemType;
    if (newBundle['chargePlanId']) newBundle['chargePlanId'] = '000000000000' + Utils.parseChargePlanId(bundleMap.get(bid).bundleSmartCode);
    return newBundle;
  }

  // const initBundleData = (bid) => {
  //   let bundleData = {};
  //   let oid = bundleOfferMap.get(bid);
  //   fields.filter(f => f.category === 'bundle').forEach(f => {
  //     if (f.use && isFieldAllowedForUserOffers(f, oid)) {
  //       switch (f.type) {
  //         case 'text':
  //           bundleData[f.id] = f.value;
  //           break;
  //         case 'datetime-local':
  //           bundleData[f.id] = Utils.toDefaultFormat(f.value);
  //           break;
  //         default:
  //           break;
  //       }
  //       switch (f.id) {
  //         case "qrn":
  //         case "associatedQrn":
  //           if (bundleData[f.id]) bundleData[f.id] = QRN;
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   });
  //   bundleData['serviceType'] = bundleMap.get(bid).bundleServiceType;
  //   bundleData['systemType'] = offerMap.get(bundleOfferMap.get(bid)).offerSystemType;
  //   bundleData['chargePlanId'] = '000000000000' + Utils.parseChargePlanId(bundleMap.get(bid).bundleSmartCode);
  //   return bundleData;
  // }

  const handleButtonClick = (action) => {
    if (activeProfile === 'offers') {
      setActiveProfile('details');
    } else if (activeProfile === 'details') {
      if (action === 'next')
        setActiveProfile('review');
      else
        setActiveProfile('offers');
    } else if (activeProfile === 'review') {
      if (action === 'next') {
        setShowSubStatusModal(true);
        setSubAlertVariant('secondary');
        setSubAlertMessage("");
      } else {
        setActiveProfile('details');
      }
    }
  };

  const triggerChangeSubscriptionRequest = async () => {

    setSubAlertVariant('primary');
    setSubAlertMessage("Triggering change subscription request...");

    // prepare payload for subscription request
    let payload = buildPayload();

    let params = {
      'stage': STAGE,
      'region': user.pool.userPoolId.split('_')[0],
      'payload': payload,
      'action': 'create'
    };

    // trigger change subscription using SSP backend subscription API
    await API.post("SspBackendApi", "/subscription", {
      body: params,
      headers: {
        "Content-Type": "application/json"
      }
    }).then((response) => {
      setSubAlertVariant("success");
      setSubAlertMessage("Subscription created successfully.");
      setSubStatusLogs(response.output.logs);
    }).catch((error) => {
      setSubAlertVariant("danger");
      setSubAlertMessage("Error occurred while creating subscription.");
      setSubStatusLogs(error.response);
    });
  }

  const handleOfferDetailsClick = (offerId) => {
    const od = offerDetails;
    if (od.indexOf(offerId) === -1) {
      od.push(offerId);
    } else {
      od.splice(od.indexOf(offerId), 1);
    }
    setOfferDetails(od.slice());
  };

  const handleBundleDetailsClick = (bundleId) => {
    const bd = bundleDetails;
    if (bd.indexOf(bundleId) === -1) {
      bd.push(bundleId);
    } else {
      bd.splice(bd.indexOf(bundleId), 1);
    }
    setBundleDetails(bd.slice());
  };

  const handleBundleSelectAllClick = (oid) => {
    const bsam = bundleSelectAllMap;
    if (bsam.indexOf(oid) === -1) {
      bsam.push(oid);
      offerMap.get(oid).offerBundles.forEach(b => {
        if (subscription['userBundles'].indexOf(b.bundleId) === -1) {
          subscription['userBundles'].push(b.bundleId);
        }
      });
    } else {
      bsam.splice(bsam.indexOf(oid), 1);
      offerMap.get(oid).offerBundles.forEach((b) => {
        if (subscription['userBundles'].indexOf(b.bundleId) !== -1) {
          subscription['userBundles'].splice(subscription['userBundles'].indexOf(b.bundleId), 1);
        }
      });
    }

    subscription['userApps'].length = 0;
    subscription['userCaps'] = {};
    subscription['bundleData'] = {};
    subscription['userBundles'].forEach((bid) => {
      bundleMap.get(bid).bundleCapabilities.forEach((c) => {
        if (subscription['userApps'].indexOf(c.applicationId) === -1) {
          subscription['userApps'].push(c.applicationId);
          subscription['userCaps'][c.applicationId] = [c.capabilityId];
        } else {
          if (subscription['userCaps'][c.applicationId].indexOf(c.capabilityId) === -1) {
            subscription['userCaps'][c.applicationId].push(c.capabilityId);
          }
        }
      });
      subscription['bundleData'][bid] = originalSubscription['bundleData'][bid] ? originalSubscription['bundleData'][bid] : getNewBundleFields(bid);
    });
    setSubscription(cloneDeep(subscription));
    setBundleSelectAllMap(bsam.slice());
  };

  const isVisibleInSimpleView = (field = '', arr = [], bId = -1) => {

    if (!simpleView) return !simpleView;
    let x = false;
    if (field !== '') {
      x = bId === -1 ? bundleOrderFields.indexOf(field) !== -1 : bundleMap.get(bId).bundleOrderDetails.indexOf(field) !== -1;
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (bId === -1 ? bundleOrderFields.indexOf(arr[i]) !== -1 : bundleMap.get(bId).bundleOrderDetails.indexOf(arr[i]) !== -1) {
          x = true;
          break;
        } else {
          continue;
        }
      }
    }
    return !simpleView || x;
  }

  const setPerBundleField = (event, b) => {
    let bundleData = { ...subscription['bundleData'][b] };
    bundleData[event.target.name] = event.target.value;
    subscription['bundleData'][b] = bundleData;
    setSubscription(cloneDeep(subscription));
  };

  const buildPayload = () => {
    let payload = {
      orderNumber: subscription['subData'] ? subscription['subData']['provisioningId1'] : '',
      lineItems: prepareCurrentPayload().lineItems
    };
    return payload;
  };

  const showBundleInPerBundleView = (bundleId) => {
    if (subscription['userBundles'].indexOf(bundleId) === -1 || !subscription['bundleData'][bundleId]) return false;

    let bundleFields = Object.keys(subscription['bundleData'][bundleId]).filter(f => simpleView ? bundleMap.get(bundleId).bundleOrderDetails.indexOf(f) !== -1 : true);
    let bundleFastFillFields = fastFillFields.filter(f => f.bundles.indexOf(bundleId) !== -1).map(f => f.field);

    return bundleFields.filter(f => bundleFastFillFields.indexOf(f) === -1).length > 0;
  };

  const showOfferInPerBundleView = (oId) => {
    return offerMap.get(oId).offerBundles.map(ob => ob.bundleId).flat().filter(b => showBundleInPerBundleView(b)).length > 0;
  };

  const showPerBundleView = () => {
    return subscription['userOffers'].filter(o => showOfferInPerBundleView(o)).length > 0;
  };

  const showBundleInFastFillView = (bundleId) => {
    if (!subscription['bundleData'][bundleId]) return false;

    let bundleFields = Object.keys(subscription['bundleData'][bundleId]).filter(f => simpleView ? bundleMap.get(bundleId).bundleOrderDetails.indexOf(f) !== -1 : true);
    let bundleFastFillFields = fastFillFields.filter(f => f.bundles.indexOf(bundleId) !== -1).map(f => f.field);
    return Utils.intersect(bundleFields, bundleFastFillFields).length > 0;
  };

  const showOfferInFastFillView = (oId) => {
    return offerMap.get(oId).offerBundles.map(ob => ob.bundleId).flat().filter(b => showBundleInFastFillView(b)).length > 0;
  };

  const showFastFillView = () => {
    return subscription['userOffers'].filter(o => showOfferInFastFillView(o)).length > 0;
  };

  const isFieldModified = (f, b) => {
    if (subscription['bundleData'][b] && originalSubscription['bundleData'][b])
      return subscription['bundleData'][b][f] !== originalSubscription['bundleData'][b][f];
    return false;
  }

  const isFieldDisabledForBundle = (f, bid) => {
    return bundleDisabledFields[bid] && bundleDisabledFields[bid].indexOf(f) !== -1 ? true : false
  };

  const removeField = (id, bid = -1) => {
    let field = getField(id);
    if (field) {
      let idx = fields.findIndex(f => f.id === id);
      if (idx !== -1) {
        if (bid === -1) {
          if (field.category === 'subscription') delete subscription['subData'][field.id]
          else if (field.category === 'customer') delete subscription['custData'][field.id]
          setSubscription(cloneDeep(subscription));
        } else {
          if (bundleDisabledFields[bid]) bundleDisabledFields[bid].push(id);
          else bundleDisabledFields[bid] = [id];
          setBundleDisabledFields({ ...bundleDisabledFields });
        }
      }
    }
  };

  const shouldShowApp = (aid) => {
    if (subPackageSelection['type'] === 'offer') {
      if (subPackageSelection['value'] === 0) return true;

      return offerMap.get(subPackageSelection['value']).offerBundles.filter(b => bundleMap.get(b.bundleId).bundleCapabilities.filter(c => c.applicationId === aid).length > 0).length > 0;
    } else if (subPackageSelection['type'] === 'bundle') {
      return bundleMap.get(subPackageSelection['value']).bundleCapabilities.filter(c => c.applicationId === aid).length > 0;
    }
  }

  const shouldShowCap = (cid) => {
    if (subPackageSelection['type'] === 'offer') {
      if (subPackageSelection['value'] === 0) return true;
      let tarr = [];
      offerMap.get(subPackageSelection['value']).offerBundles.forEach(b => bundleMap.get(b.bundleId).bundleCapabilities.forEach(bc => tarr.push(bc.capabilityId)));
      return tarr.indexOf(cid) !== -1;
    } else if (subPackageSelection['type'] === 'bundle') {
      return bundleMap.get(subPackageSelection['value']).bundleCapabilities.filter(bc => bc.capabilityId === cid).length > 0;
    }
  }

  const handleOfferBundleSelect = (type, value) => {
    if (subPackageSelection['type'] === type && subPackageSelection['value'] === value) {
      setSubPackageSelection({ ...subPackageSelection, type: 'offer', value: 0 });
    } else {
      setSubPackageSelection({ ...subPackageSelection, type: type, value: value });
    }
  }

  const formatValue = (key, value) => {
    if (value === null) return 'NULL';

    if (getField(key) && getField(key).type === 'datetime-local') {
      return Utils.toDefaultFormat(value);
    }
    return value;
  }

  const formatValueForInput = (value) => {
    if (value === 'NULL') return '';
    return value;
  }

  const formatValueForPayload = (key, value) => {
    if (value === 'NULL' || value === '') {
      if (getField(key) && getField(key).type === 'datetime-local') return '';
      return value === '' ? value : null;
    } else {
      if (getField(key) && getField(key).type === 'datetime-local') return Utils.toCBSPFormat(value);
      return value;
    }
  }

  const addField = (from, field, value, bundleId = -1) => {
    if (from === 'S')
      subscription['subData'][field] = value;
    else if (from === 'C')
      subscription['custData'][field] = value;
    else if (from === 'B' && bundleId !== -1)
      subscription['bundleData'][bundleId][field] = value;

    setSubscription(cloneDeep(subscription));
  };

  const getAvailableFields = (category) => {
    let field_category = category === 'S' ? 'subscription' : category === 'C' ? 'customer' : 'bundle';
    const p_arr = fields.filter(f => f.use && f.category === field_category);
    const f_arr = [...Object.keys(subscription['subData']), ...Object.keys(subscription['custData'])];
    Object.keys(subscription['bundleData']).forEach(b => {
      Object.keys(subscription['bundleData'][b]).forEach(k => {
        if (!f_arr.includes(k)) f_arr.push(k);
      });
    });
    return p_arr.filter(f => f_arr.indexOf(f.id) === -1);
  };

  const getNewFieldValue = (field) => {
    if (field === 'default') {
      return addFieldModal['newFieldValue'];
    } else {
      return getField(field) ? getField(field).value : '';
    }
  };

  const prepareCurrentPayload = () => {
    return {
      lineItems: subscription['userBundles'].map(b => getPayloadForBundle(subscription, b))
    };
  }

  const getBundleIdFromType = (bType) => {
    return data['bundles'].filter(f => f.bundleServiceType === bType).map(b => b.bundleId)[0];
  };

  const prepareOriginalPayload = () => {
    let payload = {
      lineItems: []
    };
    if (subResponse.current.sub) {
      subResponse.current.sub.Items.forEach(s => {
        let lineItem = getOriginalPayloadForBundle(getBundleIdFromType(s['serviceType']));
        payload['lineItems'].push(lineItem);
      });
    }
    return payload;
  }

  const getOriginalPayloadForBundle = (b) => {
    let lineItem = {};
    for (let i = 0; i < subResponse.current.sub.Items.length; i++) {
      if (subResponse.current.sub.Items[i]['serviceType'] === bundleMap.get(b).bundleServiceType) {
        lineItem = cloneDeep(subResponse.current.sub.Items[i]);
        break;
      }
    }

    if (Object.keys(lineItem).length === 0) {
      return lineItem;
    }

    if (lineItem['systemSpecificData'])
      lineItem['systemSpecificData'] = JSON.parse(lineItem['systemSpecificData']);

    skippedFieldsForPayload.forEach(f => {
      delete lineItem[f];
    });

    return lineItem;
  }

  const getSubscriptionResponseforDisplay = () => {
    let response = cloneDeep(subResponse.current);
    if (!response.sub) return response;
    for (let i = 0; i < response.sub.Items.length; i++) {
      response.sub.Items[i]['systemSpecificData'] = JSON.parse(response.sub.Items[i]['systemSpecificData']);
    }
    return response;
  }

  const findFieldPosition = (f, bid) => {
    if (bundleMap.get(bid) && globalfieldMap.get(bundleMap.get(bid).bundleServiceType) && globalfieldMap.get(bundleMap.get(bid).bundleServiceType)[f] !== undefined) {
      return globalfieldMap.get(bundleMap.get(bid).bundleServiceType)[f];
    }
    let field = fields.filter(field => field.id === f);
    return field.length > 0 ? field[0]['isSsd'] ? 1 : 0 : 1;
  }

  const getPayloadForBundle = (sub, b) => {

    let lineItem = { 'systemSpecificData': {} };

    if (!sub['bundleData'][b]) return {};

    Object.keys(sub['subData']).forEach(k => {
      if (skippedFieldsForPayload.indexOf(k) === -1) {
        let pos = findFieldPosition(k, b);
        let val = formatValueForPayload(k, sub['subData'][k]);
        switch (pos) {
          case 0:
            lineItem[k] = val;
            break;
          case 1:
            lineItem['systemSpecificData'][k] = val;
            break;
          case 2:
            lineItem[k] = val;
            lineItem['systemSpecificData'][k] = val;
            break;
          default:
            break;
        }
      }
    });

    Object.keys(sub['custData']).forEach(k => {
      if (skippedFieldsForPayload.indexOf(k) === -1) {
        let pos = findFieldPosition(k, b);
        let val = formatValueForPayload(k, sub['custData'][k]);
        switch (pos) {
          case 0:
            lineItem[k] = val;
            break;
          case 1:
            lineItem['systemSpecificData'][k] = val;
            break;
          case 2:
            lineItem[k] = val;
            lineItem['systemSpecificData'][k] = val;
            break;
          default:
            break;
        }
      }
    });

    Object.keys(sub['bundleData'][b]).forEach(k => {
      if (skippedFieldsForPayload.indexOf(k) === -1 && !isFieldDisabledForBundle(k, b)) {
        let pos = findFieldPosition(k, b);
        let val = formatValueForPayload(k, sub['bundleData'][b][k]);
        switch (pos) {
          case 0:
            lineItem[k] = val;
            break;
          case 1:
            lineItem['systemSpecificData'][k] = val;
            break;
          case 2:
            lineItem[k] = val;
            lineItem['systemSpecificData'][k] = val;
            break;
          default:
            break;
        }
      }
    });

    let ssd = lineItem['systemSpecificData'];
    delete lineItem['systemSpecificData'];
    let l1 = Object.keys(lineItem).sort().reduce((o, k) => { o[k] = lineItem[k]; return o; }, {});
    l1['systemSpecificData'] = Object.keys(ssd).sort().reduce((o, k) => { o[k] = ssd[k]; return o; }, {});

    if (originalSubscription['userBundles'].indexOf(b) !== -1) {
      if (Object.keys(l1['systemSpecificData']).filter(k => k.toLowerCase() === 'command').length > 0)
        l1['systemSpecificData']['command'] = 'UpdateSubscriptionLineItem';
      else
        l1['command'] = 'UpdateSubscriptionLineItem';
    } else {
      l1['command'] = 'AddSubscriptionLineItem';
    }

    // Fix for change subscription - if pid3 is not present in original lineItem
    if (!l1['provisioningId3']) l1['provisioningId3'] = '';

    return l1;
  };

  const getBundleOrderDetails = (bid) => {
    let data = {};
    bundleMap.get(bid).bundleOrderDetails.forEach(f => {
      let val = '';
      if (subscription['bundleData'][bid] && subscription['bundleData'][bid][f]) {
        val = subscription['bundleData'][bid][f];
      } else if (subscription['custData'][f]) {
        val = subscription['custData'][f];
      } else if (subscription['subData'][f]) {
        val = subscription['subData'][f];
      }
      data[f] = val;
    });
    return data;
  };

  const isAllowedToChangeSubscription = () => {
    let isSubChanged = !isEqual(subscription, originalSubscription) || RBACUtils.hasRole(user.username, 'ADMINISTRATOR');
    let offerBundleMissing = subscription['userOffers'].length > 0 && subscription['userBundles'].length > 0;
    let reqSubDataMissing = Object.keys(subscription['subData']).filter(f => bundleOrderFields.indexOf(f) !== -1 && Utils.isEmpty(subscription['subData'][f])).length === 0;
    let reqCustDataMissing = Object.keys(subscription['custData']).filter(f => bundleOrderFields.indexOf(f) !== -1 && Utils.isEmpty(subscription['custData'][f])).length === 0;
    let reqBundleDataMissing = Object.keys(subscription['bundleData']).map(Number).filter(b => Object.keys(subscription['bundleData'][b]).filter(f => bundleMap.get(b) && bundleMap.get(b).bundleOrderDetails.indexOf(f) !== -1 && Utils.isEmpty(subscription['bundleData'][b][f])).length !== 0).length === 0;
    return isSubChanged && offerBundleMissing && reqSubDataMissing && reqCustDataMissing && reqBundleDataMissing;
  };

  const findByID = (id, arr) => {
    let idx = arr.findIndex(f => f.id === id);
    if (idx !== -1) return arr[idx];
    else return {};
  };

  const mergeFields = (orig, user) => {
    let fields = JSON.parse(JSON.stringify(orig));
    user.forEach(f => {
      let field = findByID(f.id, fields);
      if (Object.keys(field).length > 0) {
        Object.keys(f).forEach(k => {
          if (k !== 'id') field[k] = f[k];
        });
      } else {
        fields.push(f);
      }
    });
    return fields;
  };

  const getField = (id) => {
    let field = fields.filter(f => f.id === id);
    return field.length > 0 ? field[0] : null;
  };

  const getFieldTitle = (title, id) => {
    return !Utils.isEmpty(title) ? title : Utils.displayTitle(id);
  };

  const getFieldDescription = (desc, id) => {
    return !Utils.isEmpty(desc) ? desc : Utils.displayTitle(id);
  };

  // const isFieldAllowedForUserOffers = (field, oid = null) => {
  //   if (field.offers) {
  //     return field.offers === 'ALL' || (Utils.intersect(oid ? [oid] : subscription['userOffers'], field.offers).length > 0);
  //   }
  //   return true;
  // };

  const shouldRenderField = (field, bid = -1) => {
    let a = isVisibleInSimpleView(field.id) &&
      skippedFieldsForEdit.indexOf(field.id) === -1 &&
      subTopRowFields.indexOf(field.id) === -1;
    let b = field.category === 'bundle' && bid !== -1 && isFieldDisabledForBundle(field.id, bid);
    return a && !b;
  };

  //#endregion

  //#region load catalog information 

  const loadOffers = () => {
    return new Promise(async (resolve, reject) => {
      let cachedOffers = Utils.fetchFromSessionStorage(user.username, 'offers', [], true);
      if (cachedOffers.length > 0) {
        return resolve(cachedOffers);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'path': 'offers',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };
      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rOffers = JSON.parse(JSON.parse(response).body);
        if (rOffers && rOffers.length > 0) {
          Utils.setInSessionStorage(user.username, 'offers', rOffers, true);
          return resolve(rOffers);
        }
      }).catch((error) => {
        console.error(JSON.stringify(error));
        reject([]);
      });
    });
  };

  const loadBundles = () => {
    return new Promise(async (resolve, reject) => {
      let cachedBundles = Utils.fetchFromSessionStorage(user.username, 'bundles', [], true);
      if (cachedBundles.length > 0) {
        return resolve(cachedBundles);
      }

      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'path': 'bundles',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };

      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rBundles = JSON.parse(JSON.parse(response).body);
        if (rBundles && rBundles.length > 0) {
          Utils.setInSessionStorage(user.username, 'bundles', rBundles, true);
          return resolve(rBundles);
        }
      }).catch((error) => {
        console.error(JSON.stringify(error));
        return reject([]);
      });
    });
  };

  const loadApps = () => {
    return new Promise(async (resolve, reject) => {
      let cachedApps = Utils.fetchFromSessionStorage(user.username, 'apps', [], true);
      if (cachedApps.length > 0) {
        return resolve(cachedApps);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'path': 'applications',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };
      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rApps = JSON.parse(JSON.parse(response).body);
        if (rApps && rApps.length > 0) {
          Utils.setInSessionStorage(user.username, 'apps', rApps, true);
          return resolve(rApps);
        }
      }).catch((error) => {
        console.error(JSON.stringify(error));
        reject([]);
      });
    });
  };

  const loadUserFieldsCustomizations = () => {
    return new Promise(async (resolve, reject) => {
      let cachedUserFC = Utils.fetchFromSessionStorage(user.username, 'fc', []);
      if (cachedUserFC.length > 0) {
        return resolve(cachedUserFC);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'id': user.username,
          'region': user.pool.userPoolId.split('_')[0]
        }
      };

      await API.get("SspBackendApi", "/userinfo", params)
        .then(response => {
          if (response.status === 'success') {
            let res = response.data && response.data.subTemplate ? response.data.subTemplate : [];
            Utils.setInSessionStorage(user.username, 'fc', res);
            return resolve(res);
          } else {
            Utils.setInSessionStorage(user.username, 'fc', []);
            return reject([]);
          }
        }).catch(error => {
          console.log(`Error occurred while fetching field customizations for user: ${error.message}`);
          Utils.setInSessionStorage(user.username, 'fc', []);
          return reject([]);
        });
    });
  };

  const loadInitData = async () => {
    setLoadStatus({ ...loadStatus, status: true, message: 'Loading initial data...' });
    const initDataPromises = [
      loadOffers(),
      loadBundles(),
      loadApps(),
      loadUserFieldsCustomizations()
    ];
    Promise.all(initDataPromises).then(res => {
      setFields(mergeFields(FieldData, res[3]));
      if (res[0].length > 0 && res[1].length > 0 && res[2].length > 0) {
        setData({ ...data, offers: res[0], bundles: res[1], apps: res[2] });
      }
      isInitDataLoaded.current = true;
      setLoadStatus({ ...loadStatus, status: false, message: '' });
    }).catch(error => {
      console.error('An error occurred while fetching initial data: ', error);
    });
  };

  const processSubscription = (response, subId) => {
    let selSysTypes = [], selSvcTypes = [];
    let subData = {}, custData = {};
    let perBundleDataMap = {};

    if (response.sub.Count === 0) {
      errorMessage = `No subscription found with subscription Id: ${subId}`
      setShowErrorToast(true);
      setLoadStatus({ ...loadStatus, status: false, message: '' });
      window.history.pushState("", "Title", '/sub/edit');
      document.getElementById('subId').value = '';
      return;
    }

    let knownOffers = data['offers'].map(o => o.offerSystemType);
    let knownBundles = data['bundles'].map(o => o.bundleServiceType);
    let uo = [], ub = [], sb = [], so = [];
    let duplicateLineItems = false;

    for (let i = 0; i < response.sub.Items.length; i++) {
      if (knownOffers.indexOf(response.sub.Items[i].systemType) === -1)
        uo.push(response.sub.Items[i].systemType);
      if (knownBundles.indexOf(response.sub.Items[i].serviceType) === -1)
        ub.push(response.sub.Items[i].serviceType);
      if (sb.indexOf(response.sub.Items[i].serviceType) !== -1) {
        duplicateLineItems = true;
      }
      sb.push(response.sub.Items[i].serviceType);
      if (so.indexOf(response.sub.Items[i].systemType) === -1) so.push(response.sub.Items[i].systemType);
    }

    if (uo.length > 0 || ub.length > 0) {
      errorMessage = `Subscription contains unrecognized or outdated offers/bundles: Unknown offers = ${uo}, Unknown bundles = ${ub}.`
      setShowErrorToast(true);
      setLoadStatus({ ...loadStatus, status: false, message: '' });
      window.history.pushState("", "Title", '/sub/edit');
      document.getElementById('subId').value = '';
      return;
    }

    if (duplicateLineItems) {
      errorMessage = `Duplicate line items found in the subscription. Duplicate service types: ${sb}.`
      setShowErrorToast(true);
      setLoadStatus({ ...loadStatus, status: false, message: '' });
      window.history.pushState("", "Title", '/sub/edit');
      document.getElementById('subId').value = '';
      return;
    }

    let unAuthOffers = so.filter(sys => !RBACUtils.hasAccessToOffer(user.username, data['offers'].filter(o => o.offerSystemType === sys).map(o => o.offerId)[0]));
    let unauthBundles = sb.filter(svc => !RBACUtils.hasAccessToBundle(user.username, data['bundles'].filter(b => b.bundleServiceType === svc).map(b => b.bundleId)[0]));

    if (unAuthOffers.length > 0 || unauthBundles.length > 0) {
      errorMessage = `You do not have access to following subscription resources: Offers: ${unAuthOffers.length < 5 ? unAuthOffers : unAuthOffers.length}, bundles: ${unauthBundles.length < 5 ? unauthBundles : unauthBundles.length}`;
      setShowErrorToast(true);
      setLoadStatus({ ...loadStatus, status: false, message: '' });
      window.history.pushState("", "Title", '/sub/edit');
      document.getElementById('subId').value = '';
      return;
    }

    let alignedSub = [];
    response.sub.Items.forEach(s => {
      let as = {};
      for (let k in s) {
        if (k !== 'systemSpecificData') {
          as[k] = formatValue(k, s[k]);
          if (!getField(k)) fields.push({ ...defaultNewField, id: k, value: as[k], category: 'bundle' });
        } else {
          let ssd = JSON.parse(s[k]);
          for (let e in ssd) {
            as[e] = formatValue(e, ssd[e]);
            if (!getField(e)) fields.push({ ...defaultNewField, id: e, value: as[e], category: 'bundle' });
          }
        }
      }
      alignedSub.push(as);
      selSysTypes.push(s.systemType);
      selSvcTypes.push(s.serviceType);
    });

    // re-calculate fields category based on susbcription data recceived from dynamo db
    fields.filter(f => f.category !== 'bundle').forEach(f => {
      // let isSameAcrossSub = alignedSub.filter(s => s[f] ? true : false).map(s => s[f]).every((v, i, a) => v === a[0]);
      let isSameAcrossSub = alignedSub.map(s => s[f.id]).every((v, i, a) => v === a[0]);
      if (!isSameAcrossSub) {
        let idx = fields.findIndex(e => e.id === f.id);
        if (idx !== -1) {
          fields[idx].category = 'bundle';
        }
      }
    });

    alignedSub.forEach(s => {
      for (let k in s) {
        let field = getField(k);
        if (field) {
          if (field['category'] === 'subscription') {
            subData[k] = s[k];
          } else if (field['category'] === 'customer') {
            custData[k] = s[k];
          } else if (field['category'] === 'bundle') {
            let o = {};
            if (perBundleDataMap[s['serviceType']]) o = perBundleDataMap[s['serviceType']];
            o[k] = s[k];
            perBundleDataMap[s['serviceType']] = o;
          }
        }
      }
    });

    let eOffers = [];
    data['offers'].forEach(o => {
      if (selSysTypes.indexOf(o.offerSystemType) !== -1) {
        eOffers.push(o.offerId);
      }
    });

    let eBundles = [], eApps = [], eCaps = {};
    let perBundleFieldsData = {};
    data['bundles'].forEach(b => {
      if (selSvcTypes.indexOf(b.bundleServiceType) !== -1 &&
        selSysTypes.indexOf(offerMap.get(bundleOfferMap.get(b.bundleId)).offerSystemType) !== -1) {
        eBundles.push(b.bundleId);
        perBundleFieldsData[b.bundleId] = perBundleDataMap[b.bundleServiceType];
        bundleMap.get(b.bundleId).bundleCapabilities.forEach(bc => {
          if (eApps.indexOf(bc.applicationId) === -1) {
            eApps.push(bc.applicationId);
            eCaps[bc.applicationId] = [bc.capabilityId];
          } else {
            if (eCaps[bc.applicationId].indexOf(bc.capabilityId) === -1) {
              eCaps[bc.applicationId].push(bc.capabilityId);
            }
          }
        });
      }
    });

    const subscription = {};
    subscription['subData'] = subData;
    subscription['custData'] = custData;
    subscription['userOffers'] = eOffers;
    subscription['userBundles'] = eBundles;
    subscription['userApps'] = eApps;
    subscription['userCaps'] = eCaps;
    subscription['bundleData'] = perBundleFieldsData;

    let subEntitlementData = {};
    if (response.entitlement.Items.length > 0 && Object.keys(response.entitlement.Items[0]).length > 0) {
      response.entitlement.Items.forEach(e => {
        subEntitlementData[e.applicationType] = e;
      });
    }
    subscription['entData'] = subEntitlementData;

    setLoadStatus({ ...loadStatus, status: false, message: '' });
    setSubscription(cloneDeep(subscription));
    setFields(fields.slice());
    subResponse.current = response;
    updateGlobalFieldMap(response);
    originalSubscription = cloneDeep(subscription);
  };

  const updateGlobalFieldMap = (res) => {
    res.sub.Items.forEach(sub => {
      let keyMap = {};
      Object.keys(sub).forEach(k => {
        keyMap[k] = 0;
      });
      Object.keys(JSON.parse(sub['systemSpecificData'])).forEach(k => {
        if (keyMap[k] !== undefined) {
          keyMap[k] = 2;
        } else {
          keyMap[k] = 1;
        }
      });
      globalfieldMap.set(sub['serviceType'], keyMap);
    });
  };

  const loadSubscription = async (subId) => {

    if (!subId) return;

    setLoadStatus({ ...loadStatus, status: true, message: 'Loading subscription...' });
    setSubPackageSelection({ ...subPackageSelection, type: 'offer', value: 0 });
    errorMessage = '';
    window.history.pushState("", "Title", '/sub/edit/' + subId);
    document.getElementById('subId').value = subId;

    handleMultipleOfferSelect(subscription['userOffers'].slice());
    handleMultipleBundleSelect(subscription['userBundles'].slice());

    let params = {
      response: false,
      queryStringParameters: {
        'stage': STAGE,
        'id': subId,
        'region': user.pool.userPoolId.split('_')[0]
      }
    };

    await API.get("SspBackendApi", "/subscription", params).then((response) => {
      processSubscription(response, subId);
    }).catch((error) => {
      console.error('Error occurred when processing subscription response. ' + JSON.stringify(error));
    });
  };

  useEffect(() => {
    const roleAttribute = user['attributes']['custom:role'];
    let roleValue = roleAttribute ? JSON.parse(roleAttribute) : RBACUtils.getDefaultUserRole();
    Utils.setInSessionStorage(user.username, 'userRole', roleValue);
    initActivities();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps  

  useEffect(() => {
    if (Object.keys(data).filter(k => data[k].length > 0).length === Object.keys(data).length && isInitDataLoaded.current) {
      loadSubscription(id);
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const initActivities = () => {
    if (RBACUtils.hasSubscriptionAccess(user.username, 'NONE')) {
      navigate("/user/profile");
    } else if (!RBACUtils.hasSubscriptionAccess(user.username, 'WRITE')) {
      navigate("/sub/view");
    } else {
      if (Object.keys(data).filter(k => data[k].length === 0).length > 0 || fields.length === 0) {
        loadInitData();
      } else {
        if (id) loadSubscription(id);
      }
    }
  };

  //#endregion

  return (

    <Container fluid className="p-2 mb-4" >
      <Row className='m-0'>

        {/* left sidebar */}
        <Col md={2} className='l-nav'>
          <LeftSideBar active='es' username={user.username} />
        </Col>

        {/* Content */}
        <Col md={8}>
          <Container fluid data-bs-spy="scroll" data-bs-target='#navbar' data-bs-offset="0" tabIndex="0">

            {/* top heading bar */}
            <TopHeadingBar title='Edit subscription' user={user} signOut={signOut} />

            {/* user options */}
            <Row className="g-0 px-0 pt-0 pb-0 uo-area es">
              <Col md={7}>
                <div className='search-bar w-75 d-flex align-items-center justify-content-start'>
                  <div className='text w-100'>
                    <FloatingLabel label="Enter subscription Id">
                      <Form.Control type="text" size='md' id='subId' placeholder="Subscription Id" onKeyDown={(e) => e.key === 'Enter' ? loadSubscription(e.target.value) : ''} />
                    </FloatingLabel>
                  </div>
                  <div className='sbtn'>
                    <Button variant="secondary" size='md' onClick={() => loadSubscription(document.getElementById('subId').value)}><span className='i'><MdSearch /></span></Button>
                  </div>
                </div>
              </Col>
              <Col md={5} className='d-flex align-items-center justify-content-end'>
                {/* review area */}
                {
                  Object.keys(subResponse.current).length > 0 && activeProfile === 'review' &&
                  <Button size='sm' className='me-2' variant="secondary" onClick={() => setShowPayload({ ...showPayload, payload: true })}>Payload</Button>
                }
                <Modal dialogClassName="modal-90w" show={showPayload['payload']} scrollable={true} onHide={() => setShowPayload({ ...showPayload, payload: false })}>
                  <Modal.Header closeButton>
                    <Modal.Title>Subscription payload</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ scrollBehavior: 'smooth' }}>
                    <Tabs defaultActiveKey="diff" id="payload-tabs" className="mb-3" justify>
                      <Tab eventKey='diff' title='Payload comparison'>
                        <Container fluid data-bs-spy='scroll' data-bs-target='#pld-navbar' data-bs-offset='0' tabIndex='0'>
                          <Row>
                            <Col md={10}>
                              <Container fluid className='pld-container'>
                                <Row>
                                  <Col className='pld-col-heading'>Original subscription</Col>
                                  <Col className='pld-col-heading'>Current subscription</Col>
                                </Row>
                                {
                                  [...new Set([...originalSubscription['userOffers'], ...subscription['userOffers']])].sort().map(o =>
                                    <>
                                      <Row id={`pld-offer-${o}`}>
                                        <Col className='pld-offer'>{offerMap.get(o).offerCommercialName}</Col>
                                        <Col className='pld-offer'>{offerMap.get(o).offerCommercialName}</Col>
                                      </Row>
                                      {
                                        [...new Set([...originalSubscription['userBundles'], ...subscription['userBundles']])].sort().map(b =>
                                          offerMap.get(o).offerBundles.map(b => b.bundleId).indexOf(b) !== -1 &&
                                          <>
                                            <Row id={`pld-bundle-${b}`}>
                                              <Col className='pld-bundle'>{offerMap.get(o).offerCommercialName} :: {bundleMap.get(b).bundleCommercialName}</Col>
                                              <Col className='pld-bundle'>{offerMap.get(o).offerCommercialName} :: {bundleMap.get(b).bundleCommercialName}</Col>
                                            </Row>
                                            <Row>
                                              <Col className={
                                                originalSubscription['userBundles'].indexOf(b) === -1 && subscription['userBundles'].indexOf(b) !== -1 ? 'pld-diff add' :
                                                  originalSubscription['userBundles'].indexOf(b) !== -1 && subscription['userBundles'].indexOf(b) === -1 ? 'pld-diff rem' : 'pld-diff'
                                              }>
                                                <Viewer className='pld-diff-viewer' indent={2} lineNumbers={true} inlineDiffOptions={{ mode: 'word', wordSeparator: ' ' }}
                                                  diff={new Differ({
                                                    detectCircular: true,
                                                    showModifications: true,
                                                    arrayDiffMethod: 'lcs'
                                                  }).diff(getOriginalPayloadForBundle(b), getPayloadForBundle(subscription, b))} />
                                              </Col>
                                            </Row>
                                          </>
                                        )
                                      }
                                    </>
                                  )
                                }
                              </Container>
                            </Col>
                            <Col md={2} className='m-0 p-0 ps-1'>
                              <Nav id='pld-navbar' className="r-nav pld">
                                <p className='mb-1'>Subscription Offers/Bundles</p>
                                {
                                  [...new Set([...originalSubscription['userOffers'], ...subscription['userOffers']])].sort().map(o =>
                                    <>
                                      <Nav.Link href={`#pld-offer-${o}`} eventKey={`pld-offer-${o}`}>{offerMap.get(o).offerCommercialName}</Nav.Link>
                                      {
                                        [...new Set([...originalSubscription['userBundles'], ...subscription['userBundles']])].sort().map(b =>
                                          offerMap.get(o).offerBundles.map(b => b.bundleId).indexOf(b) !== -1 &&
                                          <Nav.Link className='sub-link' href={`#pld-bundle-${b}`} eventKey={`pld-bundle-${b}`}>{bundleMap.get(b).bundleCommercialName}</Nav.Link>
                                        )
                                      }
                                    </>
                                  )
                                }
                              </Nav>
                            </Col>
                          </Row>
                        </Container>
                      </Tab>
                      <Tab eventKey='orig' title='Payload JSON'>
                        <Container fluid className='pld-container'>
                          <Row>
                            <Col className='pld-col-heading'>Original subscription</Col>
                            <Col className='pld-col-heading'>Current subscription</Col>
                          </Row>
                          <Row>
                            <Col md={6} className='pld-json'>
                              <ReactJson src={prepareOriginalPayload()} name='OriginalPayload' iconStyle="triangle" indentWidth={2} collapsed={false} enableClipboard={true} displayObjectSize={false} displayDataTypes={false} quotesOnKeys={false} groupArraysAfterLength={1000} displayArrayKey={false} />
                            </Col>
                            <Col md={6} className='pld-json'>
                              <ReactJson src={prepareCurrentPayload()} name='CurrentPayload' iconStyle="triangle" indentWidth={2} collapsed={false} enableClipboard={true} displayObjectSize={false} displayDataTypes={false} quotesOnKeys={false} groupArraysAfterLength={1000} displayArrayKey={false} />
                            </Col>
                          </Row>
                        </Container>
                      </Tab>
                    </Tabs>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" size='sm' onClick={() => setShowPayload({ ...showPayload, payload: false })}>Close</Button>
                  </Modal.Footer>
                </Modal>
                {
                  Object.keys(subResponse.current).length > 0 && activeProfile === 'review' &&
                  <Button size='sm' className='me-2' variant="secondary" onClick={() => setShowPayload({ ...showPayload, response: true })} title='Shows the response received from AWS environment for subscription'>Response</Button>
                }
                <Modal size='lg' show={showPayload['response']} onHide={() => setShowPayload({ ...showPayload, response: false })}>
                  <Modal.Header closeButton>
                    <Modal.Title>Subscription response</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 650 }}>
                      <ReactJson src={getSubscriptionResponseforDisplay()} name='Response' iconStyle="triangle" indentWidth={2} collapsed={false} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} quotesOnKeys={false} groupArraysAfterLength={1000} displayArrayKey={false} />
                    </Scrollbars>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" size='sm' onClick={() => setShowPayload({ ...showPayload, response: false })}>Close</Button>
                  </Modal.Footer>
                </Modal>
                {
                  Object.keys(subResponse.current).length > 0 && (activeProfile === 'details' || activeProfile === 'review') &&
                  <ButtonGroup className='me-3'>
                    <ToggleButton size='sm' id="details-view-switch-1" type="checkbox" variant="secondary" checked={simpleView} onChange={(e) => setSimpleView(!simpleView)}>Simple view</ToggleButton>
                    <ToggleButton size='sm' id="details-view-switch-2" type="checkbox" variant="secondary" checked={!simpleView} onChange={(e) => setSimpleView(!simpleView)}>Full view</ToggleButton>
                  </ButtonGroup>
                }
                <div>
                  <SubDropDown id={subscription['subData']?.['provisioningId1']} region={awsExports.aws_project_region} username={user.username} hideChange={true} />
                </div>
              </Col>
            </Row>
            {
              /* No subscription found message */
              originalSubscription['userOffers'].length === 0 &&
              <Row className='mx-0'>
                <Col className='d-flex align-items-center justify-content-start'>
                  <p className='mb-0 py-3'>{noSubscriptionMessage}</p>
                </Col>
              </Row>
            }
            {
              <Modal show={loadStatus['status']} size="lg" backdrop="static" keyboard={false} aria-labelledby="loading-modal" centered className='loading-modal'>
                <Modal.Body>
                  <div className='d-flex flex-column align-items-center justify-content-center'>
                    <div className="spinner-border spinner-border-md" role="status"></div>
                    <p className='mb-0 mt-1'>{loadStatus['message']}</p>
                  </div>
                </Modal.Body>
              </Modal>
            }
            {
              /* Error toast */
              errorMessage &&
              <ToastContainer className="p-3" position='top-center'>
                <Toast onClose={() => setShowErrorToast(false)} show={showErrorToast}>
                  <Toast.Header closeButton={true}>
                    <strong className="me-auto">Error</strong>
                  </Toast.Header>
                  <Toast.Body>{errorMessage}</Toast.Body>
                </Toast>
              </ToastContainer>
            }
            {
              /* pages area */
              originalSubscription['userOffers'].length !== 0 &&
              <Tabs className="steps" fill activeKey={activeProfile} onSelect={(p) => setActiveProfile(p)}>

                {/* offer page */}
                <Tab eventKey="offers" title="Select offer">
                  {
                    originalSubscription['userOffers'].length > 0 &&
                    <Row className='mx-0'>
                      <Col className='border-0 rounded px-0 py-0 mx-1' style={{ backgroundColor: '#fff' }}>
                        <h4 className='text-center section-title' style={{ backgroundColor: '#c4e4ff', color: '#333', fontWeight: 'normal' }}>Offers</h4>
                        <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 800 }}>
                          <ListGroup as="ul" className='list-group-light offer-block'>
                            {
                              data['offers'].map(offer =>
                                offer.offerBundles.length > 0 &&
                                <ListGroupItem as="li" action key={"offer-" + offer.offerId}>
                                  <input className="form-check-input offer-check" style={{ cursor: "pointer" }} type="checkbox" checked={subscription['userOffers'].indexOf(offer.offerId) === -1 ? false : true} id={"offer-" + offer.offerId} onChange={() => handleOfferSelect(offer.offerId)} />
                                  <label className="form-check-label text-truncate" style={{ cursor: "pointer", padding: "0px 0px 0px 8px", maxWidth: "85%" }} htmlFor={"offer-" + offer.offerId}>{offer.offerCommercialName}</label>
                                  <div className="float-end" style={{ cursor: "pointer", color: '#9dceff', fontSize: '22px', marginTop: '-4px' }} onClick={() => handleOfferDetailsClick(offer.offerId)} aria-controls={offer.offerId} aria-expanded={offerDetails.indexOf(offer.offerId) !== -1}>
                                    {offerDetails.indexOf(offer.offerId) !== -1 ? <BsFillPatchMinusFill /> : <BsFillPatchPlusFill />}
                                  </div>
                                  <Collapse in={offerDetails.indexOf(offer.offerId) !== -1}>
                                    <div id={offer.offerId}>
                                      <div className="px-3 py-2 mt-2 text-justify" style={{ backgroundColor: "rgba(255, 255, 255, 1)", borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                        {offer.offerDescription}
                                      </div>
                                      <p className='mb-2' style={{ backgroundColor: '#fff', padding: '8px 12px', borderRadius: '5px', borderTop: '1px solid #e4f2ff', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>System Type: <span style={{ fontWeight: '500' }}>{offer.offerSystemType}</span></p>
                                    </div>
                                  </Collapse>
                                </ListGroupItem>)
                            }
                          </ListGroup>
                        </Scrollbars>
                      </Col>
                      <Col className='border-0 rounded px-0 py-0 mx-1' style={{ backgroundColor: "#fff" }}>
                        <h4 className='text-center section-title' style={{ backgroundColor: '#e2bff1' }}>Bundles</h4>
                        <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 800 }}>
                          {
                            subscription['userOffers'].map(o =>
                              <Tabs id={o} className="bundle-tabs">
                                <Tab eventKey={o} key={o} title={offerMap.get(o).offerCommercialName}>
                                  <OverlayTrigger placement="top" trigger={['hover', 'focus']} delay={250} overlay={<Tooltip className='bs-tooltip' id={'offer-bundle-tooltip-' + o}>{bundleSelectAllMap.indexOf(o) === -1 ? "Select all bundles" : "Unselect all bundles"}</Tooltip>}>
                                    <span className='float-end bundle-s-all' onClick={() => handleBundleSelectAllClick(o)}>
                                      {bundleSelectAllMap.indexOf(o) === -1 ? <MdOutlineCheckBox /> : <MdOutlineIndeterminateCheckBox />}
                                    </span>
                                  </OverlayTrigger>
                                  <ListGroup as="ul" className='list-group-light bundle-block'>
                                    {
                                      offerMap.get(o).offerBundles.map(b =>
                                        <ListGroupItem as="li" action key={"bundle-" + b.bundleId}>
                                          <input className="form-check-input bundle-check" checked={subscription['userBundles'].indexOf(b.bundleId) === -1 ? false : true} style={{ cursor: "pointer" }} type="checkbox" id={"bundle-" + b.bundleId} onChange={() => handleBundleSelect(b.bundleId)} />&nbsp;&nbsp;
                                          <label className="form-check-label text-truncate" style={{ maxWidth: '85%', cursor: 'pointer' }} htmlFor={"bundle-" + b.bundleId}>{bundleMap.get(b.bundleId).bundleCommercialName}</label>
                                          <div className="float-end" style={{ cursor: "pointer", color: '#d9aaec', fontSize: '22px', marginTop: '-4px' }} onClick={() => handleBundleDetailsClick(b.bundleId)} aria-controls={b.bundleId} aria-expanded={bundleDetails.indexOf(b.bundleId) !== -1}>
                                            {bundleDetails.indexOf(b.bundleId) !== -1 ? <BsFillPatchMinusFill /> : <BsFillPatchPlusFill />}
                                          </div>
                                          <Collapse in={bundleDetails.indexOf(b.bundleId) !== -1}>
                                            <div id={b.bundleId}>
                                              <div className="px-3 py-2 mt-2 text-justify" style={{ backgroundColor: "#fff", borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                                {bundleMap.get(b.bundleId).bundleDescription}
                                                <p className='mb-0 mt-1'><b>Capabilities:</b> {bundleMap.get(b.bundleId).bundleCapabilities.length}</p>
                                              </div>
                                              <p className='mb-0' style={{ backgroundColor: '#fff', padding: '8px 12px', borderRadius: '0px', borderTop: '1px solid #f6ecfb' }}>Service Type: <span style={{ fontWeight: '500' }}>{bundleMap.get(b.bundleId).bundleServiceType}</span></p>
                                              <p className='mb-0' style={{ backgroundColor: '#fff', padding: '8px 12px', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', borderTop: '1px solid #f6ecfb' }}>Material Code: <span className='text-break' style={{ fontWeight: '500' }}>{bundleMap.get(b.bundleId).bundleSmartCode}</span></p>
                                            </div>
                                          </Collapse>
                                        </ListGroupItem>)
                                    }
                                  </ListGroup>
                                </Tab>
                              </Tabs>)
                          }
                        </Scrollbars>
                      </Col>
                      <Col className='border-0 rounded px-0 py-0 mx-1 cap-block' style={{ backgroundColor: "#fff" }}>
                        <h4 className='text-center section-title cap-title'>Capabilities</h4>
                        <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 800 }}>
                          {
                            subscription['userApps'].map(a =>
                              <Tabs id={a} className="app-tabs">
                                <Tab className='app-tab' eventKey={a} key={a} title={applicationMap.get(a) ? applicationMap.get(a).applicationCommercialName : applicationMap.get(a).applicationName}>
                                  <div className="chip-container nav justify-content-center">
                                    {
                                      subscription['userCaps'][a].map(c =>
                                        <OverlayTrigger trigger={['hover', 'focus']} delay={250} placement="auto" rootClose={true} overlay={
                                          <Popover id={'cap-popover-' + c} className='cap-popover'>
                                            <Popover.Header as="h3" className='text-truncate'>{capabilityMap.get(c) ? capabilityMap.get(c).capabilityCommercialName : capabilityMap.get(c).capabilityName}</Popover.Header>
                                            <Popover.Body>{capabilityMap.get(c) ? capabilityMap.get(c).capabilityDescription ? capabilityMap.get(c).capabilityDescription : 'NA' : 'NA'}</Popover.Body>
                                          </Popover>
                                        }>
                                          <div className="basic-chip background-green" >{capabilityMap.get(c) ? capabilityMap.get(c).capabilityCommercialName : capabilityMap.get(c).capabilityName}</div>
                                        </OverlayTrigger>
                                      )
                                    }
                                  </div>

                                </Tab>
                              </Tabs>
                            )
                          }
                        </Scrollbars>
                      </Col>
                    </Row>
                  }
                </Tab>

                {/* details page */}
                <Tab eventKey="details" title="Fill details" disabled={originalSubscription['userOffers'].length === 0}>
                  <Container fluid className='d-cont'>
                    {/* subscription data */}
                    <Row className='d-row'>
                      <div className='d-flex align-items-center justify-content-between p-0' style={{ borderBottom: '1px solid #eee' }}>
                        <p className='o-d-title nb mt-0 mb-0' id='d-sub-data'>Subscription data</p>
                        {
                          !simpleView &&
                          <div
                            className={getAvailableFields('S').length === 0 ? 'add-field-opt disabled' : 'add-field-opt'}
                            title={getAvailableFields('S').length === 0 ? 'No new field avaible to add' : 'Add new field'}
                            onClick={() => { if (getAvailableFields('S').length > 0) setAddFieldModal({ ...defaultFieldForModal, show: true, from: 'S' }) }}>
                            <BsFillPlusCircleFill />
                          </div>
                        }
                      </div>
                      <Row className='m-0 p-0' md={4}>
                        {
                          fields.filter(f => subTopRowFields.indexOf(f.id) !== -1)
                            .map(f =>
                              <Col className='d-col-l p-0'>
                                {
                                  <UserInputField
                                    id={f.id}
                                    title={getFieldTitle(f.title, f.id)}
                                    description={getFieldDescription(f.description, f.id)}
                                    type={f.type}
                                    value={formatValueForInput(subscription['subData'][f.id])}
                                    isDisabled={true}
                                    isRequired={true}
                                    onChangeFn={handleSubscriptionData}
                                    removeFieldFn={skippedFieldsForEdit.indexOf(f.id) === -1 && disabledFieldsInEdit.indexOf(f.id) === -1 ? removeField : null}
                                  />
                                }
                              </Col>
                            )
                        }
                      </Row>
                      {
                        (isVisibleInSimpleView() || subscription['userBundles'].length > 0) &&
                        <Row className='m-0 p-0 mt-1' md={4}>
                          {
                            fields.filter(f => f.category === 'subscription')
                              .filter(f => shouldRenderField(f))
                              .map(f =>
                                <Col className='d-col-l p-0'>
                                  {
                                    <UserInputField
                                      id={f.id}
                                      title={getFieldTitle(f.title, f.id)}
                                      description={getFieldDescription(f.description, f.id)}
                                      type={f.type}
                                      value={formatValueForInput(subscription['subData'][f.id])}
                                      isDisabled={disabledFieldsInEdit.indexOf(f.id) !== -1 || !f.mutable}
                                      isRequired={bundleOrderFields.indexOf(f.id) !== -1}
                                      onChangeFn={handleSubscriptionData}
                                      removeFieldFn={skippedFieldsForEdit.indexOf(f.id) === -1 && disabledFieldsInEdit.indexOf(f.id) === -1 && bundleOrderFields.indexOf(f.id) === -1 ? removeField : null}
                                    />
                                  }
                                </Col>
                              )
                          }
                        </Row>
                      }
                      <div className='d-flex align-items-center justify-content-between p-0' style={{ borderBottom: '1px solid #eee' }}>
                        <p className='o-d-title nb mb-0' id='d-cust-data'>Customer data</p>
                        {
                          !simpleView &&
                          <div
                            className={getAvailableFields('C').length === 0 ? 'add-field-opt disabled' : 'add-field-opt'}
                            title={getAvailableFields('C').length === 0 ? 'No new field avaible to add' : 'Add new field'}
                            onClick={() => { if (getAvailableFields('C').length > 0) setAddFieldModal({ ...defaultFieldForModal, show: true, from: 'C' }) }}>
                            <BsFillPlusCircleFill />
                          </div>
                        }
                      </div>
                      <Row className='m-0 p-0' md={4}>
                        {
                          fields.filter(f => f.category === 'customer')
                            .filter(f => shouldRenderField(f))
                            .map(f =>
                              <Col className='d-col-l p-0'>
                                {
                                  <UserInputField
                                    id={f.id}
                                    title={getFieldTitle(f.title, f.id)}
                                    description={getFieldDescription(f.description, f.id)}
                                    type={f.type}
                                    value={formatValueForInput(subscription['custData'][f.id])}
                                    isDisabled={disabledFieldsInEdit.indexOf(f.id) !== -1 || !f.mutable}
                                    isRequired={bundleOrderFields.indexOf(f.id) !== -1}
                                    onChangeFn={handleCustomerData}
                                    removeFieldFn={skippedFieldsForEdit.indexOf(f.id) === -1 && disabledFieldsInEdit.indexOf(f.id) === -1 && bundleOrderFields.indexOf(f.id) === -1 ? removeField : null}
                                  />
                                }
                              </Col>
                            )
                        }
                      </Row>

                      <p className='o-d-title' id='d-bundle-data'>Bundle data</p>
                      {
                        (isVisibleInSimpleView() || subscription['userBundles'].length > 0) &&
                        <Row className='m-0 p-0 mb-3'>
                          <Accordion alwaysOpen flush defaultActiveKey={[...Array(25).keys()].map(String)} className='d-accordion px-0'>
                            {
                              subscription['userOffers'].map((o, _idx) =>
                                <Accordion.Item eventKey={`${_idx}`} id={'offer-accordion-' + o} key={'o-a-' + o}>
                                  <Accordion.Header className='acc-o-header' > {offerMap.get(o).offerCommercialName}</Accordion.Header>
                                  <Accordion.Body className='acc-o-body px-2'>
                                    <Accordion flush defaultActiveKey={`ba-ek-0`}>
                                      {
                                        offerMap.get(o).offerBundles.filter(b => subscription['userBundles'].indexOf(b.bundleId) !== -1).map((b, _idx) =>
                                          <Accordion.Item eventKey={`ba-ek-${_idx}`} id={'bundle-accordion-' + b.bundleId} key={'b-a-' + b.bundleId}>
                                            <Accordion.Header className='acc-b-header'>
                                              <div className='d-flex align-items-center justify-content-between w-100 me-2'>
                                                <div>{bundleMap.get(b.bundleId).bundleCommercialName}</div>
                                                {
                                                  !simpleView &&
                                                  <div
                                                    className={getAvailableFields('B').length === 0 ? 'add-field-opt disabled' : 'add-field-opt'}
                                                    title={getAvailableFields('B').length === 0 ? 'No new field avaible to add' : 'Add new field'}
                                                    onClick={(e) => { if (getAvailableFields('B').length > 0) { e.stopPropagation(); setAddFieldModal({ ...defaultFieldForModal, show: true, from: 'B', bundleId: b.bundleId }); } }}>
                                                    <BsFillPlusCircleFill />
                                                  </div>
                                                }
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body className='acc-b-body'>
                                              <Row className='mb-2 pb-0' md={4}>
                                                {
                                                  fields.filter(f => f.category === 'bundle')
                                                    .filter(f => shouldRenderField(f, b.bundleId))
                                                    .map(f =>
                                                      <Col key={f.id} className='d-col-l p-0'>
                                                        {
                                                          <UserInputField
                                                            id={f.id}
                                                            title={getFieldTitle(f.title, f.id)}
                                                            description={getFieldDescription(f.description, f.id)}
                                                            type={f.type}
                                                            value={formatValueForInput(subscription['bundleData'][b.bundleId][f.id])}
                                                            isDisabled={disabledFieldsInEdit.indexOf(f.id) !== -1 || !f.mutable}
                                                            isRequired={bundleMap.get(b.bundleId).bundleOrderDetails.indexOf(f.id) !== -1}
                                                            onChangeFn={setPerBundleField}
                                                            removeFieldFn={skippedFieldsForEdit.indexOf(f.id) === -1 && disabledFieldsInEdit.indexOf(f.id) === -1 && bundleMap.get(b.bundleId).bundleOrderDetails.indexOf(f.id) === -1 ? removeField : null}
                                                            bundleId={b.bundleId} />
                                                        }
                                                      </Col>
                                                    )
                                                }
                                              </Row>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        )
                                      }
                                    </Accordion>
                                  </Accordion.Body>
                                </Accordion.Item>
                              )
                            }
                          </Accordion>
                        </Row>
                      }
                    </Row>
                  </Container>
                </Tab>

                {/* review page */}
                <Tab eventKey="review" title="Review & submit" disabled={originalSubscription['userOffers'].length === 0}>
                  <Container fluid className='d-cont'>
                    {/* subscription data */}
                    <Row className='d-row'>
                      <p className='o-d-title mt-0' id='r-sub-data'>Subscription data</p>
                      <Row className="g-0 m-0 r-f-row" md={5}>
                        {
                          subTopRowFields.map(f =>
                            !Utils.isEmpty(subscription['subData'][f]) && isVisibleInSimpleView(f) &&
                            <Col className={subscription['subData'][f] === originalSubscription['subData'][f] ? 'r-f-col' : 'r-f-col mod'}>
                              <div className='r-f-title d-flex justify-content-start'>
                                <div>{Utils.displayTitle(f)}</div>
                                {
                                  bundleOrderFields.indexOf(f) !== -1 &&
                                  <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                                }
                              </div>
                              <div className='r-f-val'>{subscription['subData'][f]}</div>
                            </Col>
                          )
                        }
                      </Row>
                      <Row className="g-0 m-0 mt-1 r-f-row" md={5}>
                        {
                          fields.filter(f => f.category === 'subscription').map(f =>
                            skippedFieldsForEdit.indexOf(f.id) === -1 &&
                            subTopRowFields.indexOf(f.id) === -1 &&
                            isVisibleInSimpleView(f.id) &&
                            !Utils.isEmpty(subscription['subData'][f.id]) &&
                            <Col className={subscription['subData'][f.id] === originalSubscription['subData'][f.id] ? 'r-f-col' : 'r-f-col mod'}>
                              <div className='r-f-title d-flex justify-content-start'>
                                <div title={subscription['subData'][f.id] !== originalSubscription['subData'][f.id] ? `Field modified from: ${originalSubscription['subData'][f.id]}` : ''}>{getFieldTitle(f.title, f.id)}</div>
                                {
                                  bundleOrderFields.indexOf(f.id) !== -1 &&
                                  <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                                }
                              </div>
                              <div className='r-f-val'>{subscription['subData'][f.id]}</div>
                            </Col>
                          )
                        }
                      </Row>
                      {/* Customer data */}
                      <p className='o-d-title' id='r-cust-data'>Customer data</p>
                      <Row className="g-0 m-0 r-f-row" md={5}>
                        {
                          fields.filter(f => f.category === 'customer').map(f =>
                            skippedFieldsForEdit.indexOf(f.id) === -1 &&
                            subTopRowFields.indexOf(f.id) === -1 &&
                            isVisibleInSimpleView(f.id) &&
                            !Utils.isEmpty(subscription['custData'][f.id]) &&
                            <Col className={subscription['custData'][f.id] === originalSubscription['custData'][f.id] ? 'r-f-col' : 'r-f-col mod'}>
                              <div className='r-f-title d-flex justify-content-start'>
                                <div title={subscription['custData'][f.id] !== originalSubscription['custData'][f.id] ? `Field modified from: ${originalSubscription['custData'][f.id]}` : ''}>{getFieldTitle(f.title, f.id)}</div>
                                {
                                  bundleOrderFields.indexOf(f.id) !== -1 &&
                                  <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                                }
                              </div>
                              <div className='r-f-val text-break'>{subscription['custData'][f.id]}</div>
                            </Col>
                          )
                        }
                      </Row>
                      {/* Selected subscription */}
                      <Row className="g-0 px-0 pt-0 pb-0 mt-3 sub-view" id='sel-sub'>
                        <p className='mb-1 view-title'>Subscription package</p>
                        <Col md={5} className='sub-view-col pe-2' style={{ borderRadius: '8px 0px 0px 8px' }}>
                          <p className='title  o-b-title'>Selected offers/bundles</p>
                          <div className='o-b-area'>
                            {
                              subscription['userOffers'].map(o =>
                                <div>
                                  <div className={(subPackageSelection['type'] === 'offer' && o === subPackageSelection['value']) ? 'offer active' : 'offer'} onClick={() => handleOfferBundleSelect('offer', o)}>
                                    {
                                      originalSubscription['userOffers'].indexOf(o) === -1 &&
                                      <span className='new' title='New offer'>{'N'}</span>
                                    }
                                    {
                                      originalSubscription['userOffers'].indexOf(o) !== -1 &&
                                      JSON.stringify(offerMap.get(o).offerBundles.filter(b => originalSubscription['userBundles'].indexOf(b.bundleId) !== -1).map(b => b.bundleId)) !== JSON.stringify(offerMap.get(o).offerBundles.filter(b => subscription['userBundles'].indexOf(b.bundleId) !== -1).map(b => b.bundleId)) &&
                                      <span className='mod' title='Offer is modified'>{'M'}</span>
                                    }
                                    {offerMap.get(o).offerCommercialName}
                                  </div>
                                  <div className='bundles d-flex flex-wrap'>
                                    {
                                      offerMap.get(o).offerBundles.filter(b => subscription['userBundles'].indexOf(b.bundleId) !== -1).map((b, _idx) =>
                                        (_idx < MAX_CHIPS || toggleChip[`ob-toggle-${o}`]) &&
                                        <div className={['b-chip', 'd-flex', 'align-items-center', 'justify-cotent-start', subPackageSelection['type'] === 'bundle' && b.bundleId === subPackageSelection['value'] ? 'active' : ''].join(' ')} onClick={() => handleOfferBundleSelect('bundle', parseInt(b.bundleId))} title={bundleMap.get(b.bundleId).bundleCommercialName}>
                                          {
                                            originalSubscription['userBundles'].indexOf(b.bundleId) === -1 &&
                                            <div className='b-state' title='New bundle'>{'N'}</div>
                                          }
                                          <div className='b-name text-truncate'>{bundleMap.get(b.bundleId).bundleCommercialName}</div>
                                          <div className='b-count'>{subscription['bundleData'][b.bundleId] && !Utils.isEmpty(subscription['bundleData'][b.bundleId]['fixedCount']) ? subscription['bundleData'][b.bundleId]['fixedCount'] : '-'}</div>
                                        </div>
                                      )
                                    }
                                    {
                                      offerMap.get(o).offerBundles.map(b => b.bundleId).filter(b => subscription['userBundles'].indexOf(b) !== -1).length > MAX_CHIPS &&
                                      !toggleChip[`ob-toggle-${o}`] &&
                                      <div className='b-chip count' onClick={() => setToggleChip({ ...toggleChip, [`ob-toggle-${o}`]: true })} style={{ cursor: 'pointer' }}>
                                        <div className='b-name' title={`Show ${offerMap.get(o).offerBundles.map(b => b.bundleId).filter(b => subscription['userBundles'].indexOf(b) !== -1).length - MAX_CHIPS} more bundles`}>
                                          {`+${offerMap.get(o).offerBundles.map(b => b.bundleId).filter(b => subscription['userBundles'].indexOf(b) !== -1).length - MAX_CHIPS}`}
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              )
                            }
                          </div>
                          {
                            originalSubscription['userOffers'].filter(o => (subscription['userOffers'].indexOf(o) === -1 || offerMap.get(o).offerBundles.filter(b => originalSubscription['userBundles'].indexOf(b.bundleId) !== -1 && subscription['userBundles'].indexOf(b.bundleId) === -1).length > 0)).length > 0 &&
                            <p className='title o-b-title'>Removed offers/bundles</p>
                          }
                          <div className='o-b-r-area'>
                            {
                              originalSubscription['userOffers'].map(o =>
                                (subscription['userOffers'].indexOf(o) === -1 || offerMap.get(o).offerBundles.filter(b => originalSubscription['userBundles'].indexOf(b.bundleId) !== -1 && subscription['userBundles'].indexOf(b.bundleId) === -1).length > 0) &&
                                <div>
                                  <div className='offer'>{offerMap.get(o).offerCommercialName}</div>
                                  <div className='bundles d-flex flex-wrap'>
                                    {
                                      offerMap.get(o).offerBundles.map(b =>
                                        (originalSubscription['userBundles'].indexOf(b.bundleId) !== -1 && subscription['userBundles'].indexOf(b.bundleId) === -1) &&
                                        <div className={subPackageSelection['type'] === 'bundle' && b.bundleId === subPackageSelection['value'] ? 'b-chip active' : 'b-chip'}>
                                          <div className='b-name'>{bundleMap.get(b.bundleId).bundleCommercialName}</div>
                                          <div className='b-count'>{originalSubscription['bundleData'][b.bundleId] ? originalSubscription['bundleData'][b.bundleId]['fixedCount'] : '-'}</div>
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        </Col>
                        <Col md={7} className='sub-view-col ps-2' style={{ borderRadius: '0px 8px 8px 0px' }}>
                          {
                            subPackageSelection['type'] === 'offer' && subPackageSelection['value'] !== 0 &&
                            <div>
                              <p className='title b-vi-title'>{'Offer: ' + offerMap.get(subPackageSelection['value']).offerCommercialName}</p>
                              <div className='b-i-area'>
                                <Container fluid className='b-i-cont'>
                                  <Row className='b-i-row'>
                                    <Col className='b-i-col desc'>{offerMap.get(subPackageSelection['value']).offerDescription}</Col>
                                  </Row>
                                  <Row className='b-i-row'>
                                    <Col className='b-i-col'>
                                      <span className='k'>System Type:</span>
                                      <span className='v'>{offerMap.get(subPackageSelection['value']).offerSystemType}</span>
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                            </div>
                          }
                          {
                            subPackageSelection['type'] === 'bundle' && subPackageSelection['value'] !== 0 &&
                            <div>
                              <p className='title b-vi-title'>{'Bundle: ' + bundleMap.get(subPackageSelection['value']).bundleCommercialName}</p>
                              <div className='b-i-area'>
                                <Container fluid className='b-i-cont'>
                                  <Row className='b-i-row'>
                                    <Col className='b-i-col desc'>{bundleMap.get(subPackageSelection['value']).bundleDescription}</Col>
                                  </Row>
                                  <Row className='b-i-row'>
                                    <Col className='b-i-col'>
                                      <span className='k'>Service Type:</span>
                                      <span className='v'>{bundleMap.get(subPackageSelection['value']).bundleServiceType}</span>
                                    </Col>
                                  </Row>
                                  <Row className='b-i-row'>
                                    <Col className='b-i-col  text-truncate'>
                                      <span className='k'>Material Code:</span>
                                      <span className='v' title={bundleMap.get(subPackageSelection['value']).bundleSmartCode}>{bundleMap.get(subPackageSelection['value']).bundleSmartCode}</span>
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                            </div>
                          }
                          {
                            subPackageSelection['type'] === 'bundle' && subPackageSelection['value'] !== 0 &&
                            <div>
                              <p className='title b-v-title'>Bundle values</p>
                              <div className='b-v-area'>
                                <Container fluid className='b-val-cont'>
                                  <Row className='b-val-row' md={3}>
                                    {
                                      Object.entries(getBundleOrderDetails(subPackageSelection['value'])).sort((a, b) => { return a[0].localeCompare(b[0]) }).map(f =>
                                        <Col className='b-val-col'>
                                          <div className='sub-title b-val-title  d-flex justify-content-start'>
                                            <div className='text-truncate' title={Utils.displayTitle(f[0])}>{Utils.displayTitle(f[0])}</div>
                                            <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                                          </div>
                                          <div className='b-val text-break'>{Utils.isEmpty(f[1]) ? '-' : f[1]}</div>
                                        </Col>
                                      )
                                    }
                                  </Row>
                                  <Row className='b-val-row' md={3}>
                                    {
                                      Object.keys(subscription['bundleData'][subPackageSelection['value']]).sort().map(f =>
                                        skippedFieldsForEdit.indexOf(f) === -1 &&
                                        isVisibleInSimpleView(f, [], subPackageSelection['value']) &&
                                        bundleMap.get(subPackageSelection['value']).bundleOrderDetails.indexOf(f) === -1 &&
                                        <Col className={isFieldModified(f, subPackageSelection['value']) ? 'b-val-col mod' : 'b-val-col'}>
                                          <div className='sub-title b-val-title  d-flex justify-content-start'>
                                            <div title={isFieldModified(f, subPackageSelection['value']) ? `Field modified from: ${originalSubscription['bundleData'][subPackageSelection['value']][f]}` : ''}>{Utils.displayTitle(f)}</div>
                                            {
                                              bundleMap.get(subPackageSelection['value']).bundleOrderDetails.indexOf(f) !== -1 &&
                                              <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                                            }
                                          </div>
                                          <div className='b-val text-break'>{Utils.isEmpty(subscription['bundleData'][subPackageSelection['value']][f]) ? '-' : subscription['bundleData'][subPackageSelection['value']][f]}</div>
                                        </Col>
                                      )
                                    }
                                  </Row>
                                </Container>
                              </div>
                            </div>
                          }
                          <p className='title a-c-title'>Enabled applications</p>
                          <div className='a-c-area'>
                            {
                              subscription['userApps'].map(a =>
                                shouldShowApp(a) &&
                                <div className='a-card' id={'app-' + a}>
                                  {/* <div className='app-title clearfix'>
                                    <div className='app-name float-start'>{applicationMap.get(a) ? applicationMap.get(a).applicationCommercialName : applicationMap.get(a).applicationName}</div>
                                    <div className='app-dc float-end'>{subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['state'] : ''}</div>
                                  </div> */}
                                  <div className='app-title d-flex align-items-start justify-content-between'>
                                    <div className='app-name'>
                                      <div className='d-flex align-items-center justify-content-start'>
                                        <div className='app-name-icon'><MdBrightnessAuto /></div>
                                        <div className='text-truncate'>{applicationMap.get(a) ? applicationMap.get(a).applicationCommercialName : applicationMap.get(a).applicationName}</div>
                                        {
                                          pcApplicationMap.get(a) && pcApplicationMap.get(a).applicationType &&
                                          <div className='app-name-type'>{`(${pcApplicationMap.get(a).applicationType})`}</div>
                                        }
                                      </div>
                                      <div className='app-instance-small text-muted' title={`Application Instance (Datacenter): ${subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['applicationInstanceId'] : ''}`}>
                                        {subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['applicationInstanceId'] : ''}
                                      </div>
                                    </div>
                                    <div className={`app-dc d-flex align-items-center justify-content-end ${Utils.parseAppStatus(subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['state'] : '')}`}>
                                      <div className='app-status-icon' style={{ margin: '-2px 4px 0px 0px' }} title={Utils.parseAppStatus(subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['state'] : '')}>{Utils.parseAppStatusIcon(subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['state'] : '')}</div>
                                      <div className='text-truncate'>{subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['state'] : ''}</div>
                                    </div>
                                  </div>
                                  {
                                    pcApplicationMap.get(a) && !subscription['entData'][pcApplicationMap.get(a).applicationType] &&
                                    <Container fluid className='p-0 px-1'>
                                      <Row className='d-flex align-items-center px-3 py-1'>
                                        <Col md={4} className='text-start text-wrap ps-1'>Application type</Col>
                                        <Col md={8} className='text-break text-end text-wrap pe-2'>{pcApplicationMap.get(a).applicationType}</Col>
                                      </Row>
                                      <Row className='d-flex align-items-center px-3 py-1'>
                                        <Col md={4} className='text-start text-wrap ps-1'>Application description</Col>
                                        <Col md={8} className='text-break text-end text-wrap pe-2'>{pcApplicationMap.get(a).applicationDescription}</Col>
                                      </Row>
                                    </Container>
                                  }
                                  {
                                    pcApplicationMap.get(a) && subscription['entData'][pcApplicationMap.get(a).applicationType] &&
                                    <EntitlementData appEntData={subscription['entData'][pcApplicationMap.get(a).applicationType]} appDesc={pcApplicationMap.get(a).applicationDescription} subId={subscription['subData']['provisioningId1']} username={user.username} />
                                  }
                                  <div className='cap-heading'>Capabilities</div>
                                  <div className='app-caps d-flex flex-wrap'>
                                    {
                                      subscription['userCaps'][a].filter(c => shouldShowCap(c)).map((c, _idx) =>
                                        (_idx < MAX_CHIPS || toggleChip[`ac-toggle-${a}`]) &&
                                        <OverlayTrigger trigger={['hover', 'focus']} delay={250} placement="auto" rootClose={true} overlay={
                                          <Popover id={'cap-popover-' + c} className='cap-popover'>
                                            <Popover.Header as="h3" className='text-truncate'>{capabilityMap.get(c) ? capabilityMap.get(c).capabilityCommercialName : capabilityMap.get(c).capabilityName}</Popover.Header>
                                            <Popover.Body>{capabilityMap.get(c) ? capabilityMap.get(c).capabilityDescription ? capabilityMap.get(c).capabilityDescription : 'NA' : 'NA'}</Popover.Body>
                                          </Popover>
                                        }>
                                          <div className='app-cap'>{capabilityMap.get(c) ? capabilityMap.get(c).capabilityCommercialName : capabilityMap.get(c).capabilityName}</div>
                                        </OverlayTrigger>
                                      )
                                    }
                                    {
                                      subscription['userCaps'][a].filter(c => shouldShowCap(c)).length > MAX_CHIPS && !toggleChip[`ac-toggle-${a}`] &&
                                      <div className='app-cap' onClick={() => setToggleChip({ ...toggleChip, [`ac-toggle-${a}`]: true })} style={{ cursor: 'pointer' }} title={`Show ${subscription['userCaps'][a].filter(c => shouldShowCap(c)).length - MAX_CHIPS} more capabilities`}>{`+${subscription['userCaps'][a].filter(c => shouldShowCap(c)).length - MAX_CHIPS}`}</div>
                                    }
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        </Col>
                      </Row>
                    </Row>
                  </Container>
                </Tab>

              </Tabs>
            }

            {
              /* bottom navigation buttons */
              originalSubscription['userOffers'].length !== 0 &&
              <div className='clearfix mt-3'>
                <div className={(activeProfile === 'details' || activeProfile === 'review') ? 'float-start profile-button visible' : 'float-start profile-button invisible'} onClick={() => handleButtonClick('prev')}>Previous</div>
                <Button className="profile-button float-end" onClick={() => handleButtonClick('next')}>{activeProfile === 'review' ? 'Submit' : 'Next'}</Button>
              </div>
            }

            {/* Trigger change subscription modal */}
            <Modal size='lg' backdrop='static' keyboard={false} show={showSubStatusModal}>
              <Modal.Header>
                <Modal.Title>Subscription status</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container fluid>
                  <Row>
                    <Col>
                      <p variant="secondary">Click <b>Change subscription</b> to trigger change subscription, you can not go back once request is triggered.</p>
                      <p variant="secondary">Click <b>Cancel</b> to continue changing subscription details.</p>
                      <ul className='m-0 p-0 mb-3'>
                        {
                          !RBACUtils.hasRole(user.username, 'ADMINISTRATOR') && isEqual(subscription, originalSubscription) &&
                          <li className='d-block text-danger'>You can not trigger change subscription request for same subscription. Please make changes and then try again.</li>
                        }
                        {
                          (subscription['userOffers'].length === 0 || subscription['userBundles'].length === 0) &&
                          <li className='d-block text-danger'>No Offer or Bundle selected. Please select appropriate offer or bundle from 'Select offer' section.</li>
                        }
                        {
                          Object.keys(subscription['subData']).map(f =>
                            bundleOrderFields.indexOf(f) !== -1 && Utils.isEmpty(subscription['subData'][f]) &&
                            <li className='d-block text-danger'>Please specify required subscription data: <b>{f}</b></li>
                          )
                        }
                        {
                          Object.keys(subscription['custData']).map(f =>
                            bundleOrderFields.indexOf(f) !== -1 && Utils.isEmpty(subscription['custData'][f]) &&
                            <li className='d-block text-danger'> Please specify required customer data: <b>{f}</b></li>
                          )
                        }
                        {
                          Object.keys(subscription['bundleData']).map(Number).map(b =>
                            Object.keys(subscription['bundleData'][b]).map(f =>
                              bundleMap.get(b) && bundleMap.get(b).bundleOrderDetails.indexOf(f) !== -1 && Utils.isEmpty(subscription['bundleData'][b][f]) &&
                              <li className='d-block text-danger'>Please specify required bundle data <i>{bundleMap.get(b).bundleCommercialName}</i>: <b>{f}</b></li>
                            )
                          )
                        }
                      </ul>
                      {
                        subAlertmessage !== '' &&
                        <Alert key={subAlertVariant} variant={subAlertVariant}>
                          {
                            subAlertVariant === 'primary' &&
                            <div className="spinner-border text-primary spinner-grow-sm me-2" role="status"></div>
                          }
                          {subAlertmessage}
                        </Alert>
                      }
                      <p style={{ margin: '-5px 0px 2px 2px', fontWeight: '500' }}>Logs:</p>
                      <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 300 }}>
                        <div className="sub-status-logs" style={{ backgroundColor: '#f5f5f5', padding: '3px', borderRadius: '5px', fontSize: '12px', minHeight: '100px' }}>
                          {
                            subStatusLogs.split('\n').map(l =>
                              <div className='text-break' style={{ borderBottom: '1px solid #e5e5e5', padding: '2px 5px' }}>{l.replace(/,/g, ", ")}</div>
                            )
                          }
                        </div>
                      </Scrollbars>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <div className='clearfix'>
                  {
                    subAlertVariant === 'secondary' &&
                    <Button variant="secondary" className='float-start me-2' size='sm' title='Close this modal, and continue editing subscription' onClick={() => { setShowSubStatusModal(false); }}>Cancel</Button>
                  }
                  {
                    subAlertVariant === 'secondary' &&
                    <Button variant="info" className='float-end ms-2' size='sm' title='Trigger change subscription' disabled={!isAllowedToChangeSubscription()} onClick={() => triggerChangeSubscriptionRequest()}>Change subscription</Button>
                  }
                  {
                    (subAlertVariant === 'success' || subAlertVariant === 'error' || subAlertVariant === 'primary') &&
                    <Button variant="primary" className='float-end ms-2' size='sm' disabled={(subAlertVariant === 'primary') ? true : false} onClick={() => { setShowSubStatusModal(false); window.location = '/'; }}>Close</Button>
                  }
                </div>
              </Modal.Footer>
            </Modal>

            {/* Move or Remove field Confirmation Modal */}
            <Modal size='md' show={confirmationModal['show']} onHide={() => setConfirmationModal({ ...confirmationModal, show: false })} backdrop="static" keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title>{confirmationModal['title']}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{confirmationModal['body']}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" size='sm' onClick={() => { setConfirmationModal({ ...confirmationModal, show: false }); confirmationModal.successFn(); }}>Ok</Button>
                <Button variant="secondary" size='sm' onClick={() => setConfirmationModal({ ...confirmationModal, show: false })}>Cancel</Button>
              </Modal.Footer>
            </Modal>

            {/* Add field Modal */}
            <Modal size='lg' show={addFieldModal['show']} onHide={() => setAddFieldModal({ ...addFieldModal, show: false })} backdrop="static" keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add new field to Subscription</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container fluid>
                  <Row className='m-0'>
                    <Col style={{ padding: '5px 0px' }}>
                      This will add new field under <span style={{ fontWeight: '500' }}>
                        {
                          addFieldModal['from'] === 'S'
                            ? ' "Subscription data"'
                            : addFieldModal['from'] === 'C'
                              ? ' "Customer data"'
                              : bundleMap.get(addFieldModal['bundleId'])
                                ? 'Bundle: "' + bundleMap.get(addFieldModal['bundleId']).bundleCommercialName + '"'
                                : ' "Bundle data"'
                        }
                      </span>.
                    </Col>
                  </Row>
                  <Row style={{ margin: '5px 0px 0px 0px' }}>
                    <Col md={6} style={{ padding: '5px 12px 5px 0px' }}>
                      <p className='mb-1'>Choose field</p>
                      <Form.Select value={addFieldModal['fieldToAdd']} onChange={(e) => setAddFieldModal({
                        ...addFieldModal,
                        fieldToAdd: e.target.value,
                        newFieldName: e.target.value,
                        newFieldValue: getNewFieldValue(e.target.value)
                      })}>
                        <option selected value='default'>Choose field to be added</option>
                        {
                          getAvailableFields(addFieldModal['from']).map((f, _idx) =>
                            <option value={f.id}>{getFieldTitle(f.title, f.id)} ({f.id})</option>
                          )
                        }
                      </Form.Select>
                    </Col>
                    <Col md={6} style={{ padding: '5px 0px 5px 12px' }}>
                      <p className='mb-1'>Field name</p>
                      <Form.Group className="mb-3" controlId="fieldValueName">
                        <Form.Control disabled={addFieldModal['fieldToAdd'] !== 'newField'} type="text" value={addFieldModal['newFieldName'] === 'newField' || addFieldModal['newFieldName'] === 'default' ? '' : addFieldModal['newFieldName']} placeholder="Enter new custom field name" onChange={(e) => setAddFieldModal({ ...addFieldModal, newFieldName: e.target.value })} />
                        {/* <Form.Text className="text-muted">The new field will be added with this name.</Form.Text> */}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='m-0'>
                    <Col md={6}></Col>
                    <Col md={6} style={{ padding: '5px 0px 5px 12px' }}>
                      <Form.Group className="mb-3" controlId="fieldValueInput">
                        <p className='mb-1'>Field value</p>
                        <Form.Control type="text" value={addFieldModal['newFieldValue']} disabled={addFieldModal['fieldToAdd'] === 'default'} placeholder="Enter field value" onChange={(e) => setAddFieldModal({ ...addFieldModal, newFieldValue: e.target.value })} />
                        {/* <Form.Text className="text-muted">The field will be added with this value, it can be changed later.</Form.Text> */}
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" size='sm' onClick={() => { setAddFieldModal({ ...addFieldModal, show: false }); addField(addFieldModal['from'], addFieldModal['newFieldName'], addFieldModal['newFieldValue'], addFieldModal['bundleId']); }}>Ok</Button>
                <Button variant="secondary" size='sm' onClick={() => setAddFieldModal({ ...addFieldModal, show: false })}>Cancel</Button>
              </Modal.Footer>
            </Modal>

          </Container>
        </Col>

        {/* Right sidebar */}
        <Col md={2}>
          {
            activeProfile === 'details' ?
              <Nav id='navbar' className="flex-column r-nav">
                <p className='mb-1'>On this page</p>
                <Nav.Link href="#d-sub-data" eventKey='d-sub-data'>Subscription data</Nav.Link>
                <Nav.Link href="#d-cust-data" eventKey='d-cust-data'>Customer data</Nav.Link>
                {
                  showFastFillView() &&
                  <Nav.Link href="#fast-fill" eventKey='fast-fill'>Fastfill values</Nav.Link>
                }
                {
                  showPerBundleView() &&
                  <>
                    <Nav.Link href="#per-bundle-val" eventKey='per-bundle-val'>Bundle values</Nav.Link>
                    {
                      subscription['userOffers'].map(o =>
                        showOfferInPerBundleView(o) &&
                        <Nav.Link className='ps-4' style={{ fontSize: '13px' }} href={'#offer-accordion-' + o} eventKey={'offer-accordion-' + o}>{offerMap.get(o).offerCommercialName}</Nav.Link>
                      )
                    }
                  </>
                }
              </Nav>
              : activeProfile === 'review' ?
                <Nav id='navbar' className="flex-column r-nav cs">
                  <p className='mb-1'>On this page</p>
                  <Nav.Link href="#r-sub-data" eventKey='r-sub-data'>Subscription data</Nav.Link>
                  {
                    Object.keys(subscription['custData']).length > 0 &&
                    <Nav.Link href="#r-cust-data" eventKey='r-cust-data'>Customer data</Nav.Link>
                  }
                  {
                    subscription['userOffers'].length > 0 &&
                    <Nav.Link href="#sel-sub" eventKey='sel-sub'>Subscription package</Nav.Link>
                  }
                  {
                    subscription['userApps'].map(a =>
                      shouldShowApp(a) &&
                      <Nav.Link className='ps-4' style={{ fontSize: '13px' }} href={'#app-' + a} eventKey={'app-' + a}>{applicationMap.get(a) ? applicationMap.get(a).applicationCommercialName : applicationMap.get(a).applicationName}</Nav.Link>
                    )
                  }
                </Nav>
                :
                <div></div>
          }
        </Col>

      </Row>
    </Container>

  );

};

export default withAuthenticator(EditSub, withAuthenticatorOptions);