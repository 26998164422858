import * as Utils from './utils';
import parseUserRole from './UserRole';
import defaultRole from '../data/default_role'
import defaultProdRole from '../data/default_role_prod'

const defaultUserRole = Utils.isProductionEnv() ? defaultProdRole : defaultRole;

export const getDefaultUserRole = () => {
  return defaultUserRole;
}

export const getUserRole = (username) => {
  let role = parseUserRole(Utils.fetchFromSessionStorage(username, 'userRole', {}));
  return role;
};

/**
 * Checks if the user has a given role.
 * 
 * @param {User} user - The user object to check.
 * @param {string} role - The role to check for ('USER' or 'ADMINISTRATOR').
 * @returns {boolean} True if the user has the given role, false otherwise.
 */
export function hasRole(user, role) {
  return getUserRole(user).role.toUpperCase() === role.toUpperCase();
}

/**
 * Checks if the user has read or write access to the subscription module.
 * 
 * @param {User} user - The user object to check.
 * @param {AccessLevel} access - The access level to check for ('READ' or 'WRITE').
 * @returns {boolean} True if the user has the given access level, false otherwise.
 */
export function hasSubscriptionAccess(user, access) {
  const subscriptionModule = getUserRole(user).modules.find((m) => m.name === 'SUBSCRIPTION');
  if (subscriptionModule) {
    return subscriptionModule.access === access;
  }
  return false;
}

/**
 * Filters all the offer ids based on access provided to the user.
 * 
 * @param {User} user - The user object to check.
 * @param {Array} arr - Array of offer ids
 * @returns {Array} - return filtered offer ids based on access provided to the user
 */
export function filterOffersForUser(user, arr) {
  const subscriptionModule = getUserRole(user).modules.find((m) => m.name === 'SUBSCRIPTION');
  if (subscriptionModule) {
    const offers = subscriptionModule.offers;
    if (offers === 'ALL') {
      return arr;
    } else if (Array.isArray(offers)) {
      return arr.filter(e => !arr.includes(e));
    }
  }
  return arr;
}

export function hasAccessToOffer(user, oid) {
  const subscriptionModule = getUserRole(user).modules.find((m) => m.name === 'SUBSCRIPTION');
  if (subscriptionModule) {
    const offers = subscriptionModule.offers;
    if (offers === 'ALL') {
      return true;
    } else if (Array.isArray(offers)) {
      return offers.includes(oid);
    }
  }
  return false;
}

export function hasAccessToBundle(user, bid) {
  const subscriptionModule = getUserRole(user).modules.find((m) => m.name === 'SUBSCRIPTION');
  if (subscriptionModule) {
    const bundles = subscriptionModule.bundles;
    if (bundles === 'ALL') {
      return true;
    } else if (Array.isArray(bundles)) {
      return bundles.includes(bid);
    }
  }
  return false;
}

export function hasFieldCustomizationAccess(username) {
  const subscriptionModule = getUserRole(username).modules.find((m) => m.name === 'SUBSCRIPTION');
  if (subscriptionModule) {
    return subscriptionModule['fieldCustomization'] ? subscriptionModule['fieldCustomization'] : false;
  }
  return false;
}

export function isSubNotesAdministrator(username) {
  const subscriptionModule = getUserRole(username).modules.find((m) => m.name === 'SUBSCRIPTION');
  if (subscriptionModule) {
    return subscriptionModule['notesAdministrator'] ? subscriptionModule['notesAdministrator'] : false;
  }
  return false;
}

/**
 * Filters all the bundle ids based on access provided to the user.
 * 
 * @param {User} user - The user object to check.
 * @param {Array} arr - Array of bundle ids
 * @returns {Array} - return filtered bundle ids based on access provided to the user
 */
export function filterBundlesForUser(user, arr) {
  const subscriptionModule = getUserRole(user).modules.find((m) => m.name === 'SUBSCRIPTION');
  if (subscriptionModule) {
    const bundles = subscriptionModule.bundles;
    if (bundles === 'ALL') {
      return arr;
    } else if (Array.isArray(bundles)) {
      return arr.filter(e => !arr.includes(e));
    }
  }
  return arr;
}

/**
 * Filters all the subscription ids based on access provided to the user.
 * 
 * @param {User} user - The user object to check.
 * @param {Subscription} str - subscription
 * @returns {Boolean} - return if user has access to usageData generation
 */
export function hasUsageDataAccess(user, subscription) {
  const subscriptionModule = getUserRole(user).modules.find((m) => m.name === 'SUBSCRIPTION');
  if (subscriptionModule) {
    const usageData= subscriptionModule.usageData;
    if (usageData === 'ALL') {
      return true;
    } else if (Array.isArray(usageData)) {
      return usageData.includes(subscription);
    }
  }
  return false;
}
