
//
import minimapModule from "diagram-js-minimap";
export const BpmnViewerProps = {
  height: "80vh",
  width:"80vw",
  additionalModules: [minimapModule],
  overflow: "hidden",
  // id: "i",
  keyboard: {
    bindTo: window,
  },
};

