import { useState, useEffect } from "react";
import { Row, Col, Button, ButtonGroup, Dropdown } from "react-bootstrap";
import * as Utils from "../../../common/utils";
import { Spinner } from "../common/Spinner";
import { API } from "aws-amplify";
import Form from "react-bootstrap/Form";
import { ReportDownloadBtn } from "../common/btn";
import "bootstrap/dist/css/bootstrap.min.css";

export const ReportsHistoryView = ({ user ,setModal}) => {
  const STAGE = Utils.getCurrentStage();
  const FETCHING_INTERVAL = 20000;
  const ITEMS_PER_PAGE = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedData, setSortedData] = useState({}); // State to store sorted data
  const [reportsData, setReportsData] = useState({});
  const [filter, setFilter] = useState("All");
  const [pageSize, setPageSize] = useState(ITEMS_PER_PAGE);

  const [spinner, setSpinner] = useState({
    status: true,
    message: 'message: Loading reports from db',
  });
  const pageOptions = [5, 10, 15];
  const parseDates = ({ startDate, endDate }) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const startFormatted = start.toLocaleDateString('en-GB', options);
    const endFormatted = end.toLocaleDateString('en-GB', options);
    
    // Same day
    if (startFormatted === endFormatted) {
      return startFormatted; // e.g., "18/01/2024"
    }
  
    const isStartOfMonth = start.getDate() === 1;
    const isEndOfMonth = end.getMonth() !== start.getMonth() || end.getDate() === new Date(end.getFullYear(), end.getMonth() + 1, 0).getDate();
  
    // Beginning and end of a month
    if (isStartOfMonth && isEndOfMonth) {
      return start.toLocaleDateString('en-GB', { month: 'short', year: '2-digit' }); // e.g., "Aug-24"
    }
  
    // Same month, partial range
    if (start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
      return `${start.getDate()}-${end.getDate()}/${startFormatted.slice(3)}`; // e.g., "1-14/08/2024"
    }
  
    // Different months or years
    return `${startFormatted} to ${endFormatted}`; // e.g., "31/01/2024 to 02/02/2024"
  };
  const isPaginationDisabled = (paginationExp) => {
    switch (paginationExp) {
      case "first":
        return currentPage === 1;
      case "previous":
        return currentPage === 1;
      case "next":
        return (
          currentPage === Math.ceil(Object.keys(sortedData).length / pageSize)
        );
      case "last":
        return (
          currentPage === Math.ceil(Object.keys(sortedData).length / pageSize)
        );
      default:
        return true;
    }
  };
  const handlePageOptionChange = (value) => {
    setCurrentPage(1);
    setPageSize(value);
  };

  const handleStateFilterChange = (value) => {
    setFilter(value);
    setCurrentPage(1);
  };
  
  useEffect(() => {
    const updateReportDataBase = async () => {
      const payload = { stage:STAGE };
      try {
        await API.post("SspBackendApi", "/updateReportStatus", {
          body: payload,
          queryStringParameters: {
            stage: STAGE,
            id: user.username,
            region: user.pool.userPoolId.split("_")[0],
          },
        });
      } catch (e) {
        console.error("unable to update report data base:", e);
      }
    };
 
    const fetchReportsStatus = async () => {
      let params = {
        response: false,
        queryStringParameters: {
          stage: STAGE,
          id: user.username,
          region: user.pool.userPoolId.split("_")[0],
        },
      };
      updateReportDataBase();
      const res1 = await API.get("SspBackendApi", "/userinfo", params);
      const { reportList } = res1.data;
      setReportsData(reportList);
    };

    let count = 0;
    fetchReportsStatus();
    setSpinner({ status: false, message: "" });
    const interval = setInterval(async () => {
      if (count >= 10) {
        clearInterval(interval);
        return;
      }

      await fetchReportsStatus();
      count++;
    }, FETCHING_INTERVAL);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

 



  const downloadUsageReport = async (reportId) => {
    const downloadUrlRes = await API.get("SspBackendApi", `/generateReport`, {
      queryStringParameters: {
        stage: STAGE,
        id: user.username,
        region: user.pool.userPoolId.split("_")[0],
        requestId: reportId,
      },
    });

    const link = document.createElement("a");
    link.href = downloadUrlRes.downloadUrl;
    // TODO: decide file name
    link.download = "raw_data_cdr.csv"; // not sure if line does anything
    link.click();
  };

  function sortByRequestTimeStamp(reportList) {
    const keys = Object.keys(reportList);
    const sortedKeys = [...keys];

    sortedKeys.sort((a, b) => {
      const timeStampA = new Date(reportList[a].RequestTimeStamp);
      const timeStampB = new Date(reportList[b].RequestTimeStamp);

      // Compare timestamps in ascending order
      return timeStampA - timeStampB;
    });

    const sortedReportList = {};
    sortedKeys.forEach((key) => {
      sortedReportList[key] = reportList[key];
    });

    return sortedReportList;
  }

  useEffect(() => {
    // Sort the data by RequestTimeStamp when it changes
    let sortedAndFilteredData = {};
    if (filter !== "All") {
      for (let report in reportsData) {
        if (reportsData[report].ReportStatus === filter) {
          sortedAndFilteredData[report] = reportsData[report];
        }
      }
    } else sortedAndFilteredData = reportsData;
    if (sortedAndFilteredData&&Object.keys(sortedAndFilteredData).length !== 0) //
          setSortedData(sortByRequestTimeStamp(sortedAndFilteredData));
  }, [reportsData, filter]);

  return (
    <div>
      <Spinner spinner={spinner} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "light",
        }}
      >
        <div className="d-flex align-items-center justify-content-between bg-light py-2 px-2 w-100% rounded">
          <div>
            <small>Report state</small>
          </div>
          <Form.Select
            size="sm"
            placeholder="Report State"
            title="Filter by billing"
            aria-label="Filter by billing"
            onChange={(e) => handleStateFilterChange(e.target.value)}
          >
            <option value={"All"}>All</option>
            <option value={"COMPLETED"}>Completed</option>
            <option value={"INFLIGHT"}>Inflight</option>
            <option value={"FAILED"}>Failed</option>
            <option value={"NO_DATA"}>No Data</option>
          </Form.Select>
        </div>
        <div className="w-auto me-3">
          <div className="mb-0">
            <small>&nbsp;</small>
          </div>
        </div>
      </div>
      <Row>
        <h4 className="text-center section-title bg-gray-100 text-gray-700 font-normal">
          Reports History
        </h4>
      </Row>
      {Object.entries(sortedData)
        .reverse() // Reverse the order here
        .slice((currentPage - 1) * pageSize, currentPage * pageSize)
        .map(([key, value]) => (
          <div key={key}>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                height: "10vh",
              }}
            >
              <Col    //tailwind doesn't support vw
                style={{
                  maxWidth: "8vw",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReportDownloadBtn
                  status={value.ReportStatus}
                  onClick={() => downloadUsageReport(key)}
                />
              </Col>
              <Col>
                <Row>
                  <Col>Subscription:</Col> <Col>{value.Sub}</Col>
                  <Col>Data Range:</Col>
                  <Col>
                  {parseDates(value)}
                    
                  </Col>
                </Row>
                <Row>
                  <Col>Request Type:</Col>
                  <Col>{value.reportType}</Col>
                  <Col>Request Date:</Col> <Col>{value.RequestTimeStamp}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                className="flex flex-row-reverse justify-end"
              >
                <div
                  className="pb-1 pe-3 text-end link-secondary bg-white rounded-5 border-t-0 border-b-0 cursor-pointer text-sm"
                  onClick={()=>{setModal({show:true,type:"report",content:value})}}
                >
                  <small> details&raquo;</small>
                </div>
              </Col>
            </Row>
          </div>
        ))}

      {/* Pagination */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div className="d-flex align-items-center justify-content-start">
          <ButtonGroup size="sm" className="me-2">
            <Button
              variant="secondary"
              onClick={() => setCurrentPage(1)}
              disabled={isPaginationDisabled("first")}
            >
              First
            </Button>
            <Button
              variant="secondary"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={isPaginationDisabled("previous")}
            >
              Previous
            </Button>
            <Button
              variant="secondary"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={isPaginationDisabled("next")}
            >
              Next
            </Button>
            <Button
              variant="secondary"
              onClick={() =>
                setCurrentPage(
                  Math.ceil(Object.keys(sortedData).length / pageSize)
                )
              }
              disabled={isPaginationDisabled("last")}
            >
              Last
            </Button>
          </ButtonGroup>
          <div className="me-2">
            Page{" "}
            <strong>
              {currentPage} of{" "}
              {Math.ceil(Object.keys(sortedData).length / pageSize)}
            </strong>
          </div>
        </div>
        <div>
          <Dropdown className="pagesize-dropdown">
            <Dropdown.Toggle
              variant="secondary"
              id="page-size-dropdown"
              size="sm"
            >
              Show {pageSize}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {pageOptions.map((pageSizeOption) => (
                <Dropdown.Item
                  key={pageSizeOption}
                  active={pageSizeOption === pageSize}
                  onClick={() => handlePageOptionChange(pageSizeOption)}
                >
                  Show {pageSizeOption}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
