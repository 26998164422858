// react lib
// eslint-disable-next-line
import { useState,useEffect } from "react";

// react bootstrap components and style
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

// stylesheet
import "../../scss/sub_dropdown.scss";

// external libs
import {
  MdOutlineMoreHoriz,
  MdOpenInNew,
  MdOutlineShop,
  MdInsights,
  MdOutlineMedicalServices,
  MdDescription 
} from "react-icons/md";
import { BiChevronLeftSquare } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import CompareOrdersModal from "./CompareOrdersModal";

// internal data and libs

const parseExtUrls = (extUrls) => {
  const extUrlsList = extUrls.split("]");
  const urls = {
    mavenLink: [],
    byoc: [],
    verint: [],
  };
  extUrlsList.forEach((urlObj) => {
    const urlSplitted = urlObj.split("|");
    const title = urlSplitted[0];
    const url = urlSplitted[1];
    if (title.includes("Mavenlink")) {
      urls.mavenLink.push(url);
    } else if (title.includes("BYOC")) {
      urls.byoc.push(url);
    } else if (title.includes("Verint")) {
      urls.verint.push(url);
    }
  });

  return urls;
};
const OrderDropDown = ({
  id,
  region,
  row,
  stage,
  username,
  hideDetails = false,
  hideChange = false,
}) => {
  const { id: flowId, ext_urls: extUrls,sub_id:subId } = row.original;
  const [showOrderDetails,setShowOrderDetails] = useState(false);
  const urls = parseExtUrls(extUrls);

  // eslint-disable-next-line
  return (
    <>

      <DropdownButton
        size="sm"
        id={`dropdown-split-basic-${id}`}
        title={<MdOutlineMoreHoriz />}
        className="d-flex align-items-center justify-content-end w-100 h-100 table-row-dropdown"
        renderMenuOnMount={true}
      >
        {!hideDetails && (
          <Dropdown.Item href={`/sub/list?id=${id}`} rel="opener">
            <div className="d-flex align-items-center justify-content-start">
              <div className="ssd-item-icon">
                <MdOutlineShop />
              </div>
              <div className="ssd-item-title">Subscription</div>
              {!hideChange && (
                <small className="newtab">
                  <MdOpenInNew />
                </small>
              )}
            </div>
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick = {() => setShowOrderDetails(true)} rel="opener">
            <div className="d-flex align-items-center justify-content-start">
              <div className="ssd-item-icon">
                <MdDescription /> 
              </div>
              <div className="ssd-item-title">Details</div>
              {!hideChange && (
                <small className="newtab">
                  <MdOpenInNew />
                </small>
              )}
            </div>
          </Dropdown.Item>
        {flowId && (
          <Dropdown.Item
            onClick={() =>
              window.open(
                `/bpmn?flow_id=${flowId}&&flow_type=flow_id`,
                "_blank"
              )
            }
            rel="opener"
          >
            <div className="d-flex align-items-center justify-content-start">
              <div className="ssd-item-icon">
                <MdInsights />
              </div>
              <div className="ssd-item-title">Workflow</div>
              {!hideChange && (
                <small className="newtab">
                  <MdOpenInNew />
                </small>
              )}
            </div>
          </Dropdown.Item>
        )}

        {urls.byoc.length > 0 &&
          urls.byoc.map((url) => (
            <Dropdown.Item onClick={() => window.open(url)} rel="opener">
              <div className="d-flex align-items-center justify-content-start">
                <div className="ssd-item-icon">
                  <MdOutlineMedicalServices />
                </div>
                <div className="ssd-item-title">BYOC</div>
                {!hideChange && (
                  <small className="newtab">
                    <MdOpenInNew />
                  </small>
                )}
              </div>
            </Dropdown.Item>
          ))}
        {urls.mavenLink.length > 0 &&
          urls.mavenLink.map((url) => (
            <Dropdown.Item onClick={() => window.open(url)} rel="opener">
              <div className="d-flex align-items-center justify-content-start">
                <div className="ssd-item-icon">
                  <BiChevronLeftSquare />
                </div>
                <div className="ssd-item-title">MavenLink</div>
                {!hideChange && (
                  <small className="newtab">
                    <MdOpenInNew />
                  </small>
                )}
              </div>
            </Dropdown.Item>
          ))}
        {urls.verint.length > 0 &&
          urls.verint.map((url) => (
            <Dropdown.Item onClick={() => window.open(url)} rel="opener">
              <div className="d-flex align-items-center justify-content-start">
                <div className="ssd-item-icon">
                  <MdOutlineMedicalServices />
                </div>
                <div className="ssd-item-title">Verint</div>
                {!hideChange && (
                  <small className="newtab">
                    <MdOpenInNew />
                  </small>
                )}
              </div>
            </Dropdown.Item>
          ))}
      </DropdownButton>
      <Modal show={showOrderDetails} onHide={()=>setShowOrderDetails(false)}>
        <Modal.Header style={{background:'rgb(238,238,238)'}} closeButton>Order Details</Modal.Header>
        <Modal.Body style={{background:'white'}} ><CompareOrdersModal region={region} stage={stage} sub={subId} id={flowId} compareLimit={1}/></Modal.Body>
        
      </Modal>
    </>
    
  );
};

export default OrderDropDown;
