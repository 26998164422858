import {
  DynamoDBStreamsClient,
  ListStreamsCommand,
} from "@aws-sdk/client-dynamodb-streams";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { QueryCommand } from "@aws-sdk/client-dynamodb";
import { getRegion } from "./awsFunctions";
import { Auth } from "aws-amplify";
import { getCurrentStage } from "../../../common/utils";
function getFlowStateTableName() {
  const stage = getCurrentStage();
  return `FlowStatev1_${stage}`;
}

export async function queryForRecentFlowByTypes(params) {
  const tableName = getFlowStateTableName();

  const queryCommand = new QueryCommand({
    KeyConditionExpression: `flow_type = :f AND begins_with(start_date,:startdateval)`,
    TableName: tableName,
    IndexName: "flowTypeIndex",
    ExpressionAttributeValues: {
      ":f": { S: params.type },
      ":startdateval": { S: `${params.year}-${params.month}-${params.day}` },
    },
  });
  return await queryDynamoDB(queryCommand);
}
async function queryDynamoDB(queryCommand) {
  const region = await getRegion();
  const ddbClient = new DynamoDBClient({
    region: region,
    credentials: Auth.currentCredentials,
  });
  const data = await ddbClient.send(queryCommand);

  return data.Items;
}
export function getString(hashMap, key) {
  return hashMap[key].S;
}

export async function getStreamArn() {
  try {
    const region = await getRegion();
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const credentials = await Auth.currentCredentials();

    const dbclient = new DynamoDBStreamsClient({
      region: region,
      credentials: credentials,
      
    });
    const tableBaseName = getFlowStateTableName();
    const command = new ListStreamsCommand({ TableName: tableBaseName });
    const res = await dbclient.send(command);
    
    return res.Streams[0].StreamArn;
  } catch (e) {
    console.log(e);
    return null;
  }
}
