// react lib
import { useState } from "react";

import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { useHoverIntent } from "react-use-hoverintent";

import * as Utils from "../../common/utils";

import { BsFillXCircleFill } from 'react-icons/bs';

import '../../scss/user_input_field.scss';

const UserInputField = ({ id, title, description, type, value, isDisabled, isRequired, onChangeFn, removeFieldFn, bundleId = -1 }) => {

  const [isHovering, intentRef] = useHoverIntent({
    timeout: 100,
    sensitivity: 10,
    interval: 200,
  });
  const [showRemoveFieldModal, setShowRemoveFieldModal] = useState(false);

  const handleChangeInput = (e, bid) => {
    bid === -1 ? onChangeFn(e) : onChangeFn(e, bid);
  };

  const classNamesForField = () => {
    const classNames = ['mb-1', 'form-style', 'sub-form', 'form-entry'];
    if (Utils.isEmpty(value)) {
      isRequired ? classNames.push('error') : classNames.push('warn');
    }
    return classNames.join(' ');
  };

  const modalBody = <div>Are you sure you want to remove the field <b>{id}</b> ?</div>

  return (
    <>
      <Form.Group className={classNamesForField()} controlId={id} ref={intentRef}>
        {
          !isRequired && removeFieldFn && isHovering &&
          <span style={{ float: 'right', marginTop: '-3px', color: '#666' }}>
            <span title='Remove field' style={{ cursor: 'pointer', paddingLeft: '5px' }} onClick={() => setShowRemoveFieldModal(true)}><BsFillXCircleFill /></span>
          </span>
        }
        <Form.Label title={description}>{title}</Form.Label>
        {
          isRequired &&
          <Form.Label className='ms-1 text-danger fw-bold fs-6' title='Required'>*</Form.Label>
        }
        <Form.Control type={type} disabled={isDisabled} name={id} value={value} onChange={(e) => handleChangeInput(e, bundleId)} />
      </Form.Group>
      <Modal size='md' show={showRemoveFieldModal} onHide={() => setShowRemoveFieldModal(false)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Remove field</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size='sm' onClick={() => { setShowRemoveFieldModal(false); removeFieldFn(id, bundleId); }}>Ok</Button>
          <Button variant="secondary" size='sm' onClick={() => setShowRemoveFieldModal(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default UserInputField;