import { useState, useEffect, useMemo, useRef } from 'react';
import { useTable, usePagination, useSortBy, useExpanded, useGlobalFilter, useFilters } from 'react-table';
import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import Collapse from 'react-bootstrap/Collapse';

import { GrPowerReset } from 'react-icons/gr';
import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown, TiFilter, TiPlus } from 'react-icons/ti';
import { MdOutlineEdit, MdOutlineCancel } from 'react-icons/md';

import Multiselect from 'multiselect-react-dropdown';

import { Amplify, API } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import LeftSideBar from "../common/LeftSideBar";
import * as Utils from "../../common/utils";
import awsExports from '../../aws-exports';
import Orgs from "./data/orgs.json";

import '../../scss/envs.scss';
import FinOpsModal from './common/FinOpsModal';

Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true
}

const STAGE = Utils.getCurrentStage();
const DEFAULT_STATUS = { show: false, type: '', progress: false, message: '' };
const COMMON_MULTISELECT_OPTIONS = {
  displayValue: 'name',
  showCheckbox: true,
  caseSensitiveSearch: true,
  className: 'filter-multiselect',
  avoidHighlightFirstOption: true,
  hidePlaceholder: true,
  showArrow: true
};

const DEFAULT_FILTER_DATA = {
  owner: [],
  owner2: [],
  alloc_budget: { min: 0, max: 10000 },
  utilized_budget: { min: 0, max: 10000 },
  org: []
};

const Products = ({ signOut, user }) => {

  //#region states

  const [tableData, setTableData] = useState([]);
  const [showFilter, setShowFilter] = useState(true);
  const [globalFilter, setGlobalFilterState] = useState('');
  const [status, setStatus] = useState(DEFAULT_STATUS);
  const [showModal, setShowModal] = useState({ type: '', data: null });
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div>
            <div title='Product name'>Name</div>
          </div>
        ),
        accessor: 'prod_name',
        Cell: ({ value, row }) => {
          return <div className='text-start'>
            <div className='d-flex flex-column align-items-start justify-content-start'>
              <div>
                <div title={String(value)} className='text-truncate me-1'>{value ? value : '--'}</div>
              </div>
              {
                row.original['is_deprecated'] &&
                <div>
                  <div title={String(value)} className='text-truncate me-1 text-danger'><small>Deprecated</small></div>
                </div>
              }
            </div>
          </div>
        },
        width: '16%'
      },
      {
        Header: () => (
          <div>
            <div title='Product owner'>Owner</div>
            <div title='Owner email' className='fw-light text-muted'><small>Owner email</small></div>
          </div>
        ),
        accessor: 'owner_name',
        Cell: ({ value, row }) => {
          return <div className='text-start'>
            <div className='d-flex flex-column align-items-start justify-content-start'>
              <div>
                <div title={String(value)} className='text-truncate me-1 status'>{value ? value : '--'}</div>
              </div>
              <div>
                <div title={String(value)} className='text-truncate me-1 text-muted'><small>{row.original['owner_email'] ? row.original['owner_email'] : '--'}</small></div>
              </div>
            </div>
          </div>
        },
        width: '16%'
      },
      {
        Header: () => (
          <div>
            <div title='Product owner 2'>Owner2</div>
            <div title='Owner2 email' className='fw-light text-muted'><small>Owner2 email</small></div>
          </div>
        ),
        accessor: 'owner2_name',
        Cell: ({ value, row }) => {
          return <div className='text-start'>
            <div className='d-flex flex-column align-items-start justify-content-start'>
              <div>
                <div title={String(value)} className='text-truncate me-1 status'>{value ? value : '--'}</div>
              </div>
              <div>
                <div title={String(value)} className='text-truncate me-1 text-muted'><small>{row.original['owner2_email'] ? row.original['owner2_email'] : '--'}</small></div>
              </div>
            </div>
          </div>
        },
        width: '16%'
      },
      {
        Header: () => (
          <div>
            <div title='Product budget'>Budget <small className='fw-normal fst-italic'>(Monthly)</small></div>
          </div>
        ),
        accessor: 'prod_budget',
        Cell: ({ value }) => {
          return prepProdBudget(value);
        },
        filter: (rows, columnIds, filterValue) => {
          return rows.filter(row =>
            parseInt(row.values[columnIds[0]]) >= parseInt(filterValue.min) &&
            parseInt(row.values[columnIds[0]]) <= parseInt(filterValue.max)
          );
        },
        width: '16%'
      },
      {
        Header: () => (
          <div>
            <div title='Product organization'>Organization</div>
          </div>
        ),
        accessor: 'org_name',
        Cell: ({ value }) => {
          return <div>
            <div className='d-flex align-items-center justify-content-start'>
              <div title={String(value)} className='text-truncate me-1'>{value ? value : '--'}</div>
            </div>
          </div>
        },
        filter: (rows, columnIds, filterValues) => {
          if (!filterValues || filterValues.length === 0) {
            return rows;
          }
          return rows.filter(row => filterValues.includes(row.values[columnIds[0]]));
        },
        width: '16%'
      },
      {
        Header: () => (
          <div>
            <div title='Number of environments'># of Environments</div>
            <div title='Utilized budget by Environments' className='fw-light text-muted'><small>Utilized budget</small></div>
          </div>
        ),
        accessor: 'num_envs',
        Cell: ({ value, row }) => {
          return <div>
            <div className='d-flex justify-content-between align-content-between'>
              <div className='d-flex flex-column align-items-start justify-content-center'>
                {prepUtilizedEnv(row.original['budget_utilized'])}
              </div>
              <div className='d-flex justify-content-end align-items-center fs-5'>
                <div className='me-1 edit-icon' onClick={() => {
                  setShowModal({
                    type: 'edit',
                    data: {
                      prod_id: row.original['prod_id'],
                      prod_name: row.original['prod_name'],
                      prod_code: row.original['prod_code'],
                      owner_name: row.original['owner_name'],
                      owner_email: row.original['owner_email'],
                      owner2_name: row.original['owner2_name'],
                      owner2_email: row.original['owner2_email'],
                      org_id: row.original['org_id'],
                      prod_budget: JSON.parse(row.original['prod_budget']),
                    }
                  })
                }} title='Update product details'><MdOutlineEdit /></div>
                <div className='me-1 remove-icon' onClick={() => {
                  setShowModal({
                    type: 'remove',
                    data: {
                      prod_id: row.original['prod_id'],
                      prod_name: row.original['prod_name']
                    }
                  })
                }} title='Remove product'><MdOutlineCancel /></div>
              </div>
            </div>
          </div>
        },
        width: '16%'
      },
      {
        Header: 'Owner email',
        accessor: 'owner_email',
        filter: (rows, columnIds, filterValues) => {
          if (!filterValues || filterValues.length === 0) {
            return rows;
          }
          return rows.filter(row => filterValues.includes(row.values[columnIds[0]]));
        }
      },
      {
        Header: 'Owner2 email',
        accessor: 'owner2_email',
        filter: (rows, columnIds, filterValues) => {
          if (!filterValues || filterValues.length === 0) {
            return rows;
          }
          return rows.filter(row => filterValues.includes(row.values[columnIds[0]]));
        }
      },
      {
        Header: 'Utlized budget',
        accessor: 'budget_utilized',
        filter: (rows, columnIds, filterValue) => {
          return rows.filter(row =>
            parseInt(row.values[columnIds[0]]) >= parseInt(filterValue.min) &&
            parseInt(row.values[columnIds[0]]) <= parseInt(filterValue.max)
          );
        },
      }
    ],
    []
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const filterData = useMemo(() => {
    if (tableData.length === 0) return DEFAULT_FILTER_DATA;
    const owners = new Map(), owner2s = new Map(), org = new Set();
    let amin = Number.MIN_VALUE, amax = Number.MAX_VALUE, umin = Number.MIN_VALUE, umax = Number.MAX_VALUE;
    tableData.forEach(row => {
      if (!owners.get(row['owner_email'])) owners.set(row['owner_email'], row['owner_name']);
      if (!owner2s.get(row['owner2_email'])) owner2s.set(row['owner2_email'], row['owner2_name']);
      if (!org.has(row['org_name'])) org.add(row['org_name']);
      amin = Math.min(row['prod_budget'], amin);
      amax = Math.max(row['prod_budget'], amax);
      umin = Math.min(row['budget_utilized'], umin);
      umax = Math.max(row['budget_utilized'], umax);
    });
    if (amin === Number.MIN_VALUE || amax === Number.MAX_VALUE || amin === amax) {
      amin = 0;
      amax = 10000;
    }
    if (umin === Number.MIN_VALUE || umax === Number.MAX_VALUE || umin === umax) {
      umin = 0
      umax = 10000;
    }

    const filter_data = {
      ...DEFAULT_FILTER_DATA,
      owner: Array.from(owners).sort((a, b) => a[1].localeCompare(b[1])),
      owner2: Array.from(owner2s).sort((a, b) => a[1].localeCompare(b[1])),
      alloc_budget: { min: amin, max: amax },
      utilized_budget: { min: umin, max: umax },
      org: [...org.values()].sort()
    };
    return filter_data;
  }, [tableData]); // eslint-disable-line react-hooks/exhaustive-deps

  const multiSelRefs = useRef({});

  //#endregion

  //#region util functions

  const handleSortIconClick = (column) => {
    column.toggleSortBy();
  };

  const formatBudget = (val) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(val);
  };

  const prepProdBudget = (val) => {
    return <div className='d-flex justify-content-start align-items-center'>
      <div className='me-2 pbe' title={`Expense budget: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(JSON.parse(val)['E'])}`}>
        <div><small>Expense</small></div>
        <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(JSON.parse(val)['E'])}</div>
      </div>
      <div className='pbc' title={`COGS spending estimate: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(JSON.parse(val)['C'])}`}>
        <div><small>COGS</small></div>
        <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(JSON.parse(val)['C'])}</div>
      </div>
    </div>
  };

  const prepUtilizedEnv = (val) => {
    return <div className='d-flex justify-content-start align-items-center'>
      <div className='me-2 pbe' title={`Utilized Expense budget: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(JSON.parse(val)['E'])}`}>
        <div>{JSON.parse(val)['CE'] || 0}</div>
        {/* <div><small>Expense</small></div> */}
        <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(JSON.parse(val)['E'])}</div>
      </div>
      <div className='pbc' title={`Utilized COGS budget: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(JSON.parse(val)['C'])}`}>
        <div>{JSON.parse(val)['CC'] || 0}</div>
        {/* <div><small>COGS</small></div> */}
        <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(JSON.parse(val)['C'])}</div>
      </div>
    </div>
  };

  const buildAPIPayload = ({ prod_budget_expense, prod_budget_cogs, ...payload }) => ({
    ...payload,
    prod_budget: { E: prod_budget_expense, C: prod_budget_cogs }
  });


  const handleGlobalFilterChange = (value) => {
    setGlobalFilterState(value);
    setGlobalFilter(value); // Set the global filter value
  };

  //#endregion

  //#region data handling

  const baseApiFn = async (amplifyAPI, httpMethod, url, params, initFn, successFn, errorFn) => {
    if (!amplifyAPI || !httpMethod || !url || !params || !initFn || !successFn || !errorFn) {
      console.error(`Missing one or more required parameters to the function. amplifyAPI = ${amplifyAPI}, httpMethod = ${httpMethod}, url = ${url}, params = ${params}, initFn = ${initFn ? 'prenset' : 'absent'}, successFn = ${successFn ? 'prenset' : 'absent'}, errorFn = ${errorFn ? 'prenset' : 'absent'}`)
      return;
    }
    let retries = 1, response = null;
    await initFn();
    while (retries <= Utils.RETRY_COUNT) {
      try {
        switch (httpMethod) {
          case 'GET':
            response = await API.get(amplifyAPI, url, params);
            break;
          case 'POST':
            response = await API.post(amplifyAPI, url, params);
            break;
          case 'PUT':
            response = await API.put(amplifyAPI, url, params);
            break;
          case 'DELETE':
            response = await API.del(amplifyAPI, url, params);
            break;
          default:
            console.error(`Unsupported http method`);
            return;
        }
        await successFn(response);
        return;
      } catch (err) {
        let error = JSON.parse(JSON.stringify(err));
        if (parseInt(error.status) === 504) {
          retries++;
          if (retries <= Utils.RETRY_COUNT) {
            await errorFn(`Request timed-out, retrying...`, true)
            await new Promise((resolve) => setTimeout(resolve, 1000));
          } else {
            errorFn(`Retry limit reached, please reload the page.`);
            return;
          }
        } else {
          await errorFn(`Error occurred while performing operation: ${httpMethod} ${url}`);
          return;
        }
      }
    }
  };

  const getRecords = async () => {
    const params = {
      queryStringParameters: {
        stage: STAGE,
        region: user.pool.userPoolId.split('_')[0]
      }
    };
    const initFn = () => setStatus({ show: true, type: 'progress', progress: true, message: 'Fetching product records...' });
    const successFn = (res) => {
      if (res?.data && res.status === 'success') {
        setTableData(res.data);
        setStatus(DEFAULT_STATUS);
      } else {
        setStatus({ show: true, type: 'error', progress: false, message: 'Error occured while fetching the products.' });
      }
    };
    const errorFn = (msg, progress = false) => setStatus({ show: true, type: 'error', progress: progress, message: msg });
    await baseApiFn('SspBackendApi', 'GET', '/products', params, initFn, successFn, errorFn);
  };

  const addRecord = async (payload) => {
    const apiPayload = buildAPIPayload(payload);
    console.log('apiPayload = ' + JSON.stringify(apiPayload));
    const params = {
      body: {
        ...apiPayload,
        stage: STAGE,
        region: user.pool.userPoolId.split('_')[0]
      },
      headers: {
        "Content-Type": "application/json"
      }
    };
    const initFn = () => setStatus({ show: true, type: 'progress', progress: true, message: 'Adding product record...' });
    const successFn = (res) => setStatus({ show: true, type: res.message ? 'success' : 'error', progress: false, message: res.message });
    const errorFn = (msg, progress = false) => setStatus({ show: true, type: 'error', progress: progress, message: msg });
    await baseApiFn('SspBackendApi', 'POST', '/products', params, initFn, successFn, errorFn);
  }

  const updateRecord = async (payload) => {
    const apiPayload = buildAPIPayload(payload);
    console.log('apiPayload = ' + JSON.stringify(apiPayload));
    const params = {
      body: {
        ...apiPayload,
        stage: STAGE,
        region: user.pool.userPoolId.split('_')[0]
      },
      headers: {
        "Content-Type": "application/json"
      }
    };
    const initFn = () => setStatus({ show: true, type: 'progress', progress: true, message: 'Updating product record...' });
    const successFn = (res) => setStatus({ show: true, type: res.message ? 'success' : 'error', progress: false, message: res.message });
    const errorFn = (msg, progress = false) => setStatus({ show: true, type: 'error', progress: progress, message: msg });
    await baseApiFn('SspBackendApi', 'PUT', `/products/${showModal['data'].prod_id}`, params, initFn, successFn, errorFn);
  }

  const removeRecord = async () => {
    const params = {
      queryStringParameters: {
        stage: STAGE,
        region: user.pool.userPoolId.split('_')[0]
      }
    };
    const initFn = () => setStatus({ show: true, type: 'progress', progress: true, message: 'Removing product record...' });
    const successFn = async (res) => {
      setStatus({ show: true, type: res.message ? 'success' : 'error', progress: false, message: res.message });
      await getRecords();
    };
    const errorFn = (msg, progress = false) => setStatus({ show: true, type: 'error', progress: progress, message: msg });
    await baseApiFn('SspBackendApi', 'DELETE', `/products/${showModal['data'].prod_id}`, params, initFn, successFn, errorFn);
  };

  //#endregion

  //#region react-table

  const handleFilterChange = (k, v) => {
    switch (k) {
      case 'prod_budget':
      case 'budget_utilized':
        if (v) setFilter(k, v);
        break;
      default:
        setFilter(k, v ? v.split(',') : undefined);
    }
  };

  const handleFilterReset = () => {
    filters.forEach(k => setFilter(k.id, undefined));
    Object.keys(multiSelRefs.current).forEach(k => multiSelRefs.current[`${k}`]?.resetSelectedValues());
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, filters },
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    setGlobalFilter,
    setFilter,
    flatRows
  } = useTable(
    {
      columns,
      data: tableData || [],
      initialState: {
        hiddenColumns: ['owner_email', 'owner2_email', 'budget_utilized']
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  //#endregion

  useEffect(() => {
    getRecords();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="p-2 mb-4" >
      <Row className='m-0'>

        {/* Left sidebar */}
        <Col md={2} className='l-nav'>
          <LeftSideBar active='pr' username={user.username} />
        </Col>

        {/* Content */}
        <Col md={10}>

          <Container fluid data-bs-spy="scroll" data-bs-target='#navbar' data-bs-offset="0" tabIndex="0" className='scrollspy-example'>

            <Row>
              <Col className='title-wrap d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-start title-text'>
                  <div>Avaya Products</div>
                </div>
                <div className='d-flex align-items-center justify-content-end title-settings'>
                  <div className='me-2'>{user.attributes.email}</div>
                  <Button size="sm" variant="outline-secondary" onClick={signOut}>Signout</Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className='d-flex align-items-center justify-content-between'>
                  <div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <div className='fs-4 fw-bold'>Products</div>
                      {
                        status['show'] && status['progress'] &&
                        <Spinner animation="border" size="sm" className='ms-2 pt-1' />
                      }
                      {
                        status['show'] && status['message'] &&
                        <div className={`ms-2 pt-1 ${status['type'] === 'success' ? 'text-success' : status['type'] === 'error' ? 'text-danger' : 'text-secondary'}`}>{status['message']}</div>
                      }
                    </div>
                    <small>List of products that represents its ownership details, environments it contains and budget allocation information</small>
                  </div>
                  <div className='d-flex align-items-center justify-content-end'>
                    <div className='d-flex justify-content-start align-items-center'>
                      <div className='d-flex justify-content-end align-items-center'>
                        <Form.Control id='gfi-id' size='sm' type="text" value={globalFilter} className='search-field' placeholder="Enter text to search products" onChange={(e) => handleGlobalFilterChange(e.target.value)} />
                        <Button size='sm' variant='secondary' className='search-reset' disabled={!globalFilter} title='Reset search' onClick={() => handleGlobalFilterChange('')}><GrPowerReset /></Button>
                      </div>
                      <div className='d-flex justify-content-end align-items-center px-2 py-1 rounded text-light ms-2 create-env' style={{ cursor: 'pointer' }} onClick={() => setShowModal({ type: 'add' })}>
                        <div><TiPlus /></div>
                        <div className='text ms-1'>Add Product</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <div>
              <FinOpsModal
                show={showModal['type'] === 'add'}
                size='lg'
                type='form'
                data={
                  [
                    {
                      id: 'prod_name',
                      title: 'Enter Product Name',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter product name',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return 'Product name can not be empty'
                      },
                      userMessageFn: (val) => {
                        return `You've selected productName: ${val}`
                      },
                      row: 1,
                      col: 8
                    },
                    {
                      id: 'prod_code',
                      title: 'Enter Product Code',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter product Code',
                      validationFn: (val) => {
                        if (!val || val.length <= 0 || val.length >= 6) return 'Product code shall be 1-6 characters long'
                      },
                      row: 1,
                      col: 4
                    },
                    {
                      id: 'owner_name',
                      title: 'Owner name',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter owner name',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return `Owner name can't be empty`
                      },
                      row: 2,
                      col: 6
                    },
                    {
                      id: 'owner_email',
                      title: 'Owner email',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter owner email',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return `Owner email can't be empty`
                      },
                      row: 2,
                      col: 6
                    },
                    {
                      id: 'owner2_name',
                      title: 'Owner2 name',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter owner2 name',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return `Owner2 name can't be empty`
                      },
                      row: 3,
                      col: 6
                    },
                    {
                      id: 'owner2_email',
                      title: 'Owner2 email',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter owner2 email',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return `Owner email2 can't be empty`
                      },
                      row: 3,
                      col: 6
                    },
                    {
                      id: 'org_id',
                      title: 'Choose Organization',
                      mutable: true,
                      type: 'select',
                      options: Orgs.sort((o1, o2) => o1.org_name.localeCompare(o2.org_name)).map(o => ({ d: o.org_name, v: o.id })),
                      placeholder: 'Choose organization',
                      userMessageFn: (val) => {
                        return `Selected Organization Id: ${val}`
                      },
                      row: 4,
                      col: 4
                    },
                    {
                      id: 'prod_budget_expense',
                      title: 'Expense budget',
                      mutable: true,
                      type: 'number',
                      placeholder: 'Enter product expense budget',
                      validationFn: (val) => {
                        if (!val || val <= 0 || val >= 100000) return 'Please enter valid Product budget in range of 1 - 100,000'
                      },
                      userMessageFn: (val) => {
                        return `Expense budget: ${formatBudget(val)}`
                      },
                      row: 4,
                      col: 4
                    },
                    {
                      id: 'prod_budget_cogs',
                      title: 'COGS spending estimate',
                      mutable: true,
                      type: 'number',
                      placeholder: 'Enter product COGS Spending Estimate',
                      validationFn: (val) => {
                        if (!val || val <= 0 || val >= 100000) return 'Please enter valid Product budget in range of 1 - 100,000'
                      },
                      userMessageFn: (val) => {
                        return `COGS Estimate: ${formatBudget(val)}`
                      },
                      row: 4,
                      col: 4
                    }
                  ]
                }
                onExit={() => setShowModal('')}
                onSubmit={async (payload) => {
                  setShowModal('');
                  await addRecord(payload);
                  await getRecords();
                }}
                title='Add new Product'
                titleDescription='Add new product definition for FinOps'
              />
            </div>
            <div>
              <FinOpsModal
                show={showModal['type'] === 'edit'}
                size='lg'
                type='form'
                data={
                  [
                    {
                      id: 'prod_name',
                      title: 'Enter Product Name',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter product name',
                      initValue: showModal['data']?.prod_name || '',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return 'Product name can not be empty'
                      },
                      userMessageFn: (val) => {
                        return `You've selected productName: ${val}`
                      },
                      row: 1,
                      col: 8
                    },
                    {
                      id: 'prod_code',
                      title: 'Enter Product Code',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter product Code',
                      initValue: showModal['data']?.prod_code || '',
                      validationFn: (val) => {
                        if (!val || val.length <= 0 || val.length >= 6) return 'Product code shall be 1-6 characters long'
                      },
                      row: 1,
                      col: 4
                    },
                    {
                      id: 'owner_name',
                      title: 'Owner name',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter owner name',
                      initValue: showModal['data']?.owner_name || '',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return `Owner name can't be empty`
                      },
                      row: 2,
                      col: 6
                    },
                    {
                      id: 'owner_email',
                      title: 'Owner email',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter owner email',
                      initValue: showModal['data']?.owner_email || '',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return `Owner email can't be empty`
                      },
                      row: 2,
                      col: 6
                    },
                    {
                      id: 'owner2_name',
                      title: 'Owner2 name',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter owner2 name',
                      initValue: showModal['data']?.owner2_name || '',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return `Owner2 name can't be empty`
                      },
                      row: 3,
                      col: 6
                    },
                    {
                      id: 'owner2_email',
                      title: 'Owner2 email',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter owner2 email',
                      initValue: showModal['data']?.owner2_email || '',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return `Owner email2 can't be empty`
                      },
                      row: 3,
                      col: 6
                    },
                    {
                      id: 'org_id',
                      title: 'Choose Organization',
                      mutable: true,
                      type: 'select',
                      initValue: showModal['data']?.org_id || '',
                      options: [
                        {
                          "id": "a0e41f1f-3d6c-4528-a150-3e2177dfc285",
                          "org_name": "???",
                          "owner_name": "NA",
                          "owner_email": "NA",
                          "bu_id": "943a45bd-9c29-4fa5-9d42-84e15c759d07"
                        },
                        {
                          "id": "de676976-11b2-40b0-849a-7fc457af4ae9",
                          "org_name": "AAEP",
                          "owner_name": "Matthew Krokosz",
                          "owner_email": "mattkrokosz@avaya.com",
                          "bu_id": "943a45bd-9c29-4fa5-9d42-84e15c759d07"
                        },
                        {
                          "id": "7a0744b4-fd76-41c8-af9d-5579b80c43b7",
                          "org_name": "ACES",
                          "owner_name": "Emir Susic",
                          "owner_email": "esusic@avaya.com",
                          "bu_id": "943a45bd-9c29-4fa5-9d42-84e15c759d07"
                        },
                        {
                          "id": "1618a7af-0985-4c8c-9bd7-5fcb594c4a8d",
                          "org_name": "ACI",
                          "owner_name": "Matthew Krokosz",
                          "owner_email": "mattkrokosz@avaya.com",
                          "bu_id": "943a45bd-9c29-4fa5-9d42-84e15c759d07"
                        },
                        {
                          "id": "116800a5-3b8b-4eb5-a0b2-53529f10849c",
                          "org_name": "AEC",
                          "owner_name": "Giovanni Domenegato",
                          "owner_email": "jdomene@avaya.com",
                          "bu_id": "943a45bd-9c29-4fa5-9d42-84e15c759d07"
                        },
                        {
                          "id": "58943f29-e119-4e42-ad33-843b38a76ffd",
                          "org_name": "AXP",
                          "owner_name": "Matthew Krokosz",
                          "owner_email": "mattkrokosz@avaya.com",
                          "bu_id": "943a45bd-9c29-4fa5-9d42-84e15c759d07"
                        },
                        {
                          "id": "b4f6ed7a-8e57-476d-9847-fbefd113d688",
                          "org_name": "AXP Connect",
                          "owner_name": "Ruby Agarwal",
                          "owner_email": "agarwal13@avaya.com",
                          "bu_id": "943a45bd-9c29-4fa5-9d42-84e15c759d07"
                        },
                        {
                          "id": "9901ea10-6ca0-4054-8a75-a2cbfc92e72f",
                          "org_name": "Cloud Ops and IT",
                          "owner_name": "Richard Driscoll",
                          "owner_email": "rdriscoll@avaya.com",
                          "bu_id": "943a45bd-9c29-4fa5-9d42-84e15c759d07"
                        },
                        {
                          "id": "3ab5e843-208b-45de-8e25-453128b92820",
                          "org_name": "QA",
                          "owner_name": "Patrick Lawless",
                          "owner_email": "plawless@avaya.com",
                          "bu_id": "943a45bd-9c29-4fa5-9d42-84e15c759d07"
                        },
                        {
                          "id": "64ecae85-c341-4d7d-b94c-f2c373f1df63",
                          "org_name": "Tier 3/Dev support/CE",
                          "owner_name": "Roy Shepard",
                          "owner_email": "rrshepard@avaya.com",
                          "bu_id": "943a45bd-9c29-4fa5-9d42-84e15c759d07"
                        }
                      ].sort((o1, o2) => o1.org_name.localeCompare(o2.org_name)).map(o => ({ d: o.org_name, v: o.id })),
                      placeholder: 'Choose organization',
                      userMessageFn: (val) => {
                        return `Selected Organization Id: ${val}`
                      },
                      row: 4,
                      col: 6
                    },
                    {
                      id: 'prod_budget_expense',
                      title: 'Expense budget',
                      mutable: true,
                      type: 'number',
                      placeholder: 'Enter product expense budget',
                      initValue: showModal['data']?.prod_budget?.E || 0,
                      validationFn: (val) => {
                        if (!val || val <= 0 || val >= 100000) return 'Please enter valid Product budget in range of 1 - 100,000'
                      },
                      userMessageFn: (val) => {
                        return `Expense budget: ${formatBudget(val)}`
                      },
                      row: 4,
                      col: 3
                    },
                    {
                      id: 'prod_budget_cogs',
                      title: 'COGS spending estimate',
                      mutable: true,
                      type: 'number',
                      initValue: showModal['data']?.prod_budget?.C || 0,
                      placeholder: 'Enter product COGS Spending Estimate',
                      validationFn: (val) => {
                        if (!val || val <= 0 || val >= 100000) return 'Please enter valid Product budget in range of 1 - 100,000'
                      },
                      userMessageFn: (val) => {
                        return `COGS Estimate: ${formatBudget(val)}`
                      },
                      row: 4,
                      col: 3
                    }
                  ]
                }
                onExit={() => setShowModal('')}
                onSubmit={async (payload) => {
                  setShowModal('');
                  await updateRecord(payload);
                  await getRecords();
                }}
                title='Update Product'
                titleDescription='Update information associated with the product in the system '
              />
            </div>
            <div>
              <FinOpsModal
                show={showModal['type'] === 'remove'}
                size='md'
                type='text'
                data={
                  <div>
                    <div>Are you sure you want to continue removing product <b>{showModal['data']?.prod_name || ''}</b> ?</div>
                    <div className='mt-3 fst-italic' style={{ fontSize: '12px', color: '#999' }}><small>This will remove Product record and all associated environments and cloud accounts.</small></div>
                  </div>
                }
                onExit={() => setShowModal('')}
                onSubmit={() => {
                  setShowModal('');
                  removeRecord();
                }}
                title='Remove Product'
                titleDescription='Removes product record and associated inforamtion'
              />
            </div>

            {/* <div className='mt-2'>
              {
                JSON.stringify(filters)
              }
            </div>
            <div className='mt-2'>
              {
                JSON.stringify(filterData)
              }
            </div> */}

            <Row className='mt-3'>
              <Col>
                {/* table filters */}
                <div className='filter-section-title'>
                  <div>
                    <span className='filter-icon pe-1'><TiFilter /></span><span>Filter</span> <small className='ms-1 toggle' onClick={() => setShowFilter(!showFilter)} >{showFilter ? 'Hide' : 'Show'}</small>
                  </div>
                  <Collapse in={showFilter}>
                    <div>
                      <div className="filter-section" id="filter-section">
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='d-flex align-items-top justify-content-start w-100'>
                            <div className='me-1' style={{ width: '20%' }}>
                              <Form.Group controlId="prod_owner">
                                <Form.Label>Owner</Form.Label>
                                <div>
                                  <Multiselect
                                    {...COMMON_MULTISELECT_OPTIONS}
                                    onSelect={(selectedList, selectedItem) => { handleFilterChange('owner_email', selectedList.map(e => e.id).join(',')) }}
                                    onRemove={(selectedList, removedItem) => { handleFilterChange('owner_email', selectedList.map(e => e.id).join(',')) }}
                                    options={filterData['owner']?.map(arr => { return { name: arr[1], id: arr[0] } })}
                                    selectedValues={filters.filter(f => f.id === 'owner_email')?.['value']?.map(s => { return { name: s, id: s } }) || null}
                                    placeholder={`Choose from ${filterData['owner']?.length || 0} owners`}
                                    ref={ref => multiSelRefs.current['owner_email'] = ref}
                                    optionValueDecorator={(v, option) => { return <span style={{ fontSize: '14px', fontWeight: 'normal' }} title={`${option.name} (${option.id})`}>{option.name} <small>({option.id})</small></span> }}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                            <div className='me-1' style={{ width: '20%' }}>
                              <Form.Group controlId="prod_owner2">
                                <Form.Label>Owner2</Form.Label>
                                <div>
                                  <Multiselect
                                    {...COMMON_MULTISELECT_OPTIONS}
                                    onSelect={(selectedList, selectedItem) => { handleFilterChange('owner2_email', selectedList.map(e => e.id).join(',')) }}
                                    onRemove={(selectedList, removedItem) => { handleFilterChange('owner2_email', selectedList.map(e => e.id).join(',')) }}
                                    options={filterData['owner2']?.map(arr => { return { name: arr[1], id: arr[0] } })}
                                    selectedValues={filters.filter(f => f.id === 'owner2_email')?.['value']?.map(s => { return { name: s, id: s } }) || null}
                                    placeholder={`Choose from ${filterData['owner2']?.length || 0} owner2's`}
                                    ref={ref => multiSelRefs.current['owner2_email'] = ref}
                                    optionValueDecorator={(v, option) => { return <span style={{ fontSize: '14px', fontWeight: 'normal' }} title={`${option.name} (${option.id})`}>{option.name} <small>({option.id})</small></span> }}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                            <div className='me-1' style={{ width: '20%' }}>
                              <Form.Group controlId="prod_owner">
                                <Form.Label>Organization</Form.Label>
                                <div>
                                  <Multiselect
                                    {...COMMON_MULTISELECT_OPTIONS}
                                    onSelect={(selectedList, selectedItem) => { handleFilterChange('org_name', selectedList.map(e => e.id).join(',')) }}
                                    onRemove={(selectedList, removedItem) => { handleFilterChange('org_name', selectedList.map(e => e.id).join(',')) }}
                                    options={filterData['org']?.map(s => { return { name: s, id: s } })}
                                    selectedValues={filters.filter(f => f.id === 'org_name')?.['value']?.map(s => { return { name: s, id: s } }) || null}
                                    placeholder={`Choose from ${filterData['org']?.length || 0} organizations`}
                                    ref={ref => multiSelRefs.current['org_name'] = ref}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                            {/* <div className='me-1 px-2' style={{ width: '21%' }}>
                            <Form.Group>
                              <Form.Label>Allocated budget (<small className='fst-italic'>{formatBudget(document.getElementById('prod-budget-min')?.value || 0)} - {formatBudget(document.getElementById('prod-budget-max')?.value || 0)}</small>)</Form.Label>
                              <div className='d-flex align-items-center justify-content-start'>
                                <div style={{ width: '27%' }}>
                                  <Form.Control type='number' id='prod-budget-min' value={filters.filter(f => f.id === 'prod_budget')?.[0]?.['value']?.['min'] || 0} size='sm' onChange={e => handleFilterChange('prod_budget', { min: e.target.value, max: document.getElementById('prod-budget-max').value })} min={filterData['alloc_budget']?.['min'] || 0} max={filterData['alloc_budget']?.['max'] || 10000} step={10} />
                                </div>
                                <div className='mx-1'>-</div>
                                <div style={{ width: '32%' }}>
                                  <Form.Control type='number' id='prod-budget-max' value={filters.filter(f => f.id === 'prod_budget')?.[0]?.['value']?.['max'] || 10000} size='sm' onChange={e => handleFilterChange('prod_budget', { min: document.getElementById('prod-budget-min').value, max: e.target.value })} min={filterData['alloc_budget']?.['min'] || 0} max={filterData['alloc_budget']?.['max'] || 10000} step={10} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className='me-1 px-2' style={{ width: '20%' }}>
                            <Form.Group>
                              <Form.Label>Utilized budget (<small className='fst-italic'>{formatBudget(document.getElementById('util-budget-min')?.value)} - {formatBudget(document.getElementById('util-budget-max')?.value)}</small>)</Form.Label>
                              <div className='d-flex align-items-center justify-content-start'>
                                <div style={{ width: '27%' }}>
                                  <Form.Control type='number' id='util-budget-min' value={filters.filter(f => f.id === 'budget_utilized')?.[0]?.['value']?.['min'] || 0} size='sm' onChange={e => handleFilterChange('budget_utilized', { min: e.target.value, max: document.getElementById('util-budget-max').value })} min={filterData['utilized_budget']?.['min'] || 0} max={filterData['utilized_budget']?.['max'] || 10000} step={10} />
                                </div>
                                <div className='mx-1'>-</div>
                                <div style={{ width: '33%' }}>
                                  <Form.Control type='number' id='util-budget-max' value={filters.filter(f => f.id === 'budget_utilized')?.[0]?.['value']?.['max'] || 10000} size='sm' onChange={e => handleFilterChange('budget_utilized', { min: document.getElementById('util-budget-min').value, max: e.target.value })} min={filterData['utilized_budget']?.['min'] || 0} max={filterData['utilized_budget']?.['max'] || 10000} step={10} />
                                </div>
                              </div>
                            </Form.Group>
                          </div> */}
                          </div>
                          <div className='d-flex align-items-top justify-content-end mt-3'>
                            <div>
                              <Button size='sm' className='pt-1' variant="secondary" onClick={() => handleFilterReset()}>Reset</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
                {/* table */}
                <div className={status['progress'] ? 'loading' : ''}>
                  <Table {...getTableProps()} className='env-table'>
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className='align-middle'>
                          {
                            headerGroup.headers.map((column, index) => (
                              <th key={index} {...column.getHeaderProps({ className: column.collapse ? 'collapse px-1' : 'px-1', style: { width: column.width } })}>
                                <div className='d-flex align-items-stretch flex-column'>
                                  <div className='mb-auto'>
                                    <div className="d-flex align-items-center justify-content-between" onClick={() => { if (!column.disableSortBy) handleSortIconClick(column) }} style={{ cursor: 'pointer' }}>
                                      <div>{column.render('Header')}</div>
                                      <div>
                                        {
                                          !column.disableSortBy &&
                                          <Button variant="link" className="p-0" title={`Sort by ${column.id}`} style={{ color: '#666' }}>
                                            <span>
                                              {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                  <TiArrowSortedDown />
                                                ) : (
                                                  <TiArrowSortedUp />
                                                )
                                              ) : (
                                                <>
                                                  <TiArrowUnsorted className="text-muted" />
                                                </>
                                              )}
                                            </span>
                                          </Button>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </th>
                            ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {
                        page.map(row => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} className='align-middle'>
                              {
                                row.cells.map(cell => (
                                  <td {...cell.getCellProps({ className: cell.column.collapse ? 'collapse px-1' : 'px-1', style: { width: cell.column.width } })}>{cell.render('Cell')}</td>
                                ))
                              }
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="d-flex align-items-center justify-content-start">
                      <ButtonGroup size="sm" className='me-2'>
                        <Button variant="secondary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>First</Button>
                        <Button variant="secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
                        <Button variant="secondary" onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
                        <Button variant="secondary" onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>Last</Button>
                      </ButtonGroup>
                      <div className='me-2'> Page{' '} <strong> {pageIndex + 1} of {pageOptions.length} </strong> <small>(Total rows: {flatRows.length})</small></div>
                    </div>
                    <div>
                      <Dropdown className='pagesize-dropdown'>
                        <Dropdown.Toggle variant="secondary" id="page-size-dropdown" size="sm">Show {pageSize}</Dropdown.Toggle>
                        <Dropdown.Menu>
                          {[10, 25, 50].map(pageSizeOption => (
                            <Dropdown.Item key={pageSizeOption} active={pageSizeOption === pageSize} onClick={() => setPageSize(pageSizeOption)}>
                              Show {pageSizeOption}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

          </Container>
        </Col>

      </Row>
    </Container>
  );

};

export default withAuthenticator(Products, withAuthenticatorOptions);