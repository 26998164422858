import { useState, React, useEffect } from 'react';
import ReactFlow, { Controls } from 'reactflow';
import 'reactflow/dist/style.css';
import { Col, Row, Table } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';

import LeftSideBar from "../common/LeftSideBar";
// import Pagination from "./Pagination.js"

import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../../aws-exports';
import axios from "axios";

import '../../scss/envs.scss';
import { StatusNode, StartNode, EndNode } from './StatusNode.js';

Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true
}

const StatusTracking = ({ signOut, user }) => {

  // ...

  // const [deploymentId, setDeploymentId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);  
  const [deleteDeploymentId, setDeleteDeploymentId] = useState('');
  const [deleteStarted, setDeleteStarted] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [overallStatus, setOverallStatus] = useState(null);
  const [overallBadge, setOverallBadge] = useState(null);
  const [modalContent, setModalContent] = useState({});
  const [statusFetchSuccessful, setStatusFetchSuccessful] = useState(false);
  // const [valErrors, setValErrors] = useState([]);
  const [deployments, setDeployments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [deploymentsPerPage, setDeploymentsPerPage] = useState(20);
  const [totalDeployments, setTotalDeployments] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('');
  const [paginationData, setPaginationData] = useState(null);

  useEffect(() => {
    setLoading(true);
    getAllDeployments(currentPage, deploymentsPerPage, filter);
    setLoading(false);
  }, [currentPage, deploymentsPerPage, filter])

  const nodeTypes = { statusNode: StatusNode, startNode: StartNode, endNode: EndNode };

  const StatusTrackingDiagram = (statusData) => {
    const elements = statusData.deployment_type === 'SUBSCRIPTION_DEPLOYMENT'? getNodesSubscriptionOnly(statusData): getNodes(statusData);

    return (
      <div style={{ width: '95vw', height: '65vh', background: '#424242', borderRadius: '20px' }}>
        <ReactFlow nodes={elements.nodes} edges={elements.edges} nodeTypes={nodeTypes} fitView >
          <Controls />
        </ReactFlow>
      </div>
    );
  };

  const handleOpenModal = async (deployment_id) => {
    const deploymentStatus = await getDeploymentStatus(deployment_id);
    if (deploymentStatus.status === 'error') {
      setOverallStatus(null);
      setModalContent({deploymentId: deployment_id, message: deploymentStatus.message});
    }
    else {
      console.log(deploymentStatus.message)
      setOverallStatus(deploymentStatus.message.status);
      setBadgeVariantForOverall(deploymentStatus.message.status);
      setModalContent({deploymentId: deployment_id, message: StatusTrackingDiagram(deploymentStatus.message)})
      setStatusFetchSuccessful(true);
      // formatStatusData(deploymentStatus.message);
      // setModalContent(StatusTrackingDiagram(deploymentStatus.message))
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setStatusFetchSuccessful(false);
    // setBadgeVariantForOverall(null);
  };

  const handleOpenDeleteModal = async (deployment_id) => {
    setDeleteDeploymentId(deployment_id);
    setShowDeleteModal(true);
  }

  const handleCloseDeleteModal = () => {
    setDeleteStarted(false);
    setDeleteDeploymentId(false);
    setShowDeleteModal(false);
  }

  const handleDeleteDeployment = async () => {
    const BASE_URL = `https://cnp-api.azurewebsites.net`;
    // const BASE_URL = `http://localhost:8000`;
    const pa55word = 'WvkDbO34p28C';
    let terraToken;
    setDeleteStarted(true);
    try {
      terraToken = await axios.post(`${BASE_URL}/token`, { username: 'form_trigger', password: pa55word }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (!terraToken.data?.access_token) return { status: 'error', message: 'Error occurred while fetching token from CN API' };
    } catch (err) {
      console.log('Error while fetching token from API.', err);
      return { status: 'error', message: 'Error occurred while deleting cloud environment' };
    }
    try {
      const getDeleteStatus = await axios.delete(`${BASE_URL}/delete`, {
        headers: {
          'Authorization': `Bearer ${terraToken.data.access_token}`
        },
        data: {
          deployment_id: deleteDeploymentId,
          requestor: user.attributes.email
        }
      });
      if (getDeleteStatus.status !== 200) {
        return { status: 'error', message: 'Error occurred while getting status from CN API' };
      }
      return (
        {
          status: 'success',
          message: getDeleteStatus.data
        }
      );
    } catch (err) {
      if (err.response.status === 404) return { status: 'error', message: 'Deployment ID not found' };
      console.error(`Error occurred while getting status from CN API. status = ${err.response.status}, data = ${JSON.stringify(err.response.data)}`);
      return { status: 'error', message: 'Error occurred while getting status from CN API' };
    }

  };


  const setBadgeVariantForOverall = (overallStatus) => {
    const status = overallStatus.toLowerCase();
    switch (status) {
      case 'success':
        setOverallBadge('success');
        break;
      case 'failed':
        setOverallBadge('danger');
        break;
      default:
        setOverallBadge('primary');
    }
  }

  const getBadgeVariant = (status) => {
    const stageStatus = status.toLowerCase();
    if (stageStatus.includes('failed')) return 'danger';
    else if (stageStatus === "success") return 'success';
    else return 'primary';
  }

  const getFormattedDate = (unformattedDate) => {
    const date = new Date(unformattedDate);

    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' }); //String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const formattedDate = `${day} ${month} ${year} ${hours}:${minutes}`;

    return formattedDate;
  }

  const getStageName = (stageCode) => {
    switch (stageCode) {
      case "SUBSCRIPTION_CREATION": return "Subscription Creation";
      case "REPO_CREATION": return "GitHub Repo Creation";
      case "WORKSPACE_CREATION": return "Terraform Cloud Workspace Creation";
      case "CODE_COPY_INTO_REPO": return "Code Copy into GitHub Repo";
      case "GITHUB_TEAM_CREATION": return "GitHub Team Creation";
      case "GITHUB_ADD_MEMBERS": return "GitHub Add Members";
      case "TFC_TEAM_CREATION": return "Terraform Cloud Team Creation";
      case "TFC_ADD_MEMBERS": return "Terraform Cloud Add Members";
      case "MG_CREATION": return "Management Group Creation";
      case "SUBSCRIPTION_DELETION": return "Subscription Deletion"
      case "WORKSPACE_DELETION" : return "Terraform Cloud Workspace Deletion"
      default: return stageCode.toLowerCase().replaceAll("_", " ");
    }
  }

  const getStatusName = (status) => {
    switch (status) {
      case "IN_PROGRESS": return "In Progress";
      case "COMPLETED": return "Completed";
      case "FAILED": return "Failed";
      default: return status.replaceAll("_", " ");
    }
  }

  const paginate = (pageNumber, totalPosts, postsPerPage) => {
    console.log("Page number: " + pageNumber);
    if (pageNumber !== currentPage)
      setCurrentPage(pageNumber > Math.ceil(totalPosts / postsPerPage)? 1: pageNumber);
  }

  const getPagination = (deploymentsPerPage, totalDeployments) => {

    return <Pagination postsPerPage={deploymentsPerPage} totalPosts={totalDeployments} paginate={paginate} />
  } 

  const getNodes = (statusData) => {
    let nodeList =[]
    let edgeList = []
    nodeList.push({id: 'start', type: 'startNode', data: { OverallStatus: statusData.status }, position: { x: -10, y: 100 } })
    statusData.stages.forEach((data) => {
      let xpos = 0;
      let ypos = 0;
      switch (data.stage_name) {
        case 'SUBSCRIPTION_CREATION' :
          xpos = 100;
          ypos = 0;
          edgeList.push({ id: 'start-sub', source: 'start', target: 'SUBSCRIPTION_CREATION', animated: false });
          break;
        case 'REPO_CREATION':
          xpos = 100;
          ypos = 200;
          edgeList.push({ id: 'start-repo', source: 'start', target: 'REPO_CREATION', animated: false });
          break;
        case 'WORKSPACE_CREATION':
          xpos = 250;
          ypos = 100;
          edgeList.push({ id: 'sub-ws', source: 'SUBSCRIPTION_CREATION', target: 'WORKSPACE_CREATION', animated: false });
          edgeList.push({ id: 'repo-ws', source: 'REPO_CREATION', target: 'WORKSPACE_CREATION', animated: false });
          break;
        case 'CODE_COPY_INTO_REPO':
          xpos = 400;
          ypos = 100;
          edgeList.push({ id: 'ws-code', source: 'WORKSPACE_CREATION', target: 'CODE_COPY_INTO_REPO', animated: false });
          break;
        case 'GITHUB_TEAM_CREATION':
          xpos = 600;
          ypos = 0;
          edgeList.push({ id: 'code-ghteam', source: 'CODE_COPY_INTO_REPO', target: 'GITHUB_TEAM_CREATION', animated: false });
          break;
        case 'TFC_TEAM_CREATION':
          xpos = 600;
          ypos = 200;
          edgeList.push({ id: 'code-tfteam', source: 'CODE_COPY_INTO_REPO', target: 'TFC_TEAM_CREATION', animated: false });
          break;
        case 'SUBSCRIPTION_DELETION':
          xpos = 50;
          ypos = 100;
          edgeList.push({ id: 'start-subdelete', source: 'start', target: 'SUBSCRIPTION_DELETION', animated: false });
          break;
        case 'WORKSPACE_DELETION':
          xpos = 150;
          ypos = 100;
          edgeList.push({ id: 'sub-wsdelete', source: 'SUBSCRIPTION_DELETION', target: 'WORKSPACE_DELETION', animated: false });
          break;
        default:
          xpos = 0;
          ypos = 0;
      }
    
      let SUCCESS = ['COMPLETED']
      let FAILED = ['GHA_TRIGGER_FAILED', 'GHA_FETCH_RUN_ID_FAILED', 'GHA_STATUS_FETCH_FAILED', 'GHA_FAILED', 'TF_STATUS_FETCH_FAILED', 'TF_NO_RUNS', "TF_FAILED",
      'SUBSCRIPTION_ID_FETCH_FAILED', 'REQUEST_CLOSED', 'MG_NOT_FOUND', 'RETRIES_EXCEEDED']
      let WAITING = ['WAITING', 'DID_NOT_EXECUTE']
      let colour = '#dcd93a';
      if (SUCCESS.includes(data.status)) {
        colour = '#0fa25c';
      } else if (FAILED.includes(data.status)) {
        colour = '#f44336';
      } else if (WAITING.includes(data.status)) {
        colour = '#40acc2';
      }

      nodeList.push({id: data.stage_name, type: 'statusNode', data: { label: getStageName(data.stage_name), status: getStatusName(data.status), statusColour: colour }, position: { x: xpos, y: ypos } })
      if (data.stage_name === "GITHUB_TEAM_CREATION") {
        nodeList.push({id: 'end', type: 'endNode', data: { }, position: { x: 800, y: 100 } })
        edgeList.push({ id: 'ghteam-end', source: 'GITHUB_TEAM_CREATION', target: 'end', animated: false });
      } else if (data.stage_name === "TFC_TEAM_CREATION") {
        edgeList.push({ id: 'tfteam-end', source: 'TFC_TEAM_CREATION', target: 'end', animated: false });
      }
    })

    return {nodes: nodeList, edges: edgeList};
  }

  const getNodesSubscriptionOnly = (statusData) => {
    let nodeList =[]
    let edgeList = []
    const PR_WAITING = ['INITIAL', 'GHA_IN_PROGRESS', 'GHA_SUCCESS', 'WAITING']
    const PR_DID_NOT_EXEC = ['GHA_TRIGGER_FAILED', 'GHA_FETCH_RUN_ID_FAILED', 'GHA_STATUS_FETCH_FAILED', 'GHA_FAILED']
    const PR_REJECTED = ['REQUEST_CLOSED']
    const TF_WAITING = ['INITIAL', 'GHA_IN_PROGRESS', 'GHA_SUCCESS', 'REQUEST_APPROVED', 'WAITING']
    const TF_DID_NOT_EXEC = ['GHA_TRIGGER_FAILED', 'GHA_FETCH_RUN_ID_FAILED', 'GHA_STATUS_FETCH_FAILED', 'GHA_FAILED', 'REQUEST_CLOSED']
    const TF_FAILED = ['TF_STATUS_FETCH_FAILED', 'TF_NO_RUNS', 'TF_FAILED']
    let pr_status = 'Success'
    let pr_colour = '#0fa25c'
    let tf_status = 'Success'
    let tf_colour = '#0fa25c'
    statusData.stages.forEach((data) => {
      switch (data.stage_name) {
        case 'SUBSCRIPTION_CREATION':
          pr_status = PR_WAITING.includes(data.status) ? 'Waiting' : PR_DID_NOT_EXEC.includes(data.status) ? 'Did Not Execute' : PR_REJECTED.includes(data.status) ? 'Rejected' : 'Approved'
          pr_colour = PR_WAITING.includes(data.status) ? '#40acc2' : PR_DID_NOT_EXEC.includes(data.status) ? '#40acc2' : PR_REJECTED.includes(data.status) ? '#f44336' : '#0fa25c'
          tf_status = TF_WAITING.includes(data.status) ? 'Waiting' : TF_DID_NOT_EXEC.includes(data.status) ? 'Did Not Execute' : TF_FAILED.includes(data.status) ? 'Failed' : 'Success'
          tf_colour = TF_WAITING.includes(data.status) ? '#40acc2' : TF_DID_NOT_EXEC.includes(data.status) ? '#40acc2' : TF_FAILED.includes(data.status) ? '#f44336' : '#0fa25c'
          break
        default:
          break
      }
    })
    nodeList.push({id: 'start', type: 'startNode', data: { }, position: { x: 0, y: 100 } })
    nodeList.push({id: 'PR_STATUS', type: 'statusNode', data: { label: 'Pull Request Status', status: pr_status, statusColour: pr_colour }, position: { x: 100, y: 100 } })
    nodeList.push({id: 'TF_STATUS', type: 'statusNode', data: { label: 'Terraform Status', status: tf_status, statusColour: tf_colour }, position: { x: 300, y: 100 } })
    edgeList.push({ id: 'start-pr', source: 'start', target: 'PR_STATUS', animated: false });
    edgeList.push({ id: 'pr-tf', source: 'PR_STATUS', target: 'TF_STATUS', animated: false });
    nodeList.push({id: 'end', type: 'endNode', data: { }, position: { x: 450, y: 100 } })
    edgeList.push({ id: 'tf-end', source: 'TF_STATUS', target: 'end', animated: false });
    return {nodes: nodeList, edges: edgeList};
  }

  const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav style={{ display: 'flex', justifyContent: 'center' }}>
            <ul className="pagination">
                {pageNumbers.map(number => (
                    <li key={number} className="page-item">
                        <Button active={currentPage === number} onClick={() => paginate(number, totalPosts, postsPerPage)}  className="page-link">
                            {number}
                        </Button>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

  const getDeploymentStatus = async (deployment_id) => {
    const BASE_URL = `https://cnp-api.azurewebsites.net`;
    // const BASE_URL = `http://localhost:8000`;
    const pa55word = 'WvkDbO34p28C';

    let terraToken;
    try {
      terraToken = await axios.post(`${BASE_URL}/token`, { username: 'form_trigger', password: pa55word }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (!terraToken.data?.access_token) return { status: 'error', message: 'Error occurred while fetching token from CN API' };
    } catch (err) {
      console.log('Error while fetching token from API.', err);
      return { status: 'error', message: 'Error occurred while creating cloud environment' };
    }

    try {
      const getDeploymentStatus = await axios.get(`${BASE_URL}/status/${deployment_id}`, {
        headers: {
          'Authorization': `Bearer ${terraToken.data.access_token}`
        }
      });
      if (getDeploymentStatus.status !== 200) return { status: 'error', message: 'Error occurred while getting status from CN API' };

      return (
        {
          status: 'success',
          message: getDeploymentStatus.data
        }
      );
    } catch (err) {
      if (err.response.status === 404) return { status: 'error', message: 'Deployment ID not found' };
      console.error(`Error occurred while getting status from CN API. status = ${err.response.status}, data = ${JSON.stringify(err.response.data)}`);
      return { status: 'error', message: 'Error occurred while getting status from CN API' };
    }

  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setFilter(searchTerm);
    console.log("Submit", searchTerm, " ", filter);
    getAllDeployments(currentPage, deploymentsPerPage, searchTerm);
    setCurrentPage(1);
  }

  const handlePageSizeChange = (e) => {
    if (e.target.value < 1) return;
    setDeploymentsPerPage(e.target.value);
    setCurrentPage(1);
  }

  const getAllDeployments = async (currentPage, deploymentsPerPage, filter = '') => {
    const BASE_URL = `https://cnp-api.azurewebsites.net`;
    // const BASE_URL = `http://localhost:8000`;
    const pa55word = 'WvkDbO34p28C';

    let terraToken;
    try {
      terraToken = await axios.post(`${BASE_URL}/token`, { username: 'form_trigger', password: pa55word }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (!terraToken.data?.access_token) return { status: 'error', message: 'Error occurred while fetching token from CN API' };
    } catch (err) {
      console.log('Error while fetching token from API.', err);
      return { status: 'error', message: 'Error occurred while creating cloud environment' };
    }

    try {
      const getDeploymentStatus = await axios.get(`${BASE_URL}/deployments?page_size=${deploymentsPerPage}&page_number=${currentPage}&filter=${filter}`, {
        headers: {
          'Authorization': `Bearer ${terraToken.data.access_token}`
        }
      });
      if (getDeploymentStatus.status !== 200) return { status: 'error', message: 'Error occurred while getting status from CN API' };
      setDeployments(getDeploymentStatus.data.deployments);
      setTotalDeployments(getDeploymentStatus.data.count);
      setPaginationData(getPagination(deploymentsPerPage, getDeploymentStatus.data.count));
      return (
        {
          status: 'success',
          message: getDeploymentStatus.data
        }
      );
    } catch (err) {
      if (err.response.status === 404) return { status: 'error', message: 'Deployment ID not found' };
      console.error(`Error occurred while getting status from CN API. status = ${err.response.status}, data = ${JSON.stringify(err.response.data)}`);
      return { status: 'error', message: 'Error occurred while getting status from CN API' };
    }

  };

  return (
    <>
    <Container fluid className="p-2 mb-4" >
      <Row className='m-0'>

        {/* Left sidebar */}
        <Col md={2} className='l-nav'>
          <LeftSideBar active="st" username={user.username} />
        </Col>

        {/* Content */}
        <Col md={9}>

          <Container fluid data-bs-spy="scroll" data-bs-target='#navbar' data-bs-offset="0" tabIndex="0" className='scrollspy-example'>

            <Row>
              <Col className='title-wrap d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-start title-text'>
                  <div>Cloud Environments</div>
                </div>
                <div className='d-flex align-items-center justify-content-end title-settings'>
                  <div className='me-2'>{user.attributes.email}</div>
                  <Button size="sm" variant="outline-secondary" onClick={signOut}>Signout</Button>
                </div>
              </Col>
            </Row>

            {/* {
              Object.keys(STATUS_FORM_DATA).map(k =>
                <Row id={k}>
                  <Col>
                    <Container fluid>
                      <Row className=''>
                        <Col className='m-0 p-0'><h5 className='fw-500 pb-1' style={{ borderBottom: '1px solid #f1f1f1' }}>{STATUS_FORM_DATA[k].title}</h5></Col>
                      </Row>
                      <Row md={3} className='pt-1 pb-2'>
                        {
                          STATUS_FORM_DATA[k].fields.map(f =>
                            <Col className='p-0 pe-3 pt-2 pb-3'>{renderField(f)}</Col>
                          )
                        }
                      </Row>
                    </Container>
                  </Col>
                </Row>
              )
            } */}
              <Form onSubmit={handleSearchSubmit}>
                <Row className='mb-3'>
                  <Col>
                    <Form.Control type="text" placeholder='Deployment ID' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                  </Col>
                  <Col>
                    <Button type="submit" variant="primary">Search</Button>
                  </Col>          
                  <Col md="auto">
                    < Form.Label style={{paddingTop: "7px"}}>Page Size</Form.Label>
                  </Col>
                  <Col xs lg="2">
                    <Form.Control type='text' onChange={handlePageSizeChange} placeholder={deploymentsPerPage} />
                  </Col>

                </Row>
              </Form>
              
              {/* {paginationData} */}
            <Row style={{paddingTop: "10px"}}>
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Deployment ID</th>
                      <th>Requestor</th>
                      <th>Status</th>
                      <th>Started at</th>
                      <th>Track</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deployments.map((deployment) => (
                      <tr key={deployment.deployment_id}>
                        <td>{deployment.deployment_id}</td>
                        <td>{deployment.requestor}</td>
                        <td><Badge padding_left='10px' bg={getBadgeVariant(deployment.status)}>{deployment.status.replaceAll('_', ' ')}</Badge></td>
                        <td>{getFormattedDate(deployment.created_at)}</td>
                        <td>
                          <Button size='sm' variant='primary' onClick={() => handleOpenModal(deployment.deployment_id)}>Track</Button>
                        </td>
                        <td>
                          <Button disabled={user.attributes.email !== deployment.requestor}  size='sm' variant='danger' onClick={() => handleOpenDeleteModal(deployment.deployment_id)}>Delete</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            {paginationData}

          </Container>

        </Col>
      </Row>
    </Container>
    <Modal show={showModal} keyboard={true} backdrop fullscreen={statusFetchSuccessful} onHide={handleCloseModal}>
      <Modal.Header >
        <Modal.Title style={{margin: '0 auto'}}>
          <div>
            Deployment Status <Badge padding_left='10px' show={overallStatus} bg={overallBadge}>
              {overallStatus?.replaceAll('_', ' ')}
            </Badge>
          </div>
          <div className='text-muted' style={{ fontSize: '14px', fontWeight: 'normal' }}>Deployment ID: {modalContent.deploymentId}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {modalContent.message}
      </Modal.Body>
      <Modal.Footer
      style={{
        display: "flex",
        justifyContent: "center",
      }}>
        <Button variant="danger" size='sm' onClick={handleCloseModal}>Close</Button>
      </Modal.Footer>
    </Modal>
    <Modal show={showDeleteModal} keyboard={true} backdrop onHide={handleCloseDeleteModal}>
      <Modal.Header >
        <Modal.Title>
          <div>
            Delete Deployment
          </div>
          <div className='text-muted' style={{ fontSize: '14px', fontWeight: 'normal' }}>Deployment ID: {deleteDeploymentId}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {deleteStarted? "Deletion triggered. Contact Subscription as a Service team for any issues": "Are you sure you want to delete deployment?"}
      </Modal.Body>
      <Modal.Footer
      style={{
        display: "flex",
        justifyContent: "center",
      }}>
        {!deleteStarted && <Button variant="danger" size='sm' onClick={handleDeleteDeployment}>Delete</Button>}
        <Button show variant="secondary" size='sm' onClick={handleCloseDeleteModal}>{deleteStarted? "Close": "Cancel"}</Button>
      </Modal.Footer>
    </Modal>
    {/* <Modal show={deleteStarted} keyboard={true} backdrop onHide={handleCloseDeleteStartedModal}>
      <Modal.Header >
        <Modal.Title>
          <div>
            {deleteError? "Deletion failed": "Deletion started"}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {deleteError? "Please contact Subscription as a Service team": "Deletion started"}
      </Modal.Body>
      <Modal.Footer
      style={{
        display: "flex",
        justifyContent: "center",
      }}>
        <Button show variant="secondary" size='sm' onClick={handleCloseDeleteStartedModal}>Close</Button>
      </Modal.Footer>
    </Modal> */}
    </>
  );

  // const getDeploymentStatus = async () => {
  //   setStatus({ show: true, type: 'progress', message: 'Fetching status...' });
  //   console.log("Deployment ID: " + deploymentId);
  //   await API.post('SspBackendApi', '/environments/status', {
  //     body: {
  //       "deployment_id": deploymentId
  //     },
  //     headers: {
  //       "Content-Type": "application/json"
  //     }
  //   }).then(response => {
  //     console.log(JSON.stringify(response));
  //     // setStatus({ show: true, type: 'success', message: 'Cloud environment created successfully.' })
  //   }).catch(error => {
  //     console.error(JSON.stringify(error));

  //     // setStatus({ show: true, type: 'error', message: 'Error occurred while creating cloud environment.' })
  //   }).finally(() => {
  //     setTimeout(() => {
  //       navigate("/environments");
  //     }, 1000);
  //   });
  // };
};

export default withAuthenticator(StatusTracking, withAuthenticatorOptions);