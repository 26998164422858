import * as React from "react"

const Aiven = (props) => {

  return (
    <div style={{ width: '24px', height: '24px' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 229.6 201.3'
        style={{
          enableBackground: "new 0 0 229.6 201.3",
        }}
        xmlSpace='preserve'
        {...props}
      >
        <linearGradient
          id='a'
          gradientUnits='userSpaceOnUse'
          x1={82.354}
          y1={154.793}
          x2={153.694}
          y2={58.353}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#f70",
            }}
          />
          <stop
            offset={0.742}
            style={{
              stopColor: "#ff3554",
            }}
          />
        </linearGradient>
        <path
          d='M159.4 123.8c2.8 0 5.5.5 8 1.4 4-4.6 6.1-9.6 6.1-14.8 0-8.5-5.8-16.7-16.2-23.1-11.2-6.8-26.2-10.6-42.1-10.6-16 0-31 3.8-42.1 10.6-10.5 6.4-16.2 14.6-16.2 23.1 0 5.2 2.1 10.2 6.1 14.8 2.5-.9 5.2-1.4 8-1.4 6.3 0 12.2 2.4 16.6 6.9 3.1 3.1 5.2 6.9 6.2 11.1 6.7 1.6 13.9 2.4 21.4 2.4 7.4 0 14.6-.8 21.4-2.4 1-4.1 3.1-8 6.2-11.1 4.4-4.5 10.3-6.9 16.6-6.9zm-44.2-19.6c-7.2 0-13.1-5.9-13.1-13.1h26.2c0 7.2-5.9 13.1-13.1 13.1z'
          style={{
            fill: "url(#a)",
          }}
        />
        <linearGradient
          id='b'
          gradientUnits='userSpaceOnUse'
          x1={33.047}
          y1={118.32}
          x2={104.386}
          y2={21.879}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#f70",
            }}
          />
          <stop
            offset={0.742}
            style={{
              stopColor: "#ff3554",
            }}
          />
        </linearGradient>
        <path
          d='M69.9 81.1c1.3-.8 2.6-1.5 3.9-2.2h-.1c-3.3-2.5-7.2-3.7-11.1-3.7-5.6 0-11.1 2.5-14.7 7.3l-1.5 2.1 10.4 7.8c3.2-4.2 7.6-8 13.1-11.3z'
          style={{
            fill: "url(#b)",
          }}
        />
        <linearGradient
          id='c'
          gradientUnits='userSpaceOnUse'
          x1={41.521}
          y1={124.588}
          x2={112.86}
          y2={28.148}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#f70",
            }}
          />
          <stop
            offset={0.742}
            style={{
              stopColor: "#ff3554",
            }}
          />
        </linearGradient>
        <path
          d='M56.9 128.5c-3.5-4.5-5.7-9.4-6.3-14.5-.2-1.2-.3-2.4-.3-3.6 0-6 2-11.7 5.7-16.9h-1.3c-10.2 0-18.4 8.3-18.4 18.4v2.6H50c-3.6 3-5.9 7.1-6.5 11.7-.7 4.9.6 9.7 3.6 13.6l1.1 1.5c1-4 3.1-7.6 6.1-10.6.9-.9 1.7-1.6 2.6-2.2z'
          style={{
            fill: "url(#c)",
          }}
        />
        <linearGradient
          id='d'
          gradientUnits='userSpaceOnUse'
          x1={104.314}
          y1={171.038}
          x2={175.653}
          y2={74.597}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#f70",
            }}
          />
          <stop
            offset={0.742}
            style={{
              stopColor: "#ff3554",
            }}
          />
        </linearGradient>
        <path
          d='m173.6 92.4 10.4-7.8-1.5-2.1c-3.6-4.8-9.1-7.3-14.7-7.3-3.9 0-7.7 1.2-11.1 3.7h-.1c1.3.7 2.7 1.4 3.9 2.2 5.5 3.3 9.9 7.1 13.1 11.3z'
          style={{
            fill: "url(#d)",
          }}
        />
        <linearGradient
          id='e'
          gradientUnits='userSpaceOnUse'
          x1={126.463}
          y1={187.422}
          x2={197.803}
          y2={90.982}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#f70",
            }}
          />
          <stop
            offset={0.742}
            style={{
              stopColor: "#ff3554",
            }}
          />
        </linearGradient>
        <path
          d='M194.1 111.9c0-10.2-8.3-18.4-18.4-18.4h-1.3c3.8 5.2 5.7 11 5.7 16.9 0 1.2-.1 2.4-.3 3.6-.7 5.1-2.9 10-6.3 14.5.9.7 1.8 1.4 2.6 2.2 3 3 5.1 6.6 6.1 10.6l1.1-1.5c3-3.9 4.3-8.8 3.6-13.6-.6-4.6-2.9-8.8-6.5-11.7h13.7v-2.6z'
          style={{
            fill: "url(#e)",
          }}
        />
        <linearGradient
          id='f'
          gradientUnits='userSpaceOnUse'
          x1={41.092}
          y1={124.271}
          x2={112.431}
          y2={27.83}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#f70",
            }}
          />
          <stop
            offset={0.742}
            style={{
              stopColor: "#ff3554",
            }}
          />
        </linearGradient>
        <path
          d='M93.1 72.4c10.2 0 18.4-8.3 18.4-18.4 0-10.2-8.3-18.4-18.4-18.4-10.2 0-18.4 8.3-18.4 18.4-.1 10.2 8.2 18.4 18.4 18.4zm0-28.5c-1.2 1.3-2 3.1-2 5.1 0 3.9 3.2 7.1 7.1 7.1 2 0 3.8-.8 5.1-2.1v.1c0 5.6-4.5 10.1-10.1 10.1C87.5 64.1 83 59.6 83 54s4.5-10.1 10.1-10.1z'
          style={{
            fill: "url(#f)",
          }}
        />
        <linearGradient
          id='g'
          gradientUnits='userSpaceOnUse'
          x1={69.74}
          y1={145.462}
          x2={141.079}
          y2={49.022}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#f70",
            }}
          />
          <stop
            offset={0.742}
            style={{
              stopColor: "#ff3554",
            }}
          />
        </linearGradient>
        <path
          d='M137.4 72.4c10.2 0 18.4-8.3 18.4-18.4 0-10.2-8.3-18.4-18.4-18.4-10.2 0-18.4 8.3-18.4 18.4 0 10.2 8.2 18.4 18.4 18.4zm0-28.5c-1.2 1.3-2 3.1-2 5.1 0 3.9 3.2 7.1 7.1 7.1 2 0 3.8-.8 5.1-2.1v.1c0 5.6-4.5 10.1-10.1 10.1s-10.1-4.5-10.1-10.1c-.1-5.7 4.4-10.2 10-10.2z'
          style={{
            fill: "url(#g)",
          }}
        />
        <linearGradient
          id='h'
          gradientUnits='userSpaceOnUse'
          x1={71.098}
          y1={146.467}
          x2={142.437}
          y2={50.026}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#f70",
            }}
          />
          <stop
            offset={0.742}
            style={{
              stopColor: "#ff3554",
            }}
          />
        </linearGradient>
        <path
          d='M71 128.9c-10.2 0-18.4 8.2-18.4 18.4s8.2 18.4 18.4 18.4c2.1 0 4.2-.4 6.1-1.1-.2-.2-.4-.3-.5-.5-5.3-5.3-7.2-12.2-4-15.3 3.1-3.1 10-1.3 15.3 4 .2.2.3.4.5.5.7-1.9 1.1-3.9 1.1-6.1-.1-10.1-8.3-18.3-18.5-18.3z'
          style={{
            fill: "url(#h)",
          }}
        />
        <linearGradient
          id='i'
          gradientUnits='userSpaceOnUse'
          x1={128.596}
          y1={189}
          x2={199.936}
          y2={92.56}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#f70",
            }}
          />
          <stop
            offset={0.742}
            style={{
              stopColor: "#ff3554",
            }}
          />
        </linearGradient>
        <path
          d='M159.5 128.9c-10.2 0-18.4 8.2-18.4 18.4 0 2.1.4 4.2 1.1 6.1.2-.2.3-.4.5-.5 5.3-5.3 12.2-7.2 15.3-4 3.1 3.1 1.3 10-4 15.3l-.5.5c1.9.7 3.9 1.1 6.1 1.1 10.2 0 18.4-8.2 18.4-18.4-.1-10.3-8.4-18.5-18.5-18.5z'
          style={{
            fill: "url(#i)",
          }}
        />
      </svg>
    </div>
  )
};

export default Aiven
